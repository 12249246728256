import React from 'react'
import * as P from 'prop-types'
import { Input, propTypes } from '../Input'
import { ErrorLabel, InputLabel } from '../Label'

export const FormInput = ({
  autoComplete,
  disabled = false,
  error,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  type = 'text',
  value,
  defaultValue,
}) => (
  <>
    {label && <InputLabel>{label}</InputLabel>}
    <Input
      autoComplete={autoComplete}
      disabled={disabled}
      error={Boolean(error)}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
      defaultValue={defaultValue}
    />
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </>
)

FormInput.propTypes = {
  ...propTypes,
  error: P.oneOfType([P.string, P.bool, P.node]),
  label: P.node,
}
