import { handleFetching } from '@lib/fetching'
import { api } from './api'
import { actions as settingsActions } from './symbiotes/settings'

export const settingsFetch = () => handleFetching(settingsActions.fetch, {
  async run(dispatch) {
    const { data } = await api.settings.get()
    dispatch(settingsActions.set(data))
  },
})

export const settingsUpdate = (values) => async (dispatch) => {
  const { data } = await api.settings.update(values)
  dispatch(settingsActions.set(data))
}
