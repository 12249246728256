import { createSymbiote } from 'redux-symbiote'
import { createFetching, initialFetching } from '@lib/fetching'

const initialState = {
  transactionId: null,
  transaction: null,
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),
  set: (state, { transactionId, transaction }) => ({
    ...state,
    transactionId,
    transaction,
  }),

  reset: () => initialState,
}

export const { actions, reducer } = createSymbiote(
  initialState,
  symbiotes,
  'transaction',
)
