import t from 'timestamp-utils'
import { useBoolean } from '@lib/hooks'
import { range } from '@lib/array'
import { formatTime } from './utils'

export const hourOptions = range(24).map((value) => ({ value, label: formatTime(value) }))
export const minuteOptions = range(60).map((value) => ({ value, label: formatTime(value) }))

export const useTimePicker = ({ date, onChange }) => {
  const {
    val: isOpen,
    on: show,
    off: close,
  } = useBoolean()

  const hour = Number(t.getHours(date))
  const minute = Number(t.getMinutes(date))

  const changeHour = (hour) => {
    onChange(t.setHours(date, Number(hour)))
  }

  const changeMinute = (minute) => {
    onChange(t.setMinutes(date, Number(minute)))
  }

  return {
    isOpen,
    hour,
    minute,
    hourOptions,
    minuteOptions,
    show,
    close,
    changeHour,
    changeMinute,
  }
}
