import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { borderRadius } from './theme'

const selection = css`
  background-color: ${theme('color.active')};
  
  &:hover {
    background-color: ${theme('color.active')};
  }
`

const selected = css`
  ${selection};
  background-color: ${theme('color.primary')};
  color: white;
  
  &:hover {
    background-color: ${theme('color.primary')};
  }
`

export const MonthWrapper = styled.div`
  width: 280px;
  white-space: initial; // fix monthView rendering in ListItem
`

export const MonthsWrapper = styled.div`
  display: flex;
  position: relative;

  ${MonthWrapper} + ${MonthWrapper} {
    margin-left: 2rem;
  }
`

const Day = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
`

export const WeekDay = styled(Day)``

export const DayView = styled(Day)`
  cursor: pointer;

  &:hover {
    background-color: ${theme('color.lightGray')};
  }

  ${ifProp('selected', css`
    ${selection};
    border-radius: ${borderRadius};
    background-color: ${theme('color.primary')};
    color: white;

    &:hover {
      background-color: ${theme('color.primary')};
    }
  `)}

  ${ifProp('sStart', css`
    ${selected};
    border-radius: ${borderRadius} 0 0 ${borderRadius};
  `)}

  ${ifProp('sEnd', css`
    ${selected};
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
  `)}

  ${ifProp('sInside', selection)}

  ${ifProp('out', css`
    background-color: white;
    color: ${theme('color.border')};
    pointer-events: none;
  `)}
`

export const DayHeader = styled.div`
  font-weight: 600;
  border-bottom: 1px solid ${theme('color.border')};
  color: ${theme('color.gray')};
  margin-bottom: 6px;
`

export const DayBody = styled.div`
  ${DayView}:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const NavDate = styled.span`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`
