/* eslint-disable no-sequences */
import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Icon, List, ListItem, roundShadow } from '../../index'

export const SortDescListItem = ({ column, title, onClose = () => {} }) => (
  <SortListItem
    isActive={column.isSortedDesc}
    onClick={() => (column.toggleSortBy(true), onClose())}
  >
    <Icon name="sortDesc" />
    <SortText>{title}</SortText>
  </SortListItem>
)

export const SortAscListItem = ({ column, title, onClose = () => {} }) => (
  <SortListItem
    isActive={column.isSorted && !column.isSortedDesc}
    onClick={() => (column.toggleSortBy(false), onClose())}
  >
    <Icon name="sortAsc" />
    <SortText>{title}</SortText>
  </SortListItem>
)

export const FilterListTemplate = ({ children, className, style }) => (
  <Content className={className} style={style}>
    <FilterList>
      {children}
    </FilterList>
  </Content>
)

export const FooterRightListItem = styled(ListItem)`
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem 0;
`

export const FooterListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 1rem;
`

const Content = styled.div`
  background-color: white;
  border: 1px solid ${theme('color.border')};
  border-radius: 3px;
  padding: 12px 0 0;

  ${roundShadow};
`

const FilterList = styled(List)`
  border: none;
`

export const FormListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  padding: 10px 1rem;
`

const SortListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
`

const SortText = styled.span`
  margin-left: 8px;
`
