import { createSelector } from 'reselect'
import { toSelectItems } from '@lib/convert'
import { $accounts } from '../selectors/quickbooks-entities'

export const createAccountOptions = (types = null) => createSelector(
  $accounts,
  (accounts) => {
    const finalAccounts = types
      ? accounts.filter((account) => types.includes(account.type))
      : accounts
    return toSelectItems(finalAccounts, 'name', 'id')
  },
)
