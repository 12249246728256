import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { theme } from 'styled-tools'
import { MainTemplate } from '@features/common'
import { MassActionModalButton, useResyncAll } from '@features/transactions'
import { Col, Row } from '@lib/styled-layout'
import { Heading, Spinner, Button, Flex, H2 } from '@ui'
import {
  $importedAmount,
  $integrationsReport,
  $isLoading,
  $statusesReport,
  $typesChartData,
} from '../selectors/statistics'
import { useModel, useTransactionsTable } from './dashboard.model'
import { IntegrationReport } from '../components/IntegrationReport'
import { StatusReport } from '../components/StatusReport'
import { TransactionsChart } from '../components/TransactionsChart'
import { TransactionsTable } from '../components/TransactionsTable'
import { DatePicker } from '../components/DatePicker'
import { Section } from '../components/Section'
import { NoDataFound } from '../components/NoDataFound'
import { afterResync } from '../effects'

export const DashboardPage = () => {
  useModel()

  return (
    <MainTemplate heading={<Heading>Dashboard</Heading>}>
      <Row>
        <Col md={6}>
          <TransactionsSummary />
        </Col>
        <Col md={6}>
          <OverallStatus />
        </Col>
      </Row>
      <ImportExportTransactions />
      <RecentTransactions />
    </MainTemplate>
  )
}

const TransactionsSummary = () => {
  const isLoading = useSelector($isLoading)
  const data = useSelector($typesChartData)
  const total = useSelector($importedAmount)

  return (
    <Section>
      <Section.Header>
        <H2>Transaction Summary</H2>
        <DatePicker />
      </Section.Header>
      <Divider />
      <Section.Body>
        {isLoading ? (
          <SectionSpinner />
        ) : data.length > 0 ? (
          <TransactionsChart
            data={data}
            total={total}
          />
        ) : <NoDataFound />}
      </Section.Body>
    </Section>
  )
}

const OverallStatus = () => {
  const isLoading = useSelector($isLoading)
  const data = useSelector($statusesReport)

  const dispatch = useDispatch()
  const resyncAll = useResyncAll({
    onDone: () => dispatch(afterResync()),
  })

  return (
    <Section>
      <Section.Header>
        <H2>Overall Status</H2>
      </Section.Header>
      <Divider />
      <Section.Body>
        {isLoading ? (
          <SectionSpinner />
        ) : data.length > 0 ? (
          <Flex direction="column" gapY="16px">
            {data.map((report, index) => (
              <StatusReport key={index} {...report} />
            ))}
          </Flex>
        ) : <NoDataFound />}
        <Flex gap="16px" mt="54px">
          <Button as={RouterLink} to="/transactions" size="full" variant="outline">VIEW ALL</Button>
          <MassActionModalButton
            size="full"
            variant="primary"
            config={resyncAll.config}
            active={resyncAll.active}
            handler={resyncAll.handler}
          />
        </Flex>
      </Section.Body>
    </Section>
  )
}

const ImportExportTransactions = () => {
  const isLoading = useSelector($isLoading)
  const data = useSelector($integrationsReport)

  return (
    <Section>
      <Section.Header>
        <H2>Import & Export Transactions</H2>
      </Section.Header>
      {isLoading ? (
        <SectionSpinner />
      ) : data.length > 0 ? (
        <ReportsRow>
          {data.map((report, index) => (
            <Col md={6} xl={4} key={index}>
              <IntegrationReport {...report} />
            </Col>
          ))}
        </ReportsRow>
      ) : <NoDataFound />}
    </Section>
  )
}

const RecentTransactions = () => (
  <div>
    <H2 mb="24px">Last Transactions</H2>
    <TransactionsTable
      table={useTransactionsTable()}
    />
  </div>
)

const SectionSpinner = styled(Spinner)`
  margin: 100px 0;
`

const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid ${theme('color.border')};
`

const ReportsRow = styled(Row)`
  & > ${Col} {
    padding: 20px 10px 0;
  }
`
