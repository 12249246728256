import React from 'react'
import * as P from 'prop-types'
import { IconButton, propTypes } from './IconButton'
import { InlineSpinner } from '../Spinner'

export const LoadingButton = ({ disabled, loading, variant, size, onClick, type, children }) => (
  <IconButton
    disabled={disabled || loading}
    variant={variant}
    onClick={onClick}
    size={size}
    type={type}
    icon={loading && (
      <InlineSpinner
        variant={['primary'].includes(variant) ? 'white' : 'secondary'}
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}
  >
    {children}
  </IconButton>
)

LoadingButton.propTypes = {
  ...propTypes,
  loading: P.bool,
  type: P.string,
  onClick: P.func,
  children: P.node,
}

LoadingButton.defaultProps = {
  variant: 'primary',
}
