/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Alert } from '@ui'
import { $integrationName } from '@features/session'
import { $isIntegrationExpired, $incompleteProcessors } from '@features/integrations'
import { $currentUser, $userSubscription } from '../selectors'

export const AccountAlerts = () => {
  const location = useLocation()
  const user = useSelector($currentUser)
  const subscription = useSelector($userSubscription)
  const integrationExpired = useSelector($isIntegrationExpired)
  const integrationName = useSelector($integrationName)
  const incompleteProcessors = useSelector($incompleteProcessors)
  const userPlanInvalid = (dayjs().isAfter(dayjs(subscription.valid_to)) || (user.status !== 'active'))

  const alerts = useMemo(
    () => userPlanInvalid ? getAlertsForInvalidPlan(user) : [],
    [user, userPlanInvalid],
  )

  const visibleIncompleteProcessors = incompleteProcessors.filter(
    (processor) => location.pathname !== processor.link,
  )

  return (
    <>
      {user.has_integration && integrationExpired && (
        <Alert type="danger">
          <strong>The connection with {integrationName} has expired</strong>{' '}
          Please <Link to="/integration/reconnect">re-connect your {integrationName} account</Link>{' '}
          to synchronize your transactions.
        </Alert>
      )}
      {alerts.map((Alert, index) => (
        <Alert key={index} />
      ))}
      {user.masquerade && (
        <Alert type="info">
          You are currently impersonating another user. To go back to the admin menu, <Link to="/admin">click here</Link>.
        </Alert>
      )}
      {visibleIncompleteProcessors.map((processor, index) => (
        <Alert type="warning" key={index}>
          Your {processor.source} integration still needs to be configured! <Link to={processor.link}>Click here</Link> to get started.
        </Alert>
      ))}
    </>
  )
}

const getAlertsForInvalidPlan = (user) => {
  const alerts = []

  if (user.status === 'active') {
    alerts.push(
      () => <Alert type="info">
        We were unable to automatically charge your card. Please <Link to="/settings?tab=payment">update your payment details</Link> as soon as possible.
      </Alert>,
    )
  } else if (user.status === 'hold') {
    alerts.push(
      () => <Alert type="warning">
        Your account is currently on hold for non-payment. Please <Link to="/settings?tab=payment">update your payment details</Link> as soon as possible.
      </Alert>,
    )
  } else if (user.status === 'exhausted') {
    alerts.push(
      () => <Alert type="danger">
        Your account was cancelled for exceeding the plan's transaction limit.
      </Alert>,
    )
  }  else if (user.status === 'delinquent') {
    alerts.push(
      () => <Alert type="danger">
        Your account was cancelled for non-payment.
      </Alert>,
    )
  } else {
    alerts.push(
      () => <Alert type="danger">
        Your account is cancelled.
      </Alert>,
    )
  }

  return alerts
}
