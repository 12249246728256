import React from 'react'
import { Empty } from '@ui'
import socket from '../../../assets/images/socket.svg'
import piggyBank from '../../../assets/images/piggy-bank.svg'

export const NoIntegrations = () => (
  <Empty
    img={socket}
    description={<>
      At this moment you have no<br />
      connected integrations
    </>}
  />
)

export const NoProcessors = () => (
  <Empty
    img={piggyBank}
    description={<>
      At this moment you have no<br />
      payments integrations
    </>}
  />
)
