import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { createSelector } from 'reselect'
import { fetchStatus } from '@lib/fetching'
import { importJobStatuses } from '@lib/constants'

dayjs.extend(utc)

const $user = (root) => root.user

export const $authUserId = createSelector(
  $user,
  (user) => user.model && user.model.id,
)

export const $currentUser = createSelector(
  $user,
  (user) => user.model,
)

export const $userDatePrefs = createSelector(
  $currentUser,
  (user) => {
    const dateformat = user.date_format || 'MM/DD/YYYY'
    return {
      timezone: user.timezone,
      dateformat,
      dateTimeFormat: `${dateformat} h:mm a`,
    }
  },
)

export const $userActive = createSelector(
  $currentUser,
  (user) => user.status === 'active',
)

export const $userSubscription = createSelector(
  $user,
  (user) => user.subscription,
)

export const $currentPlanName = createSelector(
  $userSubscription,
  (sub) => sub.plan_name,
)

export const $userFetching = createSelector(
  $user,
  (user) => user.fetching,
)

export const $userLoading = createSelector(
  $userFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)

export const $hasPendingJobs = createSelector(
  (root) => root.importJobs.active.list,
  (jobs) => jobs.some((job) => job.status === importJobStatuses.IN_PROGRESS),
)
