import { useCallback, useState } from 'react'
import { createSelector } from 'reselect'
import { useLatest, useDidUnmount } from '@lib/hooks'

export const fetchStatus = {
  failed: 'failed',
  initial: 'initial',
  loading: 'loading',
  ready: 'ready',
}

const defaultReducer = (state, payload) => ({
  ...state,
  fetching: payload,
})

/**
 * Creates fetching object
 *
 * @param {function} reducer overrides default reducer.
 */
export const createFetching = (reducer = defaultReducer) => ({
  /**
   * Executes on request start
   */
  start: (state) => reducer(state, { status: fetchStatus.loading, error: null }),

  /**
   * Executes on request finish
   */
  finish: (state) => reducer(state, { status: fetchStatus.ready, error: null }),

  /**
   * Executes on request failure
   */
  fail: (state, error) => reducer(state, { status: fetchStatus.failed, error }),
})

/**
 * @typedef {Object} Action
 * @prop {string} type
 */

export const initialFetching = {
  status: fetchStatus.initial,
  error: null,
}

/**
 * Used to fetch data from api and track fetching status, response or error in redux store.
 *
 * @param {{ start: Function, finish: Function, fail: Function }} actions
 * @param {{ before?: Function, run: Function, fail?: Function, noThrow?: boolean, prepareError?: Function }} fetcherObject
 * @example
 * export const fetchData = (id) => handleFetching(actions.fetching, {
 *   noThrow: true,
 *   prepareError: (error) => error.message,
 *   async before(dispatch) {
 *     return await dispatch(someEffect())
 *   },
 *   async run(dispatch, getState, { api }) {
 *     const result = await api.get(`/data/${id}`)
 *
 *     dispatch(actions.setData(result.data))
 *   },
 * })
 */
export const handleFetching = (actions, fetcherObject) => async (
  dispatch,
  getState,
) => {
  let beforeResult

  dispatch(actions.start())

  if (fetcherObject.before) {
    beforeResult = await fetcherObject.before(dispatch, getState)
  }

  try {
    const result = await fetcherObject.run(
      dispatch,
      getState,
      beforeResult,
    )

    dispatch(actions.finish())
    return result
  } catch (error) {
    if (fetcherObject.fail) {
      fetcherObject.fail(error, dispatch, getState, beforeResult)
    }

    const errorObj = fetcherObject.prepareError
      ? fetcherObject.prepareError(error)
      : error

    dispatch(actions.fail(errorObj))

    if (fetcherObject.noThrow) {
      return undefined
    }

    throw error
  }
}

export const createFetchingSelectors = (selector) => {
  const isStatus = (targetStatus) => ({ status }) => status === targetStatus

  const $isInitial = createSelector(selector, isStatus(fetchStatus.initial))
  const $isLoading = createSelector(selector, isStatus(fetchStatus.loading))
  const $isFailed = createSelector(selector, isStatus(fetchStatus.failed))
  const $isReady = createSelector(selector, isStatus(fetchStatus.ready))

  return {
    $isInitial,
    $isLoading,
    $isFailed,
    $isReady,
  }
}

/**
 * Used to fetch data from api and track fetching status, response or error inside component.
 * @param {function} handler - request handler
 * @param {{ initialData, prepareError }} params
 * @example
 * const request = () => useRequest(api.fetchUser)
 * const user = await request()
 * const { isLoading, error, data } = request
 */
export const useRequest = (handler, {
  initialData = null,
  prepareError = mapApiError,
  done = () => {},
  fail = () => {},
} = {}) => {
  const didUnmount = useDidUnmount()
  const handlerRef = useLatest(handler)
  const latestRef = useLatest(done)
  const failRef = useLatest(fail)

  const [data, setData] = useState(initialData)
  const [status, setStatus] = useState(fetchStatus.initial)
  const [error, setError] = useState(false)

  const request = useCallback(async (...params) => {
    setStatus(fetchStatus.loading)
    setError(false)

    try {
      const result = await handlerRef.current(...params)
      latestRef.current({ params, result })

      // prevent updating state on unmount
      if (didUnmount.current) {
        return result
      }

      setData(result)
      setStatus(fetchStatus.ready)
      return result
    } catch (err) {
      const errorObj = prepareError
        ? prepareError(err)
        : err

      setError(errorObj)
      setStatus(fetchStatus.failed)
      failRef.current(errorObj)
      throw err
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetData = useCallback(() => {
    setData(initialData)
  }, [initialData])

  request.error = error
  request.data = data
  request.setData = setData
  request.resetData = resetData
  request.isLoading = status === fetchStatus.loading
  request.isReady = status === fetchStatus.ready

  return request
}

const mapApiError = (error) => error?.response?.data
