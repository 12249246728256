import React, { useMemo, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'
import { Col, Row } from '@lib/styled-layout'
import { useStripe } from '@lib/stripe'
import { theme as libTheme } from '@lib/theme'
import { Button, ErrorLabel, FormGroup, Input, InputLabel, LoadingButton, Modal } from '@ui'

export const CreateCardModal = ({ show, onSubmit, onClose }) => {
  const stripe = useStripe()
  const {
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
  } = useStripeForm({
    async onSubmit({ setSubmitting }) {
      const { paymentMethod, error } = await stripe.createPaymentMethod('card')
      setSubmitting(false)

      if (!error) {
        onSubmit(paymentMethod.id)
        onClose()
      }
    },
  })

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <Modal.Title>Add a new payment method</Modal.Title>
      <Modal.Body>
        <CreateCardForm
          errors={errors}
          handleChange={handleChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
        <LoadingButton size="md" onClick={handleSubmit} loading={isSubmitting}>CREATE</LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

const CreateCardForm = ({ errors, handleChange }) => (
  <>
    <InputStyles />
    <FormGroup>
      <InputLabel>Card number</InputLabel>
      <CardNumberElement onChange={handleChange} style={inputStyle} />
      {errors.cardNumber && <ErrorLabel>{errors.cardNumber}</ErrorLabel>}
    </FormGroup>
    <FormGroup>
      <Row>
        <Col md={8}>
          <InputLabel>Expiration</InputLabel>
          <CardExpiryElement onChange={handleChange} style={inputStyle} />
          {errors.cardExpiry && <ErrorLabel>{errors.cardExpiry}</ErrorLabel>}
        </Col>
        <Col md={4}>
          <InputLabel>CVC</InputLabel>
          <CardCVCElement onChange={handleChange} style={inputStyle} />
          {errors.cardCvc && <ErrorLabel>{errors.cardCvc}</ErrorLabel>}
        </Col>
      </Row>
    </FormGroup>
  </>
)

const InputStyles = createGlobalStyle`
  .StripeElement {
    ${Input.baseCss};

    &--focus {
      border-color: ${theme('color.gray')};
    }

    &--invalid {
      border-color: ${theme('color.danger')};
    }
  }
`

const inputStyle = {
  base: {
    '::placeholder': {
      color: libTheme.color.gray,
    },
  },
}

const useStripeForm = ({ onSubmit }) => {
  const [errors, setErrors] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const isValid = useMemo(() => Object.values(errors).every(Boolean), [errors])

  const setError = (name, error) => {
    setErrors((errors) => ({
      ...errors,
      [name]: error,
    }))
  }

  const handleChange = (event) => {
    if (event.error) {
      setError(event.elementType, event.error.message)
    }
    if (event.complete) {
      setError(event.elementType, null)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmitting(true)
    onSubmit({ setSubmitting })
  }

  return {
    errors,
    isSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  }
}
