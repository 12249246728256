import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

export const GlobalStyles = createGlobalStyle`  
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    -webkit-font-smoothing: antialiased;
    color: ${theme('color.dark')};
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  p, b, a, span, label {
    font-size: 13px;
  }
  
  p {
    line-height: 1.55;
    margin: 0 !important;
  }
  
  *, :after, :before {
    box-sizing: border-box;
  }

  // default weight was 700
  th {
    font-weight: 600;
  }
`
