import { nanoid } from 'nanoid'

export const createFileDownload = (fileType) => (data) => {
  const a = document.createElement('a')
  const bomCode = '%EF%BB%BF'
  a.textContent = 'download'
  a.download = `${nanoid()}.${fileType}`
  a.href = `data:text/${fileType};charset=utf-8,${bomCode}${encodeURIComponent(data)}`
  a.click()
}

export const createCsvDownload = createFileDownload('csv')
