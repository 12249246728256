export const qboAccountTypes = {
  // ASSET
  BANK: 'Bank',
  OTHER_CURRENT_ASSET: 'Other Current Asset',
  FIXED_ASSET: 'Fixed Asset',
  OTHER_ASSET: 'Other Asset',
  ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  // EQUITY
  EQUITY: 'Equity',
  // EXPENSE
  EXPENSE: 'Expense',
  OTHER_EXPENSE: 'Other Expense',
  COST_OF_GOODS_SOLD: 'Cost of Goods Sold',
  // LIABILITY
  ACCOUNTS_PAYABLE: 'Accounts Payable',
  CREDIT_CARD: 'Credit Card',
  LONG_TERM_LIABILITY: 'Long Term Liability',
  OTHER_CURRENT_LIABILITY: 'Other Current Liability',
  // REVENUE
  INCOME: 'Income',
  OTHER_INCOME: 'Other Income',
}

export const qboEntityTypes = {
  CUSTOMER: 'Customer',
  PAYMENT: 'Payment',
  PURCHASE: 'Purchase',
  PRODUCT: 'Item',
  SALES_RECEIPT: 'SalesReceipt',
  REFUND_RECEIPT: 'RefundReceipt',
  TRANSFER: 'Transfer',
  ACCOUNT: 'Account',
  TAX_CODE: 'TaxCode',
  VENDOR: 'Vendor',
  PAYMENT_METHOD: 'PaymentMethod',
  PREFERENCES: 'Preferences',
  ITEM_TYPE: 'ItemType',
  TAX_RATE: 'TaxRate',
}
