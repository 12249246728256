import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Router, Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Setup } from '@features/setup'
import { $currentUser } from '@features/common'
import { history } from '@lib/routing'
import { Routes } from './routes'
import { GlobalStyles } from './global-styles'

import 'overlayscrollbars/css/OverlayScrollbars.css'
import './assets/css/animate.css'
import './assets/css/app.css'

export const App = hot(() => {
  const user = useSelector($currentUser)

  const exitFromImpersonate = !!user.masquerade && history.location.pathname === '/admin'

  if (!user.setup_complete && !exitFromImpersonate) {
    return (<Setup />)
  }

  return (
    <>
      <GlobalStyles />
      <Router history={history}>
        <Routes />
        <Route
          exact
          path="/transaction/:id"
          render={(props) => (
            <Redirect to={`/transactions/${props.match.params.id}`} />
          )}
        />
      </Router>
    </>
  )
})
