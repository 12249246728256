import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { Link as RouterLink } from 'react-router-dom'
import { DoughnutChart, Link, Text } from '@ui'
import { humanizeType } from '@lib/constants'

export const TransactionsChart = ({ data, total }) => (
  <Container>
    <ChartWrap>
      <DoughnutChart
        data={data}
        title={total}
      />
    </ChartWrap>
    <Legend>
      {data.map(({ amount, color, name }, index) => (
        <LegendItem color={color} key={index}>
          <Amount>{amount}</Amount>
          <p>
            of <Link as={RouterLink} to={`/transactions?payment_type[]=${name}`}>{humanizeType(name)}</Link>
          </p>
        </LegendItem>
      ))}
    </Legend>
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const ChartWrap = styled.div`
  max-width: 300px;
`

const Legend = styled.ul`
  padding: 0;
  margin: 0 0 0 20px;
`

const LegendItem = styled.li`
  position: relative;
  list-style: none;
  margin-bottom: 30px;
  padding-left: 16px;

  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${prop('color')};
    border-radius: 50%;
    left: 0;
    top: 6px;
  }
`

const Amount = styled(Text)`
  font-weight: 600;
  font-size: 18px;
`
