import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as P from 'prop-types'
import { useOpened } from '@lib/hooks'
import { IconLink } from './Link'
import { Chevron } from './TextIcons'

export const Collapse = ({ label, children, opened: openedProp = false }) => {
  const {
    opened,
    toggle,
    set,
  } = useOpened(openedProp)

  useEffect(() => {
    set(openedProp)
  }, [openedProp, set])

  return (
    <>
      <Header>
        <IconLink bold={opened} onClick={toggle}>
          {label}
          <Chevron bold={opened} position={opened ? 'bottom' : 'right'} />
        </IconLink>
      </Header>
      {opened && children}
    </>
  )
}

Collapse.propTypes = {
  children: P.node,
  label: P.string.isRequired,
  opened: P.bool,
}

const Header = styled.div`
  margin-bottom: 12px;
`

