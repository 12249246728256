import { createSelector } from 'reselect'
import { $userDatePrefs } from '@features/common/selectors'
import { date } from '@lib/date'
import { $integrationSettings, $isIntegrationExpired } from './integration'

export const $integrationsList = createSelector(
  $integrationSettings,
  $isIntegrationExpired,
  $userDatePrefs,
  (integration, isIntegrationExpired, { dateformat, timezone }) => {
    if (!integration) return []
    return [{
      info: {
        sourceName: integration.source,
        name: integration.org_name,
        status: isIntegrationExpired ? 'disconnected' : 'active',
        createdAt: date(integration.created_at, timezone, dateformat),
      },
      settings: integration,
    }]
  },
)
