import React, { useEffect } from 'react'
import { Elements, StripeProvider, useStripe } from '@lib/stripe'
import { useQueryParams } from '@lib/hooks'
import { useRequest } from '@lib/fetching'
import { ApiError, MainTemplate } from '@features/common'
import { Card, Spinner, H2, H3 } from '@ui'
import { Storage } from '@lib/storage'
import { api } from '../api'

export const StripeCheckoutPage = () => {
  const { plan } = useQueryParams()
  const paramsIsValid = !!plan

  const startCheckoutRequest = useRequest(() => api.startCheckout(plan, Storage.get('from_shopify') ? 'shopify': 'stripe'), {
    initialData: { sessionId: null, redirectUrl: null },
  })

  const {
    isLoading,
    isReady,
    error,
    data: { sessionId, redirectUrl },
  } = startCheckoutRequest

  useEffect(() => {
    if (paramsIsValid) {
      startCheckoutRequest()
    }
  }, [paramsIsValid, startCheckoutRequest])

  return (
    <MainTemplate>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_API_KEY}>
        <Elements>
          {paramsIsValid || <MissingParamsError />}
          {isLoading && <Loading />}
          {isReady && <Redirector sessionId={sessionId} redirectUrl={redirectUrl} />}
          {error && <ApiError message={error.error} />}
        </Elements>
      </StripeProvider>
    </MainTemplate>
  )
}

const Redirector = ({ sessionId, redirectUrl }) => {
  const stripe = useStripe()

  useEffect(() => {
    if (sessionId) {
      stripe.redirectToCheckout({
        sessionId,
      }).then((result) => {
        if (result.error) {
          alert(result.error.message)
        }
      })
    }

    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  })

  return null
}

const Loading = () => (
  <div>
    <H3>Redirecting you to checkout, please wait...</H3>
    <Spinner />
  </div>
)

const MissingParamsError = () => (
  <Card mb="1rem">
    <H2>The following params are missing in query: plan</H2>
  </Card>
)
