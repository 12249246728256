import React from 'react'
import * as P from 'prop-types'
import { getDashArray, getRadius, getTotalLength } from './utils'
import { AnimatedCircle, Container, CenteredText } from './styled'
import { Lines } from './Lines'
import { Svg } from './Svg'
import { dataPT } from './prop-types'

export const DoughnutChart = ({ data, title }) => {
  const border = 4
  const size = 42
  const center = size / 2
  const radius = getRadius()
  const growRatio = 1.5
  const borderGrowRatio = 1.1

  const values = data.map(({ value }) => Number(value))
  const getOffset = (index) => -getTotalLength(values, index)

  return (
    <Container>
      <Svg size={size}>
        <g>
          <CenteredText>
            {title}
          </CenteredText>
          {data.map(({ color, value, label }, index) => {
            value = Number(value)

            const props = {
              cx: center,
              cy: center,
              r: radius,
              transform: `rotate(-90 ${center} ${center})`,
            }

            // dash ratios are based on "value"
            // keep them greater than 1 (dashRation), 3 (innerDashRatio)
            // less values causes bad render result for circles in the chart
            const innerDashRatio = Math.max(3, value)
            const dashRatio = Math.max(1, value - growRatio)

            return [
              <AnimatedCircle
                {...props}
                key={index}
                stroke="white"
                strokeWidth={border * borderGrowRatio}
                strokeDasharray={getDashArray(innerDashRatio)}
                strokeDashoffset={getOffset(index)}
              />,
              <AnimatedCircle
                {...props}
                key={`${index}#`}
                stroke={color}
                strokeWidth={border}
                strokeDasharray={getDashArray(dashRatio)}
                strokeDashoffset={getOffset(index) - growRatio / 2}
              />,
            ]
          }).reverse()}
        </g>
        <g>
          <Lines
            center={center}
            count={48}
            lineWidth={0.3}
            lineHeight={0.8}
          />
        </g>
      </Svg>
    </Container>
  )
}

DoughnutChart.propTypes = {
  data: dataPT.isRequired,
  children: P.node,
}
