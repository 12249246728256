import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { useActions } from '@lib/hooks'
import { DateTimeRangePicker, InputLabel, LoadingButton, Select, Box } from '@ui'
import { importJobCreate } from '../effects'
import {
  $currentImport,
  $processorOptionsForConnectedSources,
  $sourcesOptionsByProcessor,
} from '../selectors/current-import'
import { currentImportActions } from '../symbiotes'

export const CreateImportForm = () => {
  const dispatch = useDispatch()
  const processorOptions = useSelector($processorOptionsForConnectedSources)
  const sourcesOptionsByProcessor = useSelector($sourcesOptionsByProcessor)

  const { startDate, endDate, connection, processor } = useSelector($currentImport)
  const { setDates, setConnection, setProcessor } = useActions(currentImportActions)

  const createImportRequest = useRequest(() => dispatch(importJobCreate()))

  const formInvalid = !startDate || !endDate || !connection
  const searchDisabled = createImportRequest.isLoading || formInvalid

  // update selected connection when the processor is changed
  useEffect(() => {
    const opts = sourcesOptionsByProcessor
    setConnection(opts.length > 0 ? opts[0].value : null)
  }, [setConnection, sourcesOptionsByProcessor])

  return (
    <>
      <Box title="Search options transactions">
        Select your payment processor, account and period
      </Box>
      <FormContainer>
        <FormControl>
          <InputLabel>Processor</InputLabel>
          <Select
            options={processorOptions}
            value={processor}
            onChange={setProcessor}
          />
        </FormControl>
        <FormControl>
          <InputLabel>Account</InputLabel>
          <Select
            placeholder="Select an account..."
            options={sourcesOptionsByProcessor}
            value={connection}
            onChange={setConnection}
          />
        </FormControl>
        <FormControl>
          <InputLabel>Period</InputLabel>
          <DateTimeRangePicker
            from={startDate}
            to={endDate}
            position="bottom"
            side="right"
            onChange={setDates}
          />
        </FormControl>
        <div>
          <LoadingButton
            size="lg"
            onClick={createImportRequest}
            disabled={searchDisabled}
            loading={createImportRequest.isLoading}
          >
            Search
          </LoadingButton>
        </div>
      </FormContainer>
    </>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 32px;
  margin-bottom: 65px;
`

const FormControl = styled.div`
  flex: 1;
  margin-right: 16px;
`
