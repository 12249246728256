export const getDashArray = (val) => `${val} ${100 - val}`

export const getRadius = (base = 2) => 100 / (base * Math.PI)

export const getTotalLength = (data, index) =>
  data.reduce((total, cur, i) =>
    i < index
      ? total + cur
      : total,
  0)
