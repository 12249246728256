import styled from 'styled-components'
import { ifProp, switchProp, theme } from 'styled-tools'
import { Link as UILink } from '../index'

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme('order.modal')};
`

export const Content = styled.div`
  background-color: ${ifProp('transparent', 'transparent', 'white')};
  border-radius: 3px;

  position: relative;
  margin: auto;
  padding: 2.5rem;
  
  width: ${switchProp('size', {
    sm: '467px',
    md: '592px',
    lg: '660px',
  })};
`

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`

export const Title = styled.div`
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
`

export const Body = styled.div`
  margin-bottom: 2rem;
`

export const Text = styled.span`
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 0;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
`

export const Link = styled(UILink)`
  font-size: 16px;
  line-height: 1.7;
`
