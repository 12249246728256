import { StripeCheckoutPage } from './pages/checkout'
import { SubscriptionCallbackPage } from './pages/subscription-callback'
import { ShopifySubscriptionCallbackPage } from './pages/shopify-subscription-callback'

export const stripeRoutes = () => [
  {
    path: '/checkout',
    component: StripeCheckoutPage,
  },
  {
    path: '/stripe/subscription',
    component: SubscriptionCallbackPage,
  },
  {
    path: '/shopify/checkout',
    component: ShopifySubscriptionCallbackPage,
  },
]
