import React from 'react'
import { useSelector } from 'react-redux'
import { H2, SelectField } from '@ui'
import { $integrationName } from '@features/session'
import { InputTemplate } from './shared'
import { useAccountPlaceholder } from '../../hooks'
import { $isIntegrationExpired } from '../../selectors/integration'

export const BankAccount = ({ options }) => {
  const integrationName = useSelector($integrationName)
  const accountPlaceholder = useAccountPlaceholder()
  const isIntegrationExpired = useSelector($isIntegrationExpired)

  return (
    <>
      <H2 mb="12px">Bank account in {integrationName}</H2>
      <InputTemplate>
        <SelectField
          name="bankfeeds_account"
          label="Account Name"
          placeholder={accountPlaceholder}
          options={options}
          disabled={isIntegrationExpired}
        />
      </InputTemplate>
    </>
  )
}
