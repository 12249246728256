export { qboAccountTypes, qboEntityTypes } from './quickbooks'

export const types = {
  CHARGE: 'charge',
  FEE: 'fee',
  REFUND: 'refund',
  FEE_REFUND: 'fee_refund',
  DISPUTE_ADJUSTMENT: 'dispute_adjustment',
  ADJUSTMENT: 'adjustment',
  PAYOUT: 'payout',
  APP_FEE: 'app_fee',
}

export const transactionTypes = Object.values(types)

export const humanizeType = (type) => {
  switch (type) {
    case types.CHARGE:
      return 'Sales'
    case types.REFUND:
      return 'Refunds'
    case types.FEE:
      return 'Fees'
    case types.PAYOUT:
      return 'Payouts'
    case types.FEE_REFUND:
      return 'Refund Fees'
    case types.DISPUTE_ADJUSTMENT:
      return 'Disputes'
    case types.ADJUSTMENT:
      return 'Adjustment'
    case types.APP_FEE:
      return 'Application Fees'
    default:
      return type
  }
}

export const typesOptions = transactionTypes.map((value) => ({
  label: humanizeType(value),
  value,
}))

export const sources = {
  STRIPE: 'stripe',
  GOCARDLESS: 'gocardless',
  PINPAYMENTS: 'pinpayments',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
}

// alias for sources
export const processors = sources

export const humanizeSource = (source) => {
  switch (source) {
    case sources.STRIPE:
      return 'Stripe'
    case sources.GOCARDLESS:
      return 'GoCardless'
    case sources.PINPAYMENTS:
      return 'Pin Payments'
    case sources.SHOPIFY:
      return 'Shopify'
    case sources.WOOCOMMERCE:
      return 'WooCommerce'
    default:
      return source
  }
}

export const sourcesOptions = Object.values(sources).map((value) => ({
  label: humanizeSource(value),
  value,
}))

// alias for sourcesOptions
export const processorOptions = sourcesOptions

export const statuses = {
  SKIPPED: 'skipped',
  QUEUED: 'queued',
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  FAILED: 'failed',
  IMPORTING: 'importing',
  DELETED: 'deleted',
}

export const humanizeStatus = (status) => {
  switch (status) {
    case statuses.SKIPPED:
      return 'SKIPPED'
    case statuses.QUEUED:
      return 'IN QUEUE'
    case statuses.IN_PROGRESS:
      return 'IN PROGRESS'
    case statuses.DONE:
      return 'DONE'
    case statuses.FAILED:
      return 'FAILED'
    case statuses.IMPORTING:
      return 'IMPORTING'
    case statuses.DELETED:
      return 'DELETED'
    default:
      return status
  }
}

export const importJobStatuses = {
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  FAILED: 'failed',
  CANCELED: 'canceled',
}

export const humanizeImportJobStatus = (status) => {
  switch (status) {
    case importJobStatuses.IN_PROGRESS:
      return 'IN PROGRESS'
    case importJobStatuses.DONE:
      return 'DONE'
    case importJobStatuses.FAILED:
      return 'FAILED'
    case importJobStatuses.CANCELED:
      return 'CANCELED'
    default:
      return status
  }
}

export const pageSizes = [25, 50, 100, 200]

export const exportStatuses = {
  QUEUED: 'queued',
  SYNCING: 'syncing',
  SYNCED: 'synced',
  NOT_SYNCED: 'not_synced',
  FAILED: 'failed',
}

export const humanizeExportStatus = (status) => {
  switch (status) {
    case exportStatuses.QUEUED:
      return 'QUEUED'
    case exportStatuses.SYNCING:
      return 'SYNCING'
    case exportStatuses.SYNCED:
      return 'SYNCED'
    case exportStatuses.NOT_SYNCED:
      return 'NOT SYNCED'
    case exportStatuses.FAILED:
      return 'FAILED'
    default:
      return status
  }
}

export const exportStatusesOptions = Object.values(exportStatuses).map((value) => ({
  label: humanizeExportStatus(value),
  value,
}))

export const operators = {
  EQ: 'eq',
  LT: 'lt',
  LTE: 'lte',
  GT: 'gt',
  GTE: 'gte',
}

export const humanizeOperator = (operator) => {
  switch (operator) {
    case operators.EQ:
      return 'Equal To'
    case operators.LT:
      return 'Less Than'
    case operators.GT:
      return 'Greater Than'
    case operators.LTE:
      return 'Less Than or Equal To'
    case operators.GTE:
      return 'Greater Than or Equal To'
    default:
      return operator
  }
}

export const operatorsOptions = Object.values(operators).map((value) => ({
  label: humanizeOperator(value),
  value,
}))

/*
  Retrieved from database using query:
  select currency, count(id) as count
  from transactions
  group by currency
  order by count desc;
 */
export const currencies = [
  'gbp',
  'aud',
  'usd',
  'eur',
  'hkd',
  'cad',
  'nzd',
  'sgd',
  'chf',
  'jpy',
  'dkk',
  'nok',
  'sek',
]

export const currenciesOptions = [...currencies.sort()].map((currency) => ({
  label: currency.toUpperCase(),
  value: currency,
}))

export const terms = {
  CUSTOM: 'custom',
}

export const importStatsTypes = {
  AMOUNT: 'amount',
  DATE: 'date',
}

export const termsOptions = [
  { label: 'This month', value: 'this_month' },
  { label: 'Last Month', value: 'last_month' },
  { label: 'Last Quarter', value: 'last_3_months' },
  { label: 'Last Year', value: 'last_year' },
  { label: 'YTD', value: 'ytd' },
]
