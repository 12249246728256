import { useSelector } from 'react-redux'
import { $integrationName } from '@features/session'
import { $processorsSettings } from './selectors/sources'
import { $isIntegrationExpired } from './selectors/integration'

export const useProcessorSettings = (processorId) => {
  const processorsSettings = useSelector($processorsSettings)
  const {
    processor, settings,
  } = processorsSettings.find((p) => p.id === Number(processorId)) || {}

  return {
    processor,
    settings,
  }
}

export const useAccountPlaceholder = (placeholder = 'Select an account...') => {
  const isExpired = useSelector($isIntegrationExpired)
  const integrationName = useSelector($integrationName)
  return isExpired ? `No ${integrationName} connection` : placeholder
}
