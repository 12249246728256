import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useActions } from '@lib/hooks'
import { Col, Row } from '@lib/styled-layout'
import { typesOptions } from '@lib/constants'
import { Flex, FormCheck, H4 } from '@ui'
import { $integrationName } from '@features/session'
import { allTypesToggle, typeToggle } from '../effects/current-import'
import { $allTypesChecked, $currentImportTypes, $exportEnabled } from '../selectors/current-import'
import { currentImportActions } from '../symbiotes'

export const ImportOptionsForm = () => (
  <Row>
    <Col md={4}>
      <TypesFilter />
    </Col>
    <Col md={4}>
      <Actions />
    </Col>
  </Row>
)

const TypesFilter = () => {
  const dispatch = useDispatch()
  const allTypesChecked = useSelector($allTypesChecked)
  const types = useSelector($currentImportTypes)

  const handleTypeChange = (e) => dispatch(typeToggle(mapNameAndChecked(e)))
  const handleAllTypesChange = (e) => dispatch(allTypesToggle(mapChecked(e)))

  return (
    <>
      <Title>Select types to filter</Title>
      <FormGroup>
        <FormCheck
          label={<strong>
            {allTypesChecked ? 'Deselect all' : 'Select all'}
          </strong>}
          onChange={handleAllTypesChange}
          checked={allTypesChecked}
        />
        {typesOptions.map(({ label, value }) => (
          <FormCheck
            key={label}
            label={label}
            name={value}
            onChange={handleTypeChange}
            checked={types.includes(value)}
          />
        ))}
      </FormGroup>
    </>
  )
}

const Actions = () => {
  const erp = useSelector($integrationName)
  const exportEnabled = useSelector($exportEnabled)
  const { setExportEnabled } = useActions(currentImportActions)

  return (
    <>
      <Title>Actions</Title>
      <FormGroup>
        <FormCheck
          label={`Export to ${erp}`}
          checked={exportEnabled}
          onChange={(e) => setExportEnabled(mapChecked(e))}
        />
      </FormGroup>
    </>
  )
}

const mapChecked = (event) => event.target.checked

const mapNameAndChecked = (event) => ({
  name: event.target.name,
  checked: event.target.checked,
})

const Title = styled(H4).attrs({
  mb: '0.5rem',
})``

const FormGroup = styled(Flex).attrs({
  direction: 'column',
  align: 'flex-start',
  gapY: '0.5rem',
  mb: '1rem',
})``
