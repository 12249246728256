import React from 'react'
import { useField } from 'formik'
import { FormSwitch } from '../index'

export const SwitchField = ({ name, label, className, ...props }) => {
  const [
    { onChange },
    { value },
  ] = useField({ name })

  return (
    <FormSwitch
      name={name}
      label={label}
      subLabel={value ? 'ON' : 'OFF'}
      checked={value}
      onChange={onChange}
      className={className}
      {...props}
    />
  )
}
