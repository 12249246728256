import React from 'react'
import { Button, FormCheck, ListDivider } from '../../index'
import { useMuliChoiceFilter } from './hooks'
import { FilterListTemplate, FooterListItem, FormListItem } from './shared-components'

export const MultiChoiceTableFilter = ({ options, sortButtons, column: { setFilter, filterValue }, onClose }) => {
  const {
    apply,
    reset,
    changeLocalFilter,
    isEqual,
    localFilterValue,
  } = useMuliChoiceFilter({ filterValue, setFilter, onClose })

  return (
    <FilterListTemplate>
      {options.map(({ label, value }) => (
        <FormListItem key={label}>
          <FormCheck
            label={label}
            name={value}
            checked={localFilterValue.includes(value)}
            onChange={changeLocalFilter}
          />
        </FormListItem>
      ))}
      <ListDivider mt="12px" />
      {sortButtons && (
        <>
          {sortButtons}
          <ListDivider />
        </>
      )}
      <FooterListItem>
        <Button variant="link" disabled={!filterValue} onClick={reset}>RESET</Button>
        <Button variant="link" disabled={isEqual} onClick={apply}>APPLY</Button>
      </FooterListItem>
    </FilterListTemplate>
  )
}
