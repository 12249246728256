/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../assets/css/style-invoice.css'
import React, { useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { history } from '@lib/routing'
import { useRequest } from '@lib/fetching'
import { $currentUser } from '@features/common'
import { Spinner } from '@ui'
import { api } from '../api'
import { InvoiceView } from '../components/InvoiceView'
import { $invoicesList } from '../selectors'

export const InvoicePage = ({ match }) => {
  const componentRef = useRef(null)
  const invoiceId = match.params.id

  const user = useSelector($currentUser)
  const invoices = useSelector($invoicesList)

  const fetchInvoice = useRequest(() => api.get(invoiceId), {
    initialData: {
      data: {},
    },
  })
  const {
    setData,
    isLoading,
    data: { data: invoice },
  } = fetchInvoice

  useDocumentMutations(invoiceId)

  useLayoutEffect(() => {
    const foundInvoice = findById(invoices, invoiceId)
    if (foundInvoice) {
      setData({ data: foundInvoice })
    } else {
      fetchInvoice()
    }
  }, [fetchInvoice, invoiceId, invoices, setData])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="wrapper">
      <div className="actions">
        <span className="print-button">
          <a onClick={() => history.push('/settings?tab=payment')}>Go Back</a>
        </span>
        <ReactToPrint
          trigger={() => <a>Print</a>}
          content={() => componentRef.current}
        />
      </div>
      <InvoiceView
        user={user}
        invoice={invoice}
        ref={componentRef}
      />
    </div>
  )
}

const findById = (list, id) => list.find((inv) => inv.id === id)

const useDocumentMutations = (invoiceId) => {
  useLayoutEffect(() => {
    const origColor = document.body.style.background
    const origTitle = document.title
    document.body.style.background = '#fff'
    document.title = invoiceId

    return () => {
      document.body.style.background = origColor
      document.title = origTitle
    }
  }, [invoiceId])
}
