import { combineReducers } from 'redux'
import { userReducer, paymentReducer } from '@features/account'
import { transactionsListReducer, transactionReducer } from '@features/transactions'
import { importJobsReducer, importingTransactionsReducer, currentImportReducer } from '@features/import-jobs'
import { integrationReducer, currentRuleReducer, sourcesReducer, configurationReducer } from '@features/integrations'
import { invoicesReducer } from '@features/invoices'
import { notificationsReducer } from '@features/notifications'
import { statisticsReducer } from '@features/dashboard'

export const rootReducer = combineReducers({
  user: userReducer,
  payment: paymentReducer,
  transactions: transactionsListReducer,
  transaction: transactionReducer,
  sources: sourcesReducer,
  importJobs: importJobsReducer,
  importingTransactions: importingTransactionsReducer,
  currentImport: currentImportReducer,
  integration: integrationReducer,
  invoices: invoicesReducer,
  notifications: notificationsReducer,
  statistics: statisticsReducer,
  currentRule: currentRuleReducer,
  configuration: configurationReducer,
})
