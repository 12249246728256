import { guards } from '@features/common'
import { connectionCallbacksRoutes } from '@features/connection-callbacks'
import { stripeRoutes } from '@features/stripe'
import { NewAccount } from './pages/new-account'
import { VerifyEmail } from './pages/verify-email'
import { ShopifyConnectPage } from './pages/shopify-connect'
import { Login } from './pages/login'

export const setupRoutes = () => [
  {
    path: '/verify-email',
    component: VerifyEmail,
  },
  ...stripeRoutes(),
  ...connectionCallbacksRoutes(),
  {
    component: NewAccount,
    guard: guards.setupNotCompleted,
  },
]

export const loginRoutes = () => [
  {
    path: '/shopify/callback',
    component: ShopifyConnectPage,
  },
  {
    component: Login,
  },
]
