import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { humanizeSource } from '@lib/constants'
import { Col, Row } from '@lib/styled-layout'
import { useActions } from '@lib/hooks'
import { Flex, H1, H2, Icon, Spinner, Text } from '@ui'
import { MainTemplate } from '@features/common'
import { XeroSettings } from './xero-settings'
import { QuickbooksSettings } from './quickbooks-settings'
import { $sourcesIsReady } from '../../selectors/sources'
import { $activeProcessorSettings } from '../../selectors/configuration'
import { AddProcessorModalButton } from '../../connect/AddIntegrationModalButtons'
import { configurationActions } from '../../symbiotes'
import { $integrationSettings } from '../../selectors/integration'

export const ConfigureProcessorPage = () => {
  const isReady = useSelector($sourcesIsReady)
  const { processorId } = useParams()
  const { processor } = useSelector($activeProcessorSettings)

  const actions = useActions(configurationActions)

  useEffect(() => {
    actions.set({ processorId: Number(processorId) })
    return () => actions.reset()
  }, [actions, processorId])

  return (
    <MainTemplate
      heading={<PageHeading processor={processor} />}
    >
      <Row>
        <Col xl={6}>
          <div id="alerts" />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          {isReady ? (
            processor ? <SettingsByIntegration /> : <NotFoundView />
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </MainTemplate>
  )
}

const SettingsByIntegration = () => {
  const integration = useSelector($integrationSettings)
  if (!integration) {
    return <NoIntegration />
  }

  switch (integration.source) {
    case 'xero':
      return <XeroSettings />
    case 'quickbooks':
      return <QuickbooksSettings />
    default:
      return <NotFoundView />
  }
}

const PageHeading = ({ processor }) => {
  const title = processor
    ? `Configuration - ${humanizeSource(processor.connection_source)}`
    : 'Configuration'

  return (
    <Row>
      <Col xl={8}>
        <RouterLink to="/configuration">
          <Icon variant="dark" name="longArrowLeft" />
          <Text ml="6px" variant="dark">BACK TO PROCESSORS</Text>
        </RouterLink>
        <TitleContainer>
          <Flex justify="space-between" mb="0.5rem">
            <H1>{title}</H1>
            <AddProcessorModalButton />
          </Flex>
          <H2>{processor ? processor.connection_name : ''}</H2>
        </TitleContainer>
      </Col>
    </Row>
  )
}

const NotFoundView = () => (
  <H2>Processor settings not found</H2>
)

const NoIntegration = () => (
  <H2>Please connect an integration to see this page.</H2>
)

const TitleContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`
