import React from 'react'
import styled from 'styled-components'
import { Text } from '@ui'

export const NoDataFound = () => (
  <Container>
    <Title variant="light">No data found</Title>
  </Container>
)

const Container = styled.div`
  margin: 100px 0;
  text-align: center;
`

const Title = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`
