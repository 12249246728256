import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@lib/auth0'
import { history } from '@lib/routing'
import { LoadingButton } from '@ui'

import { NewUserLayout } from '../components/NewUserLayout'
import { steps } from './new-account.model'

export const VerifyEmail = () => {
  const { user: { email, email_verified }, logout } = useAuth0()
  const [loggingOut, setLoggingOut] = useState(false)

  const onLogout = () => {
    logout()
    setLoggingOut(true)
  }

  useEffect(() => {
    if (email_verified) {
      history.push('/')
    }
  }, [email_verified])

  return (
    <NewUserLayout
      title={steps[0].title}
      subtitle={steps[0].subtitle || null}
      steps={steps}
      activeStep={0}
    >
      <p>Thank you for signing up! We sent you an email to <strong>{email}</strong>. Please click on the link provided to verify your account.</p>
      <p>
        <LoadingButton
          onClick={onLogout}
          disabled={loggingOut}
          loading={loggingOut}
          size="full"
          type="button"
          variant="outline"
        >
          LOG OUT
        </LoadingButton>
      </p>
    </NewUserLayout>
  )
}
