import React from 'react'
import { useModal } from '@features/common'
import { useRequest } from '@lib/fetching'
import { Button, Flex, YesNoModal, TooltipContainer } from '@ui'
import { useSelector } from 'react-redux'
import { $integrationName } from '@features/session'

export const MassActionModalButton = ({ config, active, handler, size = 'xs', variant = 'outline' }) => {
  const modal = useModal()
  const request = useRequest(handler)

  const erp = useSelector($integrationName)

  const makeRequest = async () => {
    await request()
    modal.close()
  }

  return (
    <>
      <TooltipContainer tooltip={config.tooltip} tooltipDelay={1000}>
        <Button
          size={size}
          variant={variant}
          disabled={!active}
          onClick={modal.show}
        >
          {config.text}
        </Button>
      </TooltipContainer>
      <YesNoModal
        title={config.modal.title}
        show={modal.isShown}
        onClose={modal.close}
        onConfirm={makeRequest}
        loading={request.isLoading}
        noText="NOT NOW"
        yesText="YES"
      >
        <Flex pb="2rem" justify="center" direction="column">
          {config.modal.Text({ erp })}
        </Flex>
      </YesNoModal>
    </>
  )
}
