import React from 'react'
import * as P from 'prop-types'
import { Col, Row } from '@lib/styled-layout'
import { humanizeSource } from '@lib/constants'
import { Logo } from './Logo'
import { FlexCenter } from './FlexCenter'

export const ConnectionInfo = ({ sourceName, name, status, createdAt }) => (
  <Row>
    <Col md={3}>
      <FlexCenter>
        <Logo name={sourceName} title={humanizeSource(sourceName)} />
      </FlexCenter>
    </Col>
    <Col md={3}>
      <FlexCenter>{name}</FlexCenter>
    </Col>
    <Col md={3}>
      <FlexCenter>{status}</FlexCenter>
    </Col>
    <Col md={3}>
      <FlexCenter justify="flex-end">
        linked on: {createdAt}
      </FlexCenter>
    </Col>
  </Row>
)

ConnectionInfo.propTypes = {
  sourceName: P.node.isRequired,
  name: P.node.isRequired,
  status: P.node.isRequired,
  createdAt: P.node.isRequired,
}
