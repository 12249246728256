import { handleFetching } from '@lib/fetching'
import { transactionsFetch } from '@features/transactions'
import { statisticsActions } from './symbiotes'
import { statisticsApi } from './api'
import { $filters } from './selectors/statistics'

export const statisticsFetch = () => handleFetching(statisticsActions.fetch, {
  noThrow: true,
  async run(dispatch, getState) {
    const { term, gte, lte } = $filters(getState())

    const data = await statisticsApi.transactions({
      term,
      gte: gte ? new Date(gte).toISOString() : null,
      lte: lte ? new Date(lte).toISOString() : null,
    })

    dispatch(statisticsActions.setModel(data))
  },
})

export const customTermFilterSet = ([gte, lte]) => async (dispatch) => {
  dispatch(statisticsActions.filters.setCustomTerm({ gte, lte }))

  if (gte && lte) {
    dispatch(statisticsFetch())
  }
}

export const termFilterSet = (term) => async (dispatch) => {
  dispatch(statisticsActions.filters.setTerm(term))
  dispatch(statisticsFetch())
}

export const afterResync = () => (dispatch) => {
  dispatch(transactionsFetch())
  dispatch(statisticsFetch())
}
