import React from 'react'
import { useSelector } from 'react-redux'
import { $currentUser } from '@features/common'

const userActions = [
  {
    id: 'resync',
    text: 'SYNC',
    modal: {
      title: 'Sync Transactions',
      Text: ({ erp }) => <>
        Are you sure you want to sync the selected transactions with {erp}?
        This will export the transaction only if it doesn't already exist in {erp}.
      </>,
    },
    tooltip: 'Export transactions to accounting software after checking for duplicates.',
  },
  {
    id: 'unqueue',
    text: 'UNQUEUE',
    modal: {
      title: 'Unqueue Transactions',
      Text: ({ erp }) => <>
        Are you sure you want to remove the selected transactions from the export queue?
        This will prevent these transactions from being exported to {erp}.
      </>,
    },
    tooltip: 'Remove the selected transactions from the export queue.',
  },
  {
    id: 'requeueFailed',
    text: 'RETRY FAILED',
    modal: {
      title: 'Retry Failed Transactions',
      Text: () => <>
        Are you sure you want to retry failed transactions?
        This will go through all transactions that have failed and put them in the queue again.
      </>,
    },
    tooltip: 'Queue failed transactions for export.',
  },
]

const adminActions = [
  {
    id: 'delete',
    text: 'DELETE',
    modal: {
      title: 'Delete Transactions',
      Text: () => <>
        Are you sure you want to delete the selected transactions from the Bankfeeds?
      </>,
    },
    tooltip: 'Delete transactions from Bankfeeds (the transactions will still exist in accounting software)',
  },
]

export const useActionsConfig = () => {
  const user = useSelector($currentUser)
  const isAdmin = Boolean(user.is_admin) || Boolean(user.masquerade)

  const actions = [...userActions]
  if (isAdmin) {
    actions.push(...adminActions)
  }

  return actions
}
