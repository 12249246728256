import dayjs from 'dayjs'
import moment from 'moment'
import utc from 'dayjs/plugin/utc'
import { useEffect, useRef, useState } from 'react'
import useMaskedInput from '@viewstools/use-masked-input'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

dayjs.extend(utc)

const dateTimeFormat = 'MM/DD/YYYY HH:mm'
const dateFormat = 'MM/DD/YYYY'

export const useInput = ({ startDate, endDate, showTime, isRange, onClick, onChange }) => {
  const { placeholder, value } = getInputProps({ startDate, endDate, showTime, isRange })

  const [inputValue, setInputValue] = useState(value)
  useEffect(() => {
    setInputValue(value)
  }, [value])

  const pipe = isRange
    ? createAutoCorrectedDateRangePipe({ showTime })
    : createAutoCorrectedPipe({ showTime })

  const mask = isRange
    ? showTime
      ? [...mmddyyyy, ' ', ...hhmm, '-', ...mmddyyyy, ' ', ...hhmm]
      : [...mmddyyyy, '-', ...mmddyyyy]
    : showTime
      ? [...mmddyyyy, ' ', ...hhmm]
      : [...mmddyyyy]

  const input = useRef(null)
  const maskedOnChange = useMaskedInput({
    input,
    mask,
    pipe,
    value: inputValue,
    keepCharPositions: true,
    onChange(e) {
      setInputValue(e.target.value)

      if (isRange) {
        const [start, end] = e.target.value.split('-')
        const sDate = moment(start).utc(true)
        const eDate = moment(end).utc(true)

        if (sDate.isValid() && eDate.isValid()) {
          onChange([sDate.valueOf(), eDate.valueOf()])
        }
      } else {
        const date = moment(e.target.value).utc(true)

        if (date.isValid()) {
          onChange([date.valueOf(), null])
        }
      }
    },
  })

  const onBlur = () => {
    // Fallback to datepicker value, usefull if input value is incomplete
    setInputValue(value)
  }

  return {
    getInputProps: () => ({
      ref: input,
      placeholder,
      value: inputValue,
      type: 'text',
      onChange: maskedOnChange,
      onClick,
      onBlur,
    }),
  }
}

export const getInputProps = ({ startDate, endDate, showTime, isRange }) => {
  const format = showTime ? dateTimeFormat : dateFormat

  const [start, end] = [formatDate(startDate, format), formatDate(endDate, format)]

  const isValid = isRange
    ? !!start || !!end
    : !!start

  const value = isValid
    ? isRange
      ? `${start || 'Start date'}-${end || 'End date'}`
      : start
    : ''

  const placeholder = isRange
    ? 'Start date - End date'
    : 'Date'

  return {
    value,
    placeholder,
  }
}

const formatDate = (date, format) => {
  const utcDate = dayjs(date).utc()
  return utcDate.isValid() ? utcDate.format(format) : false
}

const mmddyyyy = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
const hhmm = [/\d/, /\d/, ':', /\d/, /\d/]

const createAutoCorrectedPipe = ({ showTime }) =>
  createAutoCorrectedDatePipe(showTime ? 'mm/dd/yyyy HH:MM' : 'mm/dd/yyyy', {
    minYear: 2000,
    maxYear: 2100,
  })

const createAutoCorrectedDateRangePipe = ({ showTime }) => (conformedValue) => {
  const autoCorrectedPipe = createAutoCorrectedPipe({ showTime })

  const [startRaw, endRaw] = conformedValue.split('-')
  const start = autoCorrectedPipe(startRaw)
  const end = autoCorrectedPipe(endRaw)
  if (!start || !end) return false

  return {
    value: `${start.value}-${end.value}`,
    indexesOfPipedChars: [
      ...start.indexesOfPipedChars,
      ...end.indexesOfPipedChars.map((idx) => idx + start.value.length),
    ],
  }
}
