import { statusToExportStatus } from '@lib/mappers'

export const toExportedStatusesStatistics = (statuses) => Object.values(
  statuses.reduce((result, { name, amount }) => {
    const exportStatusName = statusToExportStatus(name)
    if (!exportStatusName) return result

    const itemAmount = result[exportStatusName]
      ? result[exportStatusName].amount
      : 0

    return {
      ...result,
      [exportStatusName]: {
        name: exportStatusName,
        amount: itemAmount + amount,
      },
    }
  }, []),
)
