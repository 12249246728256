import React from 'react'
import { useDispatch } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { history } from '@lib/routing'
import { initialDataFetch } from '@features/session'
import { LoadingButton } from '@ui'
import { api } from '../api'

export const ImpersonateButton = ({ userId }) => {
  const dispatch = useDispatch()
  const request = useRequest(api.impersonate)

  const impersonate = async () => {
    await request(userId)
    history.push('/')
    dispatch(initialDataFetch())
  }

  return (
    <LoadingButton
      size="xs"
      onClick={impersonate}
      loading={request.isLoading}
    >
      Impersonate
    </LoadingButton>
  )
}
