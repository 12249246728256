import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Portal } from '@lib/portal'
import { Divider, Flex, FormGroup, FormSubmit, H2, SaveFormChangesDialog, Text } from '@ui'
import { Guard } from '@features/common'
import { useSettingsModel } from './xero-settings.model'
import { RuleCreateModal, RuleDeleteModal, RuleEditModal } from '../../components/Rule'
import { ConfigurationAlerts } from '../../components/ConfigurationAlerts'
import { DeleteProcessorModalButton } from '../../components/DeleteProcessorModalButton'
import { xeroSettingsSchema } from '../../validation-schemas'
import {
  BankAccount,
  DisplayOption,
  InputTemplate,
  SkipInvoicesOption,
  TrackAccountWithRules,
  XeroTaxRates,
} from '../../components/SettingsOptions'
import { ImportPaymentsOption } from '../../components/SettingsOptions/ImportPayments'
import { ImportInvoicesOption } from '../../components/SettingsOptions/ImportInvoices'
import { ImportHeader } from '../../components/SettingsHeaders'
import { $bankAccountOptions, $commonAccountOptions } from '../../selectors/xero-entities'
import { BankAccountInfo } from '../../components/BankAccountInfo'

export const XeroSettings = () => {
  const { processorId } = useParams()
  const bankAccountOptions = useSelector($bankAccountOptions)
  const commonAccountOptions = useSelector($commonAccountOptions)

  const {
    handleSubmit,
    prepareRuleCreate,
    prepareRuleEdit,
    prepareRuleRemove,
    createRuleModal,
    editRuleModal,
    deleteRuleModal,
    initialValues,
  } = useSettingsModel()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialStatus={{
        ruleActions: { prepareRuleCreate, prepareRuleEdit, prepareRuleRemove },
      }}
      validationSchema={xeroSettingsSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Portal id="dialog">
          <SaveFormChangesDialog />
        </Portal>
        <Portal id="alerts">
          <ConfigurationAlerts scrollToTop />
        </Portal>
        <Text mb="16px">To start the configuration select an account</Text>
        <BankAccount
          options={bankAccountOptions}
        />
        <FormGroup>
          <Flex mt="16px" direction="column">
            <BankAccountInfo />
          </Flex>
        </FormGroup>
        <Divider />
        <InputTemplate>
          <ImportHeader />
        </InputTemplate>
        <Text variant="light" mb="24px">
          Please note that any transaction that does not meet custom rules will go into your default account
        </Text>
        <TrackAccountWithRules
          name="track_charges"
          account="charges_account"
          label="Import Charges"
          options={commonAccountOptions}
          description="This is the account that you want to assign your sales transactions."
          rulesDescription="This is the default account we will assign to your sales transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_refunds"
          account="refunds_account"
          label="Import Refunds"
          options={commonAccountOptions}
          description="This is the account that you want to assign your refunds transactions."
          rulesDescription="This is the default account we will assign to your refunds transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_fees"
          account="fees_account"
          label="Import Fees"
          options={commonAccountOptions}
          description="This is the account that you want to assign your fees transactions."
          rulesDescription="This is the default account we will assign to your fees transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_app_fees"
          account="app_fees_account"
          label="Import Application Fees"
          options={commonAccountOptions}
          description="This is the account that you want to assign your application fees transactions."
          rulesDescription="This is the default account we will assign to your application fees transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_disputes"
          account="disputes_account"
          label="Import Disputes"
          options={commonAccountOptions}
          description="This is the account that you want to assign your disputes transactions."
          rulesDescription="This is the default account we will assign to your disputes transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_adjustments"
          account="adjustments_account"
          label="Import Adjustments"
          options={commonAccountOptions}
          description="This is the account that you want to assign your adjustment transactions."
          rulesDescription="This is the default account we will assign to your adjustment transactions that do not match any rules."
        />
        <TrackAccountWithRules
          name="track_payouts"
          account="payouts_account"
          label="Import Payouts"
          description="This is the default bank account where you expect to receive payouts."
          options={bankAccountOptions}
        />
        <Divider />
        <SkipInvoicesOption />
        <Guard syncInvoices>
          <ImportInvoicesOption />
        </Guard>
        <Divider />
        <ImportPaymentsOption />
        <H2>Display setting in Xero</H2>
        <Text variant="light" mt="12px">
          Select the fields you want to include in the Description column in Xero.
        </Text>
        <DisplayOption name="name_display" label="Customer Name" />
        <DisplayOption name="email_real_display" label="Customer Email" />
        <DisplayOption name="import_source_currency" label="Currency" />
        <DisplayOption name="import_country" label="Country" />
        <DisplayOption name="import_payment_descriptor" label="Payment Descriptor" tooltip="When this option is enabled, the payment descriptor (as it appears in the customer's credit card statement) will be used as the description of the transaction." />
        <DisplayOption name="tag_display" label={'Text - "Bankfeeds"'} />
        <XeroTaxRates />
        <Flex justify="flex-end" gap="24px" mt="48px">
          <FormSubmit checkDirty size="lg">SAVE CHANGES</FormSubmit>
          <DeleteProcessorModalButton processorId={processorId} />
        </Flex>
        <RuleCreateModal
          show={createRuleModal.isShown}
          onClose={createRuleModal.close}
        />
        <RuleEditModal
          show={editRuleModal.isShown}
          onClose={editRuleModal.close}
        />
        <RuleDeleteModal
          show={deleteRuleModal.isShown}
          onClose={deleteRuleModal.close}
        />
      </Form>
    </Formik>
  )
}
