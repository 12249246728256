import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Button, Flex, InputField, SelectField } from '../../index'
import { FilterListTemplate, FooterRightListItem, FormListItem } from './shared-components'

export const AmountTableFilter = ({ options, column: { setFilter, filterValue }, onClose }) => {
  const reset = (resetForm) => {
    resetForm()
    setFilter()
    onClose()
  }

  const apply = (values) => {
    setFilter(values)
    onClose()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        value: filterValue?.value || '',
        operator: filterValue?.operator || null,
      }}
      validationSchema={Yup.object().shape({
        value: Yup.number().required('Required'),
        operator: Yup.string().nullable().required('Required'),
      })}
      onSubmit={apply}
    >
      {({ resetForm, dirty }) => (
        <Form>
          <FilterListTemplate>
            <FormListItem>
              <Flex gap="16px">
                <OperatorWrap>
                  <SelectField
                    placeholder="Select a condition"
                    name="operator"
                    options={options}
                  />
                </OperatorWrap>
                <ValueWrap>
                  <InputField
                    name="value"
                    type="number"
                    placeholder="00.00"
                  />
                </ValueWrap>
              </Flex>
            </FormListItem>
            <FooterRightListItem>
              <Button variant="link" disabled={!filterValue} onClick={() => reset(resetForm)}>RESET</Button>
              <Button variant="link" disabled={!dirty} type="submit">APPLY</Button>
            </FooterRightListItem>
          </FilterListTemplate>
        </Form>
      )}
    </Formik>
  )
}

const OperatorWrap = styled.div`
  width: 200px;
`

const ValueWrap = styled.div`
  width: 140px;
`