import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { date, toUtcDate } from '@lib/date'
import { useRequest } from '@lib/fetching'
import { $userDatePrefs, useModal } from '@features/common'
import { Button, CenterContent, LoadingButton, Modal, YesNoModal } from '@ui'
import { $currentImport } from '../selectors/current-import'
import { activeImportJobCancel } from '../effects'

export const ConfirmImportModal = ({ modal, onConfirm, loading }) => {
  const { startDate, endDate } = useSelector($currentImport)
  const { timezone, dateTimeFormat } = useSelector($userDatePrefs)
  const startDateUtc = toUtcDate(startDate, timezone)
  const endDateUtc = toUtcDate(endDate, timezone)

  return (
    <Modal
      size="sm"
      show={modal.isShown}
      onClose={modal.close}
    >
      <CenterContent>
        <Modal.Title>Are you sure you want import the following transactions?</Modal.Title>
      </CenterContent>
      <Modal.Body>
        <p>
          You are about to check transactions to import to your Bankfeeds account.
          <br />
          From {date(startDateUtc, timezone, dateTimeFormat)} to {date(endDateUtc, timezone, dateTimeFormat)}
        </p>
        <p>
          <b>You will receive an email with final import numbers once all transactions have been imported.</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" size="full" onClick={modal.close}>CANCEL</Button>
        <LoadingButton size="full" onClick={onConfirm} loading={loading}>CONFIRM</LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export const CancelImportModalButton = ({ buttonText }) => {
  const dispatch = useDispatch()
  // INFO: cancelRequest will trigger component unmount,
  // so we don't need to close modal manually.
  const cancelRequest = useRequest(() => dispatch(activeImportJobCancel()))
  const modal = useModal()

  return (
    <>
      <Button
        onClick={modal.show}
        variant="outline"
      >
        {buttonText}
      </Button>
      <YesNoModal
        show={modal.isShown}
        title="Cancel Import Job"
        onClose={modal.close}
        onConfirm={cancelRequest}
        loading={cancelRequest.isLoading}
        yesText="YES"
        noText="NO"
      >
        Are you sure you want to cancel an import job? All found transactions will be removed from Bankfeeds.
      </YesNoModal>
    </>
  )
}
