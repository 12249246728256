import { NotFoundChildPage } from '@features/common'
import { IntegrationsPage } from './pages/integrations/integrations'
import { AccountPage } from './pages/integrations/account'
import { PaymentsPage } from './pages/integrations/payments'
import { ConfigurationPage } from './pages/configuration/configuration'
import { ConfigureProcessorPage } from './pages/configuration/configure-processor'

export const integrationsRoutes = () => [
  {
    path: '/integrations',
    component: IntegrationsPage,
    routes: [
      {
        exact: true,
        path: '/integrations',
        component: AccountPage,
      },
      {
        path: '/integrations/payments',
        component: PaymentsPage,
      },
      {
        component: NotFoundChildPage,
      },
    ],
  },
  {
    exact: true,
    path: '/configuration',
    component: ConfigurationPage,
  },
  {
    path: '/configuration/:processorId',
    component: ConfigureProcessorPage,
  },
]
