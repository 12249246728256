import React from 'react'
import * as P from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Divider } from '../Divider'

export const CreditCardPreview = ({ Logo, number, validMonth, validYear, minimized, color, onClick }) => (
  <Container
    minimized={minimized}
    color={color}
    onClick={onClick}
  >
    <LogoWraper>
      {Logo}
    </LogoWraper>
    <Data>
      <Number>
        <Text>Card number:</Text>
        <Value>{number}</Value>
      </Number>
      <NumberShort>
        <Value>{number.slice(-4)}</Value>
      </NumberShort>
      <Divider />
      <Valid>
        <Text>Valid until</Text>
        <Value>{formatTime(validMonth)} / {formatTime(validYear)}</Value>
      </Valid>
    </Data>
  </Container>
)

const formatTime = (value) => (`0${value}`).slice(-2)

const dataItem = css`
  display: flex;
  justify-content: space-between;
`

const Number = styled.div`
  ${dataItem};
`
const Valid = styled.div`
  ${dataItem};
`
const NumberShort = styled.div``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 337px;
  height: 180px;
  background-color: ${prop('color')};
  border-radius: 10px;
  padding: 30px 20px 0 24px;
  box-shadow: 0 16px 35px -16px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  ${Divider} {
    margin-top: 8px;
    margin-bottom: 5px;
    opacity: 0.5;
  }

  ${NumberShort} {
    display: none;
  }

  ${ifProp('minimized', css`
    align-items: center;
    width: 54px;
    padding: 30px 0 0;
    
    ${Number},
    ${Valid},
    ${Divider} {
      display: none;
    }
    ${NumberShort} {
      display: block;
    }
  `)}
`

const LogoWraper = styled.div`
  height: 60px;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50px;

  img {
    max-width: 50px;
  }
`

const Data = styled.div`
  color: white;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
`

const Value = styled.span`
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
`

CreditCardPreview.propTypes = {
  minimized: P.bool,
  color: P.string,
  Logo: P.node.isRequired,
  number: P.string.isRequired,
  validMonth: P.number.isRequired,
  validYear: P.number.isRequired,
}

CreditCardPreview.defaultProps = {
  minimized: false,
  color: '#aaa',
  onClick: () => {},
}
