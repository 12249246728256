import { createSelector } from 'reselect'
import { createFetchingSelectors } from '@lib/fetching'
import { $importJobs } from './import-jobs'
import { getPercentByStatsType } from '../convert'

export const $active = createSelector(
  $importJobs,
  ({ active }) => active,
)

export const $latestActiveImportJob = createSelector(
  $active,
  ({ list }) => list[0],
)

export const $activeFetching = createSelector(
  $active,
  ({ fetching }) => fetching,
)

export const {
  $isLoading: $activeIsLoading,
} = createFetchingSelectors($activeFetching)

export const $activeImportJobProgressPercent = createSelector(
  $latestActiveImportJob,
  (importJob) => {
    const {
      from,
      to,
      metadata: { stats, statsType },
    } = importJob

    return getPercentByStatsType({
      stats,
      statsType,
      from,
      to,
    })
  },
)
