import { TransactionsPage } from './pages/transactions'
import { DuplicateDetectionPage } from './pages/duplicate-detection'
import { AccountsReconciliationPage } from './pages/accounts-reconciliation'

export const transactionsRoutes = () => [
  {
    path: '/transactions',
    exact: true,
    component: TransactionsPage,
  },
  {
    path: '/transactions/duplicate-detection',
    exact: true,
    component: DuplicateDetectionPage,
  },
  {
    path: '/transactions/reconciliation',
    exact: true,
    component: AccountsReconciliationPage,
  },
  {
    path: '/transactions/:transactionId',
    component: TransactionsPage,
  },
]
