import { request } from '@features/common'

export const api = {
  getUsers: (params) => request('GET', '/admin/users', { params }),

  impersonate: (userId) => request('GET', `/admin/users/${userId}/impersonate`),

  cancelAccount: (userId) => request('POST', `/admin/users/${userId}/cancel`),

  getTransactionsReport: (userId) => request('GET', `/admin/users/${userId}/reports/transactions`),
}
