import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { useActions } from '@lib/hooks'
import { YesNoModal } from '@ui'
import { ruleRemove } from '../../effects/rules'
import { $currentRule } from '../../selectors/current-rule'
import { currentRuleActions } from '../../symbiotes'

export const RuleDeleteModal = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const currentRule = useActions(currentRuleActions)
  const { processorId, account, position } = useSelector($currentRule)

  const handleClose = () => {
    currentRule.reset()
    onClose()
  }

  const removeRequest = useRequest(
    () => dispatch(ruleRemove({ processorId, account, position })),
    { done: handleClose },
  )

  return (
    <YesNoModal
      title="Rule deleting"
      show={show}
      onClose={handleClose}
      onConfirm={removeRequest}
      loading={removeRequest.isLoading}
      yesText="DELETE"
    >
      Are you sure want to delete this rule?
    </YesNoModal>
  )
}
