import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from '@lib/styled-layout'
import { useRequest } from '@lib/fetching'
import {
  Button,
  DangerButton,
  ErrorLabel,
  ExpirationField,
  Flex,
  FormGroup,
  FormInput,
  LoadingButton,
  Modal,
} from '@ui'
import { cardMakeDefault } from '../effects'

export const UpdateCardModal = ({ show, values = {}, defaultCard, onClose, onSubmit, onDeleteClick }) => {
  const [expiration, setExpiration] = useState({
    month: values.exp_month,
    year: values.exp_year,
  })
  const [error, setError] = useState(null)
  const [yearError, setYearError] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const isDefault = values.id === defaultCard
  const isValid = !error && !yearError

  const dispatch = useDispatch()
  const makeDefaultRequest = useRequest(
    () => dispatch(cardMakeDefault(values.id)),
  )

  useEffect(() => {
    setExpiration({
      month: values.exp_month,
      year: values.exp_year,
    })
  }, [values.exp_month, values.exp_year])

  const validateYear = (year) => {
    const maxYear = 2070
    const invalid = Number(year) > maxYear

    setYearError(invalid ? ExpirationField.errorMessages.YEAR_INVALID : null)
  }

  const handleChange = (value) => {
    setExpiration(value)
    validateYear(value.year)
  }

  const handleSubmit = async () => {
    if (!isValid) return

    setSubmitting(true)
    await onSubmit({
      exp_month: expiration.month,
      exp_year: Number(String(expiration.year).slice(-2)),
    })
    setSubmitting(false)
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <Flex justify="space-between">
        <Modal.Title>Update Payment Method</Modal.Title>
        <div>
          <LoadingButton
            variant="outline"
            onClick={makeDefaultRequest}
            loading={makeDefaultRequest.isLoading}
            disabled={isDefault}
          >
            {isDefault ? 'Primary Payment Method' : 'Use as Primary'}
          </LoadingButton>
        </div>
      </Flex>
      <Modal.Body>
        <FormGroup>
          <FormInput label="Card number" disabled defaultValue={values.number} />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={8}>
              <ExpirationField
                label="Expiration"
                defaultMonth={values.exp_month}
                defaultYear={values.exp_year}
                onChange={handleChange}
                onError={setError}
              />
              {yearError && (
                <ErrorLabel>{yearError}</ErrorLabel>
              )}
            </Col>
            <Col md={4}>
              <FormInput label="CVC" disabled defaultValue="***" />
            </Col>
          </Row>
        </FormGroup>
      </Modal.Body>
      <Flex justify="space-between">
        <DangerButton onClick={onDeleteClick}>DELETE</DangerButton>
        <Modal.Footer>
          <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
          <LoadingButton size="md" loading={isSubmitting} onClick={handleSubmit}>UPDATE</LoadingButton>
        </Modal.Footer>
      </Flex>
    </Modal>
  )
}
