export const capitalize = (str = '') => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return ''
}

/**
 * Pluralizes a string. Works properly only for numbers in range (0-20).
 */
export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`
