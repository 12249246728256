import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { CenterContent, H3, Link, Progress } from '@ui'
import { useRequest } from '@lib/fetching'
import { percent } from '@lib/convert'
import { useOpened, useTimeout } from '@lib/hooks'
import { $currentUser } from '@features/common'
import { $integrationName } from '@features/session'
import { importRefresh } from '../effects'
import { $activeImportJobProgressPercent, $activeIsLoading } from '../selectors/active-import-jobs'
import { api } from '../api'

const REFRESH_INTERVAL = 4000
const FADE_OUT_DELAY = 5000

export const JobFailedView = ({ error }) => (
  <CenterContent>
    <H3 mb="1rem">
      Your import job was failed.
      <br />
      Error details: {error}
    </H3>
  </CenterContent>
)

export const JobNoResultsView = () => (
  <CenterContent>
    <H3 mb="1rem">
      We didn't find any transactions for this timeframe, or all transactions have already been imported.
    </H3>
  </CenterContent>
)

export const JobInProgressView = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector($activeIsLoading)
  const processedPercent = useSelector($activeImportJobProgressPercent)

  const refresh = useCallback(() => dispatch(importRefresh()), [dispatch])

  useTimeout({
    target: refresh,
    delay: REFRESH_INTERVAL,
    filter: !isLoading,
  })

  return (
    <CenterContent>
      <H3 mb="1rem">
        We're still gathering transactions.
        <br />
        This page will automatically update when your transactions are available.
        <br />
        You can leave this page and come back any time.
      </H3>
      <div>
        <Progress
          variant="primary"
          value={processedPercent}
          stripped
          animation
        />
        <H3>{processedPercent.toFixed(2)}%</H3>
      </div>
    </CenterContent>
  )
}

export const LatestJobExportStatus = () => {
  const user = useSelector($currentUser)
  const isIntegrationExpired = user.integration_expired

  const view = useOpened()

  const request = useRequest(api.getStatsForLatest, {
    initialData: {
      total: 0,
      queuedCount: 0,
    },
    done({ result }) {
      if (result.queuedCount > 0) {
        view.open()
      }
    },
  })

  const erp = useSelector($integrationName)
  const { total, queuedCount } = request.data
  const exportInProgress = queuedCount > 0

  const processedCount = total - queuedCount
  const processedPercent = percent(processedCount, total)
  const retryAllowed = !request.isLoading && !isIntegrationExpired && exportInProgress

  const viewWillClose = useMemo(
    () => view.opened && queuedCount === 0,
    [queuedCount, view.opened],
  )

  useEffect(() => {
    request()
  }, [request])

  useTimeout({
    target: request,
    delay: REFRESH_INTERVAL,
    filter: useCallback(
      () => retryAllowed,
      [retryAllowed],
    ),
  })

  useTimeout({
    target: useCallback(
      () => view.close(),
      [view],
    ),
    filter: viewWillClose,
    delay: FADE_OUT_DELAY,
  })

  return view.opened && (
    <CenterContent>
      {isIntegrationExpired ? (
        <H3 mb="1rem">Export paused, please <Link as={RouterLink} to="/xero/reconnect">re-connect</Link> your {erp} account</H3>
      ) : (
        <H3 mb="1rem">{exportInProgress ? 'Export in progress' : 'Export finished'}</H3>
      )}
      <Progress
        variant="primary"
        value={processedPercent}
        stripped
        animation
      />
      <Container>
        <H3>{processedPercent.toFixed(2)}%</H3>
      </Container>
      {exportInProgress || (
        <H3>{total} Transactions Exported Successfully</H3>
      )}
    </CenterContent>
  )
}

const Container = styled.div`
  padding-bottom: 16px;
`
