import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Wrapper } from './styled'

export const FadeWrapper = ({ show, children }) => {
  const { shouldRender, unmount } = useShouldRender(show)

  return shouldRender && (
    <AnimWrapper
      show={show}
      onAnimationEnd={unmount}
    >
      {children}
    </AnimWrapper>
  )
}

const useShouldRender = (show) => {
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const unmount = () => {
    if (!show) setRender(false)
  }

  return {
    shouldRender,
    unmount,
  }
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const AnimWrapper = styled(Wrapper)`
  will-change: opacity;
  animation: ${ifProp('show', fadeIn, fadeOut)} 300ms;
`
