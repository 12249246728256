import { guards } from '@features/common'
import { UsersPage } from './pages/users'

export const adminRoutes = () => [
  {
    path: '/admin',
    component: UsersPage,
    guard: guards.isAdmin,
  },
]
