import { useCallback, useMemo } from 'react'
import t from 'timestamp-utils'
import dayjs from 'dayjs'
import { useBoolean } from '@lib/hooks'
import { useMonths } from './use-months'
import { useInput } from './use-input'
import { useDates } from './use-dates'
import { fillDays, initMonth, isBetween, isOut, parseRange, resetTime, decompose } from './utils'

export const useDatePicker = ({
  start = null,
  end = null,
  numberOfMonths = 2,
  closeAfterSelect = false,
  onChange = () => {},
  initialOpen = false,
  isRange = true,
  showTime,
} = {}) => {

  const {
    val: isOpen,
    on: open,
    off: close,
  } = useBoolean(initialOpen)

  const {
    startDate,
    endDate,
    updateDates,
    updateDateTime,
    resetDates,
  } = useDates({
    start,
    end,
    onChange,
  })

  const [nowYear, nowMonth] = decompose(dayjs().subtract(numberOfMonths - 1, 'month'))

  const {
    monthViews,
    goMonthLeft,
    goMonthRight,
    setViewData,
  } = useMonths({
    initialYear: nowYear,
    initialMonth: nowMonth,
    amount: numberOfMonths,
  })

  const {
    getInputProps,
  } = useInput({
    startDate,
    endDate,
    showTime,
    isRange,
    onChange: updateDateTime,
  })

  const monthsData = useMemo(
    () => monthViews.map(({ year, month }) =>
      initMonth(t.set(0, { year, month })),
    ),
    [monthViews],
  )

  const daysData = useMemo(
    () => monthsData.map((monthData) =>
      createDaysData(monthData, startDate, endDate),
    ),
    [startDate, endDate, monthsData],
  )

  const selectDate = (date) => {
    if (isRange) {
      selectDateRange(date)
    } else {
      updateDates([date])
    }
  }

  const selectDateRange = (date) => {
    if (!startDate) {
      updateDates([date])
    } else if (startDate && !endDate) {
      updateDates(parseRange(startDate, date))
      if (closeAfterSelect) {
        close()
      }
    } else {
      updateDates([date, null])
    }
  }

  const getDayProps = useCallback(({ dayIdx, monthIdx }) => {
    const {
      date,
      conditions: { out, selected, sInside, sStart, sEnd },
    } = daysData[monthIdx][dayIdx]
    return {
      out,
      selected,
      sInside,
      sStart,
      sEnd,
      key: dayIdx,
      onClick() {
        selectDate(date)
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysData])

  return {
    isOpen,
    startDate,
    endDate,
    open,
    close,
    monthsData,
    daysData,
    resetDates,
    getDayProps,
    goMonthLeft,
    goMonthRight,
    getInputProps,
    updateDateTime,
    setViewData,
  }
}

const createDaysData = (monthData, startDate, endDate) => {
  const {
    firstDayToShow,
    lastDay,
    firstDay,
  } = monthData

  return fillDays(firstDayToShow).map((date) => {
    const day = Number(t.getDay(date))
    const sDate = resetTime(startDate)
    const eDate = resetTime(endDate)

    const conditions = {
      out: isOut(date, firstDay, lastDay),
      selected: !endDate && (sDate === date),
      sInside: isBetween(date, sDate, eDate),
      sStart: endDate && (sDate === date),
      sEnd: endDate && (eDate === date),
    }

    return {
      day,
      date,
      conditions,
    }
  })
}
