import { renderRoutes } from 'react-router-config'
import { transactionsRoutes } from '@features/transactions'
import { importJobsRoutes } from '@features/import-jobs'
import { accountRoutes } from '@features/account'
import { stripeRoutes } from '@features/stripe'
import { integrationsRoutes } from '@features/integrations'
import { invoiceRoutes } from '@features/invoices'
import { adminRoutes } from '@features/admin'
import { connectionCallbacksRoutes } from '@features/connection-callbacks'
import { uiKitRoutes } from '@features/ui-kit'
import { NotFoundPage, useRouteGuards } from '@features/common'
import { dashboardRoutes } from '@features/dashboard'

const routes = [
  ...dashboardRoutes(),
  ...transactionsRoutes(),
  ...importJobsRoutes(),
  ...accountRoutes(),
  ...stripeRoutes(),
  ...integrationsRoutes(),
  ...invoiceRoutes(),
  ...connectionCallbacksRoutes(),
  ...adminRoutes(),
  ...uiKitRoutes(),
  {
    component: NotFoundPage,
  },
]

export const Routes = () => renderRoutes(useRouteGuards(routes))
