import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Icon } from './index'

export const NavLink = ({ to, children, icon, indicator, exact = false }) => {
  const match = useRouteMatch(to)
  const location = useLocation()
  const isActive = match && (exact ? location.pathname === to : true)

  return (
    <NavLinkItem as={Link} to={to}>
      {icon && (
        <IconWrapper>
          <Icon name={icon} variant={isActive ? 'primary' : 'gray'} width={18} />
          {indicator}
        </IconWrapper>
      )}
      <Text>{children}</Text>
    </NavLinkItem>
  )
}

const NavItem = styled.div`
  padding-left: 36px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-decoration: none;
`

const NavHeader = styled(NavItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;

  > span {
    flex: 1;
    font-size: 16px;
  }
`

const NavLinkItem = styled(NavItem)`
  display: block;
  padding-left: 36px;
  padding-top: 18px;
  padding-bottom: 18px;

  &:hover {
    background-color: ${theme('color.lightGray3')};
  }
`

const Text = styled.span`
  margin-left: 16px;
  color: ${theme('color.dark')};
  font-weight: bold;
  font-size: 14px;
`

const IconWrapper = styled.span`
  position: relative;
`

NavLink.Item = NavLinkItem
NavLink.Header = NavHeader
NavLink.Text = Text
