import { handleFetching } from '@lib/fetching'
import { api } from './api'
import { actions } from './symbiotes'

export const invoicesFetch = () =>
  handleFetching(actions.fetch, {
    async run(dispatch) {
      const { data } = await api.getList()
      dispatch(actions.set(data))
    },
  })
