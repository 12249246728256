import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, FormGroup, FormSubmit, InputField, SelectField } from '@ui'
import { Col, Row } from '@lib/styled-layout'
import { Header } from '../components/Settings.styled'
import { billingAddressUpdate } from '../effects'
import { $address } from '../selectors'
import { useCountriesOptions } from '../hooks'

export const BillingAddressForm = () => {
  const dispatch = useDispatch()
  const address = useSelector($address)
  const countriesOptions = useCountriesOptions()

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      await dispatch(billingAddressUpdate(values))
      setSubmitting(false)
      setStatus(null)
    } catch (err) {
      setStatus(err.response)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={address}
      validationSchema={Yup.object().shape({
        city: Yup.string().required('City is required'),
        country: Yup.string().nullable().required('Country is required'),
        line1: Yup.string().required('Address Line 1 is required'),
        line2: Yup.string(),
        state: Yup.string().required('State is required'),
        postal_code: Yup.string()
          .required('Zip code is required'),
      })}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <Form>
        <FormGroup as={Row}>
          <Col md={4}>
            <Header>Billing Address</Header>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputField
                name="line1"
                label="Address Line 1"
              />
            </FormGroup>
            <FormGroup>
              <InputField
                name="line2"
                label="Address Line 2"
              />
            </FormGroup>
            <FormGroup>
              <InputField
                name="city"
                label="City"
              />
            </FormGroup>
            <FormGroup as={Row}>
              <Col md={8}>
                <InputField
                  name="state"
                  label="State"
                />
              </Col>
              <Col md={4}>
                <InputField
                  name="postal_code"
                  label="Zip"
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <SelectField
                name="country"
                label="Country"
                isSearchable
                isClearable
                options={countriesOptions}
              />
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup>
          <Flex justify="flex-end">
            <FormSubmit size="lg" checkDirty>SAVE CHANGES</FormSubmit>
          </Flex>
        </FormGroup>
      </Form>
    </Formik>
  )
}
