import styled from 'styled-components'
import { palette, theme } from 'styled-tools'

export const BlueLabel = styled.div`
  min-width: 53px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme('color.active')};
  color: ${palette('primary', 1)};
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  margin-right: 2px;
  text-transform: uppercase;
`
