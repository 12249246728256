import React, { useEffect, useState } from 'react'
import '../../../assets/css/auth0-lock.min.css'
import { userApi } from '@features/common'
import { useAuth0 } from '@lib/auth0'
import { Spinner } from '@ui'
import { EmailSent } from '../components/EmailSent'

export const MfaPage = () => {
  const { user: auth0User } = useAuth0()
  const [fetching, setFetching] = useState(true)
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    (async () => {
      setFetching(true)
      await userApi.sendMfaCode()
      setFetching(false)
    })()
  }, [])

  useEffect(() => {
    if (code.length === 6) {
      let cancelled = false
      setFetching(true)
      setError(null)

      const execute = async () => {
        const {
          status,
          message,
        } = await userApi.confirmMfaCode(code)

        if (!cancelled) {
          if (status !== 'OK') {
            setError(message)
            setFetching(false)
          } else {
            window.location.reload()
          }
        }
      }

      execute()

      return () => {
        cancelled = true
        setFetching(false)
      }
    }

    return () => { }
  // eslint-disable-next-line
  }, [code])

  return (
    <EmailSent title="Multi-Factor Auth">
      {fetching ? (
        <Spinner marginTop={0} />
      ) : (
        <>
          <p>We sent a code to <strong>{auth0User.email}</strong>. Please enter it below to log in.</p>
          <br />
          <p>
            <input
              type="text"
              style={{
                border: `1px solid ${error ? 'red' : '#A0A0A0'}`,
                textAlign: 'center',
                fontFamily: 'monospace',
                padding: '12px',
                fontSize: '16px',
                borderRadius: '12px',
                outline: 'none',
              }}
              onChange={(e) => {
                setCode(e.target.value.replace(/[^\d]/g, ''))
              }}
              value={code}
              maxLength={6}
            />
          </p>
          {error ? (
            <p>{error}</p>
          ) : null}
        </>
      )}
    </EmailSent>
  )
}
