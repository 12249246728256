import { useDispatch, useSelector } from 'react-redux'
import { valueOrTrue } from '@lib/convert'
import { useActions } from '@lib/hooks'
import { notify } from '@lib/notification'
import { filterEmpty } from '@lib/object'
import { useModal } from '@features/common'
import { sourceSettingsUpdate } from '../../effects/sources'
import { currentRuleActions } from '../../symbiotes'
import { $activeProcessorSettings } from '../../selectors/configuration'

export const useSettingsModel = () => {
  const createRuleModal = useModal()
  const editRuleModal = useModal()
  const deleteRuleModal = useModal()
  const dispatch = useDispatch()
  const currentRule = useActions(currentRuleActions)

  const { processor, settings } = useSelector($activeProcessorSettings)

  const initialValues = getInitialValues({ settings })

  const setCurrentRule = ({ account, position = null }) => {
    currentRule.set({ processorId: processor.id, account, position })
  }

  const prepareRuleCreate = ({ account }) => {
    createRuleModal.show()
    setCurrentRule({ account })
  }

  const prepareRuleEdit = ({ account, position }) => {
    editRuleModal.show()
    setCurrentRule({ account, position })
  }

  const prepareRuleRemove = ({ account, position }) => {
    deleteRuleModal.show()
    setCurrentRule({ account, position })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...values,
      tax_mapping: filterEmpty(values.tax_mapping),
    }

    try {
      await dispatch(sourceSettingsUpdate(processor.id, data))
      setSubmitting(false)
      notify({
        message: 'Configuration updated',
      })
    } catch (err) {
      setSubmitting(false)
      notify({
        type: 'danger',
        message: 'Something went wrong',
      })
    }
  }

  return {
    handleSubmit,
    prepareRuleCreate,
    prepareRuleEdit,
    prepareRuleRemove,
    createRuleModal,
    editRuleModal,
    deleteRuleModal,
    initialValues,
    processor,
  }
}

const getInitialValues = ({ settings }) => ({
  track_charges: valueOrTrue(settings.track_charges),
  track_refunds: valueOrTrue(settings.track_refunds),
  track_fees: valueOrTrue(settings.track_fees),
  track_app_fees: valueOrTrue(settings.track_app_fees),
  track_adjustments: valueOrTrue(settings.track_adjustments),
  track_disputes: valueOrTrue(settings.track_disputes),
  track_payouts: valueOrTrue(settings.track_payouts),

  bankfeeds_account: settings.bankfeeds_account,
  charges_account: settings.charges_account,
  fees_account: settings.fees_account,
  app_fees_account: settings.app_fees_account,
  refunds_account: settings.refunds_account,
  payouts_account: settings.payouts_account,
  disputes_account: settings.disputes_account,
  adjustments_account: settings.adjustments_account,

  name_display: valueOrTrue(settings.name_display),
  email_display: settings.email_display,
  email_real_display: valueOrTrue(settings.email_real_display),
  import_source_currency: valueOrTrue(settings.import_source_currency),
  import_country: valueOrTrue(settings.import_country),
  tag_display: valueOrTrue(settings.tag_display),

  skip_invoices: valueOrTrue(settings.skip_invoices),
  import_invoices: settings.import_invoices,
  import_source_payments: settings.import_source_payments,
  import_payment_descriptor: settings.import_payment_descriptor,
  tax_mapping: settings.tax_mapping || {},
})
