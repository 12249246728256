import styled from 'styled-components'
import * as P from 'prop-types'
import { prop, theme } from 'styled-tools'

export const Divider = styled.div`
  border-top: 1px solid ${theme('color.border')};
  background-color: ${theme('color.border')};
  height: 1px;
  margin: ${prop('margin')}px 0;
`

Divider.propTypes = {
  margin: P.number,
}

Divider.defaultProps = {
  margin: 20,
}
