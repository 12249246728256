import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from '@lib/styled-layout'
import { useQueryParams, useSyncQueryParams, useTabs } from '@lib/hooks'
import { H1, TabPane, Tabs } from '@ui'
import { $currentUser, MainTemplate } from '@features/common'
import { invoicesFetch } from '@features/invoices'
import { settingsFetch as notificationsSettingsFetch } from '@features/notifications'
import { Profile } from './profile'
import { Payment } from './payment'
import { Plans } from './plans'
import { Notifications } from './notifications'
import { paymentFetch } from '../effects'

export const AccountSettingsPage = () => {
  const dispatch = useDispatch()
  const { tab } = useQueryParams()

  useEffect(() => {
    dispatch(paymentFetch())
    dispatch(invoicesFetch())
    dispatch(notificationsSettingsFetch())
  }, [dispatch])

  const { activeTab, tabs, activeKey } = useTabs(useTabsData(), {
    activeKey: tab,
  })

  useSyncQueryParams(useMemo(() => ({ tab: activeKey }), [activeKey]))

  return (
    <MainTemplate>
      <Row>
        <Col>
          <H1 mb="2rem">Account Settings</H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
      <Row>
        <Col xl={10}>
          <TabPane
            {...activeTab.getContentProps()}
            className="animated fadeInRight"
          >
            {activeTab.render('Content')}
          </TabPane>
        </Col>
      </Row>
    </MainTemplate>
  )
}

const useTabsData = () => {
  const user = useSelector($currentUser)

  const tabsData = [
    {
      key: 'profile',
      Title: 'PROFILE',
      Content: () => <Profile />,
    },
    {
      key: 'notifications',
      Title: 'NOTIFICATIONS',
      Content: () => <Notifications />,
    },
  ]
  
  if (user.stripe_customer_id) {
    tabsData.push(    {
      key: 'plans',
      Title: 'PLANS',
      Content: () => <Plans />,
    },
    {
      key: 'payment',
      Title: 'BILLING',
      Content: () => <Payment />,
    })
  }

  return tabsData
}
