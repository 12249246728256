import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'

export const SortableHeader = ({ column }) => (
  <th {...column.getHeaderProps({ width: column.width })}>
    {column.disableSorting
      ? column.render('Header')
      : (
        <SortButton {...column.getSortByToggleProps()}>
          {column.render('Header')}{' '}
          <SortIcon column={column} />
        </SortButton>
      )}
  </th>
)

export const SortButton = styled.a`
  display: inline-block;
  color: inherit !important;
  text-decoration: none !important;
  user-select: none;
  white-space: nowrap;
`

const SortIcon = ({ column }) => column.isSorted
  ? <Icon name={column.isSortedDesc ? 'arrow' : 'arrow-up'} />
  : null
