import React from 'react'
import { Alert } from '@ui'
import { Link } from 'react-router-dom'

export const LimitReachedAlert = () => (
  <Alert noButton>
    If you need to add more payment integrations,{' '}
    <Link to="/settings?tab=plans">
      please upgrade your account.
    </Link>
  </Alert>
)
