import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { MainTemplate } from '@features/common'
import { useActions, useQueryParams } from '@lib/hooks'
import { TabLink, TabsWrapper, Heading } from '@ui'
import { importInitialize, resetData } from '../effects'
import { currentImportActions } from '../symbiotes'

export const ImportJobsPage = ({ route }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(importInitialize())
    return () => dispatch(resetData())
  }, [dispatch])

  useLoadQueryDates()

  return (
    <MainTemplate heading={<Heading>Data Import</Heading>}>
      <TabsWrapper>
        <TabLink to="/imports">TRANSACTIONS</TabLink>
        <TabLink to="/imports/history">HISTORY</TabLink>
      </TabsWrapper>
      {renderRoutes(route.routes)}
    </MainTemplate>
  )
}

const useLoadQueryDates = () => {
  const { start, end } = useQueryParams()
  const { setDates } = useActions(currentImportActions)

  useEffect(() => {
    if (start && end) {
      const queryStart = dateToUnix(new Date(start))
      const queryEnd = dateToUnix(new Date(end))
      setDates([queryStart, queryEnd])
    }
  }, [start, end, setDates])
}

const dateToUnix = (date) => !isNaN(date) ? date.getTime() : null
