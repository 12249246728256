import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  DangerButton,
  Flex,
  FormGroup,
  FormStatus,
  FormSubmit,
  InputField,
  SaveFormChangesDialog,
  SelectField,
} from '@ui'
import { Col, Row } from '@lib/styled-layout'
import { Portal } from '@lib/portal'
import { FormApiError } from '@lib/form-utils'
import { $currentUser, Guard, useModal } from '@features/common'
import { FormDivider, Header } from '../components/Settings.styled'
import { DeleteAccountModal, WhyDeleteAccountModal } from '../components/CancelModals'
import { timezonesOptions } from '../hooks'
import { userSettingsUpdate } from '../effects'
import { dateFormats } from '../constants'

export const Profile = () => {
  const dispatch = useDispatch()
  const user = useSelector($currentUser)
  const deleteModal = useModal()
  const whyDeleteModal = useModal(false)

  const update = async (values) => {
    await dispatch(userSettingsUpdate(values))
  }

  const toggleModals = () => {
    deleteModal.close()
    whyDeleteModal.show()
  }

  return (
    <>
      <ProfileForm
        user={user}
        onSubmit={update}
      />
      <Guard isActive>
        <FormGroup>
          <DangerButton onClick={deleteModal.show}>
            Delete Account
          </DangerButton>
        </FormGroup>
      </Guard>
      <DeleteAccountModal
        show={deleteModal.isShown}
        onConfirm={toggleModals}
        onClose={deleteModal.close}
      />
      <WhyDeleteAccountModal
        show={whyDeleteModal.isShown}
        onClose={whyDeleteModal.close}
      />
    </>
  )
}

const ProfileForm = ({ user, onSubmit }) => {
  const handleSubmit = async (values, { setSubmitting, setStatus, setFieldValue }) => {
    try {
      await onSubmit(values)
      setSubmitting(false)
      setStatus(null)
      setFieldValue('password', '')
      setFieldValue('confirm_password', '')
    } catch (err) {
      setStatus(err.response)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(user)}
      enableReinitialize
      initialStatus={null}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Portal id="dialog">
          <SaveFormChangesDialog />
        </Portal>
        <FormGroup>
          <FormStatus render={(error) => (
            <FormApiError error={error} />
          )} />
        </FormGroup>
        <FormGroup as={Row}>
          <Col md={4}>
            <Header>Basic information</Header>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputField
                name="name"
                label="Full name"
              />
            </FormGroup>
            <FormGroup>
              <InputField
                name="company_name"
                label="Company name"
              />
            </FormGroup>
            <FormGroup>
              <InputField
                name="email"
                label="Email"
                autoComplete="email"
              />
            </FormGroup>
          </Col>
        </FormGroup>
        <FormDivider />
        <FormGroup as={Row}>
          <Col md={4}>
            <Header>Change password</Header>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputField
                name="password"
                label="New Password"
                type="password"
                autoComplete="new-password"
              />
            </FormGroup>
            <FormGroup>
              <InputField
                name="confirm_password"
                label="Confirm new password"
                type="password"
                autoComplete="new-password"
              />
            </FormGroup>
          </Col>
        </FormGroup>
        <FormDivider />
        <FormGroup as={Row}>
          <Col md={4}>
            <Header>Options</Header>
          </Col>
          <Col md={8}>
            <FormGroup>
              <SelectField
                name="timezone"
                label="Time Zone"
                options={timezonesOptions}
                isSearchable
                isClearable
              />
            </FormGroup>
            <FormGroup>
              <SelectField
                name="date_format"
                label="Date format"
                options={dateFormatOptions}
              />
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup>
          <Flex justify="flex-end">
            <FormSubmit size="lg" checkDirty>SAVE CHANGES</FormSubmit>
          </Flex>
        </FormGroup>
      </Form>
    </Formik>
  )
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
const WeakPasswordError = () => (
  <>
    Password should contain at least:
    <div>- one uppercase letter (A-Z);</div>
    <div>- one lowercase letter (a-z);</div>
    <div>- one number (0-9);</div>
    <div>- one special character (?=.*?[#?!@$%^&*-])</div>
  </>
)

const getInitialValues = (user) => ({
  name: user.name || '',
  company_name: user.company_name || '',
  email: user.email || '',
  timezone: user.timezone || '',
  date_format: user.date_format || '',
  password: '',
  confirm_password: '',
})

const schema = Yup.object().shape({
  name: Yup.string().required('Full name is required'),
  company_name: Yup.string().required('Company name is required'),
  email: Yup.string().matches(emailRegex, 'Invalid email address').required('Email is required'),
  password: Yup.string().matches(passwordRegex, WeakPasswordError).min(8),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .when('password', (password, schema) =>
      !!password ? schema.required('Confirm password is required') : schema),
  timezone: Yup.string().required('Timezone is required').nullable(),
  date_format: Yup.string(),
})

const dateFormatOptions = dateFormats.map((item) => ({ label: item, value: item }))
