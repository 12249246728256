import React, { useEffect, useMemo, useState } from 'react'
import * as P from 'prop-types'
import styled, { css } from 'styled-components'
import Downshift from 'downshift'
import { ifProp, theme } from 'styled-tools'
import { FixedSizeList } from 'react-window'
import { isEmpty } from '../../lib/is'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { ErrorLabel, InputLabel } from '../Label'
import { Menu, MenuPositioner, MenuItem } from './Menu'
import { ZeroButton } from '../Buttons'
import { Positioner } from '../Positioner'
import { CustomScrollbarsList } from './Scrollbars'

const itemToString = (item) =>
  (item === null || item === undefined) ? '' : String(item.label)

const getMatchedOptions = (options, value) => {
  const valueLower = value.toLowerCase()
  return options
    .filter((item) => !value || String(item.label.toLowerCase()).includes(valueLower))
}

const itemHeight = 36
const menuHeight = itemHeight * 6

export const Select = ({
  label = null,
  placeholder = 'Select an item...',
  name,
  options = [],
  value,
  error,
  onChange,
  onBlur,
  disabled = false,
  isSearchable = false,
  isClearable = false,
  menuPosition = 'bottom',
  components: {
    Input = StyledInput,
    MenuContainer = MenuPositioner,
    Expander = DefaultExpander,
  } = {},
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const virtualListHeight = Math.min(menuHeight, filteredOptions.length * itemHeight)

  // reinitialize options when prop options is changed
  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const selectedItem = useMemo(
    () => options.find((op) => op.value === value) || null,
    [options, value],
  )

  const handleStateChange = (changes) => {
    if (changes.hasOwnProperty('selectedItem')) {
      // if selection is cleared, changes.selectedItem will be undefined
      let value = changes.selectedItem?.value
      // cast undefined to null
      value = isEmpty(value) ? null : value

      if (name) onChange(name, value)
      else onChange(value)
    }

    if (isSearchable) {
      // input value is changed, set filtered options
      if (changes.type === Downshift.stateChangeTypes.changeInput) {
        setFilteredOptions(getMatchedOptions(options, changes.inputValue))
      }
      // menu is opened, reset filtered options
      else if (changes.type === Downshift.stateChangeTypes.clickButton) {
        setFilteredOptions(options)
      }
    }
  }

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Downshift
        selectedItem={selectedItem}
        itemToString={itemToString}
        onStateChange={handleStateChange}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getMenuProps,
          getItemProps,
          getRootProps,
          isOpen,
          clearSelection,
          selectedItem,
          inputValue,
          highlightedIndex,
        }) => {
          const inputProps = getInputProps({
            error,
            disabled,
            placeholder,
            isSearchable,
            onBlur,
            isActive: isOpen,
          })

          return (
            <Container>
              <Toggler
                {...getToggleButtonProps()}
                {...getRootProps()}
                disabled={disabled}
              >
                <Input {...inputProps} />
                <Positioner position="right">
                  <Expander
                    clearSelection={clearSelection}
                    inputValue={inputValue}
                    isClearable={isClearable}
                    selectedItem={selectedItem}
                  />
                </Positioner>
              </Toggler>
              <MenuContainer position={menuPosition}>
                <Menu {...getMenuProps()} isOpen={isOpen}>
                  {filteredOptions.length > 0 ? (
                    <FixedSizeList
                      outerElementType={CustomScrollbarsList}
                      height={virtualListHeight}
                      itemSize={itemHeight}
                      itemCount={filteredOptions.length}
                      itemData={{
                        items: filteredOptions,
                        getItemProps,
                        highlightedIndex,
                        selectedItem,
                        itemToString,
                      }}
                    >
                      {ItemRenderer}
                    </FixedSizeList>
                  ) : (
                    <MenuItem>No result</MenuItem>
                  )}
                </Menu>
              </MenuContainer>
            </Container>
          )
        }}
      </Downshift>
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  )
}

const DefaultExpander = ({ isClearable, selectedItem, inputValue, clearSelection }) => (
  <>
    {isClearable && (selectedItem || inputValue) ? (
      <ControllerBtn onClick={clearSelection}>
        <Icon name="cancel" width={12} height={12} variant="gray" />
      </ControllerBtn>
    ) : (
      <ControllerBtn>
        <Icon name="arrow" width={11} height={6} />
      </ControllerBtn>
    )}
  </>
)

const ItemRenderer = ({
  index,
  style,
  data: { items, getItemProps, selectedItem, highlightedIndex },
}) => {
  const item = items[index]
  return (
    <MenuItem
      key={`${itemToString(item)}${index}`}
      {...getItemProps({
        item,
        index,
        isSelected: selectedItem === item,
        isActive: highlightedIndex === index,
        style,
      })}
    >
      {item.label}
    </MenuItem>
  )
}

Select.propTypes = {
  label: P.string,
  options: P.arrayOf(P.shape({
    label: P.any.isRequired,
    value: P.any.isRequired,
  })).isRequired,
  name: P.string,
  value: P.any,
  error: P.oneOfType([P.string, P.bool]),
  onChange: P.func.isRequired,
  onBlur: P.func,
  disabled: P.bool,
  isSearchable: P.bool,
  isClearable: P.bool,
  components: P.object,
}

const Container = styled.div`
  position: relative;
`

const Toggler = styled.div`
  position: relative;
  pointer-events: ${(p) => p.disabled ? 'none': 'initial'};
  -webkit-appearance: initial !important;
  cursor: pointer;
`

const ControllerBtn = styled(ZeroButton).attrs({ type: 'button' })`
  right: 0;
  top: 0;
`

const StyledInput = styled(Input)`
  pointer-events: ${ifProp('isSearchable', 'auto', 'none')};
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  ${ifProp('isActive', css`
    border-color: ${theme('color.gray')};
  `)}
`
