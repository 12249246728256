import React from 'react'
import { useRequest } from '@lib/fetching'
import { useQueryParams } from '@lib/hooks'
import { MainTemplate } from '@features/common'
import { api } from '../api'
import { useConnectSource } from '../hooks'
import { CallbackView } from '../components/CallbackView'

export const StripeCallbackPage = () => {
  const { code } = useQueryParams()
  const connectRequest = useRequest(() => api.connectStripe(code))
  useConnectSource(connectRequest, { code })

  return (
    <MainTemplate>
      <CallbackView
        isLoading={connectRequest.isLoading}
        error={connectRequest.error}
        params={{ code }}
      />
    </MainTemplate>
  )
}
