import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { useTablePagination } from '@lib/table-hooks'
import { useSkipFirstRunEffect } from '@lib/hooks'
import { useRequest } from '@lib/fetching'
import { useModal } from '@features/common'
import { CellDescription } from '@features/transactions'
import { PaginatedTable, Box, Interactivity, Spinner } from '@ui'
import {
  $count,
  $isInitialLoading,
  $isLoading,
  $pageCount,
  $totalCount,
  $transactionsForTable,
} from '../selectors/transactions'
import { importJobComplete } from '../effects'
import { importingTransactionsFetch } from '../effects/transactions'
import { defaultSort, pageSizes } from '../constants'
import { JobNoResultsView } from './ResultsViews'
import { ResultsBlock } from './ResultsBlock'
import { ConfirmImportModal } from './Modals'
import { ImportOptionsForm } from './ImportOptionsForm'
import { $currentImportTypes } from '../selectors/current-import'

export const SearchResults = () => {
  const confirmModal = useModal()
  const isLoading = useSelector($isLoading)
  const isInitialLoading = useSelector($isInitialLoading)
  const totalCount = useSelector($totalCount)
  const {
    table,
    pagination,
    completeImportJob,
  } = useModel()

  if (isInitialLoading) {
    return <Spinner marginTop={0} />
  }
  // Null means no data is fetched yet
  else if (totalCount === null) {
    return null
  } else if (totalCount === 0) {
    return <JobNoResultsView />
  }

  return (
    <Box title="Search Results">
      <ResultsBlock
        onImportClick={confirmModal.show}
      />
      <ImportOptionsForm />
      <Interactivity off={isLoading}>
        <PaginatedTable
          table={table}
          pagination={pagination}
        />
      </Interactivity>
      <ConfirmImportModal
        modal={confirmModal}
        onConfirm={completeImportJob}
        loading={completeImportJob.isLoading}
      />
    </Box>
  )
}

const useModel = () => {
  const dispatch = useDispatch()
  const table = useTransactionsTable()
  const pagination = useTablePagination(table, useSelector($count))
  const types = useSelector($currentImportTypes)

  const {
    state: { pageIndex, pageSize, sortBy },
  } = table

  const completeImportJob = useRequest(() => dispatch(importJobComplete()))

  useSkipFirstRunEffect(() => {
    const params = {
      page: pageIndex + 1,
      pageSize,
      sort: sortBy[0],
      filters: {
        types,
      },
    }

    dispatch(importingTransactionsFetch(params))
  }, [pageSize, pageIndex, sortBy, types, dispatch])

  return {
    table,
    pagination,
    completeImportJob,
  }
}

const useTransactionsTable = () => useTable(
  {
    columns: useMemo(() => ([
      { accessor: 'processed_at', width: 190, Header: 'Date' },
      { accessor: 'payment_type', width: 120, Header: 'Type' },
      { accessor: 'description', width: 200, Header: 'Description', Cell: CellDescription },
      { accessor: 'amount', width: 140, Header: 'Amount' },
    ]), []),
    data: useSelector($transactionsForTable),
    pageCount: useSelector($pageCount),
    manualSortBy: true,
    manualPagination: true,
    initialState: {
      pageSize: pageSizes.transactions,
      sortBy: [defaultSort.transactions],
    },
  },
  useGlobalFilter,
  useSortBy,
  usePagination,
)
