import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { initialDataFetch, accountFetch } from '@features/session'
import { sourcesFetch } from '@features/integrations'
import { history } from '@lib/routing'

export const useConnectSource = (connectRequest, params) => {
  const dispatch = useDispatch()
  const paramsIsValid = Object.values(params).every(Boolean)

  const connect = useCallback(() => {
    const connect = async () => {
      const { data } = await connectRequest()
      dispatch(accountFetch())
      dispatch(sourcesFetch())

      if (data?.settings?.id) {
        history.push(`/configuration/${data.id}`)
      }
    }

    if (paramsIsValid) {
      connect()
    }
  }, [connectRequest, dispatch, paramsIsValid])

  useEffect(connect, [connect])
}

export const useConnectIntegration = (connectRequest, params) => {
  const dispatch = useDispatch()
  const paramsIsValid = Object.values(params).every(Boolean)

  const connect = useCallback(() => {
    const connect = async () => {
      await connectRequest()
      dispatch(initialDataFetch())
      dispatch(sourcesFetch())
      history.push('/integrations')
    }

    if (paramsIsValid) {
      connect()
    }
  }, [connectRequest, dispatch, paramsIsValid])

  useEffect(connect, [connect])
}
