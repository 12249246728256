import { createSelector } from 'reselect'
import { $rulesByProcessorId } from './sources'
import { $rulesByAccounts } from './configuration'
import { ruleEqualsMeta } from '../mappers'

const $root = (root) => root.currentRule

export const $currentRule = createSelector(
  $root,
  $rulesByProcessorId,
  ({ processorId, account, position }, rulesRegistry) => {
    const rules = rulesRegistry[processorId] || []

    const rule = rules.find(
      (rule) => ruleEqualsMeta(rule, { account, position }),
    )

    return {
      rule,
      processorId,
      account,
      position,
    }
  },
)

export const $rulesForCurrentRuleAccount = createSelector(
  $rulesByAccounts,
  $root,
  (rules, { account }) => rules[account] || [],
)
