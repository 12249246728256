import React from 'react'
import { CreditCardPreview } from '@ui'
import { useTheme } from '@lib/theme'
import visaLogo from '../../../assets/images/visa-logo.svg'
import mastercardLogo from '../../../assets/images/mastercard-logo.svg'

export const CardPreview = ({ card, defaultCard, isFirst, onClick }) => {
  const { color, name } = useCardInfo(card.brand)
  const Logo = nameToLogoComponent(name)
  const isDefault = defaultCard ? defaultCard === card.id : isFirst

  return (
    <CreditCardPreview
      number={card.number}
      validMonth={card.exp_month}
      validYear={card.exp_year}
      minimized={!isDefault}
      Logo={<Logo name={name} />}
      color={color}
      onClick={onClick}
    />
  )
}

const useCardInfo = (brand) => {
  const { color } = useTheme()
  const brandLower = brand.toLowerCase()

  const bgColor = ({
    visa: color.purple.light,
    mastercard: color.purple.dark,
    amex: color.violet,
  })[brandLower] || color.bluegreen

  return {
    color: bgColor,
    name: brandLower,
  }
}

const nameToLogoComponent = (name) => ({
  visa: VisaLogo,
  mastercard: MasterCardLogo,
}[name] || DefaultLogo)

const VisaLogo = () => (
  <img src={visaLogo} alt="visa-logo" />
)

const MasterCardLogo = () => (
  <img src={mastercardLogo} alt="master-card-logo" />
)

const DefaultLogo = ({ name }) => String(name).toUpperCase()

