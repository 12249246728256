import React from 'react'
import * as P from 'prop-types'
import { AnimatedCircle, CenteredText, Container } from './styled'
import { getRadius } from './utils'
import { Lines } from './Lines'
import { Svg } from './Svg'

export const DoughnutChartMini = ({ title, value, color }) => {
  value = Number(value)

  const border = 5
  const circleBorder = 2
  const size = 42
  const center = size / 2
  const bgRadius = getRadius()
  const circleRadius = bgRadius + 1.5
  const dasharrayRatio = 1.1 // depends on circleRadius

  return (
    <Container>
      <Svg size={size}>
        <g>
          <CenteredText>
            {title}
          </CenteredText>
          <circle
            cx={center}
            cy={center}
            r={bgRadius}
            fill="transparent"
            stroke="#EDF2F7"
            strokeWidth={border}
          />
          <AnimatedCircle
            cx={center}
            cy={center}
            r={circleRadius}
            rounded
            transform={`rotate(-90 ${center} ${center})`}
            stroke={color}
            strokeWidth={circleBorder}
            strokeDasharray={`${value * dasharrayRatio} ${dasharrayRatio * 100}`}
          />
        </g>
        <g>
          <Lines
            center={center}
            count={24}
            lineWidth={0.7}
            lineHeight={1}
          />
        </g>
      </Svg>
    </Container>
  )
}

DoughnutChartMini.propTypes = {
  value: P.oneOfType([P.string, P.number]).isRequired,
  color: P.string.isRequired,
  children: P.node,
}
