import React from 'react'
import { useFormikContext } from 'formik'
import { Button } from '@ui'

export const UseDefaultAccountsButton = () => {
  const { setValues, values } = useFormikContext()

  const defaults = {
    charges_account: '200',
    fees_account: '310',
    app_fees_account: '310',
    refunds_account: '200',
    disputes_account: '260',
    adjustments_account: '260',
  }

  const accountKeys = Object.keys(defaults)
  const someIsEmpty = accountKeys.some((key) => values[key] === null)

  const handleClick = () => {
    const accounts = {}
    for (const key of accountKeys) {
      accounts[key] = values[key] || defaults[key]
    }

    setValues({
      ...values,
      ...accounts,
    })
  }

  return someIsEmpty ? (
    <Button
      size="xs"
      variant="link"
      onClick={handleClick}
    >
      Use default settings
    </Button>
  ) : null
}
