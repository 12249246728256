import { useSelector } from 'react-redux'
import { $isSettingsLoading, $sources, $sourcesIsLoading, $integrationsList } from '@features/integrations'
import { $currentPlanName } from '@features/common'
import { Plans } from '../components/Plans'
import { AccountingSoftware } from '../components/AccountingSoftware'
import { PaymentProcessor } from '../components/PaymentProcessor'

export const steps = [
  {
    id: 'createAccount',
    color: '#5ACCD4',
    text: 'Create your account',
    title: 'Create your account',
  },
  {
    id: 'selectPlan',
    color: '#8484D7',
    text: 'Select a Bankfeeds Plan',
    subtitle: '14 days free, cancel anytime',
    title: 'Select a Bankfeeds plan',
    Component: Plans,
  },
  {
    id: 'connectAccounting',
    color: '#7697E0',
    text: 'Connect your Accounting Software',
    title: 'Connect your accounting software',
    Component: AccountingSoftware,
  },
  {
    id: 'connectPayments',
    color: '#9F5AD4',
    text: 'Connect your Payments Data',
    title: 'Connect your payments data',
    Component: PaymentProcessor,
  },
]

export const useModel = () => {
  const subscription = useSelector($currentPlanName)
  const integrationsLoading = useSelector($isSettingsLoading)
  const sourcesLoading = useSelector($sourcesIsLoading)
  const isLoading = integrationsLoading || sourcesLoading
  const integrations = useSelector($integrationsList)
  const sources = useSelector($sources)

  let activeStep = 1

  if (isLoading) {
    return -1
  } else {
    // Check if the user has a plan.
    if (subscription && subscription !== 'trial') {
      activeStep = 2

      // Check if the user has integrations.
      if (integrations.length > 0) {
        activeStep = 3

        // Check if the user has sources.
        if (sources.length > 0) {
          activeStep = 4
        }
      }
    }
  }

  const currentStep = steps[activeStep] || null
  const StepComponent = currentStep && currentStep.Component

  return {
    activeStep,
    currentStep,
    StepComponent,
    steps,
  }
}
