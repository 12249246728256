import React from 'react'
import styled from 'styled-components'
import { Card } from '@ui'
import { ConnectIntegrationModal } from '@features/integrations'
import { ConnectXeroButton } from './ConnectXeroButton'
import { useModal } from '../hooks'

export const ConnectXeroBlock = () => {
  const modal = useModal()

  return (
    <Content>
      <Card size="sm">
        <p>When you are ready to start adding transactions to your accounting platform</p>
        <ConnectXeroButton size="full" onClick={modal.show}>Connect Integration</ConnectXeroButton>
      </Card>
      <ConnectIntegrationModal
        title="Connect integration"
        show={modal.isShown}
        onClose={modal.close}
      />
    </Content>
  )
}

const Content = styled.div`
  margin: 0.5rem;
`
