import React from 'react'
import { MainTemplate } from '@features/common'
import { H2, H4, Card } from '@ui'

export const NotFoundPage = () => (
  <MainTemplate>
    <NotFoundChildPage />
  </MainTemplate>
)

export const NotFoundChildPage = () => (
  <Card>
    <H2>404 :-(</H2>
    <H4>Oops! Sorry, we couldn't find the page you requested.</H4>
  </Card>
)
