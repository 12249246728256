import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { processors } from '@lib/constants'
import { MainTemplate } from '@features/common'
import { $sources, $sourcesSettings } from '@features/integrations'
import {
  Button,
  Heading,
  SortableHeader,
  Spinner,
  Table,
  TableBody,
  TableHead,
  PaginationBlock,
} from '@ui'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { useSortBy, useTable } from 'react-table'
import { api } from '../api'

const useReconciliationTable = ({ data }) => useTable(
  {
    columns: useMemo(() => [
      { accessor: 'bankfeedsID', Header: 'BF ID', Cell: ({ cell }) => (<a href={`/transaction/${cell.value}`} target="_blank" rel="noopener noreferrer">{cell.value}</a>) },
      { accessor: 'bankfeedsType', Header: 'Type' },
      { accessor: 'xeroID', Header: 'Xero ID', Cell: ({ cell }) => (<a href={`https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=${cell.value}`} target="_blank" rel="noopener noreferrer">{cell.value}</a>) },
      { accessor: 'date', Header: 'Date', Cell: ({ cell }) => dayjs(cell.value).format('YYYY-MM-DD') },
      { accessor: 'reference', Header: 'Reference' },
      { accessor: 'contactName', Header: 'Contact Name' },
      { accessor: 'amount', Header: 'Amount',
        Cell: ({ cell }) => cell.value ? cell.value.toFixed(2) : 'N/A',
      },
      { accessor: 'bankfeedsStatus', Header: 'Status' },
    ], []),
    data,
  },
  useSortBy,
)

const ReconciliationTable = ({ table }) => (
  <Table {...table.getTableProps()}>
    <TableHead
      headerGroups={table.headerGroups}
      renderColumnHeader={(column) => (
        <SortableHeader
          key={column.id}
          column={column}
        />
      )}
    />
    <TableBody
      rows={table.rows}
      prepareRow={table.prepareRow}
      renderRow={(row) => {
        const transaction = row.original
        const style = transaction.occurences > 1 ? (transaction.isNewest ? { color: 'blue' } : { color: 'red' }) : null
        return (
          <tr {...row.getRowProps({ style })}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        )
      }}
    />
  </Table>
)

export const Search = ({ account }) => {
  const [xeroSearching, setXeroSearching] = useState(true)
  const [bankfeedsSearching, setBankfeedsSearching] = useState(true)
  const [xeroData, setXeroData] = useState([])
  const [bankfeedsData, setBankfeedsData] = useState([])
  const [reconciliationList, setReconciliationList] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [showAnomalies, setShowAnomalies] = useState(false)
  const filtered = showAnomalies ?
    reconciliationList.filter((transaction) => (!transaction.bankfeedsID || (!transaction.xeroID && transaction.bankfeedsStatus !== 'skipped')))
    : reconciliationList

  const tableData = useMemo(
    () => {
      const filtered = showAnomalies ? reconciliationList.filter((transaction) => (!transaction.bankfeedsID || (!transaction.xeroID && transaction.bankfeedsStatus !== 'skipped'))) : reconciliationList
      return filtered.slice(currentPageIndex * pageSize, currentPageIndex * pageSize + pageSize)
    },
    [reconciliationList, currentPageIndex, pageSize, showAnomalies],
  )

  const table = useReconciliationTable({
    data: tableData,
  })

  useEffect(() => {
    const reconciled = []

    // Make copies of the data.
    const xero = xeroData.slice(0)
    const bankfeeds = bankfeedsData.slice(0)

    while (xero.length > 0) {
      const xeroTransaction = xero.splice(0, 1)[0]

      // Find an exact match.
      const exactMatchIndex = bankfeeds.findIndex(({ bankfeedsReference }) => (bankfeedsReference === xeroTransaction.reference))

      if (exactMatchIndex > -1) {
        // Add them both.
        const bankfeedsTransaction = bankfeeds.splice(exactMatchIndex, 1)[0]
        reconciled.push({
          ...bankfeedsTransaction,
          ...xeroTransaction,
          date: bankfeedsTransaction.bankfeedsDate,
        })

        // TODO: Add approximate match.
      } else {
        // No matches.
        reconciled.push(xeroTransaction)
      }
    }

    reconciled.sort((a, b) => {
      if (b.date > a.date) {
        return -1
      }

      if (a.date > b.date) {
        return 1
      }

      return 0
    })

    // Add all remaining BF transactions to the reconciled list.
    reconciled.push(...bankfeeds.map((transaction) => ({
      ...transaction,
      date: transaction.bankfeedsDate,
      amount: transaction.bankfeedsAmount,
    })))
    setReconciliationList(reconciled)
  }, [xeroData, bankfeedsData, xeroSearching, bankfeedsSearching])

  useEffect(() => {
    let cancel = false
    setBankfeedsData([])
    setBankfeedsSearching(true);

    (async () => {
      let page = 1
      let lastRequestData = null

      while (!cancel && (!lastRequestData || lastRequestData.length)) {
        const { data } = await api.getList({
          pageSize: 100,
          page,
          sort: { id: 'processed_at', desc: false },
          filters: { types: ['adjustment', 'dispute_adjustment', 'fee_refund', 'refund', 'fee', 'charge', 'app_fee'] },
        })
        lastRequestData = data
        setBankfeedsData((bankfeedsData) => bankfeedsData.concat(data.map((transaction) => ({
          bankfeedsID: transaction.id,
          bankfeedsDate: transaction.processed_at,
          bankfeedsType: transaction.payment_type,
          bankfeedsReference: String(
            `${transaction.source 
            }_${ 
              transaction.payment_type.replace('_refund', '') 
            }_${ 
              String(transaction.payment_id).substring(4)}`,
          ),
          bankfeedsAmount: parseFloat(transaction.amount),
          bankfeedsStatus: transaction.status,
        }))))
        
        page++
      }

      if (!cancel) {
        setBankfeedsSearching(false)
      }
    })()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false
    setXeroSearching(true)
    setXeroData([]);
    
    (async () => {
      let page = 1
      let lastRequestData = null

      while (!cancel && (!lastRequestData || lastRequestData.length)) {
        lastRequestData = await api.accountsReconciliation(account, page)
        const data = lastRequestData
        setXeroData((xeroData) => xeroData.concat(data))
        page++
      }

      if (!cancel) {
        setXeroSearching(false)
      }
    })()

    return () => {
      cancel = true
    }
  }, [account])

  return (
    <>
      <Button onClick={() => setShowAnomalies((b) => !b)}>
        {showAnomalies ? 'Show all' : 'Show only anomalies'}
      </Button>
      {(xeroSearching || bankfeedsSearching) ? (
        <Spinner />
      ) : null}
      <ReconciliationTable table={table} />
      <PaginationBlock
        pagination={{
          page: currentPageIndex + 1,
          pageCount: Math.floor(filtered.length / pageSize) + 1,
          nextPage: () => setCurrentPageIndex((i) => (i + 1)),
          previousPage: () => setCurrentPageIndex((i) => (i - 1)),
          gotoPage: (page) => setCurrentPageIndex(page - 1),
          count: filtered.length,
          pageSize,
          setPageSize,
        }}
      />
    </>
  )
}

const $defaultSearchAccount = createSelector(
  $sources,
  $sourcesSettings,
  (sources, settings) => {
    const stripeSource = sources.find((s) => s.connection_source === processors.STRIPE)
    if (!stripeSource) return null
    const stripeSettings = settings.find((setting) => setting.connection_id === stripeSource.id)
    return stripeSettings ? stripeSettings.bankfeeds_account : null
  },
)

export const AccountsReconciliationPage = () => {
  const searchAccount = useSelector($defaultSearchAccount)

  if (!searchAccount) {
    return null
  }

  return (
    <MainTemplate heading={<Heading>Accounts Reconciliation</Heading>}>
      <Search account={searchAccount} />
    </MainTemplate>
  )
}
