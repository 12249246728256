import React from 'react'
import { ReactNotification } from '@lib/notification'
import { AccountAlerts, Sidebar } from '@features/common'
import { Normalize } from 'styled-normalize'
import { Container } from '@lib/styled-layout'
import { MainWrapper } from '@ui/layout'
import { useSelector } from 'react-redux'
import { $currentUser } from '../selectors'

export const MainTemplate = ({ heading, topSection, children }) => {
  const user = useSelector($currentUser)

  return (
    <>
      {user.setup_complete ? (
        <Sidebar />
      ) : null}
      <MainWrapper>
        {topSection}
        <Container className="animated fadeInRight">
          <div className="animated fadeIn">
            {heading}
          </div>
          <AccountAlerts />
          {children}
        </Container>
        <ReactNotification />
      </MainWrapper>
      <Normalize />
    </>
  )
}
