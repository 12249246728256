import React from 'react'
import { range } from '@lib/array'

export const Lines = ({ center, count, lineWidth, lineHeight }) =>
  range(count).map((i) => (
    <line
      key={i}
      x1={center}
      y1={0}
      x2={center}
      y2={lineHeight}
      style={{
        stroke: 'rgba(0,0,0,0.15)',
        strokeWidth: lineWidth,
      }}
      transform={`rotate(${i * 360 / count} ${center} ${center})`}
    />
  ))
