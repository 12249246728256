import React from 'react'
import { LoadingButton } from '@ui'

export const ConnectXeroButton = ({ children, size, variant, onClick }) => (
  <LoadingButton
    size={size}
    variant={variant}
    onClick={onClick}
  >
    {children}
  </LoadingButton>
)
