import React from 'react'
import { NavLeft, NavRight } from './Picker.styled'
import { ZeroButton } from '../Buttons'
import { Icon } from '../Icon'

export const PickerNav = ({ prev, next }) => (
  <div>
    <NavLeft position="left">
      <ZeroButton onClick={prev}>
        <Icon name="chevronLeft" width={12} height={12} />
      </ZeroButton>
    </NavLeft>
    <NavRight position="right">
      <ZeroButton onClick={next}>
        <Icon name="chevronRight" width={12} height={12} />
      </ZeroButton>
    </NavRight>
  </div>
)
