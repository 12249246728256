import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { switchProp, theme } from 'styled-tools'

export const Positioner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  bottom: 0;

  ${switchProp('position', {
    left: css`
      left: 0;      
    `,
    right: css`
      right: 0;
    `,
  })}
`

export const InputPositioner = styled(Positioner)`
  padding: 0 14px;
`

Positioner.propTypes = {
  position: P.oneOf(['left', 'right']).isRequired,
}

InputPositioner.propTypes = Positioner.propTypes

export const BackgroundPositioner = styled.div`
  position: absolute;
  z-index: ${theme('order.positioner')};

  ${switchProp('position', {
    top: css`
      bottom: 100%;      
    `,
    bottom: css`
      top: 100%;
    `,
  })}

  ${switchProp('side', {
    left: css`
      left: 0;
    `,
    right: css`
      right: 0;
    `,
  })}
`

BackgroundPositioner.propTypes = {
  position: P.oneOf(['top', 'bottom']),
  side: P.oneOf(['left', 'right']),
}

BackgroundPositioner.defaultProps = {
  side: 'left',
  position: 'bottom',
}
