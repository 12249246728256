import React, { useState } from 'react'
import * as P from 'prop-types'
import { Button, Flex, FormRadio, List, ListItem, LoadingButton, Modal } from '@ui'
import { Logo } from '../components/Logo'

export const ConnectModal = ({ title, show, options, loading, onClose, onConfirm }) => {
  const [selected, setSelected] = useState(options.length > 0 ? options[0].value : null)

  return (
    <Modal size="sm" show={show} onClose={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body>
        <OptionsList
          options={options}
          selected={selected}
          onSelect={setSelected}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
        <LoadingButton size="md" onClick={() => onConfirm(selected)} loading={loading}>ADD</LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export const InlineConnectButton = ({ value, onConnect }) => {
  const [loading, setLoading] = useState(false)

  const connect = async () => {
    setLoading(true)

    try {
      await onConnect(value)
    } catch (e) {
    }

    setLoading(false)
  }

  return (
    <LoadingButton
      variant="outline"
      loading={loading}
      disabled={loading}
      onClick={connect}
    >
      CONNECT
    </LoadingButton>
  )
}

export const InlineConnectModal = ({ options, onConnect }) => (
  <List gap="8px" bordered={false}>
    {options.map(({ label, value }) => (
      <ListItem bordered key={value}>
        <Flex justify="space-between">
          <Logo name={value} title={label} />
          <InlineConnectButton value={value} onConnect={onConnect} />
        </Flex>
      </ListItem>
    ))}
  </List>
)

ConnectModal.propTypes = {
  loading: P.bool.isRequired,
  title: P.string.isRequired,
  show: P.bool.isRequired,
  options: P.array.isRequired,
  onClose: P.func.isRequired,
  onConfirm: P.func.isRequired,
}

const OptionsList = ({ options, onSelect, selected }) => (
  <List gap="8px" bordered={false}>
    {options.map(({ label, value }) => (
      <ListItem bordered key={value}>
        <Flex justify="space-between">
          <FormRadio
            block
            reversed
            label={<Logo name={value} title={label} />}
            checked={value === selected}
            onChange={() => onSelect(value)}
          />
        </Flex>
      </ListItem>
    ))}
  </List>
)
