import React from 'react'
import { useField } from 'formik'
import { DateTimeRangePicker, FormInput, Select } from '@ui'
import { fieldTypes } from '../../constants'

export const ConditionValueInput = ({ name, type, options, ...inputProps }) => {
  const [
    { onChange, onBlur },
    { touched, error, value },
    { setValue },
  ] = useField(name)

  switch (type) {
    case fieldTypes.TEXT:
    case fieldTypes.NUMBER:
    default:
      return (
        <FormInput
          type={type}
          name={name}
          value={value === null ? '' : value}
          error={touched && error}
          onChange={onChange}
          onBlur={onBlur}
          {...inputProps}
        />
      )
    case fieldTypes.SELECT:
      return (
        <Select
          name={name}
          value={value}
          error={touched && error}
          onChange={(_, val) => setValue(val)}
          onBlur={onBlur}
          options={options}
          isClearable
          isSearchable
          {...inputProps}
        />
      )
    case fieldTypes.DATE:
      return (
        <DateTimeRangePicker
          numberOfMonths={1}
          showTime={false}
          onChange={([date]) => setValue(date)}
        />
      )
  }
}
