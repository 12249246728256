import { css } from 'styled-components'
import { theme } from 'styled-tools'

export const roundShadow = css`
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: ${theme('order.shadow')};
    box-shadow: 0 -3px 20px rgba(0,0,0,0.5);
    top: 30px;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-radius: 100px / 10px;
  }

  &:after {
    right: 10px;
    left: auto;
    transform: skew(8deg) rotate(3deg);
  }
`
