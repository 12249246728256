import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { history } from '@lib/routing'
import { theme } from '@lib/theme'
import { useSelector } from 'react-redux'
import { $currentUser } from '@features/common'
import { renderRoutes } from 'react-router-config'
import { setupRoutes, loginRoutes } from './routes'

import { Auth0WebProvider } from './auth0'
import { GlobalStyles } from '../../global-styles'
import '../../assets/css/app.css'

export const Setup = hot(() => {
  const user = useSelector($currentUser)

  return (
    <ThemeProvider theme={theme}>
      <Auth0WebProvider>
        <GlobalStyles />
        <Router history={history}>
          {user ? (
            renderRoutes(setupRoutes())
          ) : (
            renderRoutes(loginRoutes())
          )}
        </Router>
      </Auth0WebProvider>
    </ThemeProvider>
  )
})
