import React from 'react'
import { Table, TableBody, TableHead } from '../index'
import { PaginationBlock, SelectableRow } from './Elements'
import { FilterableHeader } from './FilterableHeader'

export const PaginatedTable = ({ table, pagination }) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = table
  return (
    <>
      <Table {...getTableProps()}>
        <TableHead
          headerGroups={headerGroups}
          renderColumnHeader={(column) => (
            <FilterableHeader
              key={column.id}
              column={column}
            />
          )}
        />
        <TableBody
          rows={rows}
          prepareRow={prepareRow}
          renderRow={(row) => (
            <SelectableRow
              key={row.index}
              row={row}
              isSelected={row.isSelected}
            />
          )}
        />
      </Table>
      <PaginationBlock
        pagination={pagination}
      />
    </>
  )
}
