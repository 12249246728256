import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '@lib/styled-layout'
import { SelectField } from '@ui'
import { $isIntegrationExpired } from '../../selectors/integration'
import { $incomeAccountOptions, $itemTypesOptions } from '../../selectors/quickbooks-entities'
import { Description } from './shared'

export const ProductsAndServices = () => {
  const isExpired = useSelector($isIntegrationExpired)
  const itemTypesOptions = useSelector($itemTypesOptions)
  const incomeAccounts = useSelector($incomeAccountOptions)

  return (
    <Row>
      <Col md={6}>
        <SelectField
          name="product_type"
          label="Product type"
          options={itemTypesOptions}
          disabled={isExpired}
        />
        <Description>
          Here you can set the type of a new product which will be created if no product found in your accounting software matching the one in the transaction.
        </Description>
      </Col>
      <Col md={6}>
        <SelectField
          name="product_income_account"
          label="Product Income Account"
          options={incomeAccounts}
          disabled={isExpired}
          isSearchable
        />
        <Description>
          Here you can set the product income account that will be applied to a newly created product.
        </Description>
      </Col>
    </Row>
  )
}
