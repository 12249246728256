import React from 'react'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Alert, H1, H2, H4 } from '@ui'
import { Link } from '@ui'

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`

const FormSpacer = styled.div`
  max-height: 78px;
  height: 10rem;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  align-self: center;
  padding: 0px 16px;
  margin-bottom: 64px;
`

const AlertContainer = styled.div`
  width: 100%;
  max-width: 800px;
  align-self: center;
  padding: 0px 16px;
  margin-bottom: 64px;
`

const SubtitleContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 48px;
`

const LogoImage = styled.img`
  width: 250px;
`

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StepContainer = styled.div`
  ${ifProp('index', `
    margin-left: 24px;
  `, '')}
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 1100px) {
    ${ifProp('isCurrent', '', 'display: none;')}
    margin-left: 0px;
  }
`

const StepNumber = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 2px solid ${ifProp('active', prop('color'), '#A1AFC4')};
  color: ${ifProp('active', prop('color'), '#A1AFC4')};
  opacity: ${ifProp('active', '1', '0.5')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
`

const StepText = styled.div`
  width: 120px;
  color: #2A3245;
  opacity: ${ifProp('active', '1', '0.5')};

  @media screen and (max-width: 1100px) {
    width: 250px;
  }
`

const StepLine = styled.div`
  width: 50px;
  opacity: 0.5;
  border: 1px solid #A1AFC4;
  margin-right: 24px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const NewUserLayout = ({
  title = '',
  subtitle = '',
  steps,
  activeStep = 0,
  children,
  user = null,
}) => (
  <Container>
    <LogoImage src="/images/bf_logo_light_transparent.svg" alt="BankFeeds.io" />
    {user && user.masquerade && (
      <AlertContainer>
        <Alert type="info">
          You are currently impersonating another user. To go back to the admin menu, <Link onClick={() => { window.location.href = '/admin' }}>click here</Link>.
        </Alert>
      </AlertContainer>
    )}
    {steps && steps.length ? (
      <StepsContainer>
        {steps.map(({ id, color, text }, index) => {
          const active = activeStep >= index

          return (
            <StepContainer key={id} index={index} isCurrent={index === activeStep}>
              {index > 0 ? (
                <StepLine />
              ) : null}
              <StepNumber color={color} active={active}>
                <H2>{index + 1}</H2>
              </StepNumber>
              <StepText active={active} color={color}>
                <H4>{text}</H4>
              </StepText>
            </StepContainer>
          )
        })}
      </StepsContainer>
    ) : null}
    <FormSpacer />
    <FormContainer>
      <H1>{title}</H1>
      <SubtitleContainer>
        {subtitle}
      </SubtitleContainer>
      {children}
    </FormContainer>
  </Container>
)
