import React from 'react'
import { Route } from 'react-router-dom'
import { FilterSearch, Flex, H2 } from '@ui'
import { history } from '@lib/routing'
import { TransactionsTable } from '../components/Table'
import { useModel } from './connected.model'
import { MassActionModalButton } from '../components/MassActionModalButton'
import { TransactionDetailsModal } from './transactionDetails'

export const ConnectedView = () => {
  const {
    table,
    setSearch,
    massActions,
  } = useModel()

  const onTransactionDetailsClose = () => {
    history.push('/transactions')
  }

  return (
    <>
      <Route path={'/transactions/:transactionId'} render={
        () => <TransactionDetailsModal onClose={onTransactionDetailsClose} />
      } />
      <FilterSearch onChange={setSearch} />
      <MassActions massActions={massActions} />
      <TransactionsTable table={table} />
    </>
  )
}

const MassActions = ({ massActions }) => (
  <>
    <Flex mt="28px">
      <H2>Actions with filters</H2>
    </Flex>
    <Flex my="8px">
      <Flex gap="8px" align="center">
        {massActions.map(({ config, active, handler }) => (
          <MassActionModalButton
            key={config.id}
            config={config}
            active={active}
            handler={handler}
          />
        ))}
      </Flex>
    </Flex>
  </>
)
