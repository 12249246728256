import React from 'react'
import { useSelector } from 'react-redux'
import { $currentUser, Guard, ConnectXeroButton } from '@features/common'
import { Card, H1, Text, H2, H4 } from '@ui'
import { AddProcessorModalButton } from '@features/integrations'

export const FaqView = () => {
  const {
    has_integration: hasIntegration,
    has_payment_connection: hasPayment,
  } = useSelector($currentUser)

  return (
    <>
      <Guard isActive>
        {(!hasPayment && hasIntegration) && <ConnectSourcesBlock />}
        {(!hasPayment && !hasIntegration) && <ConnectXeroBlock />}
        {(hasPayment && !hasIntegration) && <ConnectXeroBlock />}
      </Guard>
      <Card my="1rem">
        <H2>Frequently Asked Questions</H2>
        <H4>What kind of access do you have with my Stripe account?</H4>
        <p>We only get read-only access to your account, which allow us to get details from your transactions
          but we are not able to create any new transactions, transfer any money or update any of your personal details.</p>
        <H4>What information do you store?</H4>
        <p>We keep only the minimum details about you transaction so we can import them into your accounting system.</p>
        <H4>Can I revert this connection?</H4>
        <p>You can disconnect your account from Bankfeeds.io at any time from Stripe.</p>
        <H4>Do you have a Privacy Policy?</H4>
        <p>Yes, you can read it <a href="http://www.bankfeeds.io/privacy-policy/" target="_blank" rel="noopener noreferrer">here</a></p>
      </Card>
    </>
  )
}

const ConnectSourcesBlock = () => (
  <Card my="1rem">
    <H1 mb="0.5rem">Getting Started</H1>
    <p>
      <Text mb="0.25rem">Please add a processor</Text>
    </p>
    <AddProcessorModalButton />
  </Card>
)

const ConnectXeroBlock = () => (
  <Card my="1rem">
    <H1 mb="0.5rem">Getting Started</H1>
    <p>
      <Text mb="0.25rem">Please connect a Xero account</Text>
    </p>
    <ConnectXeroButton>Connect Xero</ConnectXeroButton>
  </Card>
)
