import React from 'react'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, theme } from 'styled-tools'
import { ErrorLabel } from '../Label'
import { HiddenInput, StyledInput, InputContainer, InputLabel } from './Controls'
import { checkPropTypes } from './propTypes'

export const FormSwitch = ({
  name,
  label,
  subLabel,
  error,
  checked,
  onChange,
  onBlur,
  title,
  disabled,
  reversed,
  block,
  className,
}) => (
  <>
    <InputContainer
      title={title}
      block={block}
      disabled={disabled}
      className={className}
    >
      <HiddenInput
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <InputContainer as="div">
        <StyledSwitch checked={checked}>
          <SwitchIcon />
        </StyledSwitch>
        {subLabel && (
          <SwitchLabel
            active={checked}
            reversed
          >
            {subLabel}
          </SwitchLabel>
        )}
      </InputContainer>
      {label && (
        <SwitchLabel
          active
          reversed={reversed}
          block={block}
        >
          {label}
        </SwitchLabel>
      )}
    </InputContainer>
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </>
)

FormSwitch.propTypes = {
  ...checkPropTypes,
}

const SwitchLabel = styled(InputLabel)`
  opacity: ${ifNotProp('active', 0.4)};
`

const SwitchIcon = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: ${theme('color.primary')};
  border-radius: 10px;
  top: 1px;
  left: 1px;
`

const StyledSwitch = styled(StyledInput)`
  width: 36px;
  height: 19px;
  border: 1px solid ${theme('color.gray')};
  border-radius: 10px;
  position: relative;

  ${ifNotProp('checked', css`
    opacity: 0.4;
  `)};

  ${SwitchIcon} {
    transform: translateX(${ifProp('checked', '16px', 0)});
    transition: .15s ease-out transform;
  }
`
