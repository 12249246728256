import { createSelector } from 'reselect'
import { fetchStatus } from '@lib/fetching'

const $invoices = (root) => root.invoices

export const $invoicesList = createSelector(
  $invoices,
  (invoices) => invoices.list,
)

export const $invoicesFetching = createSelector(
  $invoices,
  (invoices) => invoices.fetching,
)

export const $isLoading = createSelector(
  $invoicesFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)
