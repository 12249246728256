import { createSymbiote } from 'redux-symbiote'

const initialState = {
  processorId: null,
  account: null,
  position: null,
}

const symbiotes = {
  set: (state, { processorId, account, position }) => ({
    processorId,
    account,
    position,
  }),

  reset: () => initialState,
}

export const { actions, reducer } = createSymbiote(
  initialState,
  symbiotes,
  'currentRule',
)
