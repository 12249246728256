import { createSelector } from 'reselect'
import { $sources, $sourcesOptions } from '@features/integrations'
import { processorOptions, transactionTypes } from '@lib/constants'

export const $currentImport = (root) => root.currentImport

const $processor = createSelector(
  $currentImport,
  ({ processor }) => processor,
)

export const $sourcesOptionsByProcessor = createSelector(
  $sourcesOptions,
  $processor,
  (sourcesOptions, processor) => {
    if (!sourcesOptions.length) return []

    const byProcessor = sourcesOptions.filter(({ source }) => source === processor)
    return byProcessor.length > 0 ? byProcessor : [sourcesOptions[0]]
  },
)

export const $processorOptionsForConnectedSources = createSelector(
  $sources,
  (sources) => processorOptions.filter(({ value }) => (
    sources.find((source) => value === source.connection_source)
  )),
)

export const $exportEnabled = createSelector(
  $currentImport,
  ({ exportEnabled }) => exportEnabled,
)

export const $currentImportTypes = createSelector(
  $currentImport,
  ({ types }) => types,
)

export const $allTypesChecked = createSelector(
  $currentImportTypes,
  (types) => types.length === transactionTypes.length,
)
