import React from 'react'
import { useSelector } from 'react-redux'
import { $currentUser, $userSubscription } from '@features/common'
import { processors } from '@lib/constants'
import { QuestionIcon, Text, TooltipContainer } from '@ui'
import { $integrationName } from '@features/session'
import { $activeProcessorSettings } from '../../selectors/configuration'
import { CustomSwitchField, InputTemplate, TooltipText } from './shared'

export const ImportInvoicesOption = () => {
  const integrationName = useSelector($integrationName)
  const user = useSelector($currentUser)
  const subscription = useSelector($userSubscription)
  const { processor } = useSelector($activeProcessorSettings)

  const visible = (user.is_admin || subscription.parse_invoices)
    && processor.connection_source === processors.STRIPE

  return visible ? (
    <>
      <InputTemplate>
        <CustomSwitchField
          label={
            <TooltipContainer
              tooltip={`Enable this option to sync paid invoices as ${integrationName} invoices.`}
            >
              <TooltipText>
                <Text mr="6px">Import invoices</Text>
                <QuestionIcon />
              </TooltipText>
            </TooltipContainer>
          }
          name="import_invoices"
        />
      </InputTemplate>
    </>
  ) : null
}
