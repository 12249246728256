export const pageSizes = {
  importJobs: 25,
  transactions: 100,
}

export const defaultSort = {
  importJobs: {
    id: 'id',
    desc: true,
  },
  transactions: {
    id: 'processed_at',
    desc: true,
  },
}
