import React, { forwardRef, useEffect } from 'react'
import { date } from '@lib/date'

export const InvoiceView = forwardRef(({ invoice, user }, ref) => {
  const tz = user.timezone

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!invoice || !Object.keys(invoice).length) {
    return null
  }

  return (
    <div className="inv" ref={ref}>
      <header className="clearfix">
        <div id="logo">
          <img alt="" src="/images/bf_logo_light_bg.png" />
        </div>
        <h1>TAX INVOICE #{invoice.date}</h1>
        <div id="company" className="clearfix">
          <div>Bankfeeds</div>
          <div>Bishop Ranch 3,<br />2603 Camino Ramon, Suite 200<br />San Ramon, CA 94583</div>
          <div><a href="mailto:accounts@bankfeeds.io">accounts@bankfeeds.io</a></div>
        </div>
        <div id="project">
          <div><span>CLIENT</span> {user.name}</div>
          <div><span>EMAIL</span> {user.email}</div>
          <div><span>PAID AT</span> {date(invoice.date * 1000, tz)}</div>
          <div><span>DUE DATE</span> {date(invoice.period_end * 1000, tz)}</div>
        </div>
      </header>
      <main>
        <table>
          <thead>
            <tr>
              <th className="service">SERVICE</th>
              <th className="desc">DESCRIPTION</th>
              <th>PRICE</th>
              <th>QTY</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="service">Bankfeeds</td>
              <td className="desc">Monthly Subscription</td>
              <td className="unit">{(invoice.amount / 100).toFixed(2)} {invoice.currency.toUpperCase()}</td>
              <td className="qty">1</td>
              <td className="total">{(invoice.amount / 100).toFixed(2)} {invoice.currency.toUpperCase()}</td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  )
})
