import { isEmpty } from '../is'

export const Storage = {
  get(key, defaultVal = null) {
    try {
      const value = localStorage.getItem(key)
      const parsed = JSON.parse(value)

      return isEmpty(parsed)
        ? defaultVal
        : parsed
    } catch (e) {
      return defaultVal
    }
  },

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  remove(key) {
    localStorage.removeItem(key)
  },
}
