import { createSelector } from 'reselect'
import { fetchStatus } from '@lib/fetching'

const $list = (root) => root.notifications.list
const $settingsRoot = (root) => root.notifications.settings

export const $notifications = createSelector(
  $list,
  (list) => list.list,
)

export const $notificationsFetching = createSelector(
  $list,
  (list) => list.fetching,
)

export const $notificationsIsLoading = createSelector(
  $notificationsFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)

export const $settings = createSelector(
  $settingsRoot,
  (settings) => settings.model,
)

export const $settingsFetching = createSelector(
  $settingsRoot,
  (settings) => settings.fetching,
)

export const $settingsIsLoading = createSelector(
  $settingsFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)
