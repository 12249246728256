import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  list: [],
  count: null,
  totalCount: null,
  pageCount: 0,
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),
  set: (state, { data, count, totalCount, pageCount } = {}) => ({
    ...state,
    list: data,
    count,
    totalCount,
    pageCount,
  }),
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'transactions',
)
