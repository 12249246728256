import { GocardlessCallbackPage } from './pages/gocardless-callback'
import { StripeCallbackPage } from './pages/stripe-callback'
import { ShopifyCallbackPage } from './pages/shopify-callback'
import { XeroCallbackPage } from './pages/xero-callback'
import { IntegrationReconnectCallbackPage } from './pages/integration-reconnect-callback'
import { WooCommerceCallbackPage } from './pages/woocommerce-callback'
import { QuickbooksCallbackPage } from './pages/quickbooks-callback'

export const connectionCallbacksRoutes = () => [
  {
    path: '/gocardless/callback',
    component: GocardlessCallbackPage,
  },
  {
    path: '/stripe/callback',
    component: StripeCallbackPage,
  },
  {
    path: '/shopify/callback',
    component: ShopifyCallbackPage,
  },
  {
    path: '/woocommerce/callback',
    component: WooCommerceCallbackPage,
  },
  {
    path: '/xero/callback',
    component: XeroCallbackPage,
  },
  {
    path: '/xero/reconnect',
    component: IntegrationReconnectCallbackPage,
  },
  {
    path: '/integration/reconnect',
    component: IntegrationReconnectCallbackPage,
  },
  {
    path: '/quickbooks/callback',
    component: QuickbooksCallbackPage,
  },
]
