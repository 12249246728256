import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { CheckField, FormGroup, H2, SaveFormChangesDialog, Spinner } from '@ui'
import { Col, Row } from '@lib/styled-layout'
import { Portal } from '@lib/portal'
import { $settings, $settingsIsLoading, settingsUpdate } from '@features/notifications'

export const Notifications = () => {
  const dispatch = useDispatch()
  const settings = useSelector($settings)
  const isLoading = useSelector($settingsIsLoading)

  const update = async (values) => {
    await dispatch(settingsUpdate(values))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <NotificationsForm
      values={settings}
      onSubmit={update}
    />
  )
}

const NotificationsForm = ({ values, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={values}
    onSubmit={onSubmit}
  >
    <Form>
      <Portal id="dialog">
        <SaveFormChangesDialog />
      </Portal>
      {/*
      <FormGroup as={Row}>
        <Col md={4}>
          <H2>Method</H2>
        </Col>
        <Col md={8}>
          <FormGroup>
            <CheckField
              name="email"
              label="Email"
            />
          </FormGroup>
          <FormGroup>
            <CheckField
              name="app"
              label="App"
            />
          </FormGroup>
        </Col>
      </FormGroup>
      <FormDivider />
      */}
      <FormGroup as={Row}>
        <Col md={4}>
          <H2>Email Options</H2>
        </Col>
        <Col md={8}>
          <FormGroup>
            <CheckField
              name="out_of_space"
              label="Running out of transactions"
            />
          </FormGroup>
          {/*
          <FormGroup>
            <CheckField
              name="daily_summary"
              label="Daily summary"
            />
          </FormGroup>
          <FormGroup>
            <CheckField
              name="weekly_summary"
              label="Weekly summary"
            />
          </FormGroup>
          */}
          <FormGroup>
            <CheckField
              name="new_features"
              label="New features"
            />
          </FormGroup>
          <FormGroup>
            <CheckField
              name="promotions"
              label="Promotions"
            />
          </FormGroup>
        </Col>
      </FormGroup>
    </Form>
  </Formik>
)
