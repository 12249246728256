import { currenciesOptions } from '@lib/constants'

export const fieldTypes = {
  TEXT: 'text',
  SELECT: 'select',
  NUMBER: 'number',
  DATE: 'date',
}

// Transaction fields
export const fields = {
  CURRENCY: 'currency',
  COUNTRY: 'country',
  DESCRIPTION: 'description',
  AMOUNT: 'amount',
}

const humanizeField = (field) => {
  switch (field) {
    case fields.CURRENCY:
      return 'Currency'
    case fields.COUNTRY:
      return 'Country'
    case fields.DESCRIPTION:
      return 'Description'
    case fields.AMOUNT:
      return 'Amount'
    default:
      return field
  }
}

export const fieldOptions = Object.values(fields).map((field) => ({
  label: humanizeField(field),
  value: field,
}))

export const getFieldMeta = (field, payload = {}) => {
  const { type, options } = createFieldMeta(field, payload)
  const operatorOptions = getOperatorOptions(type)

  return {
    type,
    options,
    operatorOptions,
  }
}

export const createFieldMeta = (field, payload = {}) => {
  const defaultMeta = {
    type: null,
    options: [],
  }

  const config = {
    [fields.CURRENCY]: {
      type: fieldTypes.SELECT,
      options: currenciesOptions,
    },
    [fields.COUNTRY]: {
      type: fieldTypes.SELECT,
      options: payload.countriesOptions || [],
    },
    [fields.DESCRIPTION]: {
      type: fieldTypes.TEXT,
    },
    [fields.AMOUNT]: {
      type: fieldTypes.NUMBER,
    },
  }

  const meta = config[field] || defaultMeta

  return {
    ...defaultMeta,
    ...meta,
  }
}

export const operators = {
  EQ: 'equal',
  NEQ: 'notEqual',
  LT: 'lessThan',
  LTE: 'lessThanInclusive',
  GT: 'greaterThan',
  GTE: 'greaterThanInclusive',
  TEXT_CONTAINS: 'textContains',
  TEXT_DOES_NOT_CONTAIN: 'textDoesNotContain',
}

export const humanizeOperator = (operator) => {
  switch (operator) {
    case operators.EQ:
      return 'Equals'
    case operators.NEQ:
      return 'Does Not Equal'
    case operators.LT:
      return 'Less Than'
    case operators.GT:
      return 'Greater Than'
    case operators.LTE:
      return 'Less Than or Equals'
    case operators.GTE:
      return 'Greater Than or Equals'
    case operators.TEXT_CONTAINS:
      return 'Contains'
    case operators.TEXT_DOES_NOT_CONTAIN:
      return 'Does Not Contain'
    default:
      return operator
  }
}

// Associate fieldTypes with supported operators
export const getOperators = (fieldType) => {
  switch (fieldType) {
    case fieldTypes.TEXT:
      return [operators.TEXT_CONTAINS, operators.TEXT_DOES_NOT_CONTAIN, operators.EQ, operators.NEQ]
    case fieldTypes.SELECT:
      return [operators.EQ, operators.NEQ]
    case fieldTypes.NUMBER:
    case fieldTypes.DATE:
      return [operators.EQ, operators.NEQ, operators.GT, operators.GTE, operators.LT, operators.LTE]
    default:
      return [operators.EQ]
  }
}

export const getOperatorOptions = (fieldType) => getOperators(fieldType).map((op) => ({
  label: humanizeOperator(op),
  value: op,
}))

export const bankAccounts = {
  CHARGES: 'charges_account',
  FEES: 'fees_account',
  REFUNDS: 'refunds_account',
  PAYOUTS: 'payouts_account',
  DISPUTES: 'disputes_account',
  ADJUSTMENTS: 'adjustments_account',
  APP_FEES: 'app_fees_account',
}

export const events = {
  EXPORT: 'export',
  IGNORE: 'ignore',
  CODE_WITH_TAX: 'codeWithTax',
}

export const eventsOptions = [
  { label: 'Export', value: events.EXPORT },
  { label: 'Ignore', value: events.IGNORE },
  { label: 'Code with Tax', value: events.CODE_WITH_TAX },
]

export const emptyCondition = {
  field: null,
  operator: operators.EQ,
  value: null,
}

export const emptyFormRule = {
  conditions: [emptyCondition],
  event: events.EXPORT,
  account: null,
  code: null,
  taxType: null,
}
