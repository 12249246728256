import React from 'react'
import * as P from 'prop-types'
import { Button, propTypes as btnPropTypes } from './Button'
import { Flex } from '../Flex'

export const IconButton = ({ icon, children, ...buttonProps }) => (
  <Button {...buttonProps}>
    <Flex justify="center" align="center" gap="10px">
      {icon}
      {children && <div>{children}</div>}
    </Flex>
  </Button>
)

export const propTypes = {
  ...btnPropTypes,
  icon: P.node,
}
