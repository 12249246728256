import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { space } from '@styled-system/space'
import { prop } from 'styled-tools'
import { spacePT } from './prop-types/space'
import { flexboxPT } from './prop-types/flexbox'

export const Flex = styled.div`
  display: flex;
  align-items: ${prop('align')};
  justify-content: ${prop('justify')};
  flex-direction: ${prop('direction')};
  ${space};

  ${((p) => p.gap && css`
    & > :not(:first-child) {
      margin-left: ${p.gap};
    }
  `)}

  ${((p) => p.gapY && css`
    & > :not(:first-child) {
      margin-top: ${p.gapY};
    }
  `)}
`

Flex.propTypes = {
  children: P.node,

  gap: P.oneOfType([P.string, P.number]),

  gapY: P.oneOfType([P.string, P.number]),

  ...flexboxPT,
  ...spacePT,
}
