import { groupBy } from '@lib/array'
import { exportStatusesOptions as expStatusesOptions, sourcesOptions, typesOptions } from '@lib/constants'

export const typesByName = groupBy(typesOptions, ({ value, label }) => [value, label])

export const sourcesByName = groupBy(sourcesOptions, ({ value, label }) => [value, label])

export const exportStatusesOptions = [
  ...expStatusesOptions,
]

export const amountsOptions = [
  { label: 'Less Than', value: 'lt' },
  { label: 'Less Than or Equal To', value: 'lte' },
  { label: 'Equal To', value: 'eq' },
  { label: 'Greater Than', value: 'gt' },
  { label: 'Greater Than or Equal To', value: 'gte' },
]

export const pageSizes = {
  transactions: 25,
}

export const defaultSort = {
  transactions: {
    id: 'processed_at',
    desc: true,
  },
}

export const defaultFilters = {
  transactions: [
    { id: 'status', value: [] },
  ],
}

export const duplicateExportHeaders = ['ID', 'Date', 'Reference', 'Contact Name', 'Amount', 'Occurences']
