import styled from 'styled-components'
import { H2 } from '@ui'

export const Section = styled.div`
  margin-bottom: 64px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-height: 50px;
  
  ${H2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Body = styled.div`
  margin-top: 40px;
`

Section.Header = Header
Section.Body = Body
