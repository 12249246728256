import React from 'react'
import { useSelector } from 'react-redux'
import { SelectField } from '@ui'
import { $isIntegrationExpired } from '../../selectors/integration'
import { $vendorOptions } from '../../selectors/quickbooks-entities'
import { Description } from './shared'

export const Vendor = () => {
  const isExpired = useSelector($isIntegrationExpired)
  const options = useSelector($vendorOptions)

  return (
    <>
      <SelectField
        name="vendor"
        label="Vendor"
        options={options}
        disabled={isExpired}
        isSearchable
      />
      <Description>
        This Vendor will be set for QuickBooks Expenses and Checks.
      </Description>
    </>
  )
}
