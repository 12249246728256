import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  ${down('lg')} {
    margin-left: 25px;
    margin-right: 25px;
  }

  ${up('lg')} {
    margin-left: 88px;
    margin-right: 112px;
  }
`
