import * as P from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

const dash = keyframes`
  from {
    stroke-dasharray: 0, 100;
  }
`

const activeCss = css`
  stroke-width: ${(p) => p.strokeWidth * 1.2};
`

export const AnimatedCircle = styled.circle`
  animation: ${dash} 1s ease-out forwards;
  stroke-linecap: ${ifProp('rounded', 'round')};
  fill: none;
  cursor: pointer;

  ${ifProp('hoverable', css`
    transition: stroke-width 0.5s;
    
    ${ifProp('hovered', activeCss)}

    &:hover {
      ${activeCss};
    }
  `)}
`

AnimatedCircle.propTypes = {
  hoverable: P.bool,
  hovered: P.bool,
  rounded: P.bool,
}

AnimatedCircle.defaultProps = {
  hoverable: false,
  hovered: false,
  rounded: false,
}

export const Container = styled.div`
  position: relative;
`

export const CenteredText = styled.text.attrs({
  x: '50%',
  y: '50%',
  dominantBaseline: 'middle',
  textAnchor: 'middle',
})`
  font-size: 5px;
`
