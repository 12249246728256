import styled from 'styled-components'
import { List, ListItem } from '../List'
import { BackgroundPositioner } from '../Positioner'

export const MenuPositioner = styled(BackgroundPositioner)`
  width: 100%;
`

export const Menu = styled(List)`
  display: ${(p) => p.isOpen ? 'block' : 'none'};
  margin-top: 5px;
  box-shadow: 0 0.25rem 0.5rem 0.1rem rgba(114, 114, 114, 0.25);
`

export const MenuItem = styled(ListItem)`
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
`
