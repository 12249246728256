import * as P from 'prop-types'

const propType = P.oneOfType([P.number, P.string, P.array, P.object])

export const spacePT = {
  margin: propType,
  m: propType,
  mt: propType,
  mb: propType,
  ml: propType,
  mr: propType,
  mx: propType,
  my: propType,

  padding: propType,
  p: propType,
  pt: propType,
  pb: propType,
  pl: propType,
  pr: propType,
  px: propType,
  py: propType,
}
