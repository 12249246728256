import { createSelector } from 'reselect'
import { groupBy, splitArray } from '@lib/array'
import { toSelectItems } from '@lib/convert'
import { $integrationEntities, $integrationTaxRatesList } from './integration'
import { bankAccounts, events } from '../constants'
import { $rulesByProcessorId, $sources } from './sources'

const $root = (root) => root.configuration

const $activeProcessorId = createSelector(
  $root,
  ({ processorId }) => processorId,
)

export const $activeProcessorSettings = createSelector(
  $sources,
  $activeProcessorId,
  (sources, processorId) => {
    const source = sources.find((source) => source.id === processorId)
    if (!source) {
      return {}
    }
    return {
      processor: source,
      settings: source.settings,
    }
  },
)

export const $settingsRules = createSelector(
  $rulesByProcessorId,
  $activeProcessorId,
  (rules, processorId) => rules[processorId] || [],
)

export const $formTaxRatesWithOptions = createSelector(
  $activeProcessorSettings,
  $integrationTaxRatesList,
  ({ processor }, integrationTaxRates) =>
    processor.taxRates.data.map((processorTaxRate) => {
      const selectableTaxRates = integrationTaxRates.filter((integrationTaxRate) =>
        processorTaxRate.accountClass
          ? integrationTaxRate.supportedAccountClasses.includes(processorTaxRate.accountClass)
          : true,
      )
      
      let options = toSelectItems(selectableTaxRates, 'name', 'type')
      // TODO: Disabling for now, this only works for Invoices but is selectable for all charges. Need to update export rules
      // to roll up into income similarly to how it does for invoices.
      // options = options.concat(toSelectItems([{label: 'Ignore, include as income', value: '_INTERNAL_INCLUDE_AS_INCOME'}]))
      
      return {
        ...processorTaxRate,
        options: options,
      }
    }),
)

/**
 * Returns rules with labels for event.params.account,code
 */
const $labeledRules = createSelector(
  $settingsRules,
  $integrationTaxRatesList,
  $integrationEntities,
  (rules, taxRates, { bankAccounts, commonAccounts }) => {
    const mapEvent = (event) => {
      switch (event.type) {
        case events.EXPORT: {
          const { code, account, fromAccount, toAccount } = event.params

          const bankAccount = bankAccounts.find((acc) => acc.id === account)
          const fromBankAccount = bankAccounts.find((acc) => acc.id === fromAccount)
          const toBankAccount = bankAccounts.find((acc) => acc.id === toAccount)
          const codeAccount = commonAccounts.find((acc) => String(acc.code) === String(code))

          return {
            ...event,
            params: {
              ...event.params,
              // fallback to raw values, if xero accounts is not loaded yet
              accountLabel: bankAccount?.name || account,
              fromAccountLabel: fromBankAccount?.name || fromAccount,
              toAccountLabel: toBankAccount?.name || toAccount,
              codeLabel: codeAccount?.name || code,
            },
          }
        }
        case events.CODE_WITH_TAX:
          const { taxType } = event.params
          const taxRate = taxRates.find((tr) => tr.type === taxType)

          return {
            ...event,
            params: {
              ...event.params,
              taxTypeLabel: taxRate?.name || taxType,
            },
          }
        case events.IGNORE:
        default:
          return event
      }
    }

    return rules.map((rule) => ({
      ...rule,
      event: mapEvent(rule.event),
    }))
  },
)

/**
 * Selected result structure will be
 * rules object indexed by bankAccountName
 * {
 *   charges_account: [rule, rule],
 *   fees_account: [],
 * }
 */
export const $rulesByAccounts = createSelector(
  $labeledRules,
  (rules) => {
    const initialValues = groupBy(
      Object.values(bankAccounts),
      (accountName) => [accountName, []],
    )

    return splitArray(
      rules,
      (rule) => [rule.meta.account, rule],
      initialValues,
    )
  },
)

export const $hasBankfeedsAccount = createSelector(
  $integrationEntities,
  $activeProcessorSettings,
  ({ bankAccounts }, { processor }) => {
    const defaultAccountNumber = `BNKFD${processor.id}`
    return bankAccounts.find(({ number }) => number === defaultAccountNumber)
  },
)
