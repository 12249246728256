import React from 'react'
import { useSelector } from 'react-redux'
import { SelectField } from '@ui'
import { $isIntegrationExpired } from '../../selectors/integration'
import { $paymentMethodsOptions } from '../../selectors/quickbooks-entities'
import { Description } from './shared'

export const PaymentMethod = () => {
  const isExpired = useSelector($isIntegrationExpired)
  const options = useSelector($paymentMethodsOptions)

  return (
    <>
      <SelectField
        name="payment_method"
        label="Payment Method"
        options={options}
        disabled={isExpired}
      />
      <Description>
        This Payment Method will be set for QuickBooks Sales Receipts, Refund Receipts, Payments and Expenses.
      </Description>
    </>
  )
}
