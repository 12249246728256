import React from 'react'
import { sortBy } from '@lib/array'
import { currenciesOptions, operatorsOptions, sourcesOptions, typesOptions, termsOptions, terms } from '@lib/constants'
import {
  AmountTableFilter,
  CurrencyTableFilter,
  MultiChoiceTableFilter,
  SortAscListItem,
  SortDescListItem,
  DateTableFilter,
} from '@ui'
import { exportStatusesOptions } from '../constants'

export const DateFilter = ({ column, onClose }) => (
  <DateTableFilter
    options={termsOptions.concat([{ label: 'Custom', value: terms.CUSTOM }])}
    column={column}
    onClose={onClose}
  />
)

export const StatusFilter = ({ column, onClose }) => (
  <MultiChoiceTableFilter
    options={exportStatusesOptions}
    column={column}
    onClose={onClose}
    sortButtons={<SortButtonsAlpha column={column} onClose={onClose} />}
  />
)

export const ProcessorFilter = ({ column, onClose }) => (
  <MultiChoiceTableFilter
    options={sortBy(sourcesOptions, 'value')}
    column={column}
    onClose={onClose}
    sortButtons={<SortButtonsAlpha column={column} onClose={onClose} />}
  />
)

export const TypeFilter = ({ column, onClose }) => (
  <MultiChoiceTableFilter
    options={typesOptions}
    column={column}
    onClose={onClose}
    sortButtons={<SortButtonsAlpha column={column} onClose={onClose} />}
  />
)

export const AmountFilter = ({ column, onClose }) => (
  <AmountTableFilter
    options={operatorsOptions}
    column={column}
    onClose={onClose}
  />
)

export const CurrencyFilter = ({ column, onClose }) => (
  <CurrencyTableFilter
    options={currenciesOptions}
    column={column}
    onClose={onClose}
  />
)

const SortButtonsAlpha = ({ column, onClose }) => (
  <>
    <SortAscListItem column={column} title="Sort A to Z" onClose={onClose} />
    <SortDescListItem column={column} title="Sort Z to A" onClose={onClose} />
  </>
)
