import React, { useState } from 'react'
import * as P from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import { Icon } from './index'

const CloseButtonContainer = styled.a`
  float: right;
  margin: -2px -4px 8px 8px;
`

const AlertBase = styled.div`
  border-radius: 3px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  outline: none;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: 1px solid transparent;
  margin-bottom: 8px;

  ${(props) => {
    const { type } = props
    const { bg, color } = theme(`alert.${type}`)(props)

    return css`
      background-color: ${bg};
      border-color: ${bg};
      color: ${color};

      ${CloseButtonContainer} {
        color: ${color} !important;
      }

      a {
        color: ${color};
        text-decoration: underline;
  
        &:hover, &:focus {
          color: ${color};
          text-decoration: underline;
          opacity: 0.7;
        }
      }
    `  
  }}
`

export const Alert = ({
  children,
  type = 'primary',
  onClose,
  noButton = false,
  ...props
}) => {
  const [show, setShow] = useState(true)
  const close = () => setShow(false)
  const iconVariant = ['primary', 'danger'].includes(type) ? 'white' : 'dark'

  return show ? (
    <AlertBase type={type} {...props}>
      {!noButton && (
        <CloseButtonContainer onClick={onClose || close}>
          <Icon name="cancel" variant={iconVariant} height="12px" />
        </CloseButtonContainer>
      )}
      {children}
    </AlertBase>
  ) : null  
}

Alert.propTypes = {
  type: P.oneOf(['primary', 'danger', 'info', 'warning']),
}
