import { range } from '@lib/array'
import { conditionsEqual, rulesEqual } from './mappers'

export const checkDuplicateConditions = (ruleToCheck, rules) => {
  const indexes = findDuplicateConditionsIndexes(ruleToCheck, rules)

  if (indexes.length > 0) {
    const duplicateMessage = 'Same condition already exists in created rules'
    const conditionsCount = ruleToCheck.conditions.all.length

    return range(conditionsCount).map((index) =>
      indexes.includes(index)
        ? duplicateMessage
        : null,
    )
  }

  return []
}

const findDuplicateConditionsIndexes = (ruleToCheck, rules) => {
  // filter equal rules, for editing mode
  const fileredRules = rules.filter((rule) => !rulesEqual(ruleToCheck, rule))

  for (const rule of fileredRules) {
    const isEqual = rulesDeepEqual(ruleToCheck, rule)
    if (isEqual) {
      return ruleToCheck.conditions.all.map((_, index) => index)
    }
  }

  return []
}

/**
 * Checks two rules for deep equality.
 * Both rules deep equal if their conditions equal.
 * @param {*} ruleA
 * @param {*} ruleB
 * @returns {boolean}
 */
const rulesDeepEqual = (ruleA, ruleB) =>
  ruleA.conditions.all.every((conditionA) =>
    ruleB.conditions.all.some((conditionB) =>
      conditionsEqual(conditionA, conditionB),
    ),
  )
