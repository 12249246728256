import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeRangePicker, Icon } from '@ui'
import { getInputProps } from '@lib/date-picker-hooks'
import { $filters } from '../selectors/statistics'
import { customTermFilterSet, termFilterSet } from '../effects'
import { TermSelect } from './TermSelect'

export const DatePicker = () => {
  const dispatch = useDispatch()
  const { term, gte, lte } = useSelector($filters)

  const { value: dateInputValue } = getInputProps({
    startDate: gte,
    endDate: lte,
    isRange: true,
    showTime: false,
  })

  const onDatesChange = (dates) => dispatch(customTermFilterSet(dates))
  const onTermChange = (term) => dispatch(termFilterSet(term))

  return (
    <Container>
      <TermSelect
        value={term}
        dateInputValue={dateInputValue}
        onChange={onTermChange}
      />
      <Separator />
      <DateTimeRangePicker
        showTime={false}
        side="right"
        numberOfMonths={1}
        closeAfterSelect
        from={gte}
        to={lte}
        onChange={onDatesChange}
        components={{
          Input: DatePickerInput,
        }}
      />
    </Container>
  )
}

const DatePickerInput = React.forwardRef(({ open, isOpen }, ref) => (
  <PickerTrigger onClick={open} ref={ref}>
    <Icon name="calendar" variant={isOpen ? 'primary' : 'gray'} />
  </PickerTrigger>
))

const PickerTrigger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 12px 0 12px 12px;
  outline: none;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Separator = styled.div`
  width: 1px;
  height: 27px;
  border-right: 1px solid ${theme('color.border')};
  margin-left: 16px;
`
