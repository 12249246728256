import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

// Screen readers only
export const srOnly = css`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`

export const HiddenInput = styled.input`
  ${srOnly};
`

HiddenInput.defaultProps = {
  type: 'checkbox',
}

export const StyledInput = styled.div`
  display: inline-block;
  box-sizing: border-box;
  line-height: 10px; // center child elements like CheckIcon
`

export const InputContainer = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  width: ${ifProp('block', '100%')};
`

export const InputLabel = styled.span`
  margin-left: 12px;
  user-select: none;

  ${ifProp('block', css`
    margin-left: auto;
  `)};

  ${ifProp('reversed', css`
    order: -1;
    margin-left: 0;
    margin-right: 8px;
    
    ${ifProp('block', css`
      margin-right: auto;
    `)};
  `)};
`
