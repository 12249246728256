import React from 'react'
import { useSelector } from 'react-redux'
import { Divider, FormGroup, H2, InputLabel, SelectField, Text, QuestionIcon, TooltipContainer } from '@ui'
import { InputTemplate } from './shared'
import { DisplayOption } from '../../components/SettingsOptions'
import { useAccountPlaceholder } from '../../hooks'
import { $activeProcessorSettings, $formTaxRatesWithOptions } from '../../selectors/configuration'
import { $isIntegrationExpired } from '../../selectors/integration'

export const XeroTaxRates = () => {
  const taxRatePlaceholder = useAccountPlaceholder('Select a Xero tax rate...')
  const taxRates = useSelector($formTaxRatesWithOptions)
  const { processor } = useSelector($activeProcessorSettings)
  const isIntegrationExpired = useSelector($isIntegrationExpired)
  const taxRatesError = processor.taxRates.error

  return taxRates.length > 0 && (
    <>
      <Divider />
      <H2 mb="8px">
        Tax Mapping
        <div style={{float: 'right', marginRight: '200pt'}}>
          <TooltipContainer tooltip={'Use these options to map your Stripe tax codes to your tax codes in Xero. Choose the "Ignore" option to instead include any tax amounts from Stripe as income in Xero. Use this option if you would prefer to calculate tax obligations manually.'}>
            <QuestionIcon />
          </TooltipContainer>
        </div>
      </H2>
      {taxRatesError ? (
        <Text variant="danger">Error retrieving tax rates.</Text>
      ) : (
        taxRates.map(({ id, displayName, description, options }) => (
          <FormGroup key={id}>
            <InputTemplate>
              <InputLabel>
                {displayName}
                {!!description && <p>{description}</p>}
              </InputLabel>
              <SelectField
                name={`tax_mapping[${id}]`}
                placeholder={taxRatePlaceholder}
                options={options}
                disabled={isIntegrationExpired}
                isClearable
              />
            </InputTemplate>
          </FormGroup>
        ))
      )}
    </>
  )
}
