import * as P from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

export const ListItem = styled.li`
  list-style: none;
  padding: 0.75rem 1rem;
  outline: none;
  white-space: nowrap;
  line-height: 12px;

  // "important" fixes case when Menu.hoverable=true and MenuItem.isSelected=true should have correct bg color 
  background-color: ${(p) =>
    p.isSelected
      ? theme('color.active')
      : p.isActive && theme('color.activeLight')} !important;
  
    
  ${ifProp('bordered', css`
    border: 1px solid ${theme('color.border')};
    border-radius: 3px;
  `)}
`

ListItem.propTypes = {
  isActive: P.bool,
  isSelected: P.bool,
  bordered: P.bool,
}

export const ListDivider = styled.li`
  list-style: none;
  height: 0;
  border-bottom: 1px solid ${theme('color.border')};
  margin-top: ${prop('mt')};
  margin-bottom: ${prop('mb')};
`

export const List = styled.ul`
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 13px;
  font-weight: 500;
  color: ${theme('color.inputText')};
  position: relative;
  box-sizing: border-box;
  background-color: white;
  
  ${ifProp('bordered', css`
    border: 1px solid ${theme('color.border')};
    border-radius: 3px;
  `)};

  ${((p) => p.gap && css`
    & > ${ListItem}:not(:first-child) {
      margin-top: ${p.gap};
    }
  `)}

  ${ListItem} {
    ${ifProp('hoverable', css`
      &:hover {
        background-color: ${theme('color.activeLight')};
      }
    `)}
  }
`

List.propTypes = {
  hoverable: P.bool,
  gap: P.oneOfType([P.number, P.string]),
  bordered: P.bool,
}

List.defaultProps = {
  hoverable: false,
  gap: null,
  bordered: true,
}
