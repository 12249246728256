import { notify } from '@lib/notification'
import { actions } from '../symbiotes/sources'
import { rulesApi } from '../api'
import { $rulesByProcessorId } from '../selectors/sources'

export const ruleCreate = ({ processorId, rule, account }) => async (dispatch, getState) => {
  try {
    const rules = $rulesByProcessorId(getState())[processorId] || []
    const position = rules.length > 0 ? rules[rules.length - 1].meta.position + 1 : 1

    rule.meta = { account, position }

    const data = await rulesApi.create({ processorId, account, position, rule })
    dispatch(actions.rules.setByProcessorId({ processorId, data }))

    notify({
      message: 'Rule created',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: 'Something went wrong',
    })
  }
}

export const ruleUpdate = ({ processorId, account, position, rule }) => async (dispatch) => {
  try {
    const data = await rulesApi.update({ processorId, account, position, rule })
    dispatch(actions.rules.setByProcessorId({ processorId, data }))

    notify({
      message: 'Rule updated',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: 'Something went wrong',
    })
  }
}

export const ruleRemove = ({ processorId, account, position }) => async (dispatch) => {
  try {
    const data = await rulesApi.remove({ processorId, account, position })
    dispatch(actions.rules.setByProcessorId({ processorId, data }))

    notify({
      message: 'Rule deleted',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: 'Something went wrong',
    })
  }
}
