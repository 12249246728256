import React from 'react'
import { H2 } from './Heading'

export const Box = ({ title, children, ...props }) => (
  <div {...props}>
    {title && (
      <H2 style={{ marginBottom: '16px' }}>{title}</H2>
    )}
    <div style={{ marginBottom: '16px' }}>
      {children}
    </div>
  </div>
)
