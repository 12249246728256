import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ensurePluginOrder } from 'react-table'
import useOnClickOutside from 'use-onclickoutside'
import { BackgroundPositioner, FormCheck, List, ListItem, roundShadow } from '@ui'
import { useOpened } from '../hooks'

/**
 * Injects a column for selection to the table.
 * Uses a globalFilter hooks state to manage own state.
 * Allows to select all, select for a page.
 * @param hooks
 */
export const useMultiSelectionHeader = (hooks) => {
  hooks.allColumns.push(flatColumns)
  hooks.useInstance.push(useInstance)
}

useMultiSelectionHeader.pluginName = 'useMultiSelectionHeader'

const flatColumns = (columns) => [{
  id: 'selection',
  disableSorting: true,
  width: 50,
  Header: ({ setGlobalFilter, toggleAllRowsSelected, isAllRowsSelected, state }) => {
    const selectType = state.globalFilter || types.NONE

    const {
      menu,
      menuRef,
      onTogglerClick,
      toggleSelectAll,
      toggleSelectPage,
    } = useMultiSelection({
      setGlobalFilter,
      toggleAllRowsSelected,
      selectType,
      isAllRowsSelected,
    })

    return (
      <MenuWrapper ref={menuRef}>
        <FormCheck
          checked={isAllRowsSelected}
          onChange={onTogglerClick}
        />
        {menu.opened && (
          <Background>
            <List hoverable>
              <Item onClick={toggleSelectAll} isSelected={selectType === types.ALL}>
                Select all
              </Item>
              <Item onClick={toggleSelectPage} isSelected={selectType === types.PAGE}>
                Select for page
              </Item>
            </List>
          </Background>
        )}
      </MenuWrapper>
    )
  },
  Cell: ({ row }) => (
    <FormCheck {...row.getToggleRowSelectedProps()} />
  ),
}, ...columns]

const MenuWrapper = styled.span`
  display: inline-block;
  position: relative;
`

const Background = styled(BackgroundPositioner)`
  left: -4px;
  ${roundShadow};
`

const Item = styled(ListItem)`
  cursor: pointer;
`

const useInstance = (instance) => {
  const { plugins } = instance

  ensurePluginOrder(
    plugins,
    ['useRowSelect', 'useGlobalFilter'],
    'useMultiSelectionHeader',
  )
}

const useMultiSelection = ({ setGlobalFilter, toggleAllRowsSelected, selectType, isAllRowsSelected }) => {
  const menuRef = useRef()
  const menu = useOpened()

  useOnClickOutside(menuRef, menu.close)

  const updateSelection = (selectType) => {
    setGlobalFilter(selectType)
    toggleAllRowsSelected(toBoolean(selectType))
  }

  const onTogglerClick = () => {
    if (isAllRowsSelected) {
      return updateSelection(types.NONE)
    }
    menu.toggle()
  }

  const toggleSelectAll = () => {
    const nextSelectType = getNextSelectAllType(selectType)
    updateSelection(nextSelectType)
    menu.close()
  }

  const toggleSelectPage = () => {
    const nextSelectType = getNextSelectPageType(selectType)
    updateSelection(nextSelectType)
    menu.close()
  }

  useEffect(() => {
    // reset selection when react-table deselects all rows
    // this may typically happen after a table received new data
    if (!isAllRowsSelected && selectType !== types.NONE) {
      setGlobalFilter(types.NONE)

      if (selectType === types.ALL) {
        // reset all rows selection when all was selected
        toggleAllRowsSelected(false)
      }
    }
  }, [isAllRowsSelected, selectType, setGlobalFilter, toggleAllRowsSelected])

  return {
    menu,
    menuRef,
    onTogglerClick,
    toggleSelectAll,
    toggleSelectPage,
  }
}

const types = {
  NONE: 'none',
  PAGE: 'page',
  ALL: 'all',
}

export const selectionTypes = types

const getNextSelectAllType = (selectType) => {
  switch (selectType) {
    default:
    case types.NONE:
    case types.PAGE:
      return types.ALL
    case types.ALL:
      return types.NONE
  }
}

const getNextSelectPageType = (selectType) => {
  switch (selectType) {
    default:
    case types.NONE:
    case types.ALL:
      return types.PAGE
    case types.PAGE:
      return types.NONE
  }
}

const toBoolean = (selectType) => {
  switch (selectType) {
    default:
    case types.NONE:
      return false
    case types.ALL:
    case types.PAGE:
      return true
  }
}
