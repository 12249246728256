import React from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { MainTemplate, $currentUser } from '@features/common'
import { Alert, Heading } from '@ui'
import { ConnectedView } from './connected'
import { FaqView } from './faq'

export const TransactionsPage = () => {
  const user = useSelector($currentUser)
  const { search } = useLocation()
  const emailVerified = search.includes('emailVerified')
  const upgraded = search.includes('upgraded')
  const fullyConnected = user.has_transactions
    || (user.has_payment_connection && user.has_integration)

  return (
    <MainTemplate heading={<Heading>Transactions</Heading>}>
      {emailVerified && (
        <Alert>
          <strong>Thank you!</strong> Your email has been verified.
        </Alert>
      )}
      {upgraded && (
        <Alert>
          <strong>Thank you!</strong> Your trial has started.
        </Alert>
      )}
      {fullyConnected ? <ConnectedView /> : <FaqView />}
    </MainTemplate>
  )
}
