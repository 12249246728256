import * as Yup from 'yup'
import { events } from './constants'

const requiredWhenEventEqual = (requiredEvent) => (event, schema) =>
  event === requiredEvent
    ? schema.required('Required')
    : schema

export const exportRuleSchema = Yup.object({
  conditions: Yup
    .array()
    .of(
      Yup.object({
        field: Yup.string().nullable().required('Required'),
        operator: Yup.string().nullable().required('Required'),
        value: Yup.string().nullable().required('Required'),
      }).uniqueProperty('field', 'Should be unique'),
    ),
  event: Yup.string().required(),
  account: Yup.string().nullable(),
  code: Yup.string().nullable().when('event', requiredWhenEventEqual(events.EXPORT)),
  taxType: Yup.string().nullable().when('event', requiredWhenEventEqual(events.CODE_WITH_TAX)),
})

export const exportPayoutsRuleSchema = Yup.object({
  conditions: Yup
    .array()
    .of(
      Yup.object({
        field: Yup.string().nullable().required('Required'),
        operator: Yup.string().nullable().required('Required'),
        value: Yup.string().nullable().required('Required'),
      }).uniqueProperty('field', 'Should be unique'),
    ),
  event: Yup.string().required(),
  account: Yup.string().nullable(),
  fromAccount: Yup.string().nullable().when('event', requiredWhenEventEqual(events.EXPORT)),
  toAccount: Yup.string().nullable().when('event', requiredWhenEventEqual(events.EXPORT)),
  taxType: Yup.string().nullable().when('event', requiredWhenEventEqual(events.CODE_WITH_TAX)),
})

const settingsSchema = Yup.object().shape({
  bankfeeds_account: Yup.mixed()
    .required('Bank Feed Account is required'),
  //.notOneOf([Yup.ref('payouts_account')], 'Bank Feed Account should not be the same as Payouts Account'),
  payouts_account: Yup.mixed()
    .when('track_payouts', {
      is: true,
      then: Yup.mixed()
        .required('Payouts Account is required'),
      //.notOneOf([Yup.ref('bankfeeds_account')], 'Payouts Account should not be the same as Bank Feed Account'),
    }),
  charges_account: Yup.mixed(),
  fees_account: Yup.mixed(),
  app_fees_account: Yup.mixed(),
  refunds_account: Yup.mixed(),
  adjustments_account: Yup.mixed(),
  disputes_account: Yup.mixed(),
})

export const xeroSettingsSchema = Yup.object().shape({}).concat(settingsSchema)

export const quickbooksSettingsSchema = Yup.object().shape({
  product_type: Yup.string().nullable().required('Required'),
  product_income_account: Yup.string().nullable().required('Required'),
  vendor: Yup.string().nullable().required('Required'),
  payment_method: Yup.string().nullable().required('Required'),
  default_customer: Yup.string().nullable().required('Required'),
}).concat(settingsSchema)

const min3CharsMsg = 'Please type at least 3 characters'

export const quickbooksEntitiesSchema = Yup.object({
  bankfeeds_account: Yup.string().required('Required').min(3, min3CharsMsg),
  payment_method: Yup.string().required('Required').min(3, min3CharsMsg),
  vendor: Yup.string().required('Required').min(3, min3CharsMsg),
  default_customer: Yup.string().required('Required').min(3, min3CharsMsg),
})
