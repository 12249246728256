import { useEffect, useRef } from 'react'

/**
 * Determines whether a component is unmounted to prevent unnecessary state updates.
 *
 * @returns {React.MutableRefObject<boolean>}
 */
export const useDidUnmount = () => {
  const didUnmount = useRef(false)

  useEffect(() => () => {
    didUnmount.current = true
  }, [])

  return didUnmount
}
