/**
 * An Object-specific version of Array.find.
 *
 * @param {Object} object
 * @param {function} predicate
 * @returns {any|null}
 */
export const objectFind = (object, predicate) => {
  // eslint-disable-next-line
  for (const key in object) {
    const value = object[key]
    if (predicate(value, key)) {
      return value
    }
  }
}

/**
 * An Object-specific version of Array.filter.
 *
 * @param {Object} object
 * @param {function} predicate
 * @returns {Object}
 */
export const objectFilter = (object, predicate) => {
  const filtered = {}
  // eslint-disable-next-line
  for (const key in object) {
    const value = object[key]
    if (predicate(value, key)) {
      filtered[key] = value
    }
  }
  return filtered
}

/**
 * An Object-specific version of Array.map.
 *
 * @param {Object} object
 * @param {function} predicate
 * @returns {Object}
 */
export const objectMap = (object, predicate) => {
  const mapped = {}
  // eslint-disable-next-line
  for (const key in object) {
    const value = object[key]
    mapped[key] = predicate(value, key)
  }
  return mapped
}

/**
 * Omits specified key from object.
 *
 * @param {Object} object
 * @param {string} key
 * @returns {Object}
 */
export const objectOmit = (object, key) => {
  const { [key]: _, ...newObject } = object
  return newObject
}

/**
 * Recursively removes empty values from object.
 *
 * @param {Object} data
 */
export const filterEmpty = (data) => {
  const result = {}
  // eslint-disable-next-line
  for (const key in data) {
    const value = data[key]
    if (Array.isArray(value) && value.length > 0) {
      result[key] = filterArray(value)
    } else if (typeof value === 'object' && !isEmpty(value)) {
      result[key] = filterEmpty(value)
    } else if (!isEmpty(value)) {
      result[key] = value
    }
  }
  return result
}

const filterArray = (array) => {
  const result = []
  // eslint-disable-next-line
  for (const item of array) {
    if (typeof item === 'object' && !isEmpty(item)) {
      result.push(filterEmpty(item))
    } else if (!isEmpty(item)) {
      result.push(item)
    }
  }
  return result
}

const isEmpty = (val) =>
  [undefined, null, '', false].includes(val)
  || (Array.isArray(val) && val.length === 0)
