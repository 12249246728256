export const toSelectItems = (items, label = 'label', value = 'value') =>
  items.map((item) => ({ label: item[label], value: (item[value] || '').toString() }))

export const valueOrDefault = (value, defaultValue) => {
  if (value === undefined || value === null) {
    return defaultValue
  }
  return Boolean(value)
}

export const valueOrTrue = (val) => valueOrDefault(val, true)

export const amount = (amount, currency = 'USD') => {
  currency = typeof currency === 'string' ? currency.toUpperCase() : ''
  amount = parseFloat(amount)
  amount = Number.isFinite(amount) ? amount.toFixed(2) : ''
  return `${amount} ${currency}`
}

export const formatPercent = (val, total) => formatNum(percent(val, total))

export const percent = (num, total) =>
  total > 0
    ? num / total * 100
    : 0

export const formatNum = (val, fractionDigits = 1) => {
  val = Number(val)
  if (!Number.isFinite(val)) return ''
  return val.toFixed(fractionDigits)
}
