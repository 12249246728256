import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { switchProp, theme } from 'styled-tools'

export const QuestionIcon = styled.span`
  display: inline-block;
  position: relative;
  background-color: ${theme('color.border')};
  border-radius: 50%;
  width: 14px;
  height: 13px;
  font-family: Roboto, serif;
  font-size: 9px;
  cursor: pointer;

  &:before {
    content: "?";
    position: absolute;
    left: 5px;
    top: 1px;
  }
`

const chevronBorder = ({ bold }) => bold ? '0.09em' : '0.06em'

const position = switchProp('position', {
  right: css`
    top: 0;
    left: 0;
    transform: rotate(45deg);
  `,
  bottom: css`
    transform: rotate(135deg);
    top: -0.1em;
    left: 0.15em;
  `,
})

export const Chevron = styled.span`
  font-size: 18px;
  color: ${theme('color.primary')};
  padding-left: 8px;
  display: inline-flex;

  &:before {
    content: "";
    display: inline-block;
    border-style: solid;
    border-width: ${chevronBorder} ${chevronBorder} 0 0;
    position: relative;
    width: 0.45em;
    height: 0.45em;
    vertical-align: top;

    ${position};
  }
`

Chevron.propTypes = {
  bold: P.bool,
  position: P.string,
}

Chevron.defaultProps = {
  bold: false,
  position: 'right',
}
