import React from 'react'
import { Button, LoadingButton, Modal } from '../index'
import { CenterContent } from '../CenterContent'

export const YesNoModal = ({
  children,
  show,
  title,
  loading,
  yesText = 'CONFIRM',
  noText = 'CANCEL',
  onConfirm,
  onClose,
}) => (
  <Modal show={show} size="sm" onClose={onClose}>
    <CenterContent>
      {title && (
        <Modal.Title>
          {title}
        </Modal.Title>
      )}
      <Modal.Body>
        <Modal.Text>
          {children}
        </Modal.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" size="full" onClick={onClose}>{noText}</Button>
        <LoadingButton size="full" onClick={onConfirm} loading={loading}>{yesText}</LoadingButton>
      </Modal.Footer>
    </CenterContent>
  </Modal>
)
