import React from 'react'
import styled from 'styled-components'
import { Tab } from './Tab'

export const Tabs = ({ tabs }) => (
  <TabsWrapper>
    {tabs.map((tab) => (
      <Tab {...tab.getTitleProps()}>
        {tab.render('Title')}
      </Tab>
    ))}
  </TabsWrapper>
)

export const TabsWrapper = styled.div`
  margin-bottom: 40px;

  & > :not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 8px;
  }
`
