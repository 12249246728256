import { createSelector } from 'reselect'
import { toSelectItems } from '@lib/convert'
import { qboAccountTypes } from '@lib/constants'
import { $integrationEntities } from './integration'
import { $activeProcessorSettings } from './configuration'
import { createAccountOptions } from '../selector-factories/quickbooks'

const $paymentMethods = createSelector(
  $integrationEntities,
  (entities) => entities.paymentMethods,
)

export const $paymentMethodsOptions = createSelector(
  $paymentMethods,
  (paymentMethods) => toSelectItems(paymentMethods, 'name', 'id'),
)

const $items = createSelector(
  $integrationEntities,
  (entities) => entities.items,
)

export const $itemsOptions = createSelector(
  $items,
  (items) => toSelectItems(items, 'name', 'id'),
)

const $itemTypes = createSelector(
  $integrationEntities,
  (entities) => entities.itemTypes,
)

export const $itemTypesOptions = createSelector(
  $itemTypes,
  (itemTypes) => toSelectItems(itemTypes, 'name', 'id'),
)

const $vendors = createSelector(
  $integrationEntities,
  (entities) => entities.vendors,
)

export const $vendorOptions = createSelector(
  $vendors,
  (vendors) => toSelectItems(vendors, 'name', 'id'),
)

const $customers = createSelector(
  $integrationEntities,
  (entities) => entities.customers,
)

export const $customersOptions = createSelector(
  $customers,
  (customers) => toSelectItems(customers, 'name', 'id'),
)

export const $accounts = createSelector(
  $integrationEntities,
  (entities) => entities.accounts,
)

export const $incomeAccountOptions = createAccountOptions([qboAccountTypes.INCOME])

export const $accountsOptions = createAccountOptions()

export const $bankAccountOptions = createAccountOptions([qboAccountTypes.BANK])

export const $preferences = createSelector(
  $integrationEntities,
  (entities) => entities.preferences,
)

const $taxCodes = createSelector(
  $integrationEntities,
  (entities) => entities.taxCodes,
)

export const $taxOptions = createSelector(
  $activeProcessorSettings,
  $taxCodes,
  ({ processor }, taxCodes) => {
    const salesTaxCodes = taxCodes.filter((taxCode) => taxCode.sales.amount > 0)
    const purchaseTaxCodes = taxCodes.filter((taxCode) => taxCode.purchase.amount > 0)

    return processor.taxRates.data.map((taxRate) => {
      const isPurchase = taxRate.accountClass && taxRate.accountClass === 'EXPENSE'
      const finalTaxCodes = isPurchase ? purchaseTaxCodes : salesTaxCodes

      const options = finalTaxCodes.map((taxCode) => ({
        value: taxCode.id,
        label: `${taxCode.name} (${isPurchase ? taxCode.purchase.totalPercent : taxCode.sales.totalPercent}%)`,
      }))

      return {
        ...taxRate,
        options,
      }
    })
  },
)
