import React from 'react'
import { renderRoutes } from 'react-router-config'
import { useSelector } from 'react-redux'
import { MainTemplate, $currentUser, ApiError } from '@features/common'
import { Col, Row } from '@lib/styled-layout'
import { TabLink, TabsWrapper, Heading } from '@ui'
import { $fetchingError } from '../../selectors/integration'

export const IntegrationsPage = ({ route }) => {
  const user = useSelector($currentUser)
  const fetchingError = useSelector($fetchingError)

  return (
    <MainTemplate heading={<Heading>Integrations</Heading>}>
      {user.has_integration && fetchingError && (
        <ApiError
          message={fetchingError.error}
          reference={fetchingError.referenceNumber}
        />
      )}
      <TabsWrapper>
        <TabLink to="/integrations">ACCOUNT</TabLink>
        {!!user.has_integration && (
          <TabLink to="/integrations/payments">PAYMENTS</TabLink>
        )}
      </TabsWrapper>
      <Row>
        <Col xl={10}>
          {renderRoutes(route.routes)}
        </Col>
      </Row>
    </MainTemplate>
  )
}
