import React from 'react'
import * as P from 'prop-types'
import styled from 'styled-components'
import { prop, theme } from 'styled-tools'
// When you need more icons just add svg-path in getIconPath, view-box in getViewBox and call it
// with <Icon name="yourname" />

export const ICONS = {
  arrow: 'arrow',
  arrowUp: 'arrow-up',
  calendar: 'calendar',
  cancel: 'cancel',
  cardView: 'card-view',
  import: 'import',
  logout: 'logout',
  notification: 'notification',
  rows: 'rows',
  search: 'search',
  dashboard: 'dashboard',
  integrations: 'integrations',
  pay: 'pay',
  settings: 'settings',
  transactions: 'transactions',
  check: 'check',
  chevronRight: 'chevronRight',
  chevronLeft: 'chevronLeft',
  doubleChevronRight: 'doubleChevronRight',
  doubleChevronLeft: 'doubleChevronLeft',
  sortAsc: 'sortAsc',
  sortDesc: 'sortDesc',
  plus: 'plus',
  sortAscFlat: 'sortAscFlat',
  sortDescFlat: 'sortDescFlat',
  reorder: 'reorder',
  plusCircle: 'plusCircle',
  longArrowLeft: 'longArrowLeft',
  filterFlat: 'filterFlat',
}

const getViewBox = (name) => {
  switch (name) {
    case ICONS.arrow:
    case ICONS.arrowUp:
      return '0 0 11 6'
    case ICONS.calendar:
      return '0 0 17 17'
    case ICONS.cancel:
      return '0 0 13 15'
    case ICONS.cardView:
    case ICONS.import:
    case ICONS.logout:
    case ICONS.notification:
    case ICONS.rows:
    case ICONS.search:
      return '0 0 15 15'
    case ICONS.dashboard:
      return '0 0 13 14'
    case ICONS.integrations:
      return '0 0 16 14'
    case ICONS.pay:
      return '0 0 15 12'
    case ICONS.settings:
      return '0 0 15 16'
    case ICONS.transactions:
      return '0 0 18 16'
    case ICONS.check:
      return '0 0 12 12'
    case ICONS.chevronRight:
    case ICONS.chevronLeft:
      return '0 0 12 15'
    case ICONS.doubleChevronRight:
    case ICONS.doubleChevronLeft:
      return '0 0 18 15'
    case ICONS.sortAsc:
    case ICONS.sortDesc:
      return '0 0 20 20'
    case ICONS.plus:
      return '0 0 13 12'
    case ICONS.sortAscFlat:
    case ICONS.sortDescFlat:
      return '0 0 18 11'
    case ICONS.reorder:
      return '0 0 18 13'
    case ICONS.plusCircle:
      return '0 0 14 14'
    case ICONS.longArrowLeft:
      return '0 0 14 10'
    case ICONS.filterFlat:
      return '0 0 11 9'
    default:
      return ''
  }
}

const iconVariant = ({ variant }) =>
  theme(`icon.${variant}`, theme('color.dark'))

const StyledPath = styled.path`
  fill: ${iconVariant};
`

const StyledRect = styled.rect`
  fill: ${iconVariant};
`

const StyledCircle = styled.circle`
  fill: ${iconVariant};
`

const StyledLine = styled.line`
  stroke: ${iconVariant};
  stroke-width: ${prop('strokeWidth', 1.3)};
`

const getIconPath = (name, props) => {
  switch (name) {
    case ICONS.arrow:
      return (
        <StyledPath d="M5.5 6L-5.07885e-07 9.9318e-07L11 0L5.5 6Z" {...props} />
      )
    case ICONS.arrowUp:
      return (
        <g
          transform="translate(0,-291)"
        >
          <StyledPath
            d="M 5.5,291 0,297 h 11 z"
            {...props}
          />
        </g>
      )
    case ICONS.calendar:
      return (
        <StyledPath
          d="M15 15H2V6H15V15ZM12 0V1.7H5V0H3V1.7H1.88889C0.840556 1.7 0 2.4565 0 3.4V15.3C0 15.7509 0.199007 16.1833 0.553243 16.5021C0.907478 16.8209 1.38792 17 1.88889 17H15.1111C15.6121 17 16.0925 16.8209 16.4468 16.5021C16.801 16.1833 17 15.7509 17 15.3V3.4C17 2.4565 16.15 1.7 15.1111 1.7H14V0H12ZM13.6 8H8.5V13H13.6V8Z"
          {...props}
        />
      )
    case ICONS.cancel:
      return (
        <>
          <StyledLine x1="0.619109" y1="1.41447" x2="12.4618" y2="13.3237" {...props} />
          <StyledLine x1="12.2394" y1="1.63184" x2="0.581445" y2="13.722" {...props} />
        </>
      )
    case ICONS.cardView:
      return (
        <>
          <StyledRect x="7" width="7" height="7" rx="1" transform="rotate(90 7 0)" {...props} />
          <StyledRect x="15" width="7" height="7" rx="1" transform="rotate(90 15 0)" {...props} />
          <StyledRect x="7" y="8" width="7" height="7" rx="1" transform="rotate(90 7 8)" {...props} />
          <StyledRect x="15" y="8" width="7" height="7" rx="1" transform="rotate(90 15 8)" {...props} />
        </>
      )
    case ICONS.dashboard:
      return (
        <>
          <StyledPath d="M0 6.99976C0 6.44747 0.447715 5.99976 1 5.99976H2C2.55228 5.99976 3 6.44747 3 6.99976V13.9998H0V6.99976Z" {...props} />
          <StyledPath d="M5 0.999756C5 0.447471 5.44772 -0.000244141 6 -0.000244141H7C7.55228 -0.000244141 8 0.447471 8 0.999756V13.9998H5V0.999756Z" {...props} />
          <StyledPath d="M10 3.99976C10 3.44747 10.4477 2.99976 11 2.99976H12C12.5523 2.99976 13 3.44747 13 3.99976V13.9998H10V3.99976Z" {...props} />
        </>
      )
    case ICONS.import:
      return (
        <>
          <StyledRect y="11.5386" width="15" height="3.46154" rx="1" {...props} />
          <StyledPath
            d="M7.65234 -4.37114e-08C8.20463 -1.95702e-08 8.65234 0.447715 8.65234 1L8.65234 6.92308L6.34465 6.92308L6.34465 0.999999C6.34465 0.447715 6.79237 -8.13022e-08 7.34465 -5.7161e-08L7.65234 -4.37114e-08Z"
            {...props}
          />
          <StyledPath d="M7.4979 10.3847L12.1133 5.76929L2.88251 5.76929L7.4979 10.3847Z" {...props} />
        </>
      )
    case ICONS.integrations:
      return (
        <>
          <StyledPath
            fillRule="evenodd" clipRule="evenodd" d="M1 0C0.447715 0 0 0.447716 0 1V8.64286C0 9.19514 0.447716 9.64286 1 9.64286H5.35547V5.2876C5.35547 4.73531 5.80318 4.2876 6.35547 4.2876H9.63895V8.64334C9.63895 9.15289 9.25785 9.57342 8.76514 9.63546C9.25974 9.57515 9.64286 9.15375 9.64286 8.64286V1C9.64286 0.447715 9.19514 0 8.64286 0H1Z"
            {...props}
          />
          <StyledPath
            fillRule="evenodd" clipRule="evenodd" d="M6.23216 4.29512C6.27257 4.29016 6.31372 4.2876 6.35547 4.2876H9.63895V8.64334C9.63895 9.19563 9.19124 9.64334 8.63895 9.64334H5.35938V12.93C5.35938 13.4823 5.80709 13.93 6.35938 13.93H14.0022C14.5545 13.93 15.0022 13.4823 15.0022 12.93V5.28711C15.0022 4.73482 14.5545 4.28711 14.0022 4.28711H6.35938C6.31628 4.28711 6.27382 4.28984 6.23216 4.29512Z"
            {...props}
          />
        </>
      )
    case ICONS.logout:
      return (
        <>
          <StyledRect x="12" y="15" width="15" height="3" rx="0.5" transform="rotate(-90 12 15)" {...props} />
          <StyledRect y="6.34668" width="6.92308" height="2.30769" rx="0.5" {...props} />
          <StyledPath d="M10.3849 7.50015L5.76953 2.88477V12.1155L10.3849 7.50015Z" {...props} />
        </>
      )
    case ICONS.notification:
      return (
        <>
          <StyledPath
            d="M14.9468 12.2348L13.659 10.505C13.0643 9.70662 12.75 8.79215 12.75 7.86139V6.34616C12.75 4.43324 11.1659 2.81529 8.99982 2.29368V1.2088C8.99982 0.542143 8.32704 0 7.49975 0C6.67245 0 5.99968 0.542143 5.99968 1.2088V2.29368C3.83362 2.81529 2.24955 4.43324 2.24955 6.34616V7.86139C2.24955 8.79215 1.93528 9.70599 1.34126 10.5044L0.0534688 12.2342C-0.0162839 12.3279 -0.0177957 12.4439 0.0489687 12.5388C0.115733 12.6337 0.239488 12.6923 0.374493 12.6923H14.625C14.76 12.6923 14.8845 12.6337 14.9513 12.5394C15.0181 12.4451 15.0158 12.3279 14.9468 12.2348Z"
            {...props}
          />
          <StyledPath
            d="M5.36328 13.7498C5.74516 14.486 6.55359 14.9998 7.49539 14.9998C8.4372 14.9998 9.24567 14.486 9.62754 13.7498H5.36328Z"
            {...props}
          />
        </>
      )
    case ICONS.pay:
      return (
        <StyledPath
          fillRule="evenodd" clipRule="evenodd" d="M1 0C0.447715 0 0 0.447716 0 1V3.46155H15V1C15 0.447715 14.5523 0 14 0H1ZM15 5.76924H0V10.5385C0 11.0907 0.447715 11.5385 1 11.5385H14C14.5523 11.5385 15 11.0907 15 10.5385V5.76924Z"
          {...props}
        />
      )
    case ICONS.rows:
      return (
        <>
          <StyledRect y="6" width="15" height="3" rx="1" {...props} />
          <StyledRect y="12" width="15" height="3" rx="1" {...props} />
          <StyledRect width="15" height="3" rx="1" {...props} />
        </>
      )
    case ICONS.search:
      return (
        <StyledPath
          fillRule="evenodd" clipRule="evenodd" d="M1.71425 1.66949C-0.568813 3.89548 -0.568813 7.50452 1.71425 9.73051C3.82547 11.7889 7.14945 11.9439 9.44342 10.1954L13.8257 14.4681L14.8411 13.4782L10.4588 9.20543C12.2521 6.96881 12.0932 3.72793 9.98196 1.66949C7.6989 -0.556497 3.99732 -0.556497 1.71425 1.66949ZM2.72958 8.74056C1.00727 7.0613 1.00727 4.3387 2.72958 2.65944C4.4519 0.980187 7.24432 0.980186 8.96663 2.65944C10.6889 4.3387 10.6889 7.06131 8.96663 8.74056C7.24432 10.4198 4.4519 10.4198 2.72958 8.74056Z"
          {...props}
        />
      )
    case ICONS.settings:
      return (
        <StyledPath
          d="M13.2305 8.735C13.2614 8.495 13.2845 8.255 13.2845 8C13.2845 7.745 13.2614 7.505 13.2305 7.265L14.8574 6.0275C15.0039 5.915 15.0425 5.7125 14.9499 5.5475L13.4079 2.9525C13.3153 2.7875 13.1072 2.7275 12.9375 2.7875L11.0176 3.5375C10.6167 3.2375 10.1849 2.99 9.71459 2.8025L9.42159 0.815C9.39846 0.635 9.23654 0.5 9.04378 0.5H5.95962C5.76686 0.5 5.60494 0.635 5.58181 0.815L5.28882 2.8025C4.81848 2.99 4.3867 3.245 3.98576 3.5375L2.06587 2.7875C1.88853 2.72 1.68806 2.7875 1.59554 2.9525L0.0534574 5.5475C-0.0467778 5.7125 -0.000515334 5.915 0.145982 6.0275L1.77288 7.265C1.74203 7.505 1.7189 7.7525 1.7189 8C1.7189 8.2475 1.74203 8.495 1.77288 8.735L0.145982 9.9725C-0.000515334 10.085 -0.0390674 10.2875 0.0534574 10.4525L1.59554 13.0475C1.68806 13.2125 1.89624 13.2725 2.06587 13.2125L3.98576 12.4625C4.3867 12.7625 4.81848 13.01 5.28882 13.1975L5.58181 15.185C5.60494 15.365 5.76686 15.5 5.95962 15.5H9.04378C9.23654 15.5 9.39846 15.365 9.42159 15.185L9.71459 13.1975C10.1849 13.01 10.6167 12.755 11.0176 12.4625L12.9375 13.2125C13.1149 13.28 13.3153 13.2125 13.4079 13.0475L14.9499 10.4525C15.0425 10.2875 15.0039 10.085 14.8574 9.9725L13.2305 8.735ZM7.5017 10.625C6.0136 10.625 4.80306 9.4475 4.80306 8C4.80306 6.5525 6.0136 5.375 7.5017 5.375C8.98981 5.375 10.2003 6.5525 10.2003 8C10.2003 9.4475 8.98981 10.625 7.5017 10.625Z"
          {...props}
        />
      )
    case ICONS.transactions:
      return (
        <>
          <StyledPath d="M5.67188 4.40015C5.67188 3.84786 6.11959 3.40015 6.67188 3.40015H13.6054V5.66687H6.67188C6.11959 5.66687 5.67188 5.21915 5.67188 4.66687V4.40015Z" {...props} />
          <StyledPath d="M17.0022 4.53344L12.4688 0V9.06688L17.0022 4.53344Z" {...props} />
          <StyledPath d="M11.3398 11.4668C11.3398 12.0191 10.8921 12.4668 10.3398 12.4668H3.40632V10.2001H10.3398C10.8921 10.2001 11.3398 10.6478 11.3398 11.2001V11.4668Z" {...props} />
          <StyledPath d="M0.00171423 11.3337L4.53516 15.8672V6.8003L0.00171423 11.3337Z" {...props} />
        </>
      )
    case ICONS.check:
      return (
        <StyledPath
          d="M9.78557 3.20449C9.4994 2.93184 9.03575 2.93184 8.74959 3.20449L4.39253 7.35594L3.23399 6.4077C2.95174 6.13876 2.49418 6.13876 2.21168 6.4077C1.92944 6.67663 1.92944 7.11283 2.21168 7.38176L3.94255 8.7983C4.22479 9.06723 4.68236 9.06723 4.96485 8.7983C4.99318 8.77132 5.01686 8.7413 5.03957 8.7113C5.04371 8.70757 5.04835 8.70501 5.05251 8.70128L9.78558 4.19133C10.0715 3.91892 10.0715 3.4769 9.78557 3.20449Z"
          {...props}
        />
      )
    case ICONS.chevronLeft:
      return (
        <StyledPath
          d="M6.58748 3.82498L2.91248 7.49998L6.58748 11.175C8.60748 13.195 10.2725 14.85 10.2875 14.85C10.3 14.85 10.71 14.4525 11.1925 13.97L12.075 13.0875L9.28248 10.295L6.48748 7.49998L9.28248 4.70498L12.075 1.91248L11.1925 1.02998C10.71 0.547475 10.3 0.149975 10.2875 0.149975C10.2725 0.149975 8.60748 1.80248 6.58748 3.82498Z"
          {...props}
        />
      )
    case ICONS.doubleChevronLeft:
      return (
        <g transform="translate(0,-282.3)">
          <g transform="matrix(-3.7798969,0,0,3.7798969,16.77602,-825.62936)">
            <StyledPath
              d="m 2.2352136,293.21964 c -0.1351867,0.14485 -0.1351867,0.3797 0,0.52456 l 1.2234859,1.31094 -1.2241514,1.31166 c -0.1351868,0.14485 -0.1351868,0.3797 0,0.52456 0.1351867,0.14485 0.3543671,0.14485 0.4895539,0 l 1.2241382,-1.31166 3.966e-4,4.4e-4 0.4895848,-0.52455 -1.7134585,-1.83595 c -0.1351867,-0.14485 -0.3543672,-0.14485 -0.4895495,0 z"
              {...props}
            />
            <StyledPath
              d="m 0.10205122,293.21964 c -0.13518674,0.14485 -0.13518674,0.3797 0,0.52456 l 1.22346828,1.31094 -1.2241294,1.31166 c -0.13518675,0.14485 -0.13518675,0.3797 0,0.52456 0.13518674,0.14485 0.35436715,0.14485 0.48954949,0 l 1.22412941,-1.31166 4.187e-4,4.4e-4 0.4895539,-0.52455 -1.71343648,-1.83595 c -0.13518674,-0.14485 -0.35436716,-0.14485 -0.4895539,0 z"
              {...props}
            />
          </g>
        </g>
      )
    case ICONS.chevronRight:
      return (
        <StyledPath
          d="M4.70702 0.150024L2.94452 1.91402L8.52902 7.50002L2.94452 13.086L4.70702 14.85L12.0555 7.50002L4.70702 0.150024Z"
          {...props}
        />
      )
    case ICONS.doubleChevronRight:
      return (
        <g transform="translate(0,-282.3)">
          <g transform="matrix(3.7798969,0,0,3.7798969,-1.0424613e-7,-825.62936)">
            <StyledPath
              d="m 2.2352136,293.21964 c -0.1351867,0.14485 -0.1351867,0.3797 0,0.52456 l 1.2234859,1.31094 -1.2241514,1.31166 c -0.1351868,0.14485 -0.1351868,0.3797 0,0.52456 0.1351867,0.14485 0.3543671,0.14485 0.4895539,0 l 1.2241382,-1.31166 3.966e-4,4.4e-4 0.4895848,-0.52455 -1.7134585,-1.83595 c -0.1351867,-0.14485 -0.3543672,-0.14485 -0.4895495,0 z"
              {...props}
            />
            <StyledPath
              d="m 0.10205122,293.21964 c -0.13518674,0.14485 -0.13518674,0.3797 0,0.52456 l 1.22346828,1.31094 -1.2241294,1.31166 c -0.13518675,0.14485 -0.13518675,0.3797 0,0.52456 0.13518674,0.14485 0.35436715,0.14485 0.48954949,0 l 1.22412941,-1.31166 4.187e-4,4.4e-4 0.4895539,-0.52455 -1.71343648,-1.83595 c -0.13518674,-0.14485 -0.35436716,-0.14485 -0.4895539,0 z"
              {...props}
            />
          </g>
        </g>
      )
    case ICONS.sortDesc:
      return (
        <>
          <g opacity="0.8">
            <path d="M15.7 1L15.7 18L14.5 18L14.5 1L15.7 1Z" fill="#2A3245" />
            <path d="M11 13.2L15 18.2L19 13.2" stroke="#2A3245" strokeWidth="1.2" />
          </g>
          <path d="M7.028 17.218H3.178L2.419 19H0.945L4.41 11.3H5.818L9.294 19H7.798L7.028 17.218ZM6.555 16.096L5.103 12.73L3.662 16.096H6.555Z" fill="#19AEE6" />
          <path opacity="0.8" d="M9.018 7.79V9H2.451V8.043L6.983 2.51H2.528V1.3H8.875V2.257L4.343 7.79H9.018Z" fill="#2A3245" />
        </>
      )
    case ICONS.sortAsc:
      return (
        <>
          <g opacity="0.8">
            <path d="M15.7 1L15.7 18L14.5 18L14.5 1L15.7 1Z" fill="#2A3245" />
            <path d="M11 13.2L15 18.2L19 13.2" stroke="#2A3245" strokeWidth="1.2" />
          </g>
          <path d="M7.028 7.218H3.178L2.419 9H0.945L4.41 1.3H5.818L9.294 9H7.798L7.028 7.218ZM6.555 6.096L5.103 2.73L3.662 6.096H6.555Z" fill="#19AEE6" />
          <path opacity="0.8" d="M8.018 17.79V19H1.451V18.043L5.983 12.51H1.528V11.3H7.875V12.257L3.343 17.79H8.018Z" fill="#2A3245" />
        </>
      )
    case ICONS.plus:
      return (
        <StyledPath
          d="M5.91531 6.5V12H6.89515V6.5L13 6.5V5.5L6.89515 5.5V0H5.91531V5.5L1.1271e-07 5.5L0 6.5L5.91531 6.5Z"
          {...props}
        />
      )
    case ICONS.sortAscFlat:
      return (
        <StyledPath
          d="M6.96567 5.26571C6.65325 5.57813 6.14672 5.57813 5.8343 5.26571L4.48201 3.91343L4.48201 9.30002C4.48201 9.74185 4.12384 10.1 3.68201 10.1C3.24018 10.1 2.88201 9.74185 2.88201 9.30002L2.88201 3.94936L1.56567 5.26571C1.25325 5.57813 0.746716 5.57813 0.434296 5.26571C0.121877 4.95329 0.121877 4.44676 0.434296 4.13434L3.1343 1.43434L3.69998 0.868652L4.26567 1.43434L6.96567 4.13434C7.27809 4.44676 7.27809 4.95329 6.96567 5.26571ZM8 2.19999C8 1.75817 8.35817 1.39999 8.8 1.39999H12.9857C13.4275 1.39999 13.7857 1.75817 13.7857 2.19999C13.7857 2.64182 13.4275 2.99999 12.9857 2.99999L8.8 2.99999C8.35817 2.99999 8 2.64182 8 2.19999ZM8.925 5.02832C8.48317 5.02832 8.125 5.38649 8.125 5.82832C8.125 6.27015 8.48317 6.62832 8.925 6.62832L15.025 6.62832C15.4668 6.62832 15.825 6.27015 15.825 5.82832C15.825 5.38649 15.4668 5.02832 15.025 5.02832L8.925 5.02832ZM8.8 8.39999C8.35817 8.39999 8 8.75817 8 9.19999C8 9.64182 8.35817 9.99999 8.8 9.99999L16.61 9.99999C17.0518 9.99999 17.41 9.64182 17.41 9.19999C17.41 8.75817 17.0518 8.39999 16.61 8.39999L8.8 8.39999Z"
          {...props}
        />
      )
    case ICONS.sortDescFlat:
      return (
        <StyledPath
          d="M8.925 0.557526C8.48317 0.557526 8.125 0.915699 8.125 1.35753C8.125 1.79935 8.48317 2.15753 8.925 2.15753L16.735 2.15753C17.1768 2.15753 17.535 1.79935 17.535 1.35753C17.535 0.915698 17.1768 0.557526 16.735 0.557526H8.925ZM8.925 4.02832C8.48317 4.02832 8.125 4.38649 8.125 4.82832C8.125 5.27015 8.48317 5.62832 8.925 5.62832L15.025 5.62832C15.4668 5.62832 15.825 5.27015 15.825 4.82832C15.825 4.38649 15.4668 4.02832 15.025 4.02832L8.925 4.02832ZM8.125 8.3C8.125 7.85817 8.48317 7.5 8.925 7.5L13.1107 7.5C13.5525 7.5 13.9107 7.85817 13.9107 8.3C13.9107 8.74183 13.5525 9.1 13.1107 9.1H8.925C8.48317 9.1 8.125 8.74183 8.125 8.3ZM3.71798 1C4.15981 1 4.51798 1.35817 4.51798 1.8L4.51798 7.15065L5.83433 5.83431C6.14675 5.52189 6.65328 5.52189 6.9657 5.83431C7.27812 6.14673 7.27812 6.65326 6.9657 6.96568L3.70001 10.2314L0.434327 6.96568C0.121907 6.65326 0.121907 6.14673 0.434327 5.83431C0.746746 5.52189 1.25328 5.52189 1.5657 5.83431L2.91798 7.18659L2.91798 1.8C2.91798 1.35817 3.27615 1 3.71798 1Z"
          {...props}
        />
      )
    case ICONS.reorder:
      return (
        <g opacity="0.5">
          <StyledPath
            d="M0 1.75C0 1.05964 0.559644 0.5 1.25 0.5H16.75C17.4404 0.5 18 1.05964 18 1.75C18 2.44036 17.4404 3 16.75 3H1.25C0.559644 3 0 2.44036 0 1.75Z"
            {...props}
          />
          <StyledPath
            d="M0 6.75C0 6.05964 0.559644 5.5 1.25 5.5H16.75C17.4404 5.5 18 6.05964 18 6.75C18 7.44036 17.4404 8 16.75 8H1.25C0.559644 8 0 7.44036 0 6.75Z"
            {...props}
          />
          <StyledPath
            d="M1.25 10.5C0.559644 10.5 0 11.0596 0 11.75C0 12.4404 0.559644 13 1.25 13H16.75C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5H1.25Z"
            {...props}
          />
        </g>
      )
    case ICONS.plusCircle:
      return (
        <>
          <StyledCircle cx="7" cy="7" r="7" {...props} />
          <StyledRect x="6" y="3" width="2" height="8" variant="white" />
          <StyledRect x="11" y="6" width="2" height="8" transform="rotate(90 11 6)" variant="white" />
        </>
      )
    case ICONS.longArrowLeft:
      return (
        <StyledPath
          d="M5.1587 0.626617L0.24823 5L5.1587 9.37338L5.82378 8.62662L2.3132 5.5H13.125V4.5H2.3132L5.82378 1.37338L5.1587 0.626617Z"
          {...props}
        />
      )
    case ICONS.filterFlat:
      return (
        <StyledPath
          d="M0.924988 0.800001C0.924988 0.358173 1.28316 0 1.72499 0H9.53499C9.97682 0 10.335 0.358172 10.335 0.8C10.335 1.24183 9.97682 1.6 9.53499 1.6L1.72499 1.6C1.28316 1.6 0.924988 1.24183 0.924988 0.800001ZM0.924988 4.2708C0.924988 3.82897 1.28316 3.4708 1.72499 3.4708L7.82499 3.47079C8.26682 3.47079 8.62499 3.82897 8.62499 4.27079C8.62499 4.71262 8.26682 5.0708 7.82499 5.0708L1.72499 5.0708C1.28316 5.0708 0.924988 4.71262 0.924988 4.2708ZM1.72499 6.94247C1.28316 6.94247 0.924988 7.30065 0.924988 7.74247C0.924988 8.1843 1.28316 8.54247 1.72499 8.54247H5.9107C6.35253 8.54247 6.7107 8.1843 6.7107 7.74248C6.7107 7.30065 6.35253 6.94248 5.9107 6.94248L1.72499 6.94247Z"
          {...props}
        />
      )
    default:
      return <StyledPath />
  }
}

export const Icon = ({
  name = '',
  width = '',
  height = '',
  strokeWidth,
  viewBox = '',
  style = {},
  className,
  variant,
}) => {
  const finalViewBox = viewBox || getViewBox(name)
  // extract dimentions from viewBox
  const [, , w, h] = finalViewBox.split(' ')
  return (
    <svg
      width={width || w}
      height={height || h}
      style={style}
      className={className}
      viewBox={finalViewBox}
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      {getIconPath(name, { name, variant, strokeWidth })}
    </svg>
  )
}

Icon.ICONS = ICONS

const stringOrNumber = P.oneOfType([P.string, P.number])

Icon.propTypes = {
  name: P.oneOf(Object.values(ICONS)).isRequired,
  width: stringOrNumber,
  height: stringOrNumber,
  fill: P.string,
  style: P.string,
  viewBox: P.string,
  variant: P.oneOf(['primary', 'success', 'danger', 'dark', 'gray', 'white']),
  disabled: P.bool,
}
