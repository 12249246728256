import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Button, DangerButton, Text } from '@ui'
import { events, humanizeOperator } from '../../constants'
import { pathToField } from '../../mappers'

export const RuleListPreview = ({ onEditClick, onDeleteClick, rules }) => (
  <Container>
    <table>
      <tbody>
        {rules.map((rule, index) => (
          <tr key={index}>
            <td>
              <Conditions conditions={rule.conditions} />
            </td>
            <td>
              <Actions event={rule.event} />
            </td>
            <td>
              <Button
                variant="link"
                onClick={() => {
                  onEditClick(rule.meta)
                }}
              >
                Edit
              </Button>
              <DangerButton
                variant="danger"
                size="sm"
                onClick={() => {
                  onDeleteClick(rule.meta)
                }}
              >
                Delete
              </DangerButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Container>
)

const Conditions = ({ conditions }) => (
  <ConditionsContainer>
    {conditions.all.map(({ path, operator, value }, index) => [
      <div key={index}>
        <Separator>
          <Symbol>{index === 0 ? 'If' : 'and'}</Symbol>
        </Separator>
        {pathToField(path)}
        <Operator>{humanizeOperator(operator).toLowerCase()}</Operator>
        {value}
      </div>,
    ])}
  </ConditionsContainer>
)

const ConditionsContainer = styled.div`
  display: inline-block;
  margin-bottom: 8px;
`

const Separator = styled.span`
  display: inline-block;
  text-align: right;
  width: 40px;
`

const Actions = ({ event }) => {
  const { type, params } = event

  switch (type) {
    case events.EXPORT:
      return (
        <span>
          <Symbol>Then</Symbol>
          {params.accountLabel && <span>{params.accountLabel}{', '}</span>}
          {params.codeLabel && <span>{params.codeLabel}</span>}
          {params.fromAccountLabel && <span>{'Export a transfer from '}{params.fromAccountLabel}</span>}
          {params.toAccountLabel && <span>{params.fromAccountLabel ? ', ' : ''}{'Transfer goes to '}{params.toAccountLabel}</span>}
        </span>
      )
    case events.CODE_WITH_TAX:
      return (
        <span>
          <Symbol>Then</Symbol>
          <span>{params.taxTypeLabel}</span>
        </span>
      )
    case events.IGNORE:
      return (
        <span>
          <Symbol>Then</Symbol>
          <span>Ignore</span>
        </span>
      )
    default:
      return null
  }
}

const Container = styled.div`
  background-color: ${theme('color.activeLight')};
  border-radius: 3px;
  margin-left: 58px;
  padding: 8px 0 8px 8px;

  table {
    width: 100%;
    
    button {
      height: 32px;
    }
  }
`

const Symbol = styled(Text)`
  color: ${theme('color.gray')};
  font-weight: bold;
  text-align: center;
  margin: 0 4px;
  min-width: 10px;
  text-transform: uppercase;
`

const Operator = styled(Text)`
  color: ${theme('color.primary')};
  margin: 0 4px;
  font-weight: bold;
`
