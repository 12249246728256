import { createSelector } from 'reselect'
import { capitalize } from '@lib/string'
import { arrayTotal } from '@lib/array'
import { formatNum, formatPercent, percent } from '@lib/convert'
import { createFetchingSelectors } from '@lib/fetching'
import { brandColor, exportStatusColor, typeColor } from '@lib/mappers'
import { humanizeExportStatus, humanizeSource, humanizeType } from '@lib/constants'
import { $userSubscription } from '@features/common'
import { toExportedStatusesStatistics } from '../mappers'

const $root = (root) => root.statistics

export const $filters = createSelector(
  $root,
  ({ filters }) => filters,
)

const $fetching = createSelector(
  $root,
  ({ fetching }) => fetching,
)

export const {
  $isLoading,
} = createFetchingSelectors($fetching)

const $model = createSelector(
  $root,
  ({ model }) => model,
)

export const $importedAmount = createSelector(
  $model,
  ({ total, sources }) => arrayTotal(sources, (s) => s.amount),
)

export const $sourcesChartData = createSelector(
  $model,
  $importedAmount,
  $userSubscription,
  ({ total, sources }, importedAmount, subscription) => {
    const isUnlimited = subscription.max_transactions === -1
    const maxAmount = isUnlimited ? total : subscription.max_transactions

    const availableAmount = maxAmount - importedAmount
    const result = []

    result.push(
      ...sources.map(({ name, amount }) => ({
        label: `From ${humanizeSource(name)}`,
        value: formatPercent(amount, maxAmount),
        color: brandColor(name),
      })),
    )

    if (!isUnlimited) {
      result.push({
        label: 'Available',
        value: formatNum(Math.max(percent(availableAmount, maxAmount), 0)),
        color: brandColor(),
      })
    }

    return result
  },
)

export const $typesChartData = createSelector(
  $model,
  $importedAmount,
  ({ total, types }, importedAmount) => types.map(({ name, amount }) => ({
    label: `${amount} of ${humanizeType(name)}`,
    value: formatPercent(amount, importedAmount),
    amount,
    color: typeColor(name),
    name,
  })),
)

const $sourcesReport = createSelector(
  $model,
  ({ total, sources }) => sources.map(({ name, amount }) => ({
    name,
    actionName: 'Imported from',
    label: humanizeSource(name),
    amount,
    amountPercent: formatPercent(amount, total),
    color: brandColor(name),
  })),
)

const $erpsReport = createSelector(
  $model,
  ({ total, erps }) => erps.map(({ name, amount }) => ({
    name,
    actionName: 'Exported to',
    label: capitalize(name),
    amount,
    amountPercent: formatPercent(amount, total),
    color: brandColor(name),
  })),
)

export const $integrationsReport = createSelector(
  $sourcesReport,
  $erpsReport,
  (sources, erps) => [...sources, ...erps],
)

export const $statusesReport = createSelector(
  $model,
  ({ total, statuses }) => {
    const exportStatuses = toExportedStatusesStatistics(statuses)

    return exportStatuses.map(({ name, amount }) => ({
      amount,
      amountPercent: formatPercent(amount, total),
      label: humanizeExportStatus(name),
      status: name,
      color: exportStatusColor(name),
    }))
  },
)
