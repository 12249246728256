import React from 'react'
import { Button, FormCheck, ListDivider } from '../../index'
import { Col, Row } from '../../../lib/styled-layout'
import {
  FilterListTemplate,
  FooterRightListItem,
  FormListItem,
  SortAscListItem,
  SortDescListItem,
} from './shared-components'
import { useMuliChoiceFilter } from './hooks'

export const CurrencyTableFilter = ({ options, column, column: { setFilter, filterValue }, onClose }) => {
  const {
    apply,
    reset,
    changeLocalFilter,
    isEqual,
    localFilterValue,
  } = useMuliChoiceFilter({ filterValue, setFilter, onClose })

  return (
    <FilterListTemplate style={{ width: 216 }}>
      <Row>
        {options.map(({ label, value }) => (
          <Col md={6} key={label}>
            <FormListItem>
              <FormCheck
                label={label}
                name={value}
                checked={localFilterValue.includes(value)}
                onChange={changeLocalFilter}
              />
            </FormListItem>
          </Col>
        ))}
      </Row>
      <ListDivider mt="12px" />
      <SortAscListItem column={column} title="Sort A to Z" onClose={onClose} />
      <SortDescListItem column={column} title="Sort Z to A" onClose={onClose} />
      <ListDivider />
      <FooterRightListItem>
        <Button variant="link" disabled={!filterValue} onClick={reset}>RESET</Button>
        <Button variant="link" disabled={isEqual} onClick={apply}>APPLY</Button>
      </FooterRightListItem>
    </FilterListTemplate>
  )
}
