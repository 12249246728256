import { useCallback, useState } from 'react'
import { useDidUnmount } from './use-did-unmount'

export const useBoolean = (init = false) => {
  const didUnmount = useDidUnmount()
  const [val, set] = useState(init)

  const safeSet = useCallback((val) => {
    // prevent updating state on unmount
    if (didUnmount.current) return
    set(val)
  }, [didUnmount])

  const on = () => safeSet(true)
  const off = () => safeSet(false)
  const toggle = () => safeSet((val) => !val)

  return { val, on, off, toggle, set: safeSet }
}

export const useOpened = (init) => {
  const { val, on, off, toggle, set } = useBoolean(init)
  return {
    opened: val,
    open: on,
    close: off,
    toggle,
    set,
  }
}
