import { handleFetching } from '@lib/fetching'
import { transactionActions, transactionsListActions } from './symbiotes'
import { api } from './api'
import { pageSizes } from './constants'

export const transactionsFetch = (params = {}) =>
  handleFetching(transactionsListActions.fetch, {
    async run(dispatch) {
      const { data, count, totalCount } = await api.getList(params)
      const pageSize = params.pageSize || pageSizes.transactions
      const pageCount = Math.ceil(count / pageSize)

      dispatch(transactionsListActions.set({ data, count, totalCount, pageCount }))
    },
  })

export const massActionCall = ({ name, filters, data }) => {
  const handler = api[name]
  if (!handler) {
    throw new Error(`Could not find an api request "${name}"`)
  }

  return handler(filters, data)
}

export const singleTransactionFetch = (transactionId) =>
  handleFetching(transactionActions.fetch, {
    async run(dispatch) {
      const transaction = await api.getById(transactionId)

      dispatch(transactionActions.set({ transactionId, transaction }))
    },
  })
