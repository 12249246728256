import { Flex, H2 } from '@ui'
import { Row } from '@lib/styled-layout'
import React from 'react'

export const IntegrationsHeading = ({ title, children }) => (
  <Flex justify="space-between" mb="40px">
    <H2>{title}</H2>
    <Row>
      {children}
    </Row>
  </Flex>
)
