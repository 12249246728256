import React from 'react'
import styled from 'styled-components'
import { useXeroConnectRequest } from '@features/common'
import { List, ListItem, Flex, H3, LoadingButton } from '@ui'

const Image = styled.img`
  margin-right: 16px;
  width: 32px;
  height: 32px;
`

export const AccountingSoftware = () => {
  const connectRequest = useXeroConnectRequest()

  return (
    <List gap="8px" bordered={false}>
      <ListItem bordered>
        <Flex justify="space-between" align="center">
          <Image src="https://www.xero.com/etc/designs/xero-cms/clientlib/assets/img/logo/logo-xero-blue.svg" />
          <H3 style={{ flex: 1 }}>Xero</H3>
          <LoadingButton
            variant="outline"
            loading={connectRequest.isLoading}
            disabled={connectRequest.isLoading}
            onClick={connectRequest}
          >
            CONNECT
          </LoadingButton>
        </Flex>
      </ListItem>
    </List>
  )
}