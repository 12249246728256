/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/alt-text */
import React from 'react'

export const EmailSent = ({ title = 'Verify your email', children }) => (
  <div className="a0-lock-container">
    <div id="a0-lock" className="a0-lock a0-theme-default" dir="auto">
      <div className="a0-signin">
        <div className="a0-popup  ">
          <div className="a0-overlay a0-active">
            <div className="a0-centrix">
              <div id="a0-onestep" className="a0-panel a0-onestep a0-free-subscription a0-active">
                <div className="a0-header a0-top-header ">

                  <div className="a0-bg-gradient a0-blur-support">
                    <div className="a0-avatar a0-hide">
                      <img src="" />
                    </div>

                    <div className="a0-image">
                      <img className="a0-avatar-guest" src="/images/bf_icon_trans.png" />
                    </div>

                    <div className="a0-bg-solid-color" style={{backgroundColor: '#ea5323'}}></div>
                  </div>

                  <div className="a0-icon-container">
                    <div className="a0-avatar a0-hide">
                      <img src="" />
                    </div>

                    <div className="a0-image">
                      <img className="a0-avatar-guest" src="/images/bf_icon_trans.png" />
                    </div>

                    <div className="a0-blank-space"></div>

                    <h1 title="Log in">{title}</h1>
                  </div>

                  {/*if standalone, you shouldn't be able to close the widget*/}
                  <a className="a0-close a0-hide" href="#">
                    <svg enableBackground="new 0 0 128 128" version="1.1" viewBox="0 0 128 128" xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g>
                        <polygon fill="#373737"
                          points="123.5429688,11.59375 116.4765625,4.5185547 64.0019531,56.9306641 11.5595703,4.4882813     4.4882813,11.5595703 56.9272461,63.9970703 4.4570313,116.4052734 11.5244141,123.4814453 63.9985352,71.0683594     116.4423828,123.5117188 123.5126953,116.4414063 71.0732422,64.0019531   "></polygon>
                      </g>
                    </svg>
                  </a>
                </div>

                <div className="a0-mode-container">
                  <div className="a0-notloggedin a0-mode">
                    <form noValidate="">
                      <div className="a0-sso-notice-container a0-hide">
                        <i className="a0-icon-budicon"></i>
                        <span className="a0-sso-notice">Single Sign-on enabled</span>
                      </div>
                      <div className="a0-body-content">
                        {children}
                      </div>
                    </form>
                  </div>
                </div>

                <div className="a0-footer">
                  <a href="http://auth0.com/?utm_source=lock&amp;utm_campaign=badge&amp;utm_medium=widget" target="_new"
                    className="a0-logo">
                    <i className="a0-icon-badge"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const ResendEmailForm = ({ email, onLogout, onResendClick }) => (
  <>
    <p>We sent you an email to <strong>{email}</strong>. Please click on the link provided to verify your account.</p>
    <p>
      <a onClick={onResendClick}>Send email again</a>
    </p>
    <p>
      <a onClick={onLogout}>Logout</a>
    </p>
  </>
)
