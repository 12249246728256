import { useFormikContext } from 'formik'

export const FormStatus = ({ render }) => {
  const { status } = useFormikContext()

  if (status === null) {
    return status
  }
  return render(status)
}
