import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Container } from '../../lib/styled-layout'

export const FloatingBarContent = ({
  children,
}) => ReactDOM.createPortal(
  <Wrapper>
    <Container>
      {children}
    </Container>
  </Wrapper>,
  document.getElementById('floatingBar'),
)

const Wrapper = styled.div`
  position: fixed;
  left: 272px;
  bottom: 0;
  right: 0;
  padding: 9px 0;
  z-index: ${theme('order.floatingBar')};
  box-shadow: 0px -5px 15px rgba(42, 50, 69, 0.05);
  background-color: white;

  &:empty {
    display: none;
  }
`
