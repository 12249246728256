import styled from 'styled-components'
import { Divider, H2 } from '@ui'

export const FormDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 43px;
`

export const Header = styled(H2)`
  margin-top: 16px;
`
