import React from 'react'
import { Col, Row } from '@lib/styled-layout'
import { Button, Text, Modal, Spinner, QuestionIcon, TooltipContainer, H4, Link } from '@ui'
import { useModel } from './transactionDetails.model'

export const TransactionDetailsModal = ({ onClose }) => {
  const { transactionDetails, transactionId, isLoading } = useModel()

  if (isLoading) {
    return <LoadingView onClose={onClose} />
  }

  return (
    <Modal show={true} onClose={onClose}>
      { transactionDetails.length > 0 ? (
        <TransactionDetailsView transactionId={transactionId} transactionRows={transactionDetails} />
      ) : <NotFoundView />}
      <Modal.Footer>
        <Button size="md" onClick={onClose}>CLOSE</Button>
      </Modal.Footer>
    </Modal>
  )
}

const TransactionDetailsView = ({ transactionId, transactionRows }) => (
  <>
    <Modal.Title>Transaction #{transactionId}</Modal.Title>
    <Modal.Body>
      {transactionRows.map((row, key) => (
        <Row key={key}>
          <Col lg={5}>
            <H4>{row.label}:</H4>
          </Col>
          <Col lg={7}>
            <TransactionValueView row={row} />
          </Col>
        </Row>
      ))}
    </Modal.Body>
  </>
)

const TransactionValueView = ({ row }) => {
  if (row.tooltip) {
    return (
      <TooltipContainer tooltip={row.tooltip}>
        <div>
          <Text mr="6px">{row.value}</Text>
          <QuestionIcon />
        </div>
      </TooltipContainer>
    )
  }

  if (row.link) {
    return <Link target="_blank" rel="noopener noreferrer" href={row.link.url}>{row.value}</Link>
  }

  return <Text>{row.value}</Text>
}

const NotFoundView = () => (
  <Modal.Title>Transaction not found</Modal.Title>
)

const LoadingView = ({ onClose }) => (
  <Modal show={true} onClose={onClose}>
    <Spinner marginTop={0} />
    <Modal.Footer>
      <Button size="md" onClick={onClose}>CLOSE</Button>
    </Modal.Footer>
  </Modal>
)
