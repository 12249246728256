import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Logo } from '@features/integrations'
import { DoughnutChartMini, Text } from '@ui'

export const IntegrationReport = ({ name, label, actionName, amount, amountPercent, color }) => (
  <Container>
    <ProcessorTitle>
      <Logo.Icon name={name} width={28} height={28} />
      <ProcessorText>
        {actionName} <strong>{label}</strong>
      </ProcessorText>
    </ProcessorTitle>
    <ReportData>
      <ReportDataInfo>
        <TextLight>TOTAL</TextLight>
        <TextAmount>{amount}</TextAmount>
        <TextLight>transactions</TextLight>
      </ReportDataInfo>
      <MiniChartWrap>
        <DoughnutChartMini
          value={amountPercent}
          color={color}
          title={`${amountPercent}%`}
        />
      </MiniChartWrap>
    </ReportData>
  </Container>
)

const Container = styled.div`
  padding: 32px;
  border: 1px solid ${theme('color.border')};
  border-radius: 3px;
  height: 100%;
`

const ProcessorTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

const ProcessorText = styled(Text)`
  font-size: 15px;
  margin-left: 12px;

  strong {
    font-size: 15px;
  }
`

const ReportData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ReportDataInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`

const MiniChartWrap = styled.div`
  max-width: 150px;
`

const TextLight = styled(Text).attrs({
  variant: 'gray',
})`
  font-size: 12px;
`

const TextAmount = styled(Text)`
  margin-top: 12px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
`
