import { request } from '@features/common'

export const api = {
  connectGocardless: (code) =>
    request('POST', '/gocardless/callback', { data: { code } }),

  connectStripe: (code) =>
    request('POST', '/stripe/callback', { data: { code } }),

  connectShopify: (code, shop) =>
    request('POST', '/shopify/callback', { data: { code, shop } }),

  connectWooCommerse: (code) =>
    request('POST', '/woocommerce/callback', { data: { code } }),

  connectXero: (data) =>
    request('POST', '/xero/callback', {
      data,
    }),

  connectQuickbooks: (url) =>
    request('POST', '/quickbooks/callback', {
      data: { url },
    }),
}
