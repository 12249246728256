import { request } from '@features/common'

export const api = {
  updateSettings: (values) => request('POST', '/settings', {
    data: values,
  }),

  cancelAccount: (values) => request('POST', '/cancel', {
    data: values,
  }),
}

export const paymentApi = {
  getDetails: () => request('GET', '/payment-details'),

  updateBillingAddress: (data) =>
    request('POST', '/payment-details/address', { data }),

  addCard: (cardToken) => request('POST', '/payment-details', {
    data: {
      do: 'add-card',
      token: cardToken,
    },
  }),

  updateCard: (token, data) => request('POST', '/payment-details', {
    data: {
      do: 'update-card',
      token,
      data,
    },
  }),

  makeCardDefault: (cardId) => request('POST', '/payment-details', {
    data: {
      do: 'make-default',
      id: cardId,
    },
  }),

  removeCard: (cardId) => request('POST', '/payment-details', {
    data: {
      do: 'remove-card',
      id: cardId,
    },
  }),
}

export const restCountriesApi = {
  getCountries: () => request('GET', '/all?fields=name;alpha2Code', {
    baseURL: restCountriesUrl,
    headers: {},
  }),
}

const restCountriesUrl = 'https://restcountries.eu/rest/v2'
