import React from 'react'
import { useSelector } from 'react-redux'
import { useTablePagination } from '@lib/table-hooks'
import {
  Empty,
  FilterableHeader,
  Interactivity,
  PaginationBlock,
  SelectableRow,
  Spinner,
  Table,
  TableBody,
  TableHead,
} from '@ui'
import { $count, $isInitialLoading, $isLoading, $totalCount } from '../selectors'
import wallet from '../../../assets/images/wallet-no.svg'

export const TransactionsTable = ({ table }) => {
  const isLoading = useSelector($isLoading)
  const isInitialLoading = useSelector($isInitialLoading)
  const totalCount = useSelector($totalCount)
  const pagination = useTablePagination(table, useSelector($count))

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = table

  if (isInitialLoading) {
    return <Spinner />
  }

  if (totalCount === 0) {
    return <NoTransactionsView />
  }

  return (
    <Interactivity off={isLoading}>
      <Table {...getTableProps()}>
        <TableHead
          headerGroups={headerGroups}
          renderColumnHeader={(column) => (
            <FilterableHeader
              key={column.id}
              column={column}
            />
          )}
        />
        <TableBody
          rows={rows}
          prepareRow={prepareRow}
          renderRow={(row) => (
            <SelectableRow
              key={row.index}
              row={row}
              isSelected={row.isSelected}
            />
          )}
        />
      </Table>
      <PaginationBlock
        pagination={pagination}
      />
    </Interactivity>
  )
}

const NoTransactionsView = () => (
  <Empty
    img={wallet}
    description={<>
      At this moment you have<br />
      no transactions
    </>}
  />
)
