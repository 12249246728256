import React from 'react'
import { QuestionIcon, Text, TooltipContainer } from '@ui'
import { CustomSwitchField, InputTemplate, TooltipText } from './shared'

export const DisplayOption = ({ name, label, disabled, tooltip }) => (
  <InputTemplate key={name}>
    {tooltip ? (
      <CustomSwitchField
        name={name}
        label={<TooltipContainer
          tooltip={tooltip}
        >
          <TooltipText>
            <Text mr="6px">{label}</Text>
            <QuestionIcon />
          </TooltipText>
        </TooltipContainer>}
        disabled={disabled}
      />
    ) : (
      <CustomSwitchField
        name={name}
        label={<Text>{label}</Text>}
        disabled={disabled}
      />
    )}
  </InputTemplate>
)
