import { handleFetching } from '@lib/fetching'
import { notify } from '@lib/notification'
import { integrationsApi, qboApi } from '../api'
import { entitiesActions, taxRatesActions, settingsActions } from '../symbiotes/integration'
import { sourceSettingsUpdate } from './sources'

export const integrationEntitiesFetch = () => handleFetching(entitiesActions.fetch, {
  noThrow: true,
  prepareError: (error) => error.response.data,
  async run(dispatch) {
    const { taxRates, ...entities } = await integrationsApi.getEntities()
    dispatch(entitiesActions.set(entities))
    dispatch(taxRatesActions.set(taxRates))
  },
})

export const integrationEntityFetch = (type) => handleFetching(entitiesActions.fetch, {
  noThrow: true,
  prepareError: (error) => error.response.data,
  async run(dispatch) {
    const entities = await integrationsApi.getEntities([type])
    dispatch(entitiesActions.set(entities))
    return entities
  },
})

export const integrationSettingsFetch = () => handleFetching(settingsActions.fetch, {
  noThrow: true,
  prepareError: (error) => error.response.data,
  async run(dispatch) {
    const { data } = await integrationsApi.getSettings()
    dispatch(settingsActions.set(data))
  },
})

export const integrationSettingsUpdate = (settingsValues) => async (dispatch) => {
  const updatedSettings = await integrationsApi.updateSettings(settingsValues)
  dispatch(settingsActions.set(updatedSettings))
}

export const integrationDataReset = () => (dispatch) => {
  dispatch(settingsActions.reset())
  dispatch(entitiesActions.reset())
  dispatch(taxRatesActions.reset())
}

export const quickbooksConfigurationUpdate = (processor, values) => async (dispatch) => {
  try {
    await dispatch(sourceSettingsUpdate(processor.id, values))

    notify({
      message: 'Configuration updated',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: err.response.data.error,
    })
  }
}

export const quickbooksEntitiesCreate = (processorId, values) => async (dispatch) => {
  const {
    entities: { taxRates, ...entities },
    data,
  } = await qboApi.createEntities(processorId, values)
  dispatch(entitiesActions.set(entities))
  dispatch(taxRatesActions.set(taxRates))

  const successes = data.filter((result) => result.ok).reduce(reduceResult, {})
  const failures = data.filter((result) => !result.ok).reduce(reduceResult, {})

  return { successes, failures }
}

const reduceResult = (results, result) => ({
  ...results,
  [result.name]: result.message,
})
