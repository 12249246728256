import { useDispatch, useSelector } from 'react-redux'
import { valueOrTrue } from '@lib/convert'
import { filterEmpty } from '@lib/object'
import { $activeProcessorSettings } from '../../selectors/configuration'
import { quickbooksConfigurationUpdate } from '../../effects/integrations'

export const useSettingsModel = () => {
  const dispatch = useDispatch()
  const { processor, settings } = useSelector($activeProcessorSettings)

  const initialValues = getInitialValues({ settings })

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...values,
      tax_mapping: filterEmpty(values.tax_mapping),
    }

    await dispatch(quickbooksConfigurationUpdate(processor, data))
    setSubmitting(false)
  }

  return {
    handleSubmit,
    initialValues,
  }
}

const getInitialValues = ({ settings }) => ({
  track_charges: valueOrTrue(settings.track_charges),
  track_refunds: valueOrTrue(settings.track_refunds),
  track_fees: valueOrTrue(settings.track_fees),
  track_app_fees: valueOrTrue(settings.track_app_fees),
  track_adjustments: valueOrTrue(settings.track_adjustments),
  track_disputes: valueOrTrue(settings.track_disputes),
  track_payouts: valueOrTrue(settings.track_payouts),

  bankfeeds_account: settings.bankfeeds_account,
  charges_account: settings.charges_account,
  fees_account: settings.fees_account,
  app_fees_account: settings.app_fees_account,
  refunds_account: settings.refunds_account,
  payouts_account: settings.payouts_account,
  disputes_account: settings.disputes_account,
  adjustments_account: settings.adjustments_account,

  name_display: valueOrTrue(settings.name_display),
  email_display: valueOrTrue(settings.email_display),
  import_country: valueOrTrue(settings.import_country),
  tag_display: valueOrTrue(settings.tag_display),

  product_type: settings.product_type,
  product_income_account: settings.product_income_account,
  vendor: settings.vendor,
  payment_method: settings.payment_method,
  default_customer: settings.default_customer,
  tax_mapping: settings.tax_mapping || {},
  taxes_enabled: settings.taxes_enabled,
})
