import React from 'react'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { down, up } from 'styled-breakpoints'
import { Col, Row } from '@lib/styled-layout'
import { Button, Flex, H4, H5, LoadingButton } from '@ui'

export const CurrentPlanView = ({ name, validDate, features, editting, onChangeClick }) => (
  <Container isActive>
    <Row>
      <Col md={3} sm={12}>
        <Text>Plan Type:</Text>
        <Name>{name}</Name>
      </Col>
      <Col md={2} sm={12}>
        <Text>Paid Until:</Text>
        <p>{dayjs(validDate).format('MM/DD/YYYY')}</p>
      </Col>
      <Col md={4} sm={12}>
        <Text>Features:</Text>
        {features.map((feature, idx) => (
          <p key={idx}>{feature}</p>
        ))}
      </Col>
      <Col md={3} sm={12}>
        <Flex justify="flex-end">
          <div>
            <Button disabled={editting} onClick={onChangeClick} size="lg">
              CHANGE PLAN
            </Button>
          </div>
        </Flex>
      </Col>
    </Row>
  </Container>
)

export const PlanView = ({ name, price, features, isSelected, isActive, isUpgrade, isLoading, onSelect, selectedPlanIndex }) => (
  <Container>
    <Row>
      <Col md={3} sm={12}>
        <Text>Plan Type:</Text>
        <Name>{name}</Name>
        {isSelected && (
          <TextCurrent>Current Plan</TextCurrent>
        )}
      </Col>
      <Col md={2} sm={12}>
        {isActive ? (
          <>
            <H5>${price} USD</H5>
            <Text>per month</Text>
          </>
        ) : (
          <Text>coming soon</Text>
        )}
      </Col>
      <Col md={4} sm={12}>
        {features.map((feature, idx) => (
          <p key={idx}>{feature}</p>
        ))}
      </Col>
      <Col md={3} sm={12}>
        <Flex justify="center">
          {isSelected ? null : (
            <div>
              {isActive ? (
                <LoadingButton variant="link" disabled={isSelected} loading={isLoading} onClick={onSelect}>
                  {isLoading ? (
                    <>
                      {selectedPlanIndex === -1 ? (
                        'SELECT'
                      ) : (isUpgrade ? 'UPGRADING' : 'DOWNGRADING')}
                    </>
                  ) : (
                    <>
                      {selectedPlanIndex === -1 ? (
                        'SELECT'
                      ) : (isUpgrade ? 'UPGRADE' : 'DOWNGRADE')}
                    </>
                  )}
                </LoadingButton>
              ) : (
                <Button variant="link" as="a" href="mailto:support@bankfeeds.io">
                  CONTACT US
                </Button>
              )}
            </div>
          )}
        </Flex>
      </Col>
    </Row>
  </Container>
)

const Container = styled.div`
  border-radius: 3px;
  padding: 25px;
  background-color: white;
  border: 1px solid ${theme('color.border')};

  ${ifProp('isActive', css`
    background-color: #F3FBFD;
    border: none;
  `)}

  ${up('md')} {
    ${Col}:not(:first-child) {
      padding-left: 0;
    }
  }

  ${down('md')} {
    ${Col} {
      margin-bottom: 0.75rem;
    }
  }
`

const Name = styled(H4)`
  text-transform: uppercase;
`

const Text = styled.div`
  line-height: 1.55;
  margin-bottom: 3px;
`

const TextCurrent = styled.div`
  line-height: 1.55;
  font-size: 13px;  
  color: ${theme('color.gray')};
`
