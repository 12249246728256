import React from 'react'
import { useSelector } from 'react-redux'
import { SelectField } from '@ui'
import { Description } from './shared'
import { $isIntegrationExpired } from '../../selectors/integration'
import { $customersOptions } from '../../selectors/quickbooks-entities'

export const DefaultCustomer = () => {
  const isExpired = useSelector($isIntegrationExpired)
  const options = useSelector($customersOptions)

  return (
    <>
      <SelectField
        name="default_customer"
        label="Default Customer"
        options={options}
        disabled={isExpired}
        isSearchable
        isCreatable
      />
      <Description>
        This Default Customer will be set for QuickBooks Payments if no customer found in the transaction.
      </Description>
    </>
  )
}
