import React from 'react'
import styled from 'styled-components'
import { H1 } from './Heading'

export const HeadingTemplate = styled.div`
  background-color: white;
  margin-bottom: 32px;
`

export const Heading = ({ children }) => (
  <HeadingTemplate>
    <H1>{children}</H1>
  </HeadingTemplate>
)
