import { useMemo, useState } from 'react'

export const useTabs = (items, config = {}) => {
  const [activeKey, setActiveKey] = useState(() => {
    const foundItem = items.find((item) => item.key === config.activeKey)
    return foundItem ? foundItem.key : items[0].key
  })

  const tabs = useMemo(
    () => items.map((item, index) => {
      const key = item.key || index
      const Title = item.Title
      const Content = item.Content
      const active = key === activeKey

      return {
        key,
        origin: item,
        getTitleProps: () => ({
          key,
          active,
          onClick: () => setActiveKey(key),
        }),
        getContentProps: () => ({
          key,
          active,
        }),
        render(type) {
          switch (type) {
            case 'Title':
              return Title
            case 'Content':
              if (typeof Content === 'function') {
                return Content({ key, origin: item })
              }
              return Content
            default:
              return null
          }
        },
      }
    }),
    [activeKey, items],
  )

  const activeTab = useMemo(
    () => tabs.find((t) => t.key === activeKey),
    [activeKey, tabs],
  )

  return { tabs, activeTab, activeKey }
}
