import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { sources, exportStatuses, types } from '@lib/constants'

export const useTheme = () => useContext(ThemeContext)

const palette = {
  gray: ['#d9dee7', '#A1AFC4', '#3A444A'],
  primary: ['#19AEE6', '#179ecf', '#148cb8'],
  secondary: ['#ECF0F4', '#dbe3eb'],
  green: ['#73DA87', '#27AE60'],
}

export const color = {
  dark: '#2A3245',
  gray: '#A1AFC4',
  grayLight: '#939dad',
  border: palette.gray[0],
  secondary: palette.secondary[0],
  lightGray: '#ECF0F4',
  lightGray2: '#F6F7F9',
  lightGray3: '#DFE6EC',
  primary: palette.primary[0],
  success: palette.green[0],
  danger: '#DB556D',
  red: '#EB5757',
  purple: {
    dark: '#7697E0',
    light: '#8484D7',
  },
  violet: '#BB6BD9',
  bluegreen: '#5ACCD4',
  inputText: palette.gray[2],
  tooltipBg: palette.gray[2],
  active: '#e7f6fc',
  activeLight: '#f3fbfe',
  statusActive: palette.green[1],
  white: '#fff',
  warning: '#fff3cd',
}

export const theme = {
  // breakpoints for styled components like Bootstrap responsive breakpoints
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1440px',
  },

  color,
  palette,

  base: {
    primary: color.primary,
    outline: color.primary,
    secondary: color.secondary,
    light: 'transparent',
    link: 'transparent',
    'link-danger': 'transparent',
  },
  hover: {
    primary: palette.primary[1],
    outline: palette.primary[2],
    secondary: palette.secondary[1],
    light: 'transparent',
    link: 'transparent',
    'link-danger': 'transparent',
  },
  status: {
    [exportStatuses.SYNCED]: '#40bf75',
    [exportStatuses.NOT_SYNCED]: '#36c0c9',
    [exportStatuses.QUEUED]: '#e2ad1d',
    [exportStatuses.SYNCING]: '#7a9eeb',
  },
  icon: {
    primary: color.primary,
    success: color.success,
    danger: color.danger,
    dark: color.dark,
    gray: color.gray,
    white: color.white,
  },

  alert: {
    primary: {
      bg: color.primary,
      color: color.white,
    },
    danger: {
      bg: color.danger,
      color: color.white,
    },
    info: {
      bg: '#d1ecf1',
      color: '#0c5460',
    },
    warning: {
      bg: '#fff3cd',
      color: '#856404',
    },
  },

  heading: {
    danger: color.danger,
    light: color.grayLight,
    dark: color.dark,
    gray: color.gray,
    purple: color.purple.dark,
    primary: color.primary,
  },

  sizing: {
    sidebarWidth: '272px',
  },

  // z-ndexes
  order: {
    sidebar: 'initial',
    tooltip: 12,
    modal: 'initial',
    floatingBar: 'initial',
    positioner: 20,
    dialog: 'initial',
    shadow: -1,
  },

  brand: {
    [sources.STRIPE]: color.purple.light,
    [sources.GOCARDLESS]: color.purple.dark,
    [sources.PINPAYMENTS]: color.danger,
    [sources.SHOPIFY]: '#95bf47',
    [sources.WOOCOMMERCE]: '#96588a',
    xero: color.primary,
    default: '#EDF2F7',
  },

  type: {
    [types.CHARGE]: color.success,
    [types.FEE]: color.purple.dark,
    [types.REFUND]: '#e2ad1d',
    [types.FEE_REFUND]: color.purple.light,
    [types.DISPUTE_ADJUSTMENT]: color.danger,
    [types.ADJUSTMENT]: color.warning,
    [types.PAYOUT]: '#95bf47',
    [types.APP_FEE]: '#76c2e0',
    default: '#EDF2F7',
  },
}
