import * as P from 'prop-types'

export const checkPropTypes = {
  name: P.string,
  label: P.node,
  error: P.oneOfType([P.string, P.bool]),
  checked: P.bool,
  onChange: P.func,
  onBlur: P.func,
  disabled: P.bool,
  title: P.string,
  reversed: P.bool,
  block: P.bool,
  className: P.string,
}
