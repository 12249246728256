import React from 'react'
import { useDispatch } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { history } from '@lib/routing'
import { DangerButton, YesNoModal } from '@ui'
import { useModal } from '@features/common'
import { notify } from '@lib/notification'
import { sourceDisconnect } from '../effects/sources'
import { useProcessorSettings } from '../hooks'

export const DeleteProcessorModalButton = ({ processorId }) => {
  const { processor } = useProcessorSettings(processorId)
  const modal = useModal()
  const dispatch = useDispatch()
  const request = useRequest(() => dispatch(sourceDisconnect(processor.id)))

  const disconnect = async () => {
    try {
      await request()
      modal.close()
      history.push('/configuration')
    } catch (err) {
      notify({
        type: 'danger',
        message: 'Something went wrong',
      })
    }
  }

  return (
    <>
      <DangerButton variant="light" onClick={modal.show}>DELETE</DangerButton>
      <YesNoModal
        title="Delete processor"
        show={modal.isShown}
        onClose={modal.close}
        onConfirm={disconnect}
        loading={request.isLoading}
        yesText="DELETE"
      >
        Your transactions for this account will no longer flow into BankFeeds.
      </YesNoModal>
    </>
  )
}
