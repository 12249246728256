import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { toSelectItems } from '@lib/convert'
import { useRequest } from '@lib/fetching'
import { FormApiError } from '@lib/form-utils'
import { Button, FormGroup, LoadingButton, Modal, Select } from '@ui'
import { xeroApi } from '../api'
import { integrationEntitiesFetch } from '../effects/integrations'
import { $currencies, $isIntegrationExpired } from '../selectors/integration'
import { $activeProcessorSettings } from '../selectors/configuration'

export const HowToCreateAccountModal = ({ show, onClose }) => (
  <Modal show={show} onClose={onClose} bgTransparent>
    <iframe
      title="Example"
      width="600"
      height="320"
      src="https://www.youtube.com/embed/nmNGP6omziA"
      frameBorder="0"
      allowFullScreen
    />
  </Modal>
)

export const CreateBankAccountModal = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const isIntegrationExpired = useSelector($isIntegrationExpired)
  const currencies = useSelector($currencies)
  const { processor } = useSelector($activeProcessorSettings)

  const [currency, setCurrency] = useState(null)
  const [error, setError] = useState(null)

  const createRequest = useRequest(() => xeroApi.createBankAccount(currency, processor.id), {
    prepareError: (err) => err.response,
  })

  const submitCreate = async () => {
    setError(null)
    if (!currency) {
      setError('Currency is required')
      return
    }

    await createRequest(currency)
    onClose()
    dispatch(integrationEntitiesFetch())
  }

  const changeCurrency = (currency) => {
    setCurrency(currency)
    setError(null)
  }

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Title>Create BankFeeds Account</Modal.Title>
      <Modal.Body>
        {createRequest.error && (
          <ErrorContainer>
            <FormApiError error={createRequest.error} />
          </ErrorContainer>
        )}
        <FormGroup>
          <Select
            label="Specify the currency code"
            onChange={changeCurrency}
            placeholder={isIntegrationExpired ? 'No Xero connection' : 'Select a currency...'}
            options={toSelectItems(currencies, 'name', 'id')}
            disabled={isIntegrationExpired}
            value={currency}
            error={error}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
        <LoadingButton loading={createRequest.isLoading} size="md" onClick={submitCreate}>CREATE</LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

const ErrorContainer = styled.div`
  margin-bottom: 1rem;
`
