import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { usePagination, useSortBy, useTable } from 'react-table'
import { useRequest } from '@lib/fetching'
import { $currentUser, useModal } from '@features/common'
import { initialDataFetch } from '@features/session'
import { filterEmpty } from '@lib/object'
import { Button, YesNoModal } from '@ui'
import { ImpersonateButton } from '../components/ImpersonateButton'
import { defaultSort, pageSizes } from '../constants'
import { api } from '../api'
import { downloadUserReport } from '../effects'

export const useModel = () => {
  const fetchUsers = useRequest(api.getUsers, {
    initialData: {
      data: [],
      count: 0,
    },
  })
  const {
    isLoading,
    data: { data: users, count },
  } = fetchUsers

  const dispatch = useDispatch()
  const user = useSelector($currentUser)
  const [search, setSearch] = useState('')
  const pageCount = Math.ceil(count / pageSizes.users)

  const table = useUsersTable({
    data: users,
    pageCount,
    reload: fetchUsers,
  })

  const {
    state: { pageIndex, pageSize, sortBy },
  } = table

  useEffect(() => {
    const params = filterEmpty({
      page: pageIndex + 1,
      pageSize,
      sort: sortBy[0],
      filters: { search },
    })

    fetchUsers(params).then(() => {
      if (user.masquerade) {
        dispatch(initialDataFetch())
      }
    })
  }, [dispatch, fetchUsers, pageIndex, pageSize, search, sortBy, user.masquerade])

  return {
    count,
    table,
    search,
    setSearch,
    isLoading,
  }
}

const useUsersTable = ({ data, pageCount, reload }) => useTable(
  {
    columns: useColumns({ reload }),
    data,
    pageCount,
    manualSortBy: true,
    manualPagination: true,
    initialState: {
      pageSize: pageSizes.users,
      sortBy: [defaultSort.users],
    },
  },
  useSortBy,
  usePagination,
)

const useColumns = ({ reload }) => useMemo(() => ([
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'email', Header: 'Email' },
  { accessor: 'status', Header: 'Status' },
  {
    accessor: 'subscriptions.plan_name',
    Header: 'Plan',
    Cell: ({ row }) => firstSub(row).plan_name || 'N/A',
  },
  {
    Header: 'Actions',
    disableSorting: true,
    Cell: ({ row }) => <Actions row={row} reload={reload} />,
  },
]), [reload])

const Actions = ({ row, reload }) => {
  const cancelModal = useModal()
  const currentUser = useSelector($currentUser)
  const user = row.original
  const isCurrentUser = user.id === currentUser.id

  const cancelRequest = useRequest(() => api.cancelAccount(user.id), {
    done() {
      cancelModal.close()
      reload()
    },
  })

  return (
    <>
      <ButtonGroup>
        <Button size="xs" variant="outline" onClick={() => downloadUserReport(user.id)}>
          Export Data
        </Button>
        {user.status !== 'cancelled' && (
          <Button size="xs" variant="danger" onClick={cancelModal.show}>
            Cancel Account
          </Button>
        )}
        {isCurrentUser || <ImpersonateButton userId={user.id} />}
      </ButtonGroup>
      <YesNoModal
        title="Cancel account"
        show={cancelModal.isShown}
        onConfirm={cancelRequest}
        onClose={cancelModal.close}
        loading={cancelRequest.isLoading}
        yesText="YES"
        noText="NO"
      >
        Are you sure?
        <br />
        <br />
        All data associated with this account will be deleted. This action cannot be reversed.
      </YesNoModal>
    </>
  )
}

const firstSub = (row) =>
  row.original.subscriptions.length ? row.original.subscriptions[0] : {}

const ButtonGroup = styled.div`
  display: flex;

  & > * {
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`
