import React from 'react'
import { useSelector } from 'react-redux'
import { processors } from '@lib/constants'
import { Divider, QuestionIcon, Text, TooltipContainer } from '@ui'
import { $activeProcessorSettings } from '../../selectors/configuration'
import { CustomSwitchField, InputTemplate, TooltipText } from './shared'

export const ImportPaymentsOption = () => {
  const { processor } = useSelector($activeProcessorSettings)
  const visible = processor.connection_source === processors.SHOPIFY

  return visible ? (
    <>
      <InputTemplate>
        <CustomSwitchField
          label={<TooltipContainer
            tooltip="Enable this option to only import sales made through Shopify Payments."
          >
            <TooltipText>
              <Text mr="6px">Import Only Shopify Payments</Text>
              <QuestionIcon />
            </TooltipText>
          </TooltipContainer>}
          name="import_source_payments"
        />
      </InputTemplate>
      <Divider />
    </>
  ) : null
}
