import React from 'react'
import { Card, Flex, H2, H4, H5 } from '@ui'

export const ApiError = ({ message, reference, stack }) => (
  <Flex my="1rem">
    <Card>
      <H2>An Error Occurred ({message})</H2>
      <H4>Sorry, we encountered an error processing your request. {reference && <>
        If the problem persists, please contact support@bankfeeds.io and provide this reference number: #{reference}
      </>}</H4>
      {stack && (
        <>
          <H5>Development error details: (Not visible in production)</H5>
          <pre className="card card-body bg-light">{stack}</pre>
        </>
      )}
    </Card>
  </Flex>
)
