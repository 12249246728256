import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import { ifProp, theme } from 'styled-tools'
import { Portal } from '../../lib/portal'
import { usePopper } from '../../lib/popper'
import { useOpened } from '../../lib/hooks'
import { Icon } from '../index'
import { SortButton } from './SortableHeader'

export const FilterableHeader = ({ column }) => {
  const { opened, toggle, close } = useOpened()
  const bodyRef = useRef()
  const buttonRef = useRef()
  const isActive = !!column.filterValue
  const iconName = column.isSorted
    ? column.isSortedDesc
      ? 'sortDescFlat' : 'sortAscFlat'
    : 'filterFlat'


  useOnClickOutside(bodyRef, (e) => {
    // SortButton has own onClick handler, that toggles the dropdown
    if (e.target === buttonRef.current) return
    close()
  })

  return (
    <th {...column.getHeaderProps({ width: column.width })}>
      {column.canFilter ? (
        <FilterWrapper>
          <SortButton ref={buttonRef} onClick={toggle}>
            {column.render('Header')}
            <SortStatus active={isActive}>
              <Icon
                name={iconName}
                variant={isActive ? 'white' : 'primary'}
              />
            </SortStatus>
          </SortButton>
          {opened && (
            <PopperInner triggerRef={buttonRef} popperRef={bodyRef}>
              <FilterContentWrapper ref={bodyRef}>
                {column.render('Filter', { onClose: close })}
              </FilterContentWrapper>
            </PopperInner>
          )}
        </FilterWrapper>
      ) : column.render('Header')}
    </th>
  )
}

const PopperInner = ({ triggerRef, popperRef, children }) => {
  usePopper(triggerRef, popperRef, {
    placement: 'bottom-end',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 3],
      },
    }],
  })
  return (
    <Portal id="tooltip">
      {children}
    </Portal>
  )
}

const SortStatus = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 6px;
  border: 1px solid ${theme('color.primary')};
  border-radius: 3px;
  pointer-events: none;
  
  ${ifProp('active', css`
    background-color: ${theme('color.primary')};
  `)};
`

const FilterWrapper = styled.span`
  position: relative;
`

const FilterContentWrapper = styled.div`
  z-index: ${theme('order.tooltip')};
`
