import { createSelector } from 'reselect'
import { $currentUser } from '@features/common/selectors'
import { fetchStatus } from '@lib/fetching'
import { toSelectItems } from '@lib/convert'

const $root = (root) => root.integration

export const $integrationSettings = createSelector(
  $root,
  (root) => root.settings.model,
)

export const $integrationEntities = createSelector(
  $root,
  (root) => root.entities,
)

const $integrationTaxRates = createSelector(
  $root,
  (root) => root.taxRates,
)

export const $integrationTaxRatesList = createSelector(
  $integrationTaxRates,
  (taxRates) => taxRates.taxRates,
)

const $entitiesFetching = createSelector(
  $integrationEntities,
  (entities) => entities.fetching,
)

const $settingsFetching = createSelector(
  $root,
  (root) => root.settings.fetching,
)

export const $currencies = createSelector(
  $integrationEntities,
  (entities) => entities.currencies,
)

export const $fetchingError = createSelector(
  $entitiesFetching,
  $settingsFetching,
  (accountsFetching, settingsFetching) =>
    settingsFetching.error ||
    accountsFetching.error,
)

export const $isSettingsLoading = createSelector(
  $settingsFetching,
  (settingsFetching) =>
    settingsFetching.status === fetchStatus.loading,
)

export const $isEntitiesLoading = createSelector(
  $entitiesFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)

export const $isIntegrationDataLoading = createSelector(
  $entitiesFetching,
  $settingsFetching,
  (accountsFetching, settingsFetching) =>
    settingsFetching.status === fetchStatus.loading ||
    accountsFetching.status === fetchStatus.loading,
)

export const $isIntegrationExpired = createSelector(
  $currentUser,
  $fetchingError,
  (user, fetchingError) =>
    Boolean(fetchingError) ||
    !user.has_integration,
)

export const $taxRatesOptions = createSelector(
  $integrationTaxRatesList,
  (xeroTaxRates) => toSelectItems(xeroTaxRates, 'name', 'type'),
)
