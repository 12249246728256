import React from 'react'
import { useFormikContext } from 'formik'
import { SuccessLabel } from '../Label'

export const FieldStatus = ({ name }) => {
  const { status } = useFormikContext()
  const show = status && name in status && Boolean(status[name])

  return show
    ? <SuccessLabel>{status[name]}</SuccessLabel>
    : null
}
