import { useMemo } from 'react'
import { selectionTypes } from '@lib/table-hooks'
import { exportStatusToStatuses } from '@lib/mappers'
import { exportStatuses } from '@lib/constants'
import { useActionsConfig } from './actions-config'
import { massActionCall } from './effects'

export const useResyncAll = ({ onDone }) => {
  const actionsConfig = useActionsConfig()

  return createMassAction({
    config: actionsConfig.find((c) => c.id === 'resync'),
    selectionType: selectionTypes.ALL,
    selectedTransactions: [],
    filters: {},
    onDone,
  })
}

export const useMassActions = ({ selectionType, selectedTransactions, filters, onDone }) => {
  const actionsConfig = useActionsConfig()

  return useMemo(
    () => actionsConfig.map((config) => createMassAction({
      config,
      selectionType,
      selectedTransactions,
      onDone,
      filters,
    })),
    [actionsConfig, filters, onDone, selectedTransactions, selectionType],
  )
}

const createMassAction = ({ config, selectionType, selectedTransactions, onDone, filters }) => {
  const isAllSelected = selectionType === selectionTypes.ALL
  const isAnySelected = selectedTransactions.length > 0

  const notSyncedStatuses = exportStatusToStatuses(exportStatuses.NOT_SYNCED)
  const syncedStatuses = exportStatusToStatuses(exportStatuses.SYNCED)
  const queuedStatuses = exportStatusToStatuses(exportStatuses.QUEUED)

  const canExport = allInStatuses(selectedTransactions, notSyncedStatuses)
  const canSync = allInStatuses(selectedTransactions, [...notSyncedStatuses, ...syncedStatuses])
  const canUnqueue = allInStatuses(selectedTransactions, queuedStatuses)

  const isTasksActive = {
    export: canExport,
    resync: canSync,
    unqueue: canUnqueue,
    delete: isAnySelected,
    requeueFailed: isAnySelected,
  }

  const selectedIds = selectedTransactions.map(({ id }) => id)

  return {
    config,
    active: isAllSelected || isTasksActive[config.id],
    handler: () => massActionCall({
      name: config.id,
      data: isAllSelected ? {} : { ids: selectedIds },
      filters,
    }).then(onDone),
  }
}

const allInStatuses = (transactions, statusesToCheck) => {
  if (!transactions.length) return false
  return transactions.every((tr) => statusesToCheck.includes(tr.status))
}
