export const useTablePagination = (table, count) => {
  const {
    nextPage,
    previousPage,
    gotoPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = table

  return {
    page: pageIndex + 1,
    pageIndex,
    pageCount,
    pageSize,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    // INFO: react-table's pagination uses pageIndex instead of pageNumber
    gotoPage: (page) => gotoPage(page - 1),
    count,
  }
}
