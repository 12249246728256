import React from 'react'
import { processors, sourcesOptions } from '@lib/constants'
import { useConnectRequest, useModal, connectApi } from '@features/common'
import { PinPaymentsConnectModal } from './PinPaymentsConnectModal'
import { ShopifyConnectModal } from './ShopifyModal'
import { ConnectModal, InlineConnectModal } from './ConnectModal'
import { WooCommerceConnectModal } from './WooCommerceModal'

export const ConnectProcessorModal = ({ show, onClose = () => {}, inline }) => {
  const pinPaymentsModal = useModal()
  const shopifyModal = useModal()
  const wooCommerceModal = useModal()
  const shopifyRequest = useConnectRequest(connectApi.connectShopify)
  const stripeRequest = useConnectRequest(connectApi.connectStripe)
  const gocardlessRequest = useConnectRequest(connectApi.connectGocardless)
  const woocommerceRequest = useConnectRequest(connectApi.connectWooCommerce)

  const prepareConnect = (processor) => {
    switch (processor) {
      case processors.STRIPE:
        return stripeRequest()
      case processors.GOCARDLESS:
        return gocardlessRequest()
      case processors.SHOPIFY:
        onClose()
        return shopifyModal.show()
      case processors.PINPAYMENTS:
        onClose()
        return pinPaymentsModal.show()
      case processors.WOOCOMMERCE:
        onClose()
        return wooCommerceModal.show()
      default:
        return
    }
  }

  return (
    <>
      {inline ? (
        <InlineConnectModal
          options={sourcesOptions}
          onConnect={prepareConnect}
        />
      ) : (
        <ConnectModal
          title="Choose a Payments Processor"
          show={show}
          loading={stripeRequest.isLoading || gocardlessRequest.isLoading}
          onClose={onClose}
          onConfirm={prepareConnect}
          options={sourcesOptions}
        />
      )}
      <ShopifyConnectModal
        show={shopifyModal.isShown}
        onClose={shopifyModal.close}
        isLoading={shopifyRequest.isLoading}
        onSubmit={shopifyRequest}
      />
      <WooCommerceConnectModal
        show={wooCommerceModal.isShown}
        onClose={wooCommerceModal.close}
        isLoading={woocommerceRequest.isLoading}
        onSubmit={woocommerceRequest}
      />
      <PinPaymentsConnectModal
        show={pinPaymentsModal.isShown}
        onClose={pinPaymentsModal.close}
      />
    </>
  )
}
