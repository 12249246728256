import React from 'react'
import * as P from 'prop-types'
import styled from 'styled-components'

export const Table = ({ responsive, children }) => {
  if (responsive) {
    return (
      <Responsive>
        <StyledTable>
          {children}
        </StyledTable>
      </Responsive>
    )
  }

  return (
    <StyledTable>
      {children}
    </StyledTable>
  )
}

Table.propTypes = {
  responsive: P.bool,
  children: P.node,
}

Table.defaultProps = {
  responsive: true,
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    line-height: 1.55;
    padding: 10px;
  }
  
  th {
    border-bottom: 4px solid #DBE2E9;
    text-align: inherit;
    font-weight: 600;
    vertical-align: middle;
  }

  td {
    border-bottom: 1px solid #e7eaec;
  }
`

const Responsive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`
