import React from 'react'
import { H2 } from '@ui'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@lib/hooks'
import { plans, selectablePlans } from '@features/stripe'
import { $currentPlanName, $userSubscription, $userActive } from '@features/common'
import { useRequest } from '@lib/fetching'
import { notify } from '@lib/notification'
import { api } from '@features/stripe'
import { CurrentPlanView, PlanView } from '../components/PlanView'
import { userActions } from '../index'

export const Plans = () => {
  const editing = useBoolean()
  const isActive = useSelector($userActive)
  const currentPlanName = useSelector($currentPlanName)
  const subscription = useSelector($userSubscription)
  const selectedPlan = plans.find((p) => p.id === currentPlanName)
  const selectedPlanIndex = !isActive ? -1 : plans.findIndex((p) => p.id === currentPlanName)

  return (
    <>
      <Section>
        <H2>Your Plan</H2>
        <CurrentPlanView
          name={selectedPlan.name}
          validDate={subscription.valid_to}
          features={selectedPlan.features}
          editting={editing.val}
          onChangeClick={editing.on}
        />
      </Section>
      {editing.val && (
        <Section className="animated fadeInRight">
          <H2>Available Plans</H2>
          <PlansWrapper>
            <PlansList
              selectedPlanIndex={selectedPlanIndex}
              selectedPlan={!isActive ? null : selectedPlan}
              onSelected={editing.off}
            />
          </PlansWrapper>
        </Section>
      )}
    </>
  )
}

export const PlansList = ({ selectedPlanIndex, selectedPlan, onSelected, onPlanSelected }) => (
  <PlansWrapper>
    {selectablePlans.map((plan, i) => (
      <Plan
        planIndex={i}
        selectedPlanIndex={selectedPlanIndex}
        key={plan.id}
        plan={plan}
        isSelected={selectedPlan && plan.id === selectedPlan.id}
        onSelected={onSelected}
        onPlanSelected={onPlanSelected}
      />
    ))}
  </PlansWrapper>
)

const Plan = ({ plan, planIndex, selectedPlanIndex, isSelected, onSelected, onPlanSelected }) => {
  const dispatch = useDispatch()
  const updateRequest = useRequest(() => {
    if (selectedPlanIndex > -1) {
      return api.updatePlan(plan.id)
    }
    
    window.location.href = `/checkout?plan=${plan.id}`
  })

  const updatePlan = () => {
    if (onPlanSelected) {
      onPlanSelected(plan)
    } else {
      updateRequest().then(({ data }) => {
        dispatch(userActions.setSubscription(data))
        onSelected()
        notify({
          message: `Plan successfully updated to ${plan.name}`,
        })
      }).catch((err) => {
        notify({
          type: 'danger',
          message: mapErrorMessage(err),
        })
      })
    }
  }

  return (
    <PlanView
      name={plan.name}
      price={plan.price}
      features={plan.features}
      isActive={plan.active}
      isSelected={isSelected}
      isLoading={updateRequest.isLoading}
      onSelect={updatePlan}
      planIndex={planIndex}
      selectedPlanIndex={selectedPlanIndex}
      isUpgrade={planIndex > selectedPlanIndex}
    />
  )
}

const mapErrorMessage = (err) => err?.response?.data?.error || 'Got an unexpected server error. Try again later.'

const Section = styled.div`
  margin-bottom: 40px;

  ${H2} {
    margin-bottom: 32px;
  }
`

const PlansWrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`
