import { useEffect, useRef } from 'react'
import { isFunction } from '@lib/is'

/**
 * Schedules "target" call after "delay" ms, when "filter" returns true.
 *
 * @param {function} target
 * @param {function|boolean} filter
 * @param {number} delay
 */
export const useTimeout = ({ target, filter, delay }) => {
  const savedTarget = useRef(() => {})

  useEffect(() => {
    savedTarget.current = target
  }, [target])

  useEffect(() => {
    const willCall = isFunction(filter) ? filter() : filter

    if (willCall) {
      const timeout = setTimeout(() => {
        savedTarget.current()
      }, delay)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [delay, filter, target])
}
