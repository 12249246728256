import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  fetching: initialFetching,
  currencies: [],
  bankAccounts: [],
  commonAccounts: [],
  paymentMethods: [],
  items: [],
  itemTypes: [],
  accounts: [],
  vendors: [],
  taxCodes: [],
  customers: [],
  preferences: {},
}

const symbiotes = {
  fetch: createFetching(),

  set: (state, entities) => ({
    ...state,
    ...entities,
  }),

  reset: () => initialState,
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'integration/entities',
)
