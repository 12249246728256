import { createSymbiote } from 'redux-symbiote'

const initialState = {
  processorId: null,
}

const symbiotes = {
  set: (state, { processorId }) => ({
    processorId,
  }),

  reset: () => initialState,
}

export const { actions, reducer } = createSymbiote(
  initialState,
  symbiotes,
  'configuration',
)
