import React from 'react'
import { useSelector } from 'react-redux'
import { statuses } from '@lib/constants'
import { $latestActiveImportJob } from '../selectors/active-import-jobs'
import { CreateImportForm } from '../components/CreateImportForm'
import { SearchResults } from '../components/SearchResults'
import { JobFailedView, JobInProgressView, LatestJobExportStatus } from '../components/ResultsViews'

export const SearchTransactionsPage = () => (
  <div className="animated fadeInRight">
    <CreateImportForm />
    <Content />
  </div>
)

const Content = () => {
  const activeImportJob = useSelector($latestActiveImportJob)

  return activeImportJob ? (
    <>
      {activeImportJob.status === statuses.IN_PROGRESS ? (
        <JobInProgressView />
      ) : (
        <>
          {activeImportJob.status === statuses.FAILED && (
            <JobFailedView error={activeImportJob.error_details} />
          )}
          <SearchResults />
        </>
      )}
    </>
  ) : <LatestJobExportStatus />
}
