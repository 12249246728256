import { groupBy } from '@lib/array'
import { handleFetching } from '@lib/fetching'
import { actions } from '../symbiotes/sources'
import { sourcesApi } from '../api'
import { $integrationSettings } from '../selectors/integration'

export const sourceDisconnect = (id) => async (dispatch) => {
  await sourcesApi.disconnect(id)
  dispatch(sourcesFetch())
}

export const sourceSettingsUpdate = (id, settingsData) => async (dispatch, getState) => {
  const integration = $integrationSettings(getState())
  const { data } = await sourcesApi.updateSettings(id, integration.source, settingsData)
  dispatch(actions.settings.updateBySourceId({
    id,
    settings: data,
  }))
}

export const sourcesFetch = () =>
  handleFetching(actions.fetch, {
    async run(dispatch) {
      const { data } = await sourcesApi.getList()
      const { sources, settings, rules } = prepareSourcesData(data)
      dispatch(actions.set({ sources, settings, rules }))
    },
  })

const prepareSourcesData = (data) => ({
  sources: groupBy(
    data,
    (source) => source.id,
  ),
  settings: groupBy(
    data.map((source) => source.settings),
    (source) => source.id,
  ),
  rules: groupBy(
    data,
    (source) => [source.id, source.settings.rules_schema],
  ),
})
