import styled from 'styled-components'
import * as P from 'prop-types'
import { ifProp } from 'styled-tools'

export const TabPane = styled.div`
  display: ${ifProp('active', 'block', 'none')};
`

TabPane.propTypes = {
  active: P.bool,
}

TabPane.defaultProps = {
  active: false,
}
