import React from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { Guard } from '@features/common'
import { Icon, IconLink } from '@ui'
import { pluralize } from '@lib/string'
import { RuleListPreview } from '../Rule'
import { $rulesByAccounts } from '../../selectors/configuration'

export const Rules = ({ account }) => {
  const { status } = useFormikContext()
  if (!status || !status.ruleActions) {
    throw new Error('You have to pass "ruleActions" to the formik\'s "initialStatus"')
  }
  const { ruleActions } = status
  const rulesByAccounts = useSelector($rulesByAccounts)

  return (
    <Guard rulesEngine>
      <ImportOptionRules
        rules={rulesByAccounts[account]}
        onEditClick={ruleActions.prepareRuleEdit}
        onDeleteClick={ruleActions.prepareRuleRemove}
      />
      <div>
        <IconLink onClick={() => ruleActions.prepareRuleCreate({ account })}>
          <Icon name="plusCircle" variant="primary" />
          Create a rule
        </IconLink>
      </div>
    </Guard>
  )
}

const ImportOptionRules = ({ rules, onEditClick, onDeleteClick }) =>
  rules.length > 0 && (
    <div>
      <IconLink bold>
        {pluralize(rules.length, 'Rule')}
      </IconLink>
      <RuleListPreview
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        rules={rules}
      />
    </div>
  )
