import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  model: null,
  token: null,
  subscription: null,
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),

  setUser: (state, model) => ({
    ...state,
    model,
  }),

  setProfile: (state, payload) => ({
    ...state,
    model: {
      ...state.model,
      name: payload.name,
      email: payload.email,
      timezone: payload.timezone,
      company_name: payload.company_name,
      date_format: payload.date_format,
    },
  }),

  setSubscription: (state, subscription) => ({
    ...state,
    subscription,
  }),

  setToken: (state, token) => ({
    ...state,
    token,
  }),

  setTimezone: (state, timezone) => ({
    ...state,
    model: {
      ...state.model,
      timezone,
    },
  }),

  reset: (state) => ({
    ...state,
    model: null,
    subscription: null,
  }),
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'user',
)
