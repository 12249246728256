import React from 'react'
import { PlansList } from '@features/account/pages/plans'

export const Plans = () => (
  <PlansList
    selectedPlanIndex={-1}
    onPlanSelected={(plan) => {
      window.location.href = `?plan=${plan.id}`
    }}
  />
)
