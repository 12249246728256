import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  cards: [],
  defaultCard: null,
  address: null,
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),

  setCards: (state, cards) => ({
    ...state,
    cards,
  }),

  addCard: (state, card) => ({
    ...state,
    cards: state.cards.concat(card),
  }),

  removeCard: (state, id) => ({
    ...state,
    cards: state.cards.filter((card) => card.id !== id),
  }),

  updateCard: (state, newCard) => ({
    ...state,
    cards: state.cards.map((card) => card.id === newCard.id ? newCard : card),
  }),

  setDefaultCard: (state, defaultCard) => ({
    ...state,
    defaultCard,
  }),

  setAddress: (state, address) => ({
    ...state,
    address,
  }),
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'payment',
)
