import * as P from 'prop-types'
import { createPortal } from 'react-dom'

export const Portal = ({ id, children }) => {
  const el = document.getElementById(id)
  return el ? createPortal(children, el) : null
}

Portal.propTypes = {
  id: P.string.isRequired,
  children: P.node.isRequired,
}
