// Interop between Popperjs@v2 and React.
// Source https://github.com/react-bootstrap/react-overlays/blob/master/src/usePopper.js
import { useLayoutEffect, useRef } from 'react'
import { createPopper } from '@popperjs/core'

/**
 * @typedef {'auto-start'|'auto'|'auto-end'|'top-start'|'top'|'top-end'|'right-start'|'right'|'right-end'|'bottom-end'|'bottom'|'bottom-start'|'left-end'|'left'|'left-start'} Placement
 */

/**
 * @param referenceRef
 * @param popperRef
 * @param {{ placement: Placement }} options
 */
export const usePopper = (referenceRef, popperRef, options = {}) => {
  const popperInstanceRef = useRef()

  useLayoutEffect(() => {
    if (!referenceRef.current || !popperRef.current) return

    popperInstanceRef.current = createPopper(
      referenceRef.current,
      popperRef.current,
      options,
    )

    return () => {
      if (popperInstanceRef.current) {
        popperInstanceRef.current.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceRef, popperRef])

  useLayoutEffect(() => {
    if (!popperInstanceRef.current) return

    popperInstanceRef.current.setOptions(options)
  }, [options])

  return {
    instance: popperInstanceRef,
  }
}
