import React from 'react'
import styled from 'styled-components'
import { SwitchField, Text } from '@ui'
import { Col, Row } from '@lib/styled-layout'

export const InputTemplate = ({ children }) => (
  <Row>
    <Col md={9}>
      {children}
    </Col>
  </Row>
)

export const CustomSwitchField = ({ label, name, disabled }) => (
  <SwitchContainer>
    <SwitchField
      label={label}
      name={name}
      block
      reversed
      disabled={disabled}
    />
  </SwitchContainer>
)

export const SwitchContainer = styled.div`
  padding: 12px 0;
`

export const ErrorMarkContainer = styled.div`
  position: relative;
`

export const RulesContainer = styled.div`
  margin-bottom: 16px;
`

export const TooltipText = styled.div`
  display: flex;
  align-items: center;
`

export const Description = ({ children }) => (
  <Text mt="3px" mb="8px" variant="light" size="sm">
    {children}
  </Text>
)
