import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FullScreenSpinner } from '@ui'
import { Storage } from '@lib/storage'
import { $currentUser } from '@features/common'

import { NewUserLayout } from '../components/NewUserLayout'
import { useModel } from './new-account.model'
import { api } from '../api'

export const NewAccount = () => {
  const user = useSelector($currentUser)

  const {
    activeStep,
    currentStep,
    StepComponent,
    steps,
  } = useModel()

  const [setupComplete, setSetupComplete] = useState(false)

  useEffect(() => {
    if (activeStep === 1 && Storage.get('from_shopify') && Storage.get('shopify_connect_callback_url')) {
      window.location.href = Storage.get('shopify_connect_callback_url')
      Storage.remove('shopify_connect_callback_url')
    }
  
    if (activeStep === 4 && !setupComplete) {
      (async () => {
        setSetupComplete(true)
        await api.completeSetup()
        window.location.reload()
      })()
    }
  }, [activeStep, setupComplete, setSetupComplete])

  if (!currentStep) {
    return (<FullScreenSpinner />)
  }

  return (
    <NewUserLayout
      title={currentStep.title}
      subtitle={currentStep.subtitle || null}
      steps={steps}
      activeStep={activeStep}
      user={user}
    >
      {StepComponent ? (
        <StepComponent />
      ) : null}
    </NewUserLayout>
  )
}
