import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from '@lib/styled-layout'
import { $currentUser, ApiError, MainTemplate } from '@features/common'
import { Button, Flex, List, ListItem, Text, Spinner, Heading } from '@ui'
import { humanizeSource } from '@lib/constants'
import { Logo } from '../../components/Logo'
import { AddProcessorModalButton } from '../../connect/AddIntegrationModalButtons'
import { DeleteProcessorModalButton } from '../../components/DeleteProcessorModalButton'
import { NoProcessors } from '../../components/EmptyViews'
import { $sourcesIsLoading, $processorsSettings, $connectionsLimitReached } from '../../selectors/sources'
import { $fetchingError } from '../../selectors/integration'
import { LimitReachedAlert } from '../../components/LimitReachedAlert'

export const ConfigurationPage = () => {
  const user = useSelector($currentUser)
  const fetchingError = useSelector($fetchingError)
  const limitReached = useSelector($connectionsLimitReached)

  return (
    <MainTemplate heading={<Heading>Configuration</Heading>}>
      {user.has_integration && fetchingError && (
        <ApiError
          message={fetchingError.error}
          reference={fetchingError.referenceNumber}
        />
      )}
      <Row>
        <Col xl={8}>
          {limitReached && <LimitReachedAlert />}
          <Text mb="8px">Please select the processor which you want to configure</Text>
          {!limitReached && (
            <Flex justify="flex-end" mb="10px">
              <AddProcessorModalButton />
            </Flex>
          )}
          <ProcessorsList />
        </Col>
      </Row>
    </MainTemplate>
  )
}

const ProcessorsList = () => {
  const sourcesIsLoading = useSelector($sourcesIsLoading)
  const processorsSettings = useSelector($processorsSettings)

  if (sourcesIsLoading) return <Spinner />
  if (!processorsSettings.length) return <NoProcessors />

  return (
    <Row>
      <Col>
        <List gap="16px" bordered={false}>
          {processorsSettings.map(({ settings, processor }) => (
            <ListItem bordered key={settings.id}>
              <Flex justify="space-between">
                <Flex align="center">
                  <LogoContainer>
                    <Logo
                      name={processor.connection_source}
                      title={humanizeSource(processor.connection_source)}
                    />
                  </LogoContainer>
                  <Text ml="16px" variant="light">{processor.connection_name}</Text>
                </Flex>
                <Flex gap="24px">
                  <Link to={`/configuration/${processor.id}`}>
                    <Button variant="outline">CONFIGURATION</Button>
                  </Link>
                  <DeleteProcessorModalButton processorId={processor.id} />
                </Flex>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Col>
    </Row>
  )
}

const LogoContainer = styled.div`
  min-width: 200px;
`
