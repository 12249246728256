import { exportStatuses, statuses } from '@lib/constants'
import { theme } from '@lib/theme'

/**
 * Returns an array of statuses associated with the exportStatus.
 * @param exportStatus
 * @return {[]}
 */
export const exportStatusToStatuses = (exportStatus) => exportStatusToStatusesObj[exportStatus] || []

/**
 * Returns an associated exportStatus with the status.
 * @param status
 * @return {string|null}
 */
export const statusToExportStatus = (status) => statusToExportStatusObj[status] || null

const exportStatusToStatusesObj = {
  [exportStatuses.NOT_SYNCED]: [statuses.SKIPPED, statuses.DELETED],
  [exportStatuses.SYNCING]: [statuses.IN_PROGRESS],
  [exportStatuses.SYNCED]: [statuses.DONE],
  [exportStatuses.QUEUED]: [statuses.QUEUED],
  [exportStatuses.FAILED]: [statuses.FAILED],
}

const statusToExportStatusObj = Object.entries(exportStatusToStatusesObj).reduce((result, [exportStatus, statuses]) => {
  statuses.forEach((status) => {
    result[status] = exportStatus
  })
  return result
}, {})

export const brandColor = (name) => theme.brand[name] || theme.brand.default

export const typeColor = (name) => theme.type[name] || theme.type.default

export const exportStatusColor = (name) => theme.status[name]
