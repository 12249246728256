import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../../assets/css/auth0-lock.min.css'
import { userApi } from '@features/common'
import { accountReset } from '@features/session'
import { useAuth0 } from '@lib/auth0'
import { history } from '@lib/routing'
import { Spinner } from '@ui'
import { EmailSent, ResendEmailForm } from '../components/EmailSent'

export const VerifyEmailPage = () => {
  const dispatch = useDispatch()
  const { logout, user: auth0User } = useAuth0()
  const [fetching, setFetching] = useState(true)

  const sendRequest = async () => {
    setFetching(true)
    await userApi.resendEmail()
    setFetching(false)
  }

  const logoutUser = () => {
    dispatch(accountReset())
    logout()
  }

  useEffect(() => {
    if (auth0User.email_verified) {
      history.push('/?emailVerified=1')
    } else {
      sendRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <EmailSent>
      {fetching ? (
        <Spinner marginTop={0} />
      ) : (
        <ResendEmailForm
          email={auth0User.email}
          onLogout={logoutUser}
          onResendClick={sendRequest}
        />
      )}
    </EmailSent>
  )
}
