import React from 'react'
import { BillingLog } from '../components/BillingLog'
import { CardsView } from '../components/CardsView'
import { BillingAddressForm } from '../components/BillingAddressForm'

export const Payment = () => (
  <>
    <CardsView />
    <BillingAddressForm />
    <BillingLog />
  </>
)
