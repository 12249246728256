import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  registry: {},
  settings: {}, // indexed by source id
  rules: {}, // indexed by source id
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),
  set: (state, { sources, settings, rules }) => ({
    ...state,
    registry: sources,
    settings,
    rules,
  }),

  settings: {
    updateBySourceId: (state, { id, settings }) => ({
      ...state,
      registry: {
        ...state.registry,
        [id]: {
          ...state.registry[id],
          settings,
        },
      },
    }),

    update: (state, { id, settings }) => ({
      ...state,
      settings: {
        ...state.settings,
        [id]: settings,
      },
    }),
  },

  rules: {
    set: (state, rules) => ({
      ...state,
      rules,
    }),

    setByProcessorId: (state, { processorId, data }) => ({
      ...state,
      rules: {
        ...state.rules,
        [processorId]: data,
      },
    }),
  },
}

export const { actions, reducer } = createSymbiote(
  initialState,
  symbiotes,
  'sources',
)
