import React from 'react'
import * as P from 'prop-types'
import styled from 'styled-components'
import { H2 } from '@ui'
import { prop } from 'styled-tools'
import { capitalize } from '@lib/string'
import { processors } from '@lib/constants'
import xeroLogo from '../../../assets/images/xero-logo.svg'
import quickbooksLogo from '../../../assets/images/qb-logo.svg'
import stripeLogo from '../../../assets/images/stripe-logo-sm.svg'
import pinLogo from '../../../assets/images/pin-logo-sm.svg'
import shopifyLogo from '../../../assets/images/shopify-logo-sm.svg'
import gocardlessLogo from '../../../assets/images/gocardless-logo-sm.svg'
import woocommerceLogo from '../../../assets/images/woocommerce-logo-sm.svg'

export const Logo = ({ name, title }) => (
  <Container>
    <Icon name={name} alt={name} />
    <H2 ml="10px">{title || capitalize(name)}</H2>
  </Container>
)

Logo.propTypes = {
  name: P.string.isRequired,
  title: P.string,
}

const getLogo = (name) => ({
  xero: xeroLogo,
  quickbooks: quickbooksLogo,
  [processors.STRIPE]: stripeLogo,
  [processors.SHOPIFY]: shopifyLogo,
  [processors.PINPAYMENTS]: pinLogo,
  [processors.GOCARDLESS]: gocardlessLogo,
  [processors.WOOCOMMERCE]: woocommerceLogo,
}[name])

const Icon = ({ name, alt, width, height }) => {
  const logo = getLogo(name)
  return (
    <Img width={width} height={height}>
      {logo && <img src={logo} alt={alt} />}
    </Img>
  )
}

const Container = styled.div`
  display: inline-flex;
`

const defaultSize = 33

const Img = styled.div`
  width: ${prop('width', defaultSize)}px;
  height: ${prop('height', defaultSize)}px;
  
  > img {
    width: ${prop('width', defaultSize)}px;
    height: ${prop('height', defaultSize)}px;
  }
`

Logo.Icon = Icon
