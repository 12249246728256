import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'

const isDev = process.env.NODE_ENV !== 'production'
const loggerEnabled = Boolean(process.env.REACT_APP_REDUX_LOGGER)

const composeEnhancers = isDev ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const loggerOptions = {
  collapsed: true,
}

export const configureStore = ({ initialState = {} } = {}) => {
  const middlewares = [thunk]

  if (isDev && loggerEnabled) {
    middlewares.push(createLogger(loggerOptions))
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return store
}
