import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'

export function useActions(actions) {
  const dispatch = useDispatch()

  return useMemo(() =>
    bindActionCreators(actions, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [])
}
