import { userApi } from '@features/common'
import { activeJobsFetch } from '@features/import-jobs'
import { integrationSettingsFetch, sourcesFetch, integrationEntitiesFetch } from '@features/integrations'
import { handleFetching } from '@lib/fetching'
import { Token } from '@lib/token'
import { userActions } from '../account'

export const accountFetch = () => handleFetching(userActions.fetch, {
  noThrow: true,
  prepareError: (error) => error.message,
  async run(dispatch) {
    const { subscription = {}, ...user } = await userApi.login()
    dispatch(userActions.setUser(user))
    dispatch(userActions.setSubscription(subscription))
  },
})

export const initialDataFetch = () => async (dispatch) => {
  await dispatch(accountFetch())
  dispatch(sourcesFetch())
  dispatch(integrationSettingsFetch())
  dispatch(integrationEntitiesFetch())
  dispatch(activeJobsFetch())
}

export const accountReset = () => (dispatch) => {
  dispatch(userActions.reset())
  dispatch(tokenUnset())
}

export const tokenSet = (token) => (dispatch) => {
  dispatch(userActions.setToken(token))
  Token.set(token)
}

const tokenUnset = () => (dispatch) => {
  dispatch(userActions.setToken(null))
  Token.erase()
}
