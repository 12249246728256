import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { history } from '@lib/routing'
import { ApiError, MainTemplate } from '@features/common'
import { useRequest } from '@lib/fetching'
import { useQueryParams } from '@lib/hooks'
import { userActions } from '@features/account'
import { Spinner } from '@ui'
import { api } from '../api'

export const ShopifySubscriptionCallbackPage = () => {
  const dispatch = useDispatch()
  const { charge_id } = useQueryParams()
  const subscribeRequest = useRequest(() => api.subscribe({ source: 'shopify', charge: charge_id }))

  useEffect(() => {
    const subscribe = async () => {
      const { data } = await subscribeRequest()
      history.push('/?upgraded=1')
      dispatch(userActions.setSubscription(data))
    }

    subscribe()
  }, [dispatch, subscribeRequest])

  return (
    <MainTemplate>
      {subscribeRequest.isLoading && <Spinner />}
      {subscribeRequest.error && (
        <ApiError
          message={subscribeRequest.error.error}
        />
      )}
    </MainTemplate>
  )
}
