import React from 'react'
import { useSelector } from 'react-redux'
import { useModal } from '@features/common'
import { Link, Text } from '@ui'
import { CreateBankAccountModal, HowToCreateAccountModal } from './BankAccountModals'
import { $hasBankfeedsAccount } from '../selectors/configuration'

export const BankAccountInfo = () => {
  const howModal = useModal()
  const createModal = useModal()

  const showCreateAccountLink = !useSelector($hasBankfeedsAccount)

  return (
    <>
      <Text mb="8px" variant="light">
        Please select a bank account in Xero to import your payment transactions. We recommend you create a new bank account with the name "Bankfeeds".
      </Text>
      <div>
        <Link onClick={howModal.show}>How to do this</Link>
      </div>
      {showCreateAccountLink && (
        <Text mt="8px" variant="light">
          If you want us to create that for you <Link onClick={createModal.show}>click here.</Link>
        </Text>
      )}
      <HowToCreateAccountModal
        show={howModal.isShown}
        onClose={howModal.close}
      />
      <CreateBankAccountModal
        show={createModal.isShown}
        onClose={createModal.close}
      />
    </>
  )
}
