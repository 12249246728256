import React from 'react'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { FloatingBarContent } from '../layout'
import { PaginationStats } from './PaginationStats'
import { Pagination } from './Pagination'

export const TableHead = ({ headerGroups, renderColumnHeader }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          renderColumnHeader(column)
        ))}
      </tr>
    ))}
  </thead>
)

TableHead.defaultProps = {
  renderColumnHeader: (column) => {
    const headerProps = column.getHeaderProps()
    return (
      <th {...headerProps}>
        {column.render('Header')}
      </th>
    )
  },
}

export const TableBody = ({ rows, prepareRow, renderRow }) => (
  <tbody>
    {rows.map((row, index) => {
      const res = prepareRow(row)
      return res || renderRow(row, index)
    })}
  </tbody>
)

TableBody.defaultProps = {
  renderRow: (row) => (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  ),
}

const Row = styled.tr`
  background-color: ${ifProp('isSelected', theme('color.active'))};
`

export const SelectableRow = ({ row }) => (
  <Row {...row.getRowProps({
    isSelected: row.isSelected,
  })}>
    {row.cells.map((cell) => (
      <td {...cell.getCellProps()}>
        {cell.render('Cell')}
      </td>
    ))}
  </Row>
)

const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
`

export const PaginationBlock = ({ pagination }) => (
  <FloatingBarContent>
    <TableFooter>
      <PaginationStats pagination={pagination} />
      <Pagination pagination={pagination} />
    </TableFooter>
  </FloatingBarContent>
)
