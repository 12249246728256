import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { switchProp, theme } from 'styled-tools'

export const RoundLabel = styled.div`
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  border: 1px solid;
  border-radius: 12px;
  min-width: 125px;
  padding: 7px;

  ${switchProp('variant', {
    success: css`
      border-color: ${theme('color.statusActive')};
      color: ${theme('color.statusActive')};
    `,
    secondary: css`
      border-color: ${theme('color.gray')};
      color: ${theme('color.gray')};
    `,
  })}
`

RoundLabel.propTypes = {
  variant: P.oneOf(['success', 'secondary']),
}

RoundLabel.defaultProps = {
  variant: 'success',
}
