import { $currentUser } from '@features/common'
import { initialDataFetch } from '@features/session'
import { handleFetching } from '@lib/fetching'
import { history } from '@lib/routing'
import { moveItemToHead } from '@lib/array'
import { notify } from '@lib/notification'
import { actions as userActions } from './symbiotes/user'
import { actions as paymentActions } from './symbiotes/payment'
import { api, paymentApi } from './api'
import { $cards, $defaultCard } from './selectors'

export const userSettingsUpdate = (values) => async (dispatch, getState) => {
  const { data } = await api.updateSettings(values)

  notify({ message: 'Settings saved' })

  const user = $currentUser(getState())
  const passwordChanged = !!values.password
  const emailChanged = data.email !== user.email

  if (passwordChanged || emailChanged) {
    window.location.reload()
  }

  dispatch(userActions.setProfile(data))
}

export const postAccountCancel = () => async (dispatch) => {
  dispatch(initialDataFetch())
  history.push('/')
}

export const paymentFetch = () => handleFetching(paymentActions.fetch, {
  async run (dispatch) {
    const { cards, defaultCard, address } = await paymentApi.getDetails()

    dispatch(paymentActions.setAddress(address))
    dispatch(paymentActions.setDefaultCard(defaultCard))
    dispatch(paymentActions.setCards(
      moveItemToHead(cards, defaultCard),
    ))
  },
})

export const cardAdd = (token) => async (dispatch, getState) => {
  const { card } = await paymentApi.addCard(token)
  dispatch(paymentActions.addCard(card))

  const defaultCard = $defaultCard(getState())
  if (!defaultCard) {
    dispatch(cardMakeDefault(card.id))
  }
}

export const cardRemove = (cardId) => async (dispatch, getState) => {
  const { id } = await paymentApi.removeCard(cardId)
  const isDefault = cardId === $defaultCard(getState())

  dispatch(paymentActions.removeCard(id))
  if (isDefault) {
    dispatch(paymentActions.setDefaultCard(null))
  }

  const cards = $cards(getState())
  if (cards.length > 0) {
    dispatch(cardMakeDefault(cards[0].id))
  }
}

export const cardUpdate = (cardId, data) => async (dispatch) => {
  const { card } = await paymentApi.updateCard(cardId, data)
  dispatch(paymentActions.updateCard(card))
}

export const cardMakeDefault = (cardId) => async (dispatch, getState) => {
  await paymentApi.makeCardDefault(cardId)
  const cards = $cards(getState())

  dispatch(paymentActions.setDefaultCard(cardId))
  dispatch(paymentActions.setCards(
    moveItemToHead(cards, cardId),
  ))
}

export const billingAddressUpdate = (data) => async (dispatch) => {
  const { address } = await paymentApi.updateBillingAddress(data)
  dispatch(paymentActions.setAddress(address))

  notify({ message: 'Settings saved' })
}
