import { createSelector } from 'reselect'
import { $currentUser, $userDatePrefs, $userSubscription } from '@features/common/selectors'
import { fetchStatus } from '@lib/fetching'
import { date } from '@lib/date'
import { humanizeSource } from '@lib/constants'
import { capitalize } from '@lib/string'

const $sourcesRoot = (root) => root.sources

export const $sourcesById = createSelector(
  $sourcesRoot,
  (sources) => sources.registry,
)

export const $sources = createSelector(
  $sourcesById,
  (sourcesById) => Object.values(sourcesById),
)

const $settingsById = createSelector(
  $sourcesRoot,
  (sources) => sources.settings,
)

export const $sourcesSettings = createSelector(
  $settingsById,
  (settings) => Object.values(settings),
)

export const $rulesByProcessorId = createSelector(
  $sourcesRoot,
  (sources) => sources.rules,
)

export const $sourcesFetching = createSelector(
  $sourcesRoot,
  (sources) => sources.fetching,
)

export const $sourcesIsLoading = createSelector(
  $sourcesFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)

export const $sourcesIsReady = createSelector(
  $sourcesFetching,
  (fetching) => fetching.status === fetchStatus.ready,
)

export const $connectionsLimitReached = createSelector(
  $userSubscription,
  $sources,
  (subscription, sources) => subscription.payment_integrations > -1 && sources.length >= subscription.payment_integrations,
)

export const $sourcesOptions = createSelector(
  $sources,
  $currentUser,
  (sources, user) => sources.map((source) => ({
    label: createSourceLabel(source, user.name),
    value: source.id,
    source: source.connection_source,
  })),
)

const createSourceLabel = (source, userName) =>
  `${source.connection_name || userName} (${capitalize(source.connection_source)})`

export const $processorsList = createSelector(
  $sources,
  $currentUser,
  $userSubscription,
  $userDatePrefs,
  (sources, user, subscription, { timezone, dateformat }) =>
    sources.map((source) => ({
      info: {
        sourceName: source.connection_source,
        name: source.connection_name || user.name,
        status: 'ACTIVE',
        createdAt: date(source.created_at, timezone, dateformat),
      },
      id: source.id,
      settings: source.settings,
    })),
)

export const $processorsSettings = createSelector(
  $sources,
  (sources) => sources.map((source) => ({
    id: source.id,
    processor: source,
    settings: source.settings,
  })),
)

export const $incompleteProcessors = createSelector(
  $sources,
  (sources) =>
    sources
      .filter((source) => isSourceSettingIncomplete(source.settings))
      .map((source) => ({
        link: `/configuration/${source.id}`,
        source: humanizeSource(source.connection_source),
      })),
)

const isSourceSettingIncomplete = (setting) =>
  !setting.bankfeeds_account
