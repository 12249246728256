import React from 'react'
import { Link } from 'react-router-dom'
import { QuestionIcon, TableLink, Text, TooltipContainer } from '@ui'

export const CellDescription = ({ cell }) => (
  <TableLink target="_blank" rel="noopener noreferrer" href={cell.value.url}>{cell.value.text}</TableLink>
)

export const CellId = ({ cell }) => (
  <TableLink as={Link} to={`/transactions/${cell.value}`}>{cell.value}</TableLink>
)

export const CellStatus = ({ cell }) => (
  <>
    {cell.value.error ? (
      <TooltipContainer
        tooltip={cell.value.error}
      >
        <div>
          <Text mr="6px">{cell.value.text}</Text>
          <QuestionIcon />
        </div>
      </TooltipContainer>
    ) : (
      <Text>{cell.value.text}</Text>
    )}
  </>
)
