import React from 'react'
import { useField } from 'formik'
import { theme } from 'styled-tools'
import styled from 'styled-components'

export const FieldErrorMark = ({ name }) => {
  const [, { touched, error }] = useField({ name })
  const showMark = touched && error
  return showMark ? <ErrorMark /> : null
}

const ErrorMark = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme('color.danger')};
`
