import React from 'react'
import * as P from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { ifProp, prop, switchProp, theme } from 'styled-tools'

export const Progress = ({ variant, size, dots, stripped, animation, value }) => (
  <Container>
    <Background dots={dots} size={size}>
      <ProgressBar
        role="progressbar"
        width={value}
        variant={variant}
        stripped={stripped}
        animation={animation}
      />
    </Background>
  </Container>
)

const Container = styled.div`
  padding-bottom: 16px;
`

const dots = css`
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -11px;
  background-image: repeating-linear-gradient(
    90deg,
    rgba(0,0,0,0.15),
    rgba(0,0,0,0.15) 2px,
    transparent 2px,
    transparent 12px
  );
`

const stripped = css`
  background-image: linear-gradient(
    45deg,
    rgba(255,255,255,.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255,255,255,.15) 50%,
    rgba(255,255,255,.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
`

const stripes = keyframes`
  0% {
    background-position: 1rem 0;
  }
  
  100% {
    background-position: 0 0;
  }
`

const animation = css`
  animation: ${stripes} 1s linear infinite;
`

const size = switchProp('size', {
  sm: 8,
  md: 16,
  lg: 24,
}, prop('size'))

const variant = switchProp('variant', {
  purple: theme('color.purple.dark'),
  danger: theme('color.danger'),
  primary: theme('color.primary'),
}, prop('variant'))

const Background = styled.div`
  display: flex;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 20px;
  position: relative;
  height: ${size}px;

  &:after {
    ${ifProp('dots', dots)}
  }
`

const ProgressBar = styled.div`
  color: white;
  text-align: center;
  white-space: nowrap;
  transition: width 1s ease-out;
  width: ${prop('width')}%;
  border-radius: 20px;

  background-color: ${variant};
  
  ${ifProp('stripped', stripped)}
  ${ifProp('animation', animation)}
`

Progress.propTypes = {
  variant: P.oneOfType([P.string, P.oneOf(['purple', 'danger', 'primary'])]),
  size: P.oneOf(['sm', 'md', 'lg']),
  value: P.oneOfType([P.number, P.string]),
  dots: P.bool,
  stripped: P.bool,
  animation: P.bool,
}

Progress.defaultProps = {
  variant: 'primary',
  size: 'md',
  value: 0,
  dots: false,
  stripped: false,
  animation: false,
}
