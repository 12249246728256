import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  list: [],
  fetching: initialFetching,
}

const symbiotes = {
  fetch: createFetching(),

  set: (state, list) => ({
    ...state,
    list,
  }),
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'notifications/list',
)
