import React from 'react'
import { VisibleForField } from '@ui'
import { InputTemplate, RulesContainer } from './shared'
import { Account } from './Account'
import { Track } from './Track'

export const TrackAccount = ({ name, account, options, label, placeholder, description, rulesDescription }) => (
  <>
    <InputTemplate>
      <Track
        name={name}
        account={account}
        label={label}
      />
    </InputTemplate>
    <VisibleForField name={name}>
      <InputTemplate>
        <RulesContainer>
          <Account
            account={account}
            options={options}
            placeholder={placeholder}
            description={description}
            rulesDescription={rulesDescription}
          />
        </RulesContainer>
      </InputTemplate>
    </VisibleForField>
  </>
)
