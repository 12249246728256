import styled from 'styled-components'
import { theme } from 'styled-tools'

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
`

export const InputPrepend = styled.div`
  border: 1px solid ${theme('color.border')};
  border-right-color: ${theme('color.lightGray')};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all .13s ease-in-out;
`

export const InputAppend = styled.div`
  background-color: ${theme('color.secondary')};
  border: 1px solid ${theme('color.border')};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
  transition: all .13s ease-in-out;
`
