import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from '@features/common'
import { accountFetch } from '@features/session'
import { notify } from '@lib/notification'
import { useRequest } from '@lib/fetching'
import { integrationDataReset, integrationSettingsUpdate } from '../../effects/integrations'
import { integrationsApi } from '../../api'
import { $integrationSettings } from '../../selectors/integration'

export const useSettingsModel = ({ settings }) => {
  const dispatch = useDispatch()
  const [autoReconcile, setAutoReconcile] = useState(settings.auto_reconcile)
  const [isActive, setActive] = useState(settings.is_active)
  const updateSettingsRequest = useRequest((values) => dispatch(integrationSettingsUpdate(values)))
  const deleteConnectionRequest = useRequest(integrationsApi.disconnect)
  const deleteModal = useModal()
  const integration = useSelector($integrationSettings)

  const updateSettings = async (values) => {
    try {
      await updateSettingsRequest(values)
      notify({
        message: 'Settings updated',
      })
    } catch (err) {
      notify({
        type: 'danger',
        message: 'Something went wrong',
      })
    }
  }
  
  const updateAutoReconcile = (e) => {
    const value = e.target.checked
    setAutoReconcile(value)
    return updateSettings({
      auto_reconcile: value,
    })
  }

  const updateIsActive = (e) => {
    const value = e.target.checked
    setActive(value)
    return updateSettings({
      is_active: value,
    })
  }

  const deleteConnection = async () => {
    try {
      await deleteConnectionRequest()
      deleteModal.close()
      dispatch(integrationDataReset())
      dispatch(accountFetch())
    } catch (err) {
      notify({
        type: 'danger',
        message: 'Something went wrong',
      })
    }
  }

  return {
    integration,
    autoReconcile,
    isActive,
    updateAutoReconcile,
    updateIsActive,
    updateSettingsRequest,
    deleteConnection,
    deleteConnectionRequest,
    deleteModal,
  }
}
