import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import querystring from 'query-string'
import { useAuth0 } from '@lib/auth0'
import { history } from '@lib/routing'
import { createProfitwell } from '@lib/extra'
import { FullScreenSpinner } from '@ui'
import { MfaPage } from '@features/account'
import { Setup } from '@features/setup'
import { initialDataFetch } from '@features/session'
import { $authUserId, $currentUser, $userLoading, connectApi, useConnectRequest } from '@features/common'
import { tokenSet } from '../effects'

export const AccountLoader = ({ children }) => {
  const dispatch = useDispatch()
  const userId = useSelector($authUserId)
  const userLoading = useSelector($userLoading)
  const user = useSelector($currentUser)
  const { getTokenSilently } = useAuth0()

  useEffect(() => {
    getTokenSilently().then(async (token) => {
      dispatch(tokenSet(token))

      await dispatch(initialDataFetch())
    })
    // eslint-disable-next-line
  }, [dispatch])

  if (userLoading || !user) {
    return <FullScreenSpinner />
  }

  if (user.require_2fa && !user.authenticated_2fa) {
    return (
      <MfaPage />
    )
  }

  return userId ? children : null
}

const UserRedirects = () => {
  const { user: auth0User } = useAuth0()
  const user = useSelector($currentUser)

  useEffect(() => {
    if (!auth0User.email_verified) {
      return history.push('/verify-email')
    }

    const searchParams = querystring.parse(history.location.search)

    // Redirect user to checkout page.
    if ('plan' in searchParams) {
      return history.push(`/checkout?plan=${searchParams.plan}`)
    }

    createProfitwell(user.name, auth0User.email)
    // eslint-disable-next-line
  }, [])

  return null
}

const connectShopify = (shop) => connectApi.connectShopify(shop)

const AutoLogin = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect({
      appState: {
        targetUrl: `${history.location.pathname}${history.location.search}`,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const Auth0Loader = ({ children }) => {
  const { loading, user } = useAuth0()

  const shopifyRequest = useConnectRequest(connectShopify, {
    redirect: true,
  })

  useEffect(() => {
    const searchParams = querystring.parse(history.location.search)

    if ('shop' in searchParams && !('code' in searchParams)) {
      shopifyRequest(searchParams.shop)
    }
  // eslint-disable-next-line
  }, [])

  if (loading || shopifyRequest.isLoading) {
    return <FullScreenSpinner />
  }

  if (!user) {
    if (history.location.pathname === '/shopify/callback') {
      return <Setup />
    }
    return <AutoLogin />
  }

  return (
    <AccountLoader>
      <UserRedirects />
      {children}
    </AccountLoader>
  )
}
