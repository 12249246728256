import { history } from '@lib/routing'
import { initialDataFetch } from '@features/session'
import { createCsvDownload } from '@lib/file-download'
import { api } from './api'

export const userImpersonate = (userId) => async (dispatch) => {
  await api.impersonate(userId)
  history.push('/')

  dispatch(initialDataFetch())
}

export const downloadUserReport = async (userId) => {
  const { data } = await api.getTransactionsReport(userId)
  createCsvDownload(data)
}
