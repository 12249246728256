import React, { useState } from 'react'
import { LoadingButton } from '@ui'
import styled from 'styled-components'
import { useAuth0 } from '@lib/auth0'
import { Storage } from '@lib/storage'

import { NewUserLayout } from '../components/NewUserLayout'

const InputContainer = styled.div`
  margin-bottom: 28px;
`

export const Login = () => {
  const auth0 = useAuth0()
  const [loggingIn, setLoggingIn] = useState(false)
  const fromShopify = Storage.get('from_shopify') || false

  const login = () => {
    setLoggingIn(true)
    auth0.loginWithRedirect()
  }

  return (
    <NewUserLayout
      title={fromShopify ? 'Welcome, Shopify user!' : 'Welcome to BankFeeds!'}
      subtitle={fromShopify ? (
        <>
          BankFeeds allows you to sync your Shopify transactions into Xero. To proceed, you'll need a BankFeeds account. You can register or login with the button below. Once you've registered, you'll be asked to choose one of our plans (all plans have a 14-day trial) and connect a Xero account.
        </>
      ) : (
        <>
          Please press the button below to login or create a new account.
        </>
      )}
    >
      <InputContainer>
        <LoadingButton
          onClick={login}
          disabled={loggingIn}
          loading={loggingIn}
          size="full"
          type="button"
        >
          LOG IN / REGISTER
        </LoadingButton>
      </InputContainer>
    </NewUserLayout>
  )
}
