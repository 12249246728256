export const pageSizes = {
  users: 25,
}

export const defaultSort = {
  users: {
    id: 'name',
    desc: true,
  },
}
