import React from 'react'
import { terms } from '../../../lib/constants'
import { Button, DateTimeRangePicker, FormRadio, ListDivider, SortAscListItem, SortDescListItem } from '../../index'
import { useSingleChoiceFilter } from './hooks'
import { FilterListTemplate, FooterListItem, FormListItem } from './shared-components'

export const DateTableFilter = ({ options, column, column: { setFilter, filterValue = {} }, onClose }) => {
  const {
    apply,
    reset,
    setLocalFilter,
    isEqual,
    localFilterValue,
  } = useSingleChoiceFilter({ filterValue, setFilter, onClose })

  const changeByCustomDateRange = ([from, to]) => {
    if (from && to) {
      setLocalFilter({
        term: terms.CUSTOM,
        gte: new Date(from).toISOString(),
        lte: new Date(to).toISOString(),
      })
    }
  }

  return (
    <FilterListTemplate style={{ width: 250 }}>
      {options.map(({ label, value }) => (
        <FormListItem key={label}>
          <FormRadio
            label={label}
            name={value}
            checked={value === (localFilterValue.term)}
            onChange={() => setLocalFilter({ term: value })}
          />
        </FormListItem>
      ))}
      {localFilterValue.term === terms.CUSTOM && (
        <FormListItem>
          <DateTimeRangePicker
            position="top"
            showTime={false}
            from={toUnix(filterValue.gte)}
            to={toUnix(filterValue.lte)}
            onChange={changeByCustomDateRange}
          />
        </FormListItem>
      )}
      <ListDivider mt="12px" />
      <SortDescListItem column={column} title="Sort Newest to Oldest" onClose={onClose} />
      <SortAscListItem column={column} title="Sort Oldest to Newest" onClose={onClose} />
      <ListDivider />
      <FooterListItem>
        <Button variant="link" disabled={!filterValue} onClick={reset}>RESET</Button>
        <Button variant="link" disabled={isEqual} onClick={apply}>APPLY</Button>
      </FooterListItem>
    </FilterListTemplate>
  )
}

const toUnix = (dateStr) => dateStr ? new Date(dateStr).valueOf() : null
