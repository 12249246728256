import React from 'react'
import { H3, ErrorBox } from '@ui'

export const mapErrorResponseToFormErrors = (response) => {
  switch (response.status) {
    case 422:
      return response.data.errors.map((error, index) => (
        <div key={index}>{error.message}</div>
      ))
    case 400:
      return response.data.error
    default:
      return 'Got an unexpected server error. Try again later.'
  }
}

export const FormApiError = ({ error }) => (
  <>
    <H3>Validation errors from server</H3>
    <br />
    <ErrorBox>
      {mapErrorResponseToFormErrors(error)}
    </ErrorBox>
  </>
)
