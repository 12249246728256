import React from 'react'
import { Interactivity, PaginationBlock, SortableHeader, Table, TableBody, TableHead } from '@ui'
import { useTablePagination } from '@lib/table-hooks'

export const UsersTable = ({ table, isLoading, count }) => {
  const pagination = useTablePagination(table, count)

  return (
    <Interactivity off={isLoading}>
      <Table {...table.getTableProps()} responsive={false}>
        <TableHead
          headerGroups={table.headerGroups}
          renderColumnHeader={(column) => (
            <SortableHeader
              key={column.id}
              column={column}
            />
          )}
        />
        <TableBody
          rows={table.rows}
          prepareRow={table.prepareRow}
        />
      </Table>
      <PaginationBlock
        pagination={pagination}
      />
    </Interactivity>
  )
}
