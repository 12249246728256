import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useAuth0 } from '@lib/auth0'
import { theme } from 'styled-tools'
import { Guard } from '@features/common'
import { accountReset } from '@features/session'
import { Icon, NavLink } from '@ui'
import { $currentUser, $hasPendingJobs } from '../selectors'
import { ConnectXeroBlock } from '../components/ConnectXeroBlock'

const Nav = styled.div`
  position: fixed;
  width: 272px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: ${theme('color.lightGray')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: ${theme('order.sidebar')};
`

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
`

const Separator = styled.div`
  border-bottom: 1px solid #D6DDE6;
  margin: 12px 0;
`

const BottomNav = styled.div`
  margin-bottom: 32px;
`

const LogoImage = styled.img`
  width: 250px;
`

const UnreadIndicator = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme('color.danger')};
`

export const Sidebar = () => {
  const user = useSelector($currentUser)
  const hasPendingJobs = useSelector($hasPendingJobs)
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  const logoutUser = (e) => {
    e.preventDefault()
    dispatch(accountReset())
    logout()
  }

  return (
    <Nav role="navigation">
      <div>
        <LogoImage src="/images/bf_logo_light_transparent.svg" alt="BankFeeds.io" />
        <NavLink.Header>
          <ProfileImage src={user.picture} />
          <NavLink.Text>
            {user.name}
          </NavLink.Text>
        </NavLink.Header>
        <Guard isAdmin>
          <NavLink to="/admin" icon="rows">
            Admin
          </NavLink>
        </Guard>
        <NavLink exact to="/" icon="dashboard">
          Dashboard
        </NavLink>
        <NavLink to="/transactions" icon="transactions">
          Transactions
        </NavLink>
        <NavLink to="/imports" icon="import" indicator={hasPendingJobs && <UnreadIndicator />}>
          Data Import
        </NavLink>
        <Separator />
        <NavLink to="/integrations" icon="integrations">
          Integrations
        </NavLink>
        <Guard hasIntegration>
          <NavLink to="/configuration" icon="pay">
            Configuration
          </NavLink>
        </Guard>
        <Separator />
        {/*
        <NavLink to="/notifications" icon="notification" indicator={<UnreadIndicator />}>
          Notifications
        </NavLink>
        */}
        <NavLink to="/settings" icon="settings">
          Account Settings
        </NavLink>
        <Guard noIntegration>
          <ConnectXeroBlock />
        </Guard>
      </div>
      <BottomNav>
        <Guard isAdmin>
          <NavLink to="/transactions/reconciliation" icon="settings">
            Accounts Reconciliation
          </NavLink>
          <NavLink to="/transactions/duplicate-detection" icon="settings">
            Duplicate Detection
          </NavLink>
        </Guard>
        <Guard isAdmin>
          <NavLink to="/ui-kit" icon="settings">
            UI Kit
          </NavLink>
        </Guard>
        <NavLink.Item as="a" href="/" onClick={logoutUser}>
          <Icon name="logout" variant="gray" width={18} />
          <NavLink.Text>Log out</NavLink.Text>
        </NavLink.Item>
      </BottomNav>
    </Nav>
  )
}
