import React from 'react'
import { ApiError } from '@features/common'
import { Card, Spinner, H2 } from '@ui'

export const CallbackView = ({ isLoading, error, params }) => {
  const paramsKeys = Object.keys(params)
  const paramsIsValid = Object.values(params).every(Boolean)

  return (
    <>
      {isLoading && <Spinner />}
      {paramsIsValid || <MissingParamsError params={paramsKeys} />}
      {error && (
        <ApiError
          message={error.error}
          reference={error.referenceNumber}
        />
      )}
    </>
  )
}

const MissingParamsError = ({ params }) => (
  <Card mb="1rem">
    <H2>The following params are missing in query: {params.join(', ')}</H2>
  </Card>
)
