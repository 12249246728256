import { isEmpty } from '@lib/is'

/**
 * Groups items in array by specified key.
 * @param {Array} array
 * @param {function} fn
 * @returns {Object}
 * @example
 * groupBy([{id: 1, name: 'foo'}], (item) => item.id) // { 1: { id: 1, name: 'foo' } } // group by "id"
 * groupBy([{id: 1, name: 'foo'}], (item) => [item.id, item.name]) // { 1: 'foo' } // group by "id" and take "name" as value
 */
export const groupBy = (array, fn) => array.reduce((object, item, index, array) => {
  // skip empty values
  if (isEmpty(item)) {
    return object
  }

  const [key, value = item] = wrapArray(fn(item, index, array))
  object[key] = value
  return object
}, {})

export const wrapArray = (val) => Array.isArray(val) ? val : [val]

export const range = (n) => Array.from({ length: n }, (_, x) => x)

export const moveItemToHead = (array, id) => {
  const found = array.find((item) => item.id === id)
  const rest = array.filter((item) => item.id !== id)
  return found ? [found, ...rest] : rest
}

/***
 * @param array
 * @param item
 * @param {"add"|"remove"} operation
 */
export const updateWith = (array, item, operation = 'add') =>
  operation === 'add'
    ? addUnlessExists(array, item)
    : remove(array, item)

const addUnlessExists = (array, item) => {
  const exists = array.includes(item)
  return exists ? array : array.concat(item)
}

const remove = (array, itemToRemove) => array.filter((item) => item !== itemToRemove)

/**
 * Checks whether two arrays are equal.
 * 
 * @param {[]} first
 * @param {[]} second
 * @return {boolean}
 */
export const arraysEqual = (first, second) => (
  first.every((item) => second.includes(item)) &&
  second.every((item) => first.includes(item))
)

/**
 * Immutable sorts an array of objects by specified key.
 *
 * @param {[]} array
 * @param {string} key
 * @param {boolean} isDesc
 * @return {[]}
 */
export const sortBy = (array, key, isDesc = false) => {
  const sorted = [...array].sort((a, b) => String(a[key]).localeCompare(b[key]))
  return isDesc ? sorted.reverse() : sorted
}

/**
 * Calculates the array total by summing each item.
 *
 * @param {[]} array
 * @param {function} fn - specify to get item property to sum with total.
 * @returns {number}
 */
export const arrayTotal = (array, fn = null) =>
  array.reduce((total, cur) => total + (fn ? fn(cur) : cur), 0)

/**
 * Splits source array into groups of arrays.
 * @param {[]} source
 * @param {function} fn - Returns an array of [groupName, item] for passed item.
 * @param defaultValue
 * @returns {*}
 */
export const splitArray = (source, fn, defaultValue = {}) =>
  source.reduce(
    (results, item) => {
      const [groupName, mappedItem] = wrapArray(fn(item))
      const group = results[groupName] || []

      return {
        ...results,
        [groupName]: group.concat([mappedItem]),
      }
    },
    defaultValue,
  )
