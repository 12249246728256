import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { history } from '@lib/routing'
import { MainTemplate } from '@features/common'
import { initialDataFetch } from '@features/session'
import { Logo, sourcesFetch } from '@features/integrations'
import { Button, Flex, H1, List, ListItem } from '@ui'
import { CallbackView } from '../components/CallbackView'
import { api } from '../api'

export const XeroCallbackPage = () => {
  const dispatch = useDispatch()
  const params = useRef({
    url: window.location.href,
  })
  const paramsIsValid = Object.values(params.current).every(Boolean)

  const [credentials, setCredentials] = useState(null)
  const [tenants, setTenants] = useState(null)

  const connectRequest = useRequest(api.connectXero)

  const complete = useCallback(() => {
    dispatch(initialDataFetch())
    dispatch(sourcesFetch())
    history.push('/integrations')
  }, [dispatch])

  const connectTenant = async (tenantId) => {
    setCredentials(null)
    setTenants(null)

    await connectRequest({
      credentials,
      tenant: tenantId,
    })

    complete()
  }

  useEffect(() => {
    const connect = async () => {
      const response = await connectRequest(params.current)

      if (response.tenants && response.credentials) {
        setCredentials(response.credentials)
        setTenants(response.tenants)
      } else {
        complete()
      }
    }

    if (paramsIsValid) {
      connect()
    }
  }, [complete, connectRequest, dispatch, paramsIsValid])

  if (credentials && tenants) {
    return (
      <MainTemplate heading={<H1>Which organization would you like to connect?</H1>}>
        <List gap="8px" bordered={false}>
          {tenants.map(({ tenantId, orgData: { name } }) => (
            <ListItem bordered key={tenantId}>
              <Flex justify="space-between">
                <Logo name="xero" title={name} />
                <Button
                  variant="outline"
                  onClick={() => connectTenant(tenantId)}
                >
                  CONNECT
                </Button>
              </Flex>
            </ListItem>
          ))}
        </List>
      </MainTemplate>
    )
  }

  return (
    <MainTemplate>
      <CallbackView
        isLoading={connectRequest.isLoading}
        error={connectRequest.error}
        params={params}
      />
    </MainTemplate>
  )
}
