import styled from 'styled-components'
import * as P from 'prop-types'
import space from '@styled-system/space'
import { switchProp, theme } from 'styled-tools'
import { spacePT, headingVariantsPT } from './prop-types'

export const Text = styled.span`
  display: inline-block;
  line-height: 1.55;
  color: ${(p) => theme(`heading.${p.variant}`, p.variant)};
  font-size: ${switchProp('size', {
    sm: 10,
  }, 13)}px;

  ${space};
`

Text.propTypes = {
  variant: P.oneOfType([headingVariantsPT, P.string]),
  size: P.oneOf(['sm', 'md']),
  ...spacePT,
}
