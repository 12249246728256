import { events, fields } from './constants'

export const ruleToFormData = (rule) => {
  const { conditions, event } = rule

  const mapParams = () => {
    const { type, params } = event

    switch (type) {
      case events.EXPORT:
        return {
          account: params.account,
          code: params.code,
          taxType: null,
        }
      case events.CODE_WITH_TAX:
        return {
          account: null,
          code: null,
          taxType: params.taxType,
        }
      case events.IGNORE:
      default:
        return {
          account: null,
          code: null,
          taxType: null,
        }
    }
  }

  return {
    conditions: conditions.all.map(({ path, operator, value }) => ({
      field: pathToField(path),
      operator,
      value,
    })),
    event: event.type,
    ...mapParams(),
  }
}

export const formDataToRule = (data, meta = {}) => {
  const { conditions, event, account, code, fromAccount, toAccount, taxType } = data

  const mapParams = () => {
    switch (event) {
      case events.EXPORT:
        if (meta.account === 'payouts_account') {
          return { fromAccount, toAccount }
        }

        return { account, code }
      case events.CODE_WITH_TAX:
        return { taxType }
      case events.IGNORE:
      default:
        return {}
    }
  }

  return {
    meta: {
      account: meta.account,
      position: meta.position,
    },
    conditions: {
      all: conditions.map(({ field, operator, value }) => ({
        fact: 'transaction',
        path: fieldToPath(field),
        operator,
        value,
      })),
    },
    event: {
      type: event,
      params: mapParams(),
    },
  }
}

export const fieldToPath = (field) => {
  if (!fieldToPathObj[field])
    throw new Error(`Could not found field "${field}", available: ${Object.keys(fieldToPathObj).join(', ')}`)
  return fieldToPathObj[field]
}

export const pathToField = (path) => {
  if (!pathToFieldObj[path])
    throw new Error(`Could not found path "${path}", available: ${Object.values(fieldToPathObj).join(', ')}`)
  return pathToFieldObj[path]
}

const fieldToPathObj = {
  [fields.CURRENCY]: '$.currency',
  [fields.COUNTRY]: '$.customer.country',
  [fields.DESCRIPTION]: '$.description',
  [fields.AMOUNT]: '$.amount',
}

const pathToFieldObj = Object.entries(fieldToPathObj).reduce((result, [field, path]) => {
  result[path] = field
  return result
}, {})

export const ruleEqualsMeta = (rule, { account, position }) =>
  rule.meta.account === account &&
  rule.meta.position === position

export const rulesEqual = (one, two) =>
  ruleEqualsMeta(one, two.meta)

export const conditionsEqual = (one, two) =>
  one.field === two.field &&
  one.operator === two.operator &&
  one.value === two.value
