import { request } from '@features/common'

export const api = {
  getList: (params) => request('GET', '/transactions', { params }),

  getById: (id) => request('GET', `/transactions/${id}`),

  getImportingList: (importId, params) => request('GET', '/transactions/importing', {
    params: {
      ...params,
      importId,
    },
  }),

  export: (filters, data) => request('POST', '/transactions/export', { params: { filters }, data }),

  resync: (filters, data) => request('POST', '/transactions/resync', { params: { filters }, data }),

  unqueue: (filters, data) => request('POST', '/transactions/unqueue', { params: { filters }, data }),

  delete: (filters, data) => request('DELETE', '/transactions', { params: { filters }, data }),

  duplicateDetection: (account, page) => request('GET', '/xero/duplicate-detection', { params: { account, page } }),

  duplicateDetectionRemoval: (ids) => request('DELETE', '/xero/duplicate-detection', { data: { ids } }),

  accountsReconciliation: (account, page) => request('GET', '/xero/accounts-reconciliation', { params: { account, page } }),

  requeueFailed: () => request('POST', '/transactions/requeue-failed'),
}

export const xeroApi = {
  getBalances: () => request('GET', '/xero/balances'),
}
