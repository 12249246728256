import React from 'react'
import { useSelector } from 'react-redux'
import { useGuard } from '@features/common'
import { SelectField } from '@ui'
import { Description } from './shared'
import { useAccountPlaceholder } from '../../hooks'
import { $isIntegrationExpired } from '../../selectors/integration'

export const Account = ({ account, options, placeholder, description, rulesDescription }) => {
  const hasRulesEngine = useGuard({ rulesEngine: true })
  const accountDescription = hasRulesEngine ? (rulesDescription || description) : description
  const accountPlaceholder = useAccountPlaceholder(placeholder)
  const isIntegrationExpired = useSelector($isIntegrationExpired)

  return (
    <>
      <SelectField
        name={account}
        placeholder={accountPlaceholder}
        options={options}
        disabled={isIntegrationExpired}
        isSearchable
        isClearable
      />
      <Description>
        {accountDescription}
      </Description>
    </>
  )
}
