import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Icon } from '@ui'

export const DraggableButton = ({ onClick, isDragged, hidden = false }) => (
  <HiddenButton onClick={onClick} isDragged={isDragged} type="button" data-movable-handle hidden={hidden}>
    <Icon name="reorder" variant="gray" />
  </HiddenButton>
)

const HiddenButton = styled.button`
  height: 40px;
  appearance: none;
  border: 0;
  padding: 0 8px;
  background: none;
  cursor: ${ifProp('isDragged', 'grabbing', 'grab')} !important;
  display: ${ifProp('hidden', 'none', 'block')};
`

export const DraggableItem = forwardRef(({ children, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    style={{
      ...props.style,
      zIndex: 'initial',
      outline: 'none',
      padding: '8px 0',
    }}
  >
    {children}
  </div>
))

