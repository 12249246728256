import React from 'react'
import * as P from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Icon } from './Icon'

export const TagLabel = ({ children, onClose }) => (
  <Container>
    <Content>{children}</Content>
    <Close onClick={onClose}>
      <Icon name="cancel" width={10} height={10} strokeWidth={2} />
    </Close>
  </Container>
)

const Close = styled.span``
const Content = styled.span``

const Container = styled.div`
  display: inline-flex;
  border: 1px solid ${theme('color.gray')};
  border-radius: 3px;
  justify-content: space-around;
  align-items: center;
  padding-left: 8px;
  text-transform: lowercase;

  ${Content} {
    padding: 6px 0;
  }

  ${Close} {
    padding: 6px;
    line-height: 13px;
    cursor: pointer;
  }
`

TagLabel.propTypes = {
  children: P.node.isRequired,
  onClose: P.func,
}

TagLabel.defaultProps = {
  onClose: () => {},
}
