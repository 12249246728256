import styled, { css } from 'styled-components'
import { ifProp, palette, switchProp, theme } from 'styled-tools'
import * as P from 'prop-types'

export const baseCss = css`
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: ${theme('color.inputText')};
  border: 1px solid ${theme('color.border')};
  border-radius: 3px;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  outline: none;
  transition:
    color .15s ease-in-out,
    border-color .15s ease-in-out,
    background-color .15s ease-in-out;

  padding: ${switchProp('size', {
    lg: 'calc(1rem - 1px)',
  }, 'calc(0.75rem - 1px)')} 1rem;
`

export const Input = styled.input`
  ${baseCss};

  ::placeholder {
    color: ${theme('color.gray')};
  }

  &:focus {
    border-color: ${theme('color.gray')};
 }

  &:disabled {
    color: ${palette('gray', 1)};
    background-color: ${theme('color.secondary')};
}

  ${ifProp('error', css`
    border-color: ${theme('color.danger')} !important;
  `)};  
`

Input.baseCss = baseCss

export const propTypes = {
  autoComplete: P.string,
  disabled: P.bool,
  name: P.string,
  onBlur: P.func,
  onChange: P.func,
  placeholder: P.string,
  type: P.string,
  value: P.any,
  defaultValue: P.any,
  size: P.oneOfType([P.string, P.number]),
}

Input.propTypes = propTypes
