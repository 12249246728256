import { createSelector } from 'reselect'
import { createFetchingSelectors } from '@lib/fetching'
import { date } from '@lib/date'
import { humanizeImportJobStatus, humanizeSource } from '@lib/constants'
import { $currentUser, $userDatePrefs } from '@features/common'
import { $sourcesById } from '@features/integrations'

export const $importJobs = (root) => root.importJobs

export const $fetching = createSelector(
  $importJobs,
  ({ fetching }) => fetching,
)

export const $importJobsList = createSelector(
  $importJobs,
  ({ list }) => list,
)

export const $pageCount = createSelector(
  $importJobs,
  ({ pageCount }) => pageCount,
)

export const $count = createSelector(
  $importJobs,
  ({ count }) => count,
)

export const $totalCount = createSelector(
  $importJobs,
  ({ totalCount }) => totalCount,
)

export const {
  $isLoading,
} = createFetchingSelectors($fetching)

export const $isInitialLoading = createSelector(
  $isLoading,
  $totalCount,
  (isLoading, totalCount) => isLoading && totalCount === null,
)

export const $importJobsForTable = createSelector(
  $importJobsList,
  $sourcesById,
  $currentUser,
  $userDatePrefs,
  (importJobs, sourcesById, user, { timezone, dateformat }) =>
    importJobs.map(({ connection_id, status, from, to, total }) => {
      const source = sourcesById[connection_id] || {}

      return {
        processor: humanizeSource(source.connection_source || 'N/A'),
        account: source.connection_name || user.name,
        rangeDate: `${date(from, timezone, dateformat)} - ${date(to, timezone, dateformat)}`,
        status: humanizeImportJobStatus(status),
        total: total === null ? 'N/A' : total,
      }
    }),
)
