import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import * as P from 'prop-types'
import useOnClickOutside from 'use-onclickoutside'
import { FadeWrapper } from './FadeWrapper'
import { Backdrop, Content, Body, Footer, Text, Title, Link } from './styled'

export const Modal = ({ children, size, show, bgTransparent, onClose }) => {
  const rootElement = document.querySelector('#modal')
  const ref = useRef(null)

  useOnClickOutside(ref, onClose)

  const closeOnEsc = (e) => e.code === 'Escape' && onClose()

  useEffect(() => {
    window.addEventListener('keydown', closeOnEsc)
    return () => window.removeEventListener('keydown', closeOnEsc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return rootElement &&
    createPortal(
      <FadeWrapper show={show}>
        <Backdrop />
        <Content ref={ref} size={size} transparent={bgTransparent}>
          {children}
        </Content>
      </FadeWrapper>,
      rootElement,
    )
}

Modal.propTypes = {
  children: P.node,
  show: P.bool.isRequired,
  bgTransparent: P.bool,
  size: P.oneOf(['md', 'sm', 'lg']),
  onClose: P.func.isRequired,
}

Modal.defaultProps = {
  size: 'md',
  bgTransparent: false,
}

Modal.Title = Title
Modal.Body = Body
Modal.Footer = Footer
Modal.Text = Text
Modal.Link = Link
