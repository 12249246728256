import React from 'react'
import * as yup from 'yup'
import { Form as FormikForm, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Button, ErrorBox, FormGroup, InputField, Link, LoadingButton, Modal } from '@ui'
import { useRequest } from '@lib/fetching'
import { history } from '@lib/routing'
import { connectApi } from '@features/common'
import { accountFetch } from '@features/session'
import { sourcesFetch } from '../effects/sources'

export const PinPaymentsConnectModal = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const connectRequest = useRequest(connectApi.connectPin)

  const handleSubmit = async ({ token }, { resetForm }) => {
    const { data } = await connectRequest(token)
    resetForm()
    onClose()
    dispatch(accountFetch())
    dispatch(sourcesFetch())
    history.push(`/configuration/${data.id}`)
  }

  return (
    <Formik
      initialValues={{ token: '' }}
      onSubmit={handleSubmit}
      validationSchema={yup.object().shape({
        token: yup.string().required('Token is required'),
      })}
    >
      <Modal show={show} onClose={onClose}>
        <Modal.Title>Pin Payments</Modal.Title>
        <FormikForm>
          <Modal.Body>
            {connectRequest.error && (
              <ErrorBox>{connectRequest.error.error}</ErrorBox>
            )}
            <FormGroup>
              <InputField
                name="token"
                label={<>
                  Please enter your api secret token. It can be found{' '}
                  <Link target="_blank" href="https://dashboard.pinpayments.com/api_keys">here</Link>.
                </>}
                placeholder="Your api secret"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
            <LoadingButton size="md" type="submit" loading={connectRequest.isLoading}>ADD</LoadingButton>
          </Modal.Footer>
        </FormikForm>
      </Modal>
    </Formik>
  )
}
