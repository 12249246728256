import React, { useState } from 'react'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { theme } from 'styled-tools'
import * as P from 'prop-types'
import { Icon } from './Icon'
import { Input } from './Input'
import { InputGroup } from './InputGroup'
import { ZeroButton } from './Buttons'
import { InputPositioner, Positioner } from './Positioner'

export const FilterSearch = ({ onChange }) => {
  const [value, setValue] = useState('')
  const [debouncedOnChange] = useDebouncedCallback(onChange, 1000)

  const update = (event) => {
    const val = event.target.value
    setValue(val)
    debouncedOnChange(val.trim())
  }

  const reset = () => {
    setValue('')
    onChange('')
  }

  return (
    <Container>
      <InputGroup>
        <Input
          size="lg"
          value={value}
          onChange={update}
          placeholder="Search"
        />
        <InputPositioner position="left">
          <Icon name="search" variant="gray" />
        </InputPositioner>
        {value && (
          <Positioner position="right">
            <ZeroButton onClick={reset}>
              <Icon name="cancel" width={12} height={12} variant="gray"  />
            </ZeroButton>
          </Positioner>
        )}
      </InputGroup>
    </Container>
  )
}

const Container = styled(InputGroup)`
  ${InputPositioner} {
    pointer-events: none;
  }

  ${Input} {
    padding-left: 2.5rem;
    
    background-color: ${theme('color.lightGray2')};
    border-color: ${theme('color.lightGray2')};

    &:focus {
      background-color: white;
      border: 1px solid ${theme('color.border')};
    }
  }
`

FilterSearch.propTypes = {
  onChange: P.func.isRequired,
}
