import React from 'react'
import { Button, CenterContent, LoadingButton, Modal } from '@ui'

export const DeleteCardModal = ({ onConfirm, onClose, isLoading, show }) => (
  <Modal onClose={onClose} size="sm" show={show}>
    <CenterContent>
      <Modal.Title>
        Delete a card?
      </Modal.Title>
      <Modal.Body>
        <Modal.Text>
          Are you sure you want to delete this payment method?
        </Modal.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" size="full" onClick={onClose}>CANCEL</Button>
        <LoadingButton size="full" onClick={onConfirm} loading={isLoading}>DELETE</LoadingButton>
      </Modal.Footer>
    </CenterContent>
  </Modal>
)
