import React from 'react'
import { useField } from 'formik'
import { ErrorLabel } from '../Label'

export const FieldError = ({ name }) => {
  const [, { touched, error }] = useField({ name })
  const showError = touched && error

  return showError
  && typeof error === 'string' // ignore nested errors
    ? <ErrorLabel>{error}</ErrorLabel>
    : null
}
