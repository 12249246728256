import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import config, { DIMENSIONS } from '../config'

export const Col = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;

  ${(p) => !p.noGutter && css`
    padding-right: ${config(p).gutterWidth / 2}px;
    padding-left: ${config(p).gutterWidth / 2}px;
  `}
  ${(p) => css`
    ${DIMENSIONS.map((d) =>
    config(p).breakpoints[d] && config(p).media[d]`
      ${p[d] && `
        flex-basis: ${(p[d] / config(p).columns[d]) * 100}%;
        max-width: ${(p[d] / config(p).columns[d]) * 100}%;
      `}
    `)}
  `}
  ${(p) => p.offset && css`
    ${DIMENSIONS.map((d) =>
    config(p).breakpoints[d] && config(p).media[d]`
    ${typeof p.offset === 'object'
    ? p.offset[d] !== undefined && `margin-left: ${p.offset[d] > 0 ? (p.offset[d] / config(p).columns[d]) * 100 : 0}%`
    : `margin-left: ${(p.offset / config(p).columns['xs']) * 100}%`};
    `)}
  `}
  ${(p) => p.reverse && css`
    ${Array.isArray(p.reverse)
    ? DIMENSIONS.map((d) => config(p).breakpoints[d] && config(p).media[d]`
        flex-direction: ${p.reverse.indexOf(d) !== -1 ? 'column-reverse' : 'column'};
      `)
    : 'flex-direction: column-reverse;'}
  `}
`

const numberOrString = P.oneOfType([
  P.string,
  P.number,
])

const numberOrObject = P.oneOfType([
  P.bool,
  P.object,
])

const boolOrArray = P.oneOfType([
  P.bool,
  P.array,
])

Col.propTypes = {
  xs: numberOrString,
  sm: numberOrString,
  md: numberOrString,
  lg: numberOrString,
  xl: numberOrString,
  offset: numberOrObject,
  reverse: boolOrArray,
  noGutter: P.bool,
  children: P.node,
}
