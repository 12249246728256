import { handleFetching } from '@lib/fetching'
import { importJobStatuses } from '@lib/constants'
import { notify } from '@lib/notification'
import { toUtcDate } from '@lib/date'
import { $currentUser } from '@features/common'
import { currentImportActions, importJobsActions, transactionsActions } from '../symbiotes'
import { $latestActiveImportJob } from '../selectors/active-import-jobs'
import { api } from '../api'
import { pageSizes } from '../constants'
import { $currentImport } from '../selectors/current-import'
import { importingTransactionsFetchAll } from './transactions'
import { currentImportInit } from './current-import'

export const importInitialize = () => async (dispatch, getState) => {
  await dispatch(activeJobsFetch())
  const importJob = $latestActiveImportJob(getState())

  if (importJob) {
    dispatch(currentImportInit(importJob))

    // Skip fetching transactions for in-progress job
    if (importJob.status !== importJobStatuses.IN_PROGRESS) {
      dispatch(importingTransactionsFetchAll())
    }
  }
}

export const importRefresh = () => async (dispatch, getState) => {
  await dispatch(activeJobsFetch())
  const importJob = $latestActiveImportJob(getState())

  if (importJob && importJob.status !== importJobStatuses.IN_PROGRESS) {
    dispatch(importingTransactionsFetchAll())
  }
}

export const importJobsFetch = (params = {}) =>
  handleFetching(importJobsActions.fetch, {
    async run(dispatch) {
      const { data, count, totalCount } = await api.getList(params)
      const pageSize = params.pageSize || pageSizes.importJobs
      const pageCount = Math.ceil(count / pageSize)

      dispatch(importJobsActions.set({ data, count, totalCount, pageCount }))
    },
  })

export const activeJobsFetch = () => handleFetching(importJobsActions.active.fetch, {
  async run(dispatch) {
    const { data } = await api.getActive()
    dispatch(importJobsActions.active.set(data))
  },
})

export const importJobCreate = () => async (dispatch, getState) => {
  const user = $currentUser(getState())
  const { startDate, endDate, connection, duplicateCheck } = $currentImport(getState())

  // DatePicker works with unix dates so we loose info about timezone offset.
  // We need to convert dates in UTC timezone.
  const from = toUtcDate(startDate, user.timezone).toISOString()
  const to = toUtcDate(endDate, user.timezone).toISOString()

  try {
    const { data: importJob } = await api.create({ from, to, connection, duplicateCheck })
    dispatch(importJobsActions.active.set([importJob]))

    dispatch(importInitialize())

    notify({
      message: 'Import started',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: err.response.data.error,
    })
  }
}

export const importJobComplete = () => async (dispatch, getState) => {
  const importJob = $latestActiveImportJob(getState())
  if (!importJob) return

  const { types, duplicateCheck, exportEnabled } = $currentImport(getState())
  try {
    await api.complete(importJob.id, { types, duplicateCheck, exportEnabled })

    dispatch(resetData())
    dispatch(importInitialize())

    notify({
      message: 'Import completed',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: err.response.data.error,
    })
  }
}

export const activeImportJobCancel = () => async (dispatch, getState) => {
  const importJob = $latestActiveImportJob(getState())
  if (!importJob) return

  try {
    await api.cancel(importJob.id)

    dispatch(resetData())
    dispatch(importInitialize())

    notify({
      message: 'Import canceled',
    })
  } catch (err) {
    notify({
      type: 'danger',
      message: err.response.data.error,
    })
  }
}

export const resetData = () => (dispatch) => {
  dispatch(currentImportActions.reset())
  dispatch(transactionsActions.reset())
}
