import React from 'react'
import { useModal } from '@features/common'
import { Link, Text } from '@ui'
import { QboCreateEntityModal } from './QboCreateEntityModal'

export const QboEntities = () => {
  const createModal = useModal()

  return (
    <>
      <Text mb="8px" variant="light">
        Please select a bank account in Quickbooks to import your payment transactions. We recommend you create a new bank account with the name "Bankfeeds".
      </Text>
      <Text mt="8px" variant="light">
        If you want us to create that for you <Link onClick={createModal.show}>click here.</Link>
      </Text>
      <QboCreateEntityModal
        show={createModal.isShown}
        onClose={createModal.close}
      />
    </>
  )
}
