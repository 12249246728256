import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { space } from '@styled-system/space'
import { spacePT } from '@ui/prop-types'
import config, { DIMENSIONS } from '../config'

export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  ${space};

  ${(p) => css`
    margin-left: -${config(p).gutterWidth / 2}px;
    margin-right: -${config(p).gutterWidth / 2}px;
  `}
  ${(p) => p.reverse && css`
    ${Array.isArray(p.reverse)
    ? DIMENSIONS.map((d) =>
      config(p).breakpoints[d] && config(p).media[d]`
        flex-direction: ${p.reverse.indexOf(d) !== -1 ? 'row-reverse' : 'row'};
      `)
    : 'flex-direction: row-reverse;'}
  `}
  ${(p) => p.align && css`
    ${typeof p.align === 'object'
    ? DIMENSIONS.map((d) => config(p).breakpoints[d] && config(p).media[d]`${p.align[d] && `align-items: ${p.align[d]}`}`)
    : `align-items: ${p.align};`}
  `}
  
  ${(p) => p.justify && css`
    ${typeof p.justify === 'object'
    ? DIMENSIONS.map((d) => config(p).breakpoints[d] && config(p).media[d]`${p.justify[d] && `justify-content: ${p.justify[d]}`}`)
    : `justify-content: ${p.justify};`}
  `}
`

const boolOrArray = P.oneOfType([
  P.bool,
  P.array,
])

const stringOrObject = P.oneOfType([
  P.string,
  P.object,
])

Row.propTypes = {
  reverse: boolOrArray,
  align: stringOrObject,
  justify: stringOrObject,
  children: P.node,
  ...spacePT,
}
