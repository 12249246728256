import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Form, Formik } from 'formik'
import { Portal } from '@lib/portal'
import { Col, Row } from '@lib/styled-layout'
import { Divider, Flex, FormGroup, FormSubmit, H2, SaveFormChangesDialog, Text } from '@ui'
import { ConfigurationAlerts } from '../../components/ConfigurationAlerts'
import {
  BankAccount,
  DefaultCustomer,
  DisplayOption,
  InputTemplate,
  PaymentMethod,
  ProductsAndServices,
  Track,
  TrackAccount,
  Vendor,
} from '../../components/SettingsOptions'
import { useSettingsModel } from './quickbooks-settings.model'
import { DeleteProcessorModalButton } from '../../components/DeleteProcessorModalButton'
import { quickbooksSettingsSchema } from '../../validation-schemas'
import {
  $accountsOptions,
  $bankAccountOptions,
} from '../../selectors/quickbooks-entities'
import { $isEntitiesLoading } from '../../selectors/integration'
import { QboEntities } from '../../components/QboEntities'
import { QboTaxRates } from '../../components/SettingsOptions/QboTaxRates'

export const QuickbooksSettings = () => {
  const { processorId } = useParams()
  const bankAccountOptions = useSelector($bankAccountOptions)
  const accountsOptions = useSelector($accountsOptions)
  const isEntitiesLoading = useSelector($isEntitiesLoading)

  const {
    handleSubmit,
    initialValues,
  } = useSettingsModel()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={quickbooksSettingsSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Portal id="dialog">
          <SaveFormChangesDialog disabled={isEntitiesLoading} />
        </Portal>
        <Portal id="alerts">
          <ConfigurationAlerts scrollToTop />
        </Portal>
        <Text mb="16px">To start the configuration select an account</Text>
        <BankAccount
          options={bankAccountOptions}
        />
        <FormGroup>
          <Flex mt="16px" direction="column">
            <QboEntities />
          </Flex>
        </FormGroup>
        <Divider />
        <Row>
          <Col md={6}>
            <PaymentMethod />
          </Col>
          <Col md={6}>
            <Vendor />
          </Col>
          <Col md={6}>
            <DefaultCustomer />
          </Col>
        </Row>
        <Divider />
        <H2>Import</H2>
        <Text variant="light" mb="24px">
          Please note that any transaction that does not meet custom rules will go into your default account
        </Text>
        <InputTemplate>
          <Track
            name="track_charges"
            account="charges_account"
            label="Import Charges"
          />
        </InputTemplate>
        <InputTemplate>
          <Track
            name="track_refunds"
            account="refunds_account"
            label="Import Refunds"
          />
        </InputTemplate>
        <TrackAccount
          name="track_fees"
          account="fees_account"
          label="Import Fees"
          options={accountsOptions}
          placeholder="Select a category..."
          description="This is the category that you want to assign your fees transactions."
          rulesDescription="This is the default category we will assign to your fees transactions that do not match any rules."
        />
        <InputTemplate>
          <Track
            name="track_app_fees"
            account="app_fees_account"
            label="Import Application Fees"
          />
        </InputTemplate>
        <TrackAccount
          name="track_disputes"
          account="disputes_account"
          label="Import Disputes"
          options={accountsOptions}
          placeholder="Select a category..."
          description="This is the category that you want to assign your disputes transactions."
          rulesDescription="This is the default category we will assign to your disputes transactions that do not match any rules."
        />
        <TrackAccount
          name="track_adjustments"
          account="adjustments_account"
          label="Import Adjustments"
          options={accountsOptions}
          placeholder="Select a category..."
          description="This is the category that you want to assign your adjustment transactions."
          rulesDescription="This is the default category we will assign to your adjustment transactions that do not match any rules."
        />
        <TrackAccount
          name="track_payouts"
          account="payouts_account"
          label="Import Payouts"
          options={bankAccountOptions}
          description="This is the default bank account where you expect to receive payouts."
        />
        <Divider />
        <H2>Products/Services</H2>
        <ProductsAndServices />
        <Divider />
        <H2>Display setting in Quickbooks</H2>
        <Text variant="light" mt="12px">
          Select the fields you want to include to the Customer Name in Quickbooks.
        </Text>
        <DisplayOption name="name_display" label="Customer Name" />
        <DisplayOption name="email_display" label="Customer Email" />
        <DisplayOption name="tag_display" label={'Text - "Bankfeeds"'} />
        <Text variant="light" mt="12px">
          Select to attach a billing address to the Customer in Quickbooks.
        </Text>
        <DisplayOption name="import_country" label="Address" />
        <Divider />
        <QboTaxRates />
        <Divider />
        <Flex justify="flex-end" gap="24px" mt="48px">
          <FormSubmit checkDirty size="lg" disabled={isEntitiesLoading}>SAVE CHANGES</FormSubmit>
          <DeleteProcessorModalButton processorId={processorId} />
        </Flex>
      </Form>
    </Formik>
  )
}
