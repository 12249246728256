import * as P from 'prop-types'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

export const Link = styled.a.attrs({
  rel: 'noreferrer noopener',
})`
  color: ${theme('color.primary')} !important;
  text-decoration: underline !important;
  cursor: pointer;
  line-height: 1.55;
  font-weight: ${ifProp('bold', 'bold')};

  &:hover {
    color: ${theme('hover.outline')};
  }
`

Link.propTypes = {
  bold: P.bool,
}

Link.defaultProps = {
  bold: undefined, // get rid off the React warning: Received `false` for a non-boolean attribute `bold`
}

export const TableLink = styled.a`
  color: ${theme('color.dark')};
  text-decoration: underline;
  
  &:hover, &:focus {
    color: ${theme('color.dark')};
    text-decoration: underline;
  }
`

export const IconLink = styled(Link)`
  svg {
    margin-right: 6px;
  }
`
