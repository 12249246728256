import React from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { Form as FormikForm, Formik } from 'formik'
import {
  Button,
  ErrorLabel,
  FormGroup,
  FormInput,
  InputGroup,
  InputLabel,
  InputAppend,
  LoadingButton,
  Modal,
} from '@ui'

export const ShopifyConnectModal = ({ show, onClose, onSubmit, isLoading }) => (
  <Formik
    initialValues={{ shop: '' }}
    onSubmit={({ shop }) => onSubmit(`${shop}.myshopify.com`)}
    validationSchema={
      yup.object().shape({
        shop: yup.string().required('Shop name is required'),
      })
    }
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
    }) => (
      <Modal show={show} onClose={onClose}>
        <Modal.Title>Add new connection</Modal.Title>
        <FormikForm>
          <Modal.Body>
            <FormGroup>
              <InputLabel>Please enter your shop name.</InputLabel>
              <InputGroup>
                <FormInput
                  name="shop"
                  value={values.shop}
                  placeholder="Your shop name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputAppend>
                  <AppendText>.myshopify.com</AppendText>
                </InputAppend>
              </InputGroup>
              {touched.shop && errors.shop && (
                <ErrorLabel>{errors.shop}</ErrorLabel>
              )}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
            <LoadingButton size="md" type="submit" loading={isLoading}>ADD</LoadingButton>
          </Modal.Footer>
        </FormikForm>
      </Modal>
    )}
  </Formik>
)

const AppendText = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 100%;
`
