import React from 'react'
import styled from 'styled-components'
import { H2 } from '@ui'
import { UseDefaultAccountsButton } from './UseDefaultAccountsButton'

export const ImportHeader = () => (
  <Container>
    <H2>Import</H2>
    <UseDefaultAccountsButton />
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -8px;
  margin-bottom: 8px;
`
