import React from 'react'
import { useRequest } from '@lib/fetching'
import { useQueryParams } from '@lib/hooks'
import { MainTemplate } from '@features/common'
import { api } from '../api'
import { useConnectSource } from '../hooks'
import { CallbackView } from '../components/CallbackView'

export const ShopifyCallbackPage = () => {
  const { code, shop } = useQueryParams()
  const connectRequest = useRequest(() => api.connectShopify(code, shop))
  useConnectSource(connectRequest, { code, shop })

  return (
    <MainTemplate>
      <CallbackView
        isLoading={connectRequest.isLoading}
        error={connectRequest.error}
        params={{ code, shop }}
      />
    </MainTemplate>
  )
}
