import { useDispatch } from 'react-redux'
import { notify } from '@lib/notification'
import { useRequest } from '@lib/fetching'
import { useModal } from '@features/common'
import { sourceDisconnect } from '../../effects/sources'

export const useSettingsModel = ({ settings }) => {
  const dispatch = useDispatch()
  const deleteModal = useModal()
  const deleteRequest = useRequest(() => dispatch(sourceDisconnect(settings.connection_id)))

  const deleteProcessor = async () => {
    try {
      await deleteRequest()
      deleteModal.close()
    } catch (err) {
      notify({
        type: 'danger',
        message: 'Something went wrong',
      })
    }
  }

  return {
    deleteProcessor,
    deleteModal,
    deleteRequest,
  }
}
