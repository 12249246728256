import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Button, Flex, H3, Link, LoadingButton } from '@ui'
import { $currentUser } from '@features/common'
import { useSelector } from 'react-redux'
import { duplicateExportHeaders } from '../constants'

export const SheetExport = ({ data }) => {
  const [isAuth, setAuth] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [resultUrl, setResultUrl] = useState(null)
  const user = useSelector($currentUser)

  const signIn = () => {
    window.gapi.auth2.getAuthInstance().signIn()
  }

  const signOut = () => {
    window.gapi.auth2.getAuthInstance().signOut()
  }

  const exportData = async () => {
    const rowData = [
      {
        values: duplicateExportHeaders.map((header) => ({
          userEnteredValue: { stringValue: header },
        })),
      },
      ...data.map((transaction) => ({
        values: [{
          userEnteredValue: { stringValue: transaction.bankTransactionID },
        }, {
          userEnteredValue: { stringValue: dayjs(transaction.date).format('YYYY-MM-DD') },
        }, {
          userEnteredValue: { stringValue: transaction.reference },
        }, {
          userEnteredValue: { stringValue: transaction.contact.name },
        }, {
          userEnteredValue: { numberValue: transaction.total },
        }, {
          userEnteredValue: { numberValue: transaction.occurences },
        }],
      })),
    ]

    try {
      setLoading(true)
      setResultUrl(null)
      const res = await window.gapi.client.sheets.spreadsheets.create({
        properties: {
          title: `${user.company_name || user.name} - Duplicates`,
        },
        sheets: [
          {
            data: [{
              rowData,
            }],
          },
        ],
      })
      setResultUrl(res.result.spreadsheetUrl)
    } catch (response) {
      alert(response.result.error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const initClient = () => {
      window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        scope: 'https://www.googleapis.com/auth/spreadsheets',
      }).then(() => {
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(setAuth)
        setAuth(window.gapi.auth2.getAuthInstance().isSignedIn.get())
      }, (error) => {
        alert(error)
      })
    }
    window.gapi.load('client', initClient)
  }, [])

  return (
    <>
      <Flex gap="0.5rem" my="0.5rem">
        {isAuth ? (
          <LoadingButton loading={isLoading} onClick={exportData}>Export to Google Sheets</LoadingButton>
        ) : (
          <Button onClick={signIn}>Sign in Google Sheets</Button>
        )}
        {isAuth && (
          <Button variant="outline" onClick={signOut}>Sign out Google Sheets</Button>
        )}
      </Flex>
      {resultUrl && <H3>Exported! Click to see <Link target="_blank" href={resultUrl}>{resultUrl}</Link></H3>}
    </>
  )
}
