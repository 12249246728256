import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePagination, useSortBy, useTable } from 'react-table'
import { useTablePagination } from '@lib/table-hooks'
import { PaginatedTable, Interactivity, Spinner } from '@ui'
import { $count, $importJobsForTable, $isInitialLoading, $isLoading, $pageCount } from '../selectors/import-jobs'
import { importJobsFetch } from '../effects'
import { defaultSort, pageSizes } from '../constants'

export const HistoryPage = () => (
  <div className="animated fadeInRight">
    <ImportJobsTable />
  </div>
)

const ImportJobsTable = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector($isLoading)
  const isInitialLoading = useSelector($isInitialLoading)
  const table = useImportJobsTable()
  const pagination = useTablePagination(table, useSelector($count))

  const {
    state: { pageIndex, pageSize, sortBy },
  } = table

  useEffect(() => {
    dispatch(importJobsFetch({
      page: pageIndex + 1,
      pageSize,
      sort: sortBy[0],
    }))
  }, [pageSize, pageIndex, sortBy, dispatch])

  if (isInitialLoading) {
    return <Spinner />
  }

  return (
    <Interactivity off={isLoading}>
      <PaginatedTable
        table={table}
        pagination={pagination}
      />
    </Interactivity>
  )
}

const useImportJobsTable = () => useTable(
  {
    columns: useColumns(),
    data: useSelector($importJobsForTable),
    pageCount: useSelector($pageCount),
    manualSortBy: true,
    manualPagination: true,
    initialState: {
      pageSize: pageSizes.importJobs,
      sortBy: [defaultSort.importJobs],
    },
  },
  useSortBy,
  usePagination,
)

const useColumns = () => useMemo(() => ([
  { accessor: 'processor', Header: 'Processor', disableSorting: true },
  { accessor: 'account', Header: 'Account', disableSorting: true },
  { id: 'from', accessor: 'rangeDate', Header: 'Date Range', width: 200 },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'total', Header: 'Transactions Imported', width: 200 },
]), [])
