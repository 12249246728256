import React, { useEffect } from 'react'
import { linkAutoClick, MainTemplate, userApi } from '@features/common'
import { Alert, Spinner } from '@ui'
import { useSelector } from 'react-redux'
import { $integrationSettings, $isSettingsLoading } from '@features/integrations'

export const IntegrationReconnectCallbackPage = () => {
  const integration = useSelector($integrationSettings)
  const isLoading = useSelector($isSettingsLoading)
  const noIntegrationLoaded = !isLoading && !integration

  useEffect(() => {
    const getUrl = async () => {
      switch (integration.source) {
        case 'xero':
          return userApi.xeroGetConnectUrl()
        case 'quickbooks':
          return userApi.quickbooksGetConnectUrl()
        default:
          throw new Error(`Integration ${integration.source} is not implemented`)
      }
    }

    if (integration) {
      getUrl().then(linkAutoClick)
    }
  }, [integration])

  return (
    <MainTemplate>
      {noIntegrationLoaded ? (
        <Alert type="info" noButton>
          Please connect an integration first.
        </Alert>
      ) : (
        <Spinner />
      )}
    </MainTemplate>
  )
}
