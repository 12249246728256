import React from 'react'
import { useRequest } from '@lib/fetching'
import { MainTemplate } from '@features/common'
import { useConnectIntegration } from '../hooks'
import { CallbackView } from '../components/CallbackView'
import { api } from '../api'

export const QuickbooksCallbackPage = () => {
  const url = window.location.href

  const connectRequest = useRequest(() => api.connectQuickbooks(url))
  useConnectIntegration(connectRequest, { url })

  return (
    <MainTemplate>
      <CallbackView
        isLoading={connectRequest.isLoading}
        error={connectRequest.error}
        params={{ url }}
      />
    </MainTemplate>
  )
}
