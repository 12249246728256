import querystring from 'query-string'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

export const useQueryParams = () => {
  const location = useLocation()

  return useMemo(
    () => querystring.parse(location.search, {arrayFormat: 'bracket'}),
    [location.search],
  )
}

export const useSyncQueryParams = (data) => {
  const history = useHistory()

  useEffect(() => {
    const search = querystring.stringify(data, {arrayFormat: 'bracket'})
    history.push(`${history.location.pathname}?${search}`)
  }, [data, history])
}
