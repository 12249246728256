import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, CenterContent, Flex, FormGroup, H2, Text, Spinner, Icon } from '@ui'
import { Col, Row } from '@lib/styled-layout'
import styled from 'styled-components'
import { useModal } from '@features/common'
import { useRequest } from '@lib/fetching'
import { Elements, StripeProvider } from '@lib/stripe'
import { $cards, $defaultCard, $paymentLoading } from '../selectors'
import { cardAdd, cardRemove, cardUpdate } from '../effects'
import { FormDivider } from './Settings.styled'
import { CardPreview } from './CardPreview'
import { CreateCardModal } from './CreateCardModal'
import { UpdateCardModal } from './UpdateCardModal'
import { DeleteCardModal } from './DeleteCardModal'

export const CardsView = () => {
  const dispatch = useDispatch()
  const createModal = useModal()
  const updateModal = useModal()
  const deleteModal = useModal()
  const [activeCardId, setActiveCardId] = useState(null)
  const cards = useSelector($cards)
  const defaultCard = useSelector($defaultCard)
  const removeRequest = useRequest((id) => dispatch(cardRemove(id)))
  const isLoading = useSelector($paymentLoading)

  const activeCard = useMemo(
    () => cards.find((card) => card.id === activeCardId),
    [cards, activeCardId],
  )

  const createCard = async (token) => {
    await dispatch(cardAdd(token))
  }

  const updateCard = async (data) => {
    await dispatch(cardUpdate(activeCardId, data))
    updateModal.close()
  }

  const removeCard = async () => {
    await removeRequest(activeCardId)
    deleteModal.close()
  }

  const prepareCardRemove = () => {
    updateModal.close()
    deleteModal.show()
  }

  const prepareCardEdit = (cardId) => {
    setActiveCardId(cardId)
    updateModal.show()
  }

  return (
    <>
      <Flex justify="space-between" align="center">
        <H2>Your Payment Methods</H2>
        <Row>
          <IconButton
            variant="link"
            onClick={createModal.show}
            icon={<Icon name="plus" variant="primary" />}
          >
            ADD PAYMENT METHOD
          </IconButton>
        </Row>
      </Flex>
      <FormGroup as={Row}>
        <Col>
          <CardsWrapper>
            {isLoading ? (
              <Spinner marginTop={0} />
            ) : cards.length > 0 ? (
              <Flex gap="12px" justify="flex-end">
                {cards.map((card, idx) => (
                  <CardPreview
                    key={card.id}
                    card={card}
                    defaultCard={defaultCard}
                    isFirst={idx === 0}
                    onClick={() => prepareCardEdit(card.id)}
                  />
                ))}
              </Flex>
            ) : <NoCards />}
          </CardsWrapper>
        </Col>
      </FormGroup>
      <FormDivider />
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_API_KEY}>
        <Elements>
          <CreateCardModal
            show={createModal.isShown}
            onSubmit={createCard}
            onClose={createModal.close}
          />
          <UpdateCardModal
            values={activeCard}
            defaultCard={defaultCard}
            show={updateModal.isShown}
            onClose={updateModal.close}
            onSubmit={updateCard}
            onDeleteClick={prepareCardRemove}
          />
          <DeleteCardModal
            isLoading={removeRequest.isLoading}
            show={deleteModal.isShown}
            onClose={deleteModal.close}
            onConfirm={removeCard}
          />
        </Elements>
      </StripeProvider>
    </>
  )
}

const CardsWrapper = styled.div`
  margin: 50px 0;
`

const NoCards = () => (
  <CenterContent>
    <Title variant="light">No payment methods added</Title>
  </CenterContent>
)

const Title = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`
