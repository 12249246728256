import { createSelector } from 'reselect'
import { createFetchingSelectors } from '@lib/fetching'
import { date } from '@lib/date'
import { humanizeSource, humanizeType } from '@lib/constants'
import { amount as convertAmount } from '@lib/convert'
import { $userDatePrefs, buildTransactionLink, buildTransactionStatus } from '@features/common'

export const $transactions = (root) => root.transactions
export const $transaction = (root) => root.transaction

export const $transactionsList = createSelector(
  $transactions,
  (transactions) => transactions.list,
)

export const $pageCount = createSelector(
  $transactions,
  ({ pageCount }) => pageCount,
)

export const $totalCount = createSelector(
  $transactions,
  ({ totalCount }) => totalCount,
)

export const $count = createSelector(
  $transactions,
  ({ count }) => count,
)

export const $fetching = createSelector(
  $transactions,
  (transactions) => transactions.fetching,
)

export const { $isLoading } = createFetchingSelectors($fetching)

export const $singleTransactionFetching = createSelector(
  $transaction,
  (transaction) => transaction.fetching,
)

export const { $isLoading: $isSingleTransactionLoading } = createFetchingSelectors($singleTransactionFetching)

export const $isInitialLoading = createSelector(
  $isLoading,
  $totalCount,
  (isLoading, totalCount) => isLoading && totalCount === null,
)

export const $activeTransaction = createSelector(
  $transaction,
  (transaction) => transaction.transaction,
)

export const $transactionsForTable = createSelector(
  $transactionsList,
  $userDatePrefs,
  (transactions, { timezone, dateTimeFormat}) =>
    transactions.map(({ id, processed_at, source, payment_type, description, status, error_details, amount, currency, metadata }) => ({
      id,
      processed_at: date(processed_at, timezone, dateTimeFormat),
      source: humanizeSource(source),
      payment_type: humanizeType(payment_type),
      description: buildTransactionLink({ source, metadata, value: description }),
      amount: convertAmount(amount, ''),
      currency: currency.toUpperCase(),
      status: buildTransactionStatus(status, error_details),
    })),
)
