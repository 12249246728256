import { useEffect, useState } from 'react'
import { useDidUnmount } from './use-did-unmount'

export const useDeferredValue = (value, initital = 0) => {
  const didUnmount = useDidUnmount()
  const [deferredValue, set] = useState(initital)

  useEffect(() => {
    setTimeout(() => {
      if (!didUnmount.current) {
        set(value)
      }
    }, 1)
  }, [didUnmount, value])

  return deferredValue
}
