import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { processors } from '@lib/constants'
import { MainTemplate } from '@features/common'
import { $bankAccountOptions, $sources, $sourcesSettings } from '@features/integrations'
import {
  Button,
  Flex,
  Heading,
  LoadingButton,
  SelectField,
  SortableHeader,
  Spinner,
  Table,
  TableBody,
  TableHead,
} from '@ui'
import { Formik, useField } from 'formik'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { useSortBy, useTable } from 'react-table'
import { useRequest } from '@lib/fetching'
import { api, xeroApi } from '../api'
import { SheetExport } from '../components/SheetExport'
import { duplicateExportHeaders } from '../constants'
import { AccountBalances } from '../components/AccountBalances'

export const Search = () => {
  const [, { value: account }] = useField('search_account')
  const [dateGroups, setDateGroups] = useState([])
  const [searching, setSearching] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [error, setError] = useState(null)

  const tableData = useMemo(
    () => dateGroups.reduce((tableData, group) => tableData.concat(
      group.transactions.filter((tr) => tr.occurences > 1),
    ), []),
    [dateGroups],
  )

  const table = useDuplicatesTable({
    data: tableData,
  })

  useEffect(() => {
    let cancelled = false
    setDateGroups([])
    setError(null)

    if (account) {
      setSearching(true)

      const run = async () => {
        let lastCount = 100
        let page = 1

        try {
          while (lastCount >= 100 && !cancelled) {
            const transactions = await api.duplicateDetection(account, page++)
            lastCount = transactions.length

            if (!cancelled) {
              setDateGroups((groups) => {
                let newGroups = groups.slice(0)

                transactions.forEach((transaction) => {
                  const date = dayjs(transaction.date).format('YYYY-MM-DD')
                  let group = newGroups.find((group) => group.date === date)

                  if (!group) {
                    group = {
                      date: dayjs(transaction.date).format('YYYY-MM-DD'),
                      transactions: [],
                    }

                    newGroups.push(group)
                  }

                  group = {
                    ...group,
                    transactions: [
                      ...group.transactions,
                      transaction,
                    ],
                  }

                  // Recompute the group.
                  group.transactions = group.transactions.map((transaction) => {
                    // Count the other transactions in this date that have the same reference.
                    const duplicates = group.transactions
                      .filter(({ reference }) => reference === transaction.reference)
                    const sortedDates = duplicates.map(({ updatedDateUTC }) => updatedDateUTC).sort()
                    const { length } = duplicates

                    // Check if this is the newest transaction.
                    const isNewest = transaction.updatedDateUTC === sortedDates[sortedDates.length - 1]

                    return {
                      ...transaction,
                      occurences: length,
                      isNewest,
                    }
                  })

                  newGroups = newGroups.map((oldGroup) => {
                    if (oldGroup.date === group.date) {
                      return group
                    }

                    return oldGroup
                  })
                })

                return newGroups.map((group) => ({
                  ...group,
                  transactions: group
                    .transactions
                    .sort((a, b) => {
                      // Sort by reference.
                      if (a.reference > b.reference) {
                        return 1
                      }
    
                      if (b.reference > a.reference) {
                        return -1
                      }

                      // If they're the same, sort by updatedDateUTC.
                      if (a.updatedDateUTC > b.updatedDateUTC) {
                        return 1
                      }
    
                      if (b.updatedDateUTC > a.updatedDateUTC) {
                        return -1
                      }
    
                      return 0
                    }),
                }))
              })
            }
          }

          if (!cancelled) {
            setSearching(false)
          }
        } catch (e) {
          if (!cancelled) {
            setSearching(false)
            setError(e.message)
          }
        }
      }

      run()
    } else {
      setSearching(false)
    }

    return () => {
      cancelled = true
    }
  }, [account])

  const exportCsv = () => {
    const csvString = tableData.reduce(
      (csvString, transaction) =>
        `${csvString}\n${transaction.bankTransactionID},${dayjs(transaction.date).format('YYYY-MM-DD')},${transaction.reference},${transaction.contact.name},${transaction.total},${transaction.occurences}`,
      duplicateExportHeaders.join(','),
    )

    const csvData = new Blob([csvString], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)
    const a = document.createElement('a')
    a.href = csvUrl
    a.download = 'export.csv'
    a.click()
    URL.revokeObjectURL(csvUrl)
  }

  const getDeleteList = () => dateGroups
    .reduce((array, group) => array.concat(group.transactions), [])
    .filter((transaction) => (transaction.occurences > 1 && !transaction.isNewest))

  const deleteAll = async () => {
    setDeleting(true)

    try {
      const deleteList = getDeleteList()

      // Delete in batches.
      const batchSize = 30

      for (let i = 0; i < deleteList.length; i += batchSize) {
        const transactions = deleteList.slice(i, i + batchSize)

        if (transactions.length > 0) {
          await api.duplicateDetectionRemoval(transactions.map(({ bankTransactionID }) => bankTransactionID))
        }
      }
    } catch (e) {
      alert(e.response ? e.response.data.error : e.message)
    }

    setDeleting(false)
  }

  if (!account) {
    return null
  }

  return (
    <>
      {dateGroups.length > 0 && (
        <>
          <Flex gap="0.5rem" my="0.5rem">
            <Button onClick={exportCsv}>Export CSV</Button>
            <LoadingButton
              onClick={deleteAll}
              loading={deleting}
              disabled={deleting}
            >
            Delete Duplicates
            </LoadingButton>
          </Flex>
          <SheetExport data={tableData} />
        </>
      )}
      {searching && <Spinner marginTop="2rem" />}
      {error && <div>Error: {error}</div>}
      <DuplicatesTable table={table} />
    </>
  )
}

const DuplicatesTable = ({ table }) => (
  <Table {...table.getTableProps()}>
    <TableHead
      headerGroups={table.headerGroups}
      renderColumnHeader={(column) => (
        <SortableHeader
          key={column.id}
          column={column}
        />
      )}
    />
    <TableBody
      rows={table.rows}
      prepareRow={table.prepareRow}
      renderRow={(row) => {
        const transaction = row.original
        const style = transaction.occurences > 1 ? (transaction.isNewest ? { color: 'blue' } : { color: 'red' }) : null
        return (
          <tr {...row.getRowProps({ style })}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        )
      }}
    />
  </Table>
)

const $defaultSearchAccount = createSelector(
  $sources,
  $sourcesSettings,
  (sources, settings) => {
    const stripeSource = sources.find((s) => s.connection_source === processors.STRIPE)
    if (!stripeSource) return null
    const stripeSettings = settings.find((setting) => setting.connection_id === stripeSource.id)
    return stripeSettings ? stripeSettings.bankfeeds_account : null
  },
)

const useDuplicatesTable = ({ data }) => useTable(
  {
    columns: useMemo(() => [
      { accessor: 'bankTransactionID', Header: 'ID' },
      { accessor: 'date', Header: 'Date', Cell: ({ cell }) => dayjs(cell.value).format('YYYY-MM-DD') },
      { accessor: 'reference', Header: 'Reference' },
      { accessor: 'contact.name', Header: 'Contact Name' },
      { accessor: 'total', Header: 'Amount',
        Cell: ({ cell }) => cell.value ? cell.value.toFixed(2) : 'N/A',
      },
      { accessor: 'occurences', Header: 'Occurrences' },
    ], []),
    data,
  },
  useSortBy,
)

export const DuplicateDetectionPage = () => {
  const bankAccounts = useSelector($bankAccountOptions)
  const searchAccount = useSelector($defaultSearchAccount)
  const balancesRequest = useRequest(xeroApi.getBalances, {
    initialData: [],
  })

  useEffect(() => {
    balancesRequest()
  }, [balancesRequest])

  return (
    <MainTemplate heading={<Heading>Duplicate Detection</Heading>}>
      <AccountBalances balances={balancesRequest.data} />
      <Formik
        onSubmit={() => {}}
        enableReinitialize
        initialValues={{
          search_account: searchAccount,
        }}
      >
        <>
          <SelectField
            name="search_account"
            placeholder="Please select an account"
            options={bankAccounts}
            isSearchable
            isClearable
          />
          <Search />
        </>
      </Formik>
    </MainTemplate>
  )
}
