import React from 'react'
import * as P from 'prop-types'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { ButtonNative } from '../Buttons'

export const Tab = styled(ButtonNative)`
  min-width: 130px;

  ${ifProp('active',
    css`
      background-color: ${theme('color.success')};
      color: white;
    `,
    css`
      background-color: ${theme('color.lightGray')};
      color: ${theme('color.dark')};
    `,
  )}
`

export const TabLink = ({ to, children }) => {
  const match = useRouteMatch(to)
  return (
    <Link to={to}>
      <Tab active={match && match.isExact}>
        {children}
      </Tab>
    </Link>
  )
}

Tab.propTypes = {
  active: P.bool,
}

Tab.defaultProps = {
  active: false,
}
