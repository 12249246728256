import { useEffect, useState } from 'react'
import { parseRange, setMaxDayTime } from './utils'

/**
 * @param {number} start - The start date in unix timestamp (milliseconds).
 * @param {number} end - The end date in unix timestamp (milliseconds).
 * @param {function} onChange
 */
export const useDates = ({ start, end, onChange }) => {
  const [[startDate, endDate], setDates] = useState(
    () => parseRange(start, end),
  )

  useEffect(() => {
    setDates([start, end])
  }, [start, end])

  const _setDates = ([sDate, eDate]) => {
    setDates([sDate, eDate])
    onChange([sDate, eDate])
  }

  const updateDates = ([sDate = startDate, eDate = endDate]) => {
    let [parsedStartDate, parsedEndDate] = parseRange(sDate, eDate)
    parsedEndDate = setMaxDayTime(parsedEndDate)

    _setDates([parsedStartDate, parsedEndDate])
  }

  const updateDateTime = ([sDate = startDate, eDate = endDate]) => {
    _setDates(parseRange(sDate, eDate))
  }

  const resetDates = () => {
    updateDates([start, end])
  }

  return {
    startDate,
    endDate,
    updateDates,
    updateDateTime,
    resetDates,
  }
}
