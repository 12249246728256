import React, { useEffect } from 'react'
import { Expiration } from 'react-credit-card-primitives'
import { Input } from '../Input'
import { ErrorLabel, InputLabel } from '../Label'

export const ExpirationField = ({ defaultMonth, defaultYear, label, onChange, onError }) => {
  const handleChange = ({ month, year }) => {
    onChange({ month, year })
  }

  return (
    <Expiration
      defaultMonth={defaultMonth}
      defaultYear={defaultYear}
      onChange={handleChange}
      render={({ getInputProps, error }) => (
        <Component
          getInputProps={getInputProps}
          error={error}
          label={label}
          onError={onError}
        />
      )}
    />
  )
}

const Component = ({ getInputProps, error, label, onError }) => {
  useEffect(() => {
    onError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...getInputProps()}
      />
      {error && (
        <ErrorLabel>
          {getErrorMessage(error)}
        </ErrorLabel>
      )}
    </>
  )
}

const errorMessages = {
  DATE_INCOMPLETE: 'Your card\'s expiration date is incomplete.',
  YEAR_INVALID: 'Your card\'s expiration year is invalid.',
  YEAR_IN_THE_PAST: 'Your card\'s expiration year is in the past.',
}

const getErrorMessage = (error) => ({
  [Expiration.ERROR_MONTH_YEAR]: errorMessages.DATE_INCOMPLETE,
  [Expiration.ERROR_MONTH]: errorMessages.DATE_INCOMPLETE,
  [Expiration.ERROR_YEAR]: errorMessages.YEAR_INVALID,
  [Expiration.ERROR_PAST_DATE]: errorMessages.YEAR_IN_THE_PAST,
}[error] || null)

ExpirationField.errorMessages = errorMessages
