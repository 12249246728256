import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FieldArray, useFormikContext } from 'formik'
import { List as MovableList } from 'react-movable'
import { Collapse, FieldError, Flex, FormGroup, InputLabel, Link, SelectField, Text } from '@ui'
import { useCountriesOptions } from '@features/account'
import { emptyCondition, events, eventsOptions, fieldOptions, getFieldMeta, operators } from '../../constants'
import { Condition } from './Condition'
import { BlueLabel } from './BlueLabel'
import { DraggableButton, DraggableItem } from './DraggableButton'
import { $currencies, $taxRatesOptions } from '../../selectors/integration'
import { $bankAccountOptions, $commonAccountOptions } from '../../selectors/xero-entities'
import { useAccountPlaceholder } from '../../hooks'
import { ConditionValueInput } from './ConditionValueInput'

export const RuleConditionList = ({ account }) => {
  const countriesOptions = useCountriesOptions()

  return (
    <>
      <FieldArray
        name="conditions"
        render={({ push, form: { values }, move }) => (
          <FormGroup>
            <Flex justify="space-between">
              <InputLabel>
                <Text variant="light">Condition</Text>
              </InputLabel>
              <Link bold onClick={() => push(emptyCondition)}>Add a condition</Link>
            </Flex>
            <MovableList
              lockVertically
              values={values.conditions}
              onChange={({ oldIndex, newIndex }) => {
                move(oldIndex, newIndex)
              }}
              renderList={({ children, props }) => (
                <div {...props}>{children}</div>
              )}
              renderItem={({ value, props, index, isDragged }) => (
                <DraggableItem {...props}>
                  <Flex>
                    <RuleCondition
                      condition={value}
                      index={index}
                      countriesOptions={countriesOptions}
                    />
                    <DraggableButton
                      isDragged={isDragged}
                      hidden={values.conditions.length < 2}
                    />
                  </Flex>
                  <FieldError name={`conditions.${index}`} />
                </DraggableItem>
              )}
            />
          </FormGroup>
        )}
      >
      </FieldArray>
      <FormGroup>
        <InputLabel>
          <Text variant="light">When all conditions are satisfied</Text>
        </InputLabel>
        <RuleActions account={account} />
      </FormGroup>
      {account !== 'payouts_account' ? (
        <FormGroup>
          <AdvancedOptions />
        </FormGroup>
      ) : null}
    </>
  )
}

const RuleCondition = ({ condition, index, countriesOptions }) => {
  const { setFieldValue } = useFormikContext()
  const { type, options, operatorOptions } = getFieldMeta(condition.field, { countriesOptions })

  const getDefaultFieldOperator = (field) => {
    const { operatorOptions } = getFieldMeta(field, { countriesOptions })
    return operatorOptions.length > 0
      ? operatorOptions[0].value
      : operators.EQ
  }

  const handleFieldChange = (_, field) => {
    const operator = getDefaultFieldOperator(field)

    setFieldValue(`conditions.${index}`, {
      field,
      operator,
      value: null,
    })
  }

  return (
    <>
      <BlueLabel>
        {index === 0 ? 'If' : 'and'}
      </BlueLabel>
      <Condition>
        <Condition.Item basis="30%">
          <SelectField
            name={`conditions.${index}.field`}
            placeholder="Field"
            options={fieldOptions}
            onChange={handleFieldChange}
          />
        </Condition.Item>
        <Condition.Item basis="40%">
          <SelectField
            name={`conditions.${index}.operator`}
            placeholder="Operator"
            options={operatorOptions}
          />
        </Condition.Item>
        <Condition.Item basis="30%">
          <ConditionValueInput
            name={`conditions.${index}.value`}
            type={type}
            options={options}
            placeholder="Value"
          />
        </Condition.Item>
      </Condition>
    </>
  )
}

const RuleActions = ({ account }) => {
  const { values, setValues } = useFormikContext()
  const { event } = values
  const commonAccountOptions = useSelector($commonAccountOptions)
  const bankAccountOptions = useSelector($bankAccountOptions)
  const taxRatesOptions = useSelector($taxRatesOptions)
  const codePlaceholder = useAccountPlaceholder('Account')
  const fromPlaceholder = useAccountPlaceholder('From account')
  const toPlaceholder = useAccountPlaceholder('To account')
  const taxTypePlaceholder = useAccountPlaceholder('Tax type')

  const handleEventChange = (_, value) => {
    setValues(({
      ...values,
      event: value,
      ...(account === 'payouts_account' ? ({
        fromAccount: null,
        toAccount: null,
      }) : ({
        account: null,
      })),
      code: null,
      taxType: null,
    }))
  }

  return (
    <Flex>
      <BlueLabel>Then</BlueLabel>
      <Condition>
        <Condition.Item basis="30%">
          <SelectField
            name="event"
            placeholder="Action"
            options={eventsOptions}
            onChange={handleEventChange}
          />
        </Condition.Item>
        {event === events.EXPORT && (
          account !== 'payouts_account' ? (
            <Condition.Item basis="70%">
              <SelectField
                name="code"
                placeholder={codePlaceholder}
                options={commonAccountOptions}
                isSearchable
                isClearable
              />
            </Condition.Item>
          ) : (
            <Condition.Item basis="70%">
              <SelectField
                name="fromAccount"
                placeholder={fromPlaceholder}
                options={bankAccountOptions}
                isSearchable
                isClearable
              />
              <SelectField
                name="toAccount"
                placeholder={toPlaceholder}
                options={bankAccountOptions}
                isSearchable
                isClearable
              />
            </Condition.Item>
          )
        )}
        {event === events.CODE_WITH_TAX && (
          <Condition.Item basis="70%">
            <SelectField
              name="taxType"
              placeholder={taxTypePlaceholder}
              options={taxRatesOptions}
            />
          </Condition.Item>
        )}
      </Condition>
    </Flex>
  )
}

const AdvancedOptions = () => {
  const { setFieldValue } = useFormikContext()
  const bankAccountOptions = useSelector($bankAccountOptions)
  const currencies = useSelector($currencies)
  const accountPlaceholder = useAccountPlaceholder('Bank account')

  const isMultiCurrency = useMemo(() => currencies.length > 1, [currencies])

  const handleChange = (_, value) => {
    setFieldValue('account', value)
  }

  return (
    <Collapse
      label="Advanced options"
      opened={isMultiCurrency}
    >
      <InputLabel>
        Bank Account
      </InputLabel>
      <SelectField
        name="account"
        placeholder={accountPlaceholder}
        options={bankAccountOptions}
        onChange={handleChange}
        isClearable
      />
    </Collapse>
  )
}
