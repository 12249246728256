import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  list: [],
  count: null,
  totalCount: null,
  pageCount: 0,
  fetching: initialFetching,

  active: {
    list: [],
    fetching: initialFetching,
  },
}

const symbiotes = {
  fetch: createFetching(),
  set: (state, { data, count, totalCount, pageCount }) => ({
    ...state,
    list: data,
    count,
    totalCount,
    pageCount,
  }),

  active: {
    fetch: createFetching((state, fetching) => ({
      ...state,
      active: {
        ...state.active,
        fetching,
      },
    })),

    set: (state, list) => ({
      ...state,
      active: {
        ...state.active,
        list,
      },
    }),

    reset: (state) => ({
      ...state,
      active: initialState.active,
    }),
  },
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'importJobs',
)
