import { createFetching, initialFetching } from '@lib/fetching'
import { createSymbiote } from 'redux-symbiote'
import { terms } from '@lib/constants'
import { dashboardTermOptions } from '../constants'

const initialState = {
  fetching: initialFetching,
  model: {
    total: null,
    statuses: [],
    sources: [],
    types: [],
    erps: [],
  },
  filters: {
    term: dashboardTermOptions[0].value,
    gte: null,
    lte: null,
  },
}

const symbiotes = {
  fetch: createFetching(),

  setModel: (state, model) => ({ ...state, model }),

  reset: () => initialState,

  filters: {
    setTerm: (state, term) => ({
      ...state,
      filters: {
        term,
        gte: null,
        lte: null,
      },
    }),

    setCustomTerm: (state, { gte, lte }) => ({
      ...state,
      filters: {
        term: terms.CUSTOM,
        gte,
        lte,
      },
    }),

    reset: (state) => ({
      ...state,
      filters: initialState.filters,
    }),
  },
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'statistics',
)
