import * as P from 'prop-types'

export const flexboxPT = {
  align: P.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch',
  ]),

  justify: P.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-around',
    'space-between',
    'space-evenly',
    'safe center',
    'unsafe center',
  ]),

  direction: P.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
}
