import { sources, transactionTypes } from '@lib/constants'
import { fromUtcDate } from '@lib/date'
import { $currentUser } from '@features/common'
import { $sourcesById } from '@features/integrations'
import { actions } from '../symbiotes/current-import'
import { $currentImportTypes } from '../selectors/current-import'
import { currentImportActions } from '../symbiotes'

export const currentImportInit = (importJob) => (dispatch, getState) => {
  const user = $currentUser(getState())
  const from = fromUtcDate(importJob.from, user.timezone).getTime()
  const to = fromUtcDate(importJob.to, user.timezone).getTime()

  const foundSource = $sourcesById(getState())[importJob.connection_id]
  const processor =  foundSource ? foundSource.connection_source : sources.STRIPE

  dispatch(currentImportActions.setInitial({
    startDate: from,
    endDate: to,
    connection: importJob.connection_id,
    processor,
  }))
}

export const typeToggle = ({ name, checked }) => (dispatch, getState) => {
  const types = $currentImportTypes(getState())

  const newTypes = checked
    ? types.concat(name)
    : types.filter((x) => x !== name)

  dispatch(actions.setTypes(newTypes))
}

export const allTypesToggle = (checked) => (dispatch) => {
  dispatch(actions.setTypes(checked ? transactionTypes : []))
}
