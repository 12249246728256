import styled from 'styled-components'
import { prop } from 'styled-tools'

const Item = styled.div`
  flex: 1 1 ${prop('basis')};
`

export const Condition = styled.div`
  display: flex;
  width: 100%;

  ${Item}:not(:last-child) {
    margin-right: 8px;
  }
`

Condition.Item = Item
