import { createSelector } from 'reselect'
import { fetchStatus } from '@lib/fetching'

const $payment = (root) => root.payment

export const $cards = createSelector(
  $payment,
  (payment) => payment.cards,
)

export const $paymentFetching = createSelector(
  $payment,
  (payment) => payment.fetching,
)

export const $paymentLoading = createSelector(
  $paymentFetching,
  (fetching) => fetching.status === fetchStatus.loading,
)

export const $defaultCard = createSelector(
  $payment,
  (payment) => payment.defaultCard,
)

export const $address = createSelector(
  $payment,
  (payment) => payment.address || defaultAddress,
)

const defaultAddress = {
  country: '',
  line1: '',
  line2: '',
  state: '',
  city: '',
  postal_code: '',
}
