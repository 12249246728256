import React from 'react'
import { useField } from 'formik'
import { FormInput } from '../Form/Input'

export const InputField = ({ name, label, type, placeholder, autoComplete, disabled }) => {
  const [
    { onChange, onBlur },
    { touched, error, value },
  ] = useField(name)

  return (
    <FormInput
      name={name}
      label={label}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      disabled={disabled}
      value={value}
      error={touched && error}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
