import React from 'react'

export const plans = [
  {
    id: 'starter',
    name: 'Starter',
    active: true,
    price: 15,
    features: [
      <>500 transactions synced per month</>,
      <>1 payment integration</>,
      <>Historical data imports</>,
      <>Automatic Reconciliation</>,
    ],
  },
  {
    id: 'professional',
    name: 'Professional',
    active: true,
    price: 25,
    features: [
      <><strong>Unlimited</strong> transactions synced per month</>,
      <><strong>2</strong> payment integrations</>,
      <>Historical data imports</>,
      <>Automatic Reconciliation</>,
      <>Stripe Invoices Support</>,
    ],
  },
  {
    id: 'advanced',
    name: 'Advanced',
    active: true,
    price: 50,
    features: [
      <>All <strong>Starter</strong> features</>,
      <><strong>Unlimited</strong> transactions synced per month</>,
      <><strong>3</strong> payment integrations</>,
      <>Support for Xero Invoices</>,
      <>Custom accounting rules engine</>,
    ],
  },
  {
    id: 'unlimited_plan_migrated',
    name: 'Unlimited',
    active: false,
    price: 9,
    features: [
      <>All <strong>Starter</strong> features</>,
      <><strong>Unlimited</strong> transactions synced per month</>,
      <><strong>2</strong> Stripe account integrations</>,
      <><strong>Unlimited</strong> historical data imports</>,
      <>Support for Xero Invoices</>,
    ],
  },
]

export const selectablePlans = plans.filter(
  ({ id }) => !['advanced', 'unlimited_plan_migrated'].includes(id),
)
