import React, { useRef } from 'react'
import * as P from 'prop-types'
import useOnClickOutside from 'use-onclickoutside'
import { useDatePicker } from '../../lib/date-picker-hooks'
import { MonthView } from './MonthView'
import { PickerWrapper, PickerBody, TimePickerWrapper } from './Picker.styled'
import { MonthsWrapper, MonthWrapper, NavDate } from './MonthView.styled'
import { DateInput } from './DateInput'
import { PickerNav } from './Nav'
import { TimePicker } from './TimePicker'
import { BackgroundPositioner } from '../Positioner'
import { getMonthName } from './constants'

export const DateTimeRangePicker = ({
  from,
  to,
  onChange,
  position,
  side,
  disabled,
  showTime,
  numberOfMonths,
  isRange,
  closeAfterSelect,
  components: { Input },
}) => {
  const {
    isOpen,
    daysData,
    monthsData,
    getDayProps,
    startDate,
    endDate,
    updateDateTime,
    getInputProps,
    goMonthLeft,
    goMonthRight,
    close,
    open,
  } = useDatePicker({
    start: from,
    end: to,
    onChange,
    showTime,
    numberOfMonths,
    isRange,
    closeAfterSelect,
  })

  const bodyRef = useRef(null)
  const inputProps = getInputProps()

  useOnClickOutside(bodyRef, (e) => {
    if (e.target === inputProps.ref.current) return
    close()
  })

  return (
    <PickerWrapper>
      <Input
        {...inputProps}
        open={open}
        isOpen={isOpen}
        disabled={disabled}
      />
      <BackgroundPositioner position={position} side={side}>
        <PickerBody ref={bodyRef} isOpen={isOpen}>
          <MonthsWrapper>
            {monthsData.map(({ month, year }, idx) => (
              <MonthWrapper key={idx}>
                <NavDate>
                  {getMonthName(month)} {year}
                </NavDate>
                <MonthView
                  daysData={daysData[idx]}
                  monthIdx={idx}
                  getDayProps={getDayProps}
                />
              </MonthWrapper>
            ))}
            <PickerNav
              prev={goMonthLeft}
              next={goMonthRight}
            />
          </MonthsWrapper>
          {showTime && (
            <TimePickerWrapper>
              <TimePicker
                disabled={!startDate}
                date={startDate}
                onChange={(date) => updateDateTime([date])}
              />
              <TimePicker
                disabled={!endDate}
                date={endDate}
                onChange={(date) => updateDateTime([undefined, date])}
              />
            </TimePickerWrapper>
          )}
        </PickerBody>
      </BackgroundPositioner>
    </PickerWrapper>
  )
}

const dateType = P.oneOfType([
  P.instanceOf(Date),
  P.number,
  P.string,
])

DateTimeRangePicker.propTypes = {
  from: dateType,
  to: dateType,
  position: P.string,
  side: P.string,
  disabled: P.bool,
  onChange: P.func,
  showTime: P.bool,
  numberOfMonths: P.number,
  isRange: P.bool,
  closeAfterSelect: P.bool,
  components: P.object,
}

DateTimeRangePicker.defaultProps = {
  from: null,
  to: null,
  position: 'bottom',
  side: 'left',
  showTime: true,
  isRange: true,
  numberOfMonths: 2,
  onChange: () => {},
  closeAfterSelect: false,
  components: {
    Input: DateInput,
  },
}
