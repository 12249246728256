import React from 'react'
import { useModal, useXeroConnectRequest, Guard } from '@features/common'
import { Icon, IconButton } from '@ui'
import { useQuickbooksConnectRequest } from '@features/common/hooks'
import { ConnectProcessorModal } from './ConnectProcessorModal'
import { ConnectModal, InlineConnectModal } from './ConnectModal'

export const AddProcessorModalButton = () => {
  const modal = useModal()

  return (
    <Guard isActive>
      <AddIntegrationButton onClick={modal.show}>
        ADD A PROCESSOR
      </AddIntegrationButton>
      <ConnectProcessorModal
        show={modal.isShown}
        onClose={modal.close}
      />
    </Guard>
  )
}

export const AddIntegrationModalButton = () => {
  const modal = useModal()

  return (
    <Guard noIntegration>
      <AddIntegrationButton onClick={modal.show}>
        ADD AN INTEGRATION
      </AddIntegrationButton>
      <ConnectIntegrationModal
        title="Connect Integration"
        show={modal.isShown}
        onClose={modal.close}
      />
    </Guard>
  )
}

export const ConnectIntegrationModal = ({ show, onClose, inline }) => {
  const xeroConnectRequest = useXeroConnectRequest()
  const quickbooksConnectRequest = useQuickbooksConnectRequest()

  const prepareConnection = (value) => {
    if (value === 'quickbooks') {
      return quickbooksConnectRequest()
    }

    return xeroConnectRequest()
  }

  if (inline) {
    return (
      <InlineConnectModal
        onConfirm={prepareConnection}
        options={[
          { value: 'xero', label: 'Xero' },
        ]}
      />
    )
  }

  return (
    <ConnectModal
      title="Connect integration"
      show={show}
      onClose={onClose}
      onConfirm={prepareConnection}
      loading={xeroConnectRequest.isLoading || quickbooksConnectRequest.isLoading}
      options={[
        { value: 'xero', label: 'Xero' },
        { value: 'quickbooks', label: 'QuickBooks' },
      ]}
    />
  )
}

export const AddIntegrationButton = ({ children, onClick }) => (
  <IconButton
    variant="link"
    icon={<Icon name="plus" variant="primary" />}
    onClick={onClick}
  >
    {children}
  </IconButton>
)
