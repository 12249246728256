import { useState } from 'react'
import { shallowEqual } from 'react-redux'
import { arraysEqual, updateWith } from '../../../lib/array'

export const useSingleChoiceFilter = ({ filterValue, setFilter, onClose }) => {
  const [localFilterValue, setLocalFilter] = useState(filterValue)
  const isEqual = shallowEqual(localFilterValue === filterValue)

  const changeLocalFilter = (e) => {
    const { name } = e.target
    setLocalFilter(name)
  }

  const reset = () => {
    setLocalFilter()
    setFilter()
    onClose()
  }

  const apply = () => {
    setFilter(localFilterValue)
    onClose()
  }

  return {
    isEqual,
    localFilterValue,
    setLocalFilter,
    changeLocalFilter,
    reset,
    apply,
  }
}

export const useMuliChoiceFilter = ({ filterValue, setFilter, onClose }) => {
  const [localFilterValue, setLocalFilter] = useState(filterValue || [])
  const isEqual = arraysEqual(localFilterValue, filterValue || [])

  const changeLocalFilter = (e) => {
    e.persist() // Fixes the React error: This synthetic event is reused for performance reasons
    const { name, checked } = e.target
    setLocalFilter(
      updateWith(localFilterValue, name, checked ? 'add' : 'remove'),
    )
  }

  const reset = () => {
    setLocalFilter([])
    setFilter()
    onClose()
  }

  const apply = () => {
    setFilter(localFilterValue)
    onClose()
  }

  return {
    isEqual,
    localFilterValue,
    changeLocalFilter,
    reset,
    apply,
  }
}
