import { useMemo, useState } from 'react'
import { range } from '@lib/array'

const MONTHS_AMOUNT = 12

export const useMonths = ({ initialYear, initialMonth, amount }) => {
  const [monthViews, setMonthViews] = useState(
    () => createMonths(initialYear, initialMonth, amount),
  )

  const { year, month } = useMemo(
    () => monthViews[0],
    [monthViews],
  )

  const goMonthLeft = () => {
    setView(month - 1)
  }

  const goMonthRight = () => {
    setView(month + 1)
  }

  const setView = (month) => {
    setMonthViews(createMonths(year, month, amount))
  }

  const setViewData = (viewIndex, { month, year }) => {
    setMonthViews((views) => views.map((view, index) => {
      const newYear = year || view.year
      const newMonth = month || view.month

      return index === viewIndex
        ? createMonth(newYear, newMonth)
        : view
    }))
  }

  return {
    monthViews,
    setViewData,
    goMonthLeft,
    goMonthRight,
  }
}

const createMonths = (year, month, amount) =>
  range(amount).map((idx) => createMonth(year, month + idx))

const createMonth = (year, month) => {
  if (month < 1) {
    return {
      month: MONTHS_AMOUNT,
      year: year - 1,
    }
  } else if (month <= MONTHS_AMOUNT) {
    return {
      month,
      year,
    }
  }
  return {
    month: month - MONTHS_AMOUNT,
    year: year + 1,
  }
}
