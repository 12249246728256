import React from 'react'
import { useFormikContext } from 'formik'
import { LoadingButton } from '../Buttons'

const SUBMIT_TYPE_FIELD = '@submit-type-field'

export const FormSubmit = ({ children, submitType = null, checkDirty = false, disabled, ...props }) => {
  const { isSubmitting, handleSubmit, dirty, setFieldValue } = useFormikContext()

  const handleClick = (event) => {
    if (submitType) {
      setFieldValue(SUBMIT_TYPE_FIELD, submitType)
    }
    handleSubmit(event)
  }

  return (
    <LoadingButton
      {...props}
      disabled={(checkDirty && !dirty) || disabled}
      loading={isSubmitting}
      onClick={handleClick}
      type="submit"
    >
      {children}
    </LoadingButton>
  )
}

FormSubmit.SUBMIT_TYPE_FIELD = SUBMIT_TYPE_FIELD
