import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { $userDatePrefs, buildTransactionLink } from '@features/common'
import { date } from '@lib/date'
import { humanizeSource, humanizeStatus, humanizeType } from '@lib/constants'
import { amount as convertCurrency } from '@lib/convert'
import { $activeTransaction, $isSingleTransactionLoading } from '../selectors'
import { singleTransactionFetch } from '../effects'
import { transactionActions } from '../symbiotes'

export const useModel = () => {
  const dispatch = useDispatch()
  const { transactionId } = useParams()
  const transaction = useSelector($activeTransaction)
  const { timezone, dateTimeFormat } = useSelector($userDatePrefs)
  const isLoading = useSelector($isSingleTransactionLoading)

  useEffect(() => {
    dispatch(singleTransactionFetch(transactionId))
    return () => dispatch(transactionActions.reset())
  }, [transactionId, dispatch])

  const transactionDetails = useMemo(
    () => transaction ? prepareTransactionRows(transaction, timezone, dateTimeFormat) : [],
    [transaction, timezone, dateTimeFormat],
  )

  return {
    transactionDetails,
    transactionId,
    isLoading,
  }
}

const prepareTransactionRows = (transaction, timezone, format) => {
  if (!transaction) {
    return []
  }

  const { processed_at, source, amount, currency, payment_type, description, status, error_details, metadata } = transaction

  return [
    { label: 'Processed at', value: date(processed_at, timezone, format) },
    { label: 'Processor', value: humanizeSource(source) },
    { label: 'Amount', value: `${convertCurrency(amount, '')} ${currency.toUpperCase()}`  },
    { label: 'Type', value: humanizeType(payment_type) },
    { label: 'Description', value: description, link: buildTransactionLink({ metadata, source, value: description }) },
    { label: 'Status', value: humanizeStatus(status), tooltip: error_details },
  ]
}
