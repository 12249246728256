import { useSelector } from 'react-redux'
import { $currentUser, $userSubscription } from '../selectors'
import { guards } from '../guards'

export const Guard = ({ children, isActive, isAdmin, hasIntegration, noIntegration, rulesEngine, syncInvoices }) => {
  const passed = useGuard({
    isActive,
    isAdmin,
    hasIntegration,
    noIntegration,
    rulesEngine,
    syncInvoices,
  })

  return passed ? children : null
}

export const useGuard = ({ isActive, isAdmin, hasIntegration, noIntegration, rulesEngine, syncInvoices }) => {
  const user = useSelector($currentUser)
  const subscription = useSelector($userSubscription)

  const activeGuards = [
    { enabled: isActive, guard: guards.isActive },
    { enabled: isAdmin, guard: guards.isAdmin },
    { enabled: hasIntegration, guard: guards.hasIntegration },
    { enabled: noIntegration, guard: guards.noIntegration },
    { enabled: rulesEngine, guard: guards.rulesEngine },
    { enabled: syncInvoices, guard: guards.syncInvoices },
  ]
    .filter(({ enabled }) => enabled)
    .map(({ guard }) => guard)

  return compileGuardChecks({
    params: { user, subscription },
    guards: activeGuards,
  })
}

const compileGuardChecks = ({ params, guards }) => {
  const checks = []

  for (const key in guards) {
    const fn = guards[key]
    const passed = fn(params)

    checks.push(passed)
  }

  return checks.every(Boolean)
}
