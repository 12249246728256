import React, { useEffect } from 'react'
import { useQueryParams } from '@lib/hooks'
import { history } from '@lib/routing'
import { MainTemplate } from '@features/common'
import { useSelector } from 'react-redux'
import { $sources, $sourcesSettings } from '@features/integrations'
import { CallbackView } from '../components/CallbackView'

export const WooCommerceCallbackPage = () => {
  const { user_id, success } = useQueryParams()
  const sources = useSelector($sources)
  const sourcesSettings = useSelector($sourcesSettings)
  const successfull = success === '1'

  const error = successfull
    ? null
    : { error: 'Got an unexpected error. Please try again.' }

  useEffect(() => {
    if (successfull && user_id && sources.length > 0) {
      const [,domain] = String(user_id).split('@')
      const source = sources.find((source) => source.connection_id === domain)
      if (source) {
        const settings = sourcesSettings.find((settings) => settings.connection_id === source.id)
        if (settings) {
          history.push(`/configuration/${source.id}`)
        }
      } else {
        history.push('/integrations/payments')
      }
    }
  }, [sources, sourcesSettings, successfull, user_id])

  return (
    <MainTemplate>
      <CallbackView
        isLoading={false}
        error={error}
        params={{ success, user_id }}
      />
    </MainTemplate>
  )
}
