import styled from 'styled-components'
import { theme } from 'styled-tools'

export const Label = styled.label`
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  text-align: left;
`

export const InputLabel = styled(Label)`
  color: ${theme('color.text')};
  font-weight: 600;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`

export const ErrorLabel = styled(Label)`
  color: ${theme('color.danger')};
  font-weight: 500;
  margin-top: 0.5rem;
`

export const SuccessLabel = styled(Label)`
  color: ${theme('color.statusActive')};
  font-weight: 500;
  margin-top: 0.5rem;
`
