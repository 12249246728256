import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './style.css'

/**
 * @param {Object} params
 * @param params.title
 * @param params.message
 * @param {'success'|'danger'|'info'|'default'|'warning'} params.type
 * @param params.container
 * @param params.duration
 */
export const notify = ({
  title,
  message,
  type = 'success',
  container = 'bottom-right',
  duration = 3000,
}) => {
  store.addNotification({
    title,
    message,
    type,
    container,
    insert: 'top',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
    },
    slidingExit: {
      duration: 300,
    },
  })
}

export {
  ReactNotification,
}
