import { createSelector } from 'reselect'
import { toSelectItems } from '@lib/convert'
import { $integrationEntities } from './integration'

export const $bankAccountOptions = createSelector(
  $integrationEntities,
  (entities) => toSelectItems(entities.bankAccounts, 'name', 'id'),
)

export const $commonAccountOptions = createSelector(
  $integrationEntities,
  (entities) => toSelectItems(entities.commonAccounts, 'name', 'code'),
)
