import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { borderRadius } from './theme'
import { roundShadow } from '../shadows'

export const PickerWrapper = styled.div`
  position: relative;
`

export const PickerBody = styled.div`
  position: relative;
  display: none;
  padding: 2rem;
  border: 1px solid ${theme('color.border')};
  border-radius: ${borderRadius};
  margin-top: 4px;
  font-size: 14px;
  background-color: white;

  ${ifProp('isOpen', css`
    display: block;
  `)}
  
  ${roundShadow};
`

const NavSide = styled.div`
  position: absolute;
  top: -8px;

  button {
    padding: 8px;
  }
`

export const NavLeft = styled(NavSide)`
  left: 0;
`

export const NavRight = styled(NavSide)`
  right: 0;
`

export const TimePickerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`
