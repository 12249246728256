import { useEffect } from 'react'
import { history } from '@lib/routing'
import { Storage } from '@lib/storage'

export const ShopifyConnectPage = () => {
  useEffect(() => {
    Storage.set('shopify_connect_callback_url', window.location.href)
    Storage.set('from_shopify', true)
    history.push('/')
  }, [])

  return null
}
