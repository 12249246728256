import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { useTable } from 'react-table'
import { $transactionsForTable, transactionsFetch, CellDescription, CellStatus } from '@features/transactions'
import { statisticsFetch } from '../effects'

export const useModel = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(statisticsFetch())
    dispatch(transactionsFetch())
  }, [dispatch])
}

export const useTransactionsTable = () => useTable(
  {
    columns: useMemo(() => ([
      { accessor: 'source', width: 120, Header: 'Processor' },
      { accessor: 'processed_at', width: 190, Header: 'Date' },
      { accessor: 'payment_type', width: 120, Header: 'Type' },
      { accessor: 'description', width: 200, Header: 'Description', Cell: CellDescription },
      { accessor: 'status', width: 100, Header: 'Status', Cell: CellStatus },
      { accessor: 'amount', width: 140, Header: 'Amount' },
      { accessor: 'currency', width: 120, Header: 'Currency' },
    ]), []),
    data: useSelector($transactionsForTable).slice(0, 3),
  },
)
