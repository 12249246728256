import React from 'react'
import { useSelector } from 'react-redux'
import { ConnectXeroButton } from '@features/common'
import { Col, Row } from '@lib/styled-layout'
import { Card, DangerButton, Flex, FormSwitch, Spinner, Text, YesNoModal } from '@ui'
import { ConnectionInfo } from '../../components/ConnectionInfo'
import { NoIntegrations } from '../../components/EmptyViews'
import { $isSettingsLoading } from '../../selectors/integration'
import { $integrationsList } from '../../selectors/integrations'
import { useSettingsModel } from './account.model'
import { IntegrationsHeading } from '../../components/IntegrationsHeading'
import { AddIntegrationModalButton } from '../../connect/AddIntegrationModalButtons'

export const AccountPage = () => (
  <div className="animated fadeInRight">
    <IntegrationsHeading title="Accounting Integration">
      <AddIntegrationModalButton />
    </IntegrationsHeading>
    <IntegrationsList />
  </div>
)

const IntegrationsList = () => {
  const isLoading = useSelector($isSettingsLoading)
  const integrations = useSelector($integrationsList)

  if (isLoading) return <Spinner />
  if (!integrations.length) return <NoIntegrations />

  return integrations.map(({ info, settings }) => (
    <Card
      key={settings.id}
      header={<ConnectionInfo
        name={info.name}
        createdAt={info.createdAt}
        sourceName={info.sourceName}
        status={<IntegrationStatus status={info.status} />}
      />}
    >
      <IntegrationSettings settings={settings} />
    </Card>
  ))
}

const IntegrationStatus = ({ status }) => {
  switch (status) {
    case 'active':
    default:
      return String(status).toUpperCase()
    case 'disconnected':
      return <ConnectXeroButton>Reconnect</ConnectXeroButton>
  }
}

const IntegrationSettings = ({ settings }) => {
  const {
    integration,
    autoReconcile,
    isActive,
    updateAutoReconcile,
    updateIsActive,
    updateSettingsRequest,
    deleteConnection,
    deleteConnectionRequest,
    deleteModal,
  } = useSettingsModel({ settings })

  return (
    <Row>
      <Col md={6}>
        <Flex gapY="16px" direction="column">
          <Flex justify="space-between">
            <FormSwitch
              label={<Text>Export Transactions</Text>}
              subLabel={isActive ? 'ON' : 'OFF'}
              disabled={updateSettingsRequest.isLoading}
              checked={isActive}
              onChange={updateIsActive}
              block
              reversed
            />
          </Flex>
          {integration.source === 'xero' && (
            <Flex justify="space-between">
              <FormSwitch
                label={<Text>Automatically Reconcile Transactions</Text>}
                subLabel={autoReconcile ? 'ON' : 'OFF'}
                disabled={updateSettingsRequest.isLoading}
                checked={autoReconcile}
                onChange={updateAutoReconcile}
                reversed
                block
              />
            </Flex>
          )}
        </Flex>
      </Col>
      <Col md={6}>
        <Flex justify="flex-end">
          <DangerButton onClick={deleteModal.show}>Delete integration</DangerButton>
        </Flex>
      </Col>
      <YesNoModal
        title="Delete integration"
        show={deleteModal.isShown}
        loading={deleteConnectionRequest.isLoading}
        onClose={deleteModal.close}
        onConfirm={deleteConnection}
        yesText="DELETE"
      >
        Are you sure you want to delete the integration?
      </YesNoModal>
    </Row>
  )
}
