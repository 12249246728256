import React from 'react'
import { ErrorBox, Button, CenterContent, CheckField, FormGroup, FormStatus, FormSubmit, H3, InputField, Modal } from '@ui'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { mapErrorResponseToFormErrors } from '@lib/form-utils'
import { api } from '../api'
import { postAccountCancel } from '../effects'

export const DeleteAccountModal = ({ onConfirm, onClose, show }) => (
  <Modal onClose={onClose} size="sm" show={show}>
    <CenterContent>
      <Modal.Title>
        Delete an account?
      </Modal.Title>
      <Modal.Body>
        <Modal.Text>
          Are you sure you want to delete your Bankfeeds account?<br /><br />
          All data associated with this account will be deleted. This action cannot be reversed.
        </Modal.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" size="full" onClick={onClose}>NOT NOW</Button>
        <Button size="full" onClick={onConfirm}>DELETE</Button>
      </Modal.Footer>
    </CenterContent>
  </Modal>
)

export const WhyDeleteAccountModal = ({ onClose, show }) => {
  const dispatch = useDispatch()

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      await api.cancelAccount(values)
      setSubmitting(false)
      setStatus(null)
      onClose()

      dispatch(postAccountCancel())
    } catch (err) {
      setSubmitting(false)
      setStatus(err.response)
    }
  }

  return (
    <Formik
      initialValues={{
        missing_functionality: false,
        too_buggy: false,
        too_expensive: false,
        cancel_reason: '',
        comment: '',
        other: false,
      }}
      initialStatus={null}
      onSubmit={handleSubmit}
    >
      {({
        values,
      }) => (
        <Modal onClose={onClose} size="sm" show={show}>
          <Modal.Title>
            We're sorry to see you go. Can you please let us know what we did wrong?
          </Modal.Title>
          <Modal.Body>
            <FormGroup>
              <ErrorBox>
                <FormStatus
                  render={mapErrorResponseToFormErrors}
                />
              </ErrorBox>
            </FormGroup>
            <FormGroup>
              <H3>Please check all that apply:</H3>
            </FormGroup>
            <FormGroup>
              <CheckField
                name="missing_functionality"
                label="Missing functionality"
              />
            </FormGroup>
            <FormGroup>
              <CheckField
                name="too_buggy"
                label="Too buggy"
              />
            </FormGroup>
            <FormGroup>
              <CheckField
                name="too_expensive"
                label="Too expensive"
              />
            </FormGroup>
            <FormGroup>
              <CheckField
                name="other"
                label="Other"
              />
            </FormGroup>
            {values.other && (
              <FormGroup>
                <InputField
                  name="cancel_reason"
                  label="What was your main reason for cancellation?"
                />
              </FormGroup>
            )}
            <FormGroup>
              <InputField
                name="comment"
                label="Any additional comments? Our CEO and engineering team reads all of these messages"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <FormSubmit size="lg">SUBMIT</FormSubmit>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
