import React from 'react'
import * as P from 'prop-types'
import styled from 'styled-components'
import space from '@styled-system/space'
import { switchProp, theme } from 'styled-tools'
import { spacePT } from './prop-types'

export const Card = ({ header, size, children, ...props }) => (
  <Container {...props}>
    {header && <Header size={size}>{header}</Header>}
    <Body size={size}>{children}</Body>
  </Container>
)

Card.propTypes = {
  size: P.oneOf(['sm', 'md']),
  header: P.node,
  children: P.node,
  ...spacePT,
}

Card.defaultProps = {
  size: 'md',
}

const size = switchProp('size', {
  sm: 16,
  md: 24,
})

const Container = styled.div`
  border: 1px solid ${theme('color.border')};
  border-radius: 3px;
  ${space}
`

const Header = styled.div`
  padding: ${size}px;
  border-bottom: 1px solid ${theme('color.border')};
`

const Body = styled.div`
  padding: ${size}px;
  background-color: white;
`
