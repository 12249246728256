import { NotFoundChildPage } from '@features/common'
import { ImportJobsPage } from './pages/import-jobs'
import { SearchTransactionsPage } from './pages/search-transactions'
import { HistoryPage } from './pages/history'

export const importJobsRoutes = () => [
  {
    path: '/imports',
    component: ImportJobsPage,
    routes: [
      {
        exact: true,
        path: '/imports',
        component: SearchTransactionsPage,
      },
      {
        path: '/imports/history',
        component: HistoryPage,
      },
      {
        component: NotFoundChildPage,
      },
    ],
  },
]
