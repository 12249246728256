import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row } from '@lib/styled-layout'
import { range } from '@lib/array'
import { MainTemplate } from '@features/common'
import {
  Button,
  Card,
  DangerButton,
  DateTimeRangePicker,
  Divider,
  FilterSearch,
  Flex,
  FormCheck,
  FormInput,
  FormRadio,
  FormSwitch,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  LoadingButton,
  RoundLabel,
  Select,
  StatusLabel,
  Tab,
  TagLabel,
  Text,
} from '@ui'
import { theme } from '@lib/theme'
import { isObject } from 'formik'

export const UiKitPage = () => (
  <MainTemplate>
    <div style={{ padding: 25 }}>
      <Row>
        <H2>New Design Components</H2>
      </Row>
      <Row>
        <Col md={3}>
          <Section>
            <DateTimeRangePicker />
          </Section>
          <Section>
            <FormInput
              label="Email"
            />
          </Section>
          <Section>
            <FormInput
              label="Email"
              {...useInputState('Text')}
            />
          </Section>
          <Section>
            <FormInput
              label="Email"
              disabled
              {...useInputState('Text')}
            />
          </Section>
          <Section>
            <FormInput
              label="Email"
              value="Text"
              error="Required"
              {...useInputState('Text')}
            />
          </Section>
          <Section>
            <FormInput
              label="Email"
              placeholder="Placeholder"
            />
          </Section>
          <Section>
            <FormCheck
              label="Selected"
              {...useCheckState(true)}
            />
          </Section>
          <Section>
            <FormCheck
              label="Non Selected"
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormCheck
              label="Disabled"
              {...useCheckState()}
              disabled
            />
          </Section>
          <Section>
            <FormCheck
              label="Errored"
              error="Required"
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormSwitch
              label="Switch"
              {...useCheckState()}
            />
            <FormSwitch
              label="Switch"
              disabled
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormRadio
              label="Radio1"
              name="radio"
              {...useCheckState(true)}
            />
          </Section>
          <Section>
            <FormRadio
              label="Radio2"
              name="radio"
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormRadio
              label="Disabled"
              name="radio"
              disabled
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormCheck
              label="Reversed"
              reversed
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormSwitch
              label="Reversed"
              reversed
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormRadio
              label="Reversed"
              name="radio"
              reversed
            />
          </Section>
          <Section>
            <FormRadio
              label="Block"
              block
              {...useCheckState()}
            />
          </Section>
          <Section>
            <FormRadio
              label="Block Reversed"
              block
              reversed
              {...useCheckState()}
            />
          </Section>
        </Col>
        <Col md={3}>
          <Section>
            <Select
              label="Colors"
              options={colors}
              {...useSelectState()}
            />
          </Section>
          <Section>
            <Select
              label="Colors"
              options={colors}
              disabled
              {...useSelectState(colors[0].value)}
            />
          </Section>
          <Section>
            <Select
              label="Colors"
              options={colors}
              error="Required"
              {...useSelectState(colors[0].value)}
            />
          </Section>
          <Section>
            Clearable
            <Select
              label="Colors"
              options={colors}
              isClearable
              {...useSelectState()}
            />
          </Section>
          <Section>
            Searchable
            <Select
              label="Colors"
              options={colors}
              isSearchable
              {...useSelectState()}
            />
          </Section>
          <Section>
            Clearable
            <br />
            Searchable
            <Select
              label="Colors"
              options={colors}
              isClearable
              isSearchable
              {...useSelectState()}
            />
          </Section>
          <Section>
            Menu position "top"
            <Select
              label="Colors"
              options={colors}
              menuPosition="top"
              {...useSelectState(colors[0].value)}
            />
          </Section>
          <Section>
            <H3>Card</H3>
            <Card header="Header">Body</Card>
          </Section>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Section>
                <Button>Primary</Button>
              </Section>
              <Section>
                <Button variant="outline">Outline</Button>
              </Section>
              <Section>
                <Button variant="link">Link</Button>
              </Section>
              <Section>
                <Button variant="light">Light</Button>
              </Section>
              <Section>
                <Button variant="secondary">Secondary</Button>
              </Section>
              <Section>
                <Button size="full">Size full</Button>
              </Section>
              <Section>
                <Button size="lg">Size lg</Button>
              </Section>
              <Section>
                <Button size="md">Size md</Button>
              </Section>
              <Section>
                <Button size="xs">Side xs</Button>
              </Section>
              <Section>
                <LoadingButton loading>Loading</LoadingButton>
              </Section>
              <Section>
                <LoadingButton size="full" loading>Loading full</LoadingButton>
              </Section>
              <Section>
                <IconButton icon={<Icon name="search" variant="white" />}>Button with icon</IconButton>
              </Section>
              <Section>
                <DangerButton>Danger</DangerButton>
              </Section>
            </Col>
            <Col md={6}>
              <Section>
                <Button disabled>Primary</Button>
              </Section>
              <Section>
                <Button disabled variant="outline">Outline</Button>
              </Section>
              <Section>
                <Button disabled variant="link">Link</Button>
              </Section>
              <Section>
                <Button disabled variant="light">Light</Button>
              </Section>
              <Section>
                <Button disabled variant="secondary">Secondary</Button>
              </Section>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Section>
                <H1>H1</H1>
              </Section>
              <Section>
                <H2>H2</H2>
              </Section>
              <Section>
                <H3>H3</H3>
              </Section>
              <Section>
                <H4>H4</H4>
              </Section>
              <Section>
                <H5>H5</H5>
              </Section>
              <Section>
                <H6>H6</H6>
              </Section>
              <Section>
                <Link>Link</Link>
              </Section>
            </Col>
            <Col md={6}>
              <Section>
                <StatusLabel variant="done">Done</StatusLabel>
              </Section>
              <Section>
                <StatusLabel variant="queue">In Queue</StatusLabel>
              </Section>
              <Section>
                <StatusLabel variant="progress">In Progress</StatusLabel>
              </Section>
              <Section>
                <StatusLabel variant="failed">Failed</StatusLabel>
              </Section>
              <Section>
                <StatusLabel variant="skipped">Skipped</StatusLabel>
              </Section>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Section>
            <H2>Icons</H2>
            <Flex style={{ flexWrap: 'wrap' }}>
              {Object.values(Icon.ICONS).map((icon) => (
                <Flex m="0.5rem" style={{ flexDirection: 'column', width: 150 }} key={icon}>
                  <Icon name={icon} variant="dark" />
                  <Text>{icon}</Text>
                </Flex>
              ))}
            </Flex>
          </Section>
          <Section>
            <H2>Colors</H2>
            <Flex style={{ flexWrap: 'wrap' }}>
              {Object.entries(theme.color).map(([name, color]) => isObject(color) ? null : (
                <Flex m="0.5rem" style={{ flexDirection: 'column', width: 150 }} key={name}>
                  <Flex style={{ height: '20px', backgroundColor: color, border: '1px solid #ccc' }} />
                  <Text>{name} {color}</Text>
                </Flex>
              ))}
            </Flex>
          </Section>
        </Col>
        <Col md={6}>
          <Section>
            <H2>List</H2>
            <List style={{ width: 200 }} hoverable>
              <ListItem>First</ListItem>
              <ListItem>Second</ListItem>
              <ListItem isSelected>Third</ListItem>
            </List>
          </Section>
          <Section>
            <H2>List (Items with borders)</H2>
            <List style={{ width: 200 }} gap="8px" bordered={false}>
              <ListItem bordered>First</ListItem>
              <ListItem bordered>Second</ListItem>
              <ListItem isSelected bordered>Third</ListItem>
            </List>
          </Section>
          <Section>
            <TagLabel>for the last month</TagLabel>
          </Section>
          <Section>
            <TagLabel>sales</TagLabel>
          </Section>
          <Section>
            <RoundLabel variant="success">Active</RoundLabel>
          </Section>
          <Section>
            <RoundLabel variant="secondary">Disconnected</RoundLabel>
          </Section>
          <TabSection>
            <Tab>All</Tab>
            <Tab variant="secondary">Notifications</Tab>
          </TabSection>
          <Section>
            <FilterSearch
              {...useInputState()}
            />
          </Section>
          <Section>
            <FilterSearch
              {...useInputState()}
              dark
            />
          </Section>
          <Divider />
          <Divider />
        </Col>
      </Row>
    </div>
  </MainTemplate>
)

const useSelectState = (init) => {
  const [value, setValue] = useState(init)
  return {
    value,
    onChange(val) {
      setValue(val)
    },
  }
}

const useInputState = (init = '') => {
  const [value, setValue] = useState(init)
  return {
    value,
    onChange(e) {
      setValue(e.target.value)
    },
    onReset() {
      setValue(init)
    },
  }
}

const useCheckState = (init = false) => {
  const [value, setValue] = useState(init)
  return {
    checked: value,
    onChange(e) {
      setValue(e.target.checked)
    },
  }
}

const colors = range(1000).map((value) => ({ label: `Color ${value}`, value }))

const Section = styled.div`
  margin-top: 20px;
`

const TabSection = styled(Section)`
  > * {
    margin-right: 30px;
  }
`
