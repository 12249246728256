import React, { useCallback, memo, useRef, useEffect } from 'react'
import { useField } from 'formik'
import { Select } from '../Select'

const MemoSelect = memo(Select)

export const SelectField = ({ name, label, options, placeholder, disabled, isSearchable, isClearable, onChange }) => {
  const [
    { onBlur },
    { touched, error, value },
    { setValue },
  ] = useField(name)

  const savedOnChange = useRef()

  useEffect(() => {
    savedOnChange.current = onChange
  }, [onChange])

  const setValueCb = useCallback(
    (name, value) =>
      savedOnChange.current
        ? savedOnChange.current(name, value)
        : setValue(value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <MemoSelect
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      name={name}
      value={value}
      error={touched && error}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onChange={setValueCb}
      onBlur={onBlur}
    />
  )
}
