import React, { forwardRef, useCallback } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

// make scrollbars compatible with react-window
export const CustomScrollbarsList = forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
))

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef._osInstance.getElements('viewport'))
    } else {
      forwardedRef(null)
    }
  }, [forwardedRef])

  return (
    <OverlayScrollbarsComponent
      ref={refSetter}
      options={{
        callbacks: {
          onScroll,
        },
        scrollbars: {
          autoHide: 'leave',
        },
      }}
      style={style}
      onScroll={onScroll}
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}
