import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { theme } from 'styled-tools'
import { useFormikContext } from 'formik'
import { Button, H5, LoadingButton } from '../index'

export const SaveFormChangesDialog = ({ disabled }) => {
  const { resetForm, handleSubmit, isSubmitting, dirty } = useFormikContext()

  return dirty ? (
    <Offset>
      <Wrapper>
        <Container>
          <H5 variant="light">Unsaved Changes</H5>
          <Actions>
            <Button variant="light" onClick={resetForm}>RESET</Button>
            <LoadingButton
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={disabled}
              type="button"
            >
              SAVE CHANGES
            </LoadingButton>
          </Actions>
        </Container>
      </Wrapper>
    </Offset>
  ) : null
}

const Offset = styled.div`
  position: relative;
  margin-bottom: 56px;
  z-index: ${theme('order.dialog')};
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 272px;
  right: 0;
`

const Actions = styled.div`
  ${Button}:not(:first-child) {
    margin-left: 13px;
  }
`

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme('color.border')};
  padding: 16px 24px 16px 88px;

  ${down('md')} {
    padding-left: 25px;
  }
`
