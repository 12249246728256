import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from '@lib/styled-layout'
import { useUserDatePrefs } from '@features/common'
import { $invoicesList, $isLoading } from '@features/invoices'
import { date } from '@lib/date'
import { capitalize } from '@lib/string'
import { H2, H4, Table, TableBody, TableHead, TableLink, Spinner } from '@ui'
import { useTable } from 'react-table'

export const BillingLog = () => (
  <Row>
    <Col>
      <H2 mb="2rem">Billing Log</H2>
      <BillingTable />
    </Col>
  </Row>
)

const BillingTable = () => {
  const invoices = useSelector($invoicesList)
  const isLoading = useSelector($isLoading)
  const table = useBillingTable()

  if (isLoading) {
    return <Spinner marginTop={0} />
  }
  if (!invoices.length) {
    return <H4>Billing log empty.</H4>
  }

  return (
    <Table {...table.getTableProps()}>
      <TableHead
        headerGroups={table.headerGroups}
      />
      <TableBody
        rows={table.rows}
        prepareRow={table.prepareRow}
      />
    </Table>
  )
}

const useBillingTable = () => useTable({
  columns: useColumns(),
  data: useSelector($invoicesList),
})

const useColumns = () => {
  const { timezone, dateformat } = useUserDatePrefs()
  return useMemo(() => {
    const formatTimestamp = (timestamp) => date(timestamp * 1000, timezone, dateformat)
    return [
      {
        accessor: 'created',
        Header: 'Date',
        width: 100,
        Cell: ({ cell }) => formatTimestamp(cell.value),
      },
      {
        accessor: 'description',
        Header: 'Description',
        width: 300,
        Cell: ({ row: { original: invoice } }) => (
          `Bankfeeds ${capitalize(invoice.name)} (${formatTimestamp(invoice.plan_start)} - ${formatTimestamp(invoice.plan_end)})`
        ),
      },
      {
        id: 'receipt',
        Header: '',
        width: 50,
        Cell: ({ row: { original: invoice } }) => (
          invoice.pdf ? (
            <TableLink as="a" href={invoice.pdf} rel="noopener noreferrer" target="_blank">
              Receipt
            </TableLink>
          ) : null
        ),
      },
      {
        id: 'invoice',
        Header: '',
        width: 50,
        Cell: ({ row }) => (
          <TableLink as={Link} to={`/invoices/${row.original.id}`}>
            Invoice
          </TableLink>
        ),
      },
      {
        accessor: 'amount',
        Header: 'Amount',
        Cell: ({ row: { original: invoice } }) => (
          `${(invoice.amount / 100).toFixed(2)} ${invoice.currency.toUpperCase()}`
        ),
      },
    ]
  }, [dateformat, timezone])
}
