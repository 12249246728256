import React from 'react'
import styled from 'styled-components'
import { Flex, H2, H3 } from '@ui'

export const AccountBalances = ({ balances }) => (
  <Container>
    <H2>Account Balances</H2>
    <Flex direction="column">
      {balances.map((balance) => (
        <Flex key={balance.id}>
          <BankName>{balance.name}</BankName>
          <H3>{balance.balance}</H3>
        </Flex>
      ))}
    </Flex>
  </Container>
)

const Container = styled.div`
  margin: 0.5rem 0;
`

const BankName = styled(H3)`
  min-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
