import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Input } from '../Input'
import { InputGroup } from '../InputGroup'
import { Icon } from '../Icon'
import { Positioner } from '../Positioner'

export const DateInput = React.forwardRef(({ open, isOpen, disabled, ...props }, ref) => (
  <InputGroup>
    <InputLabel disabled={disabled}>
      <StyledInput {...props} disabled={disabled} ref={ref} />
      <Positioner position="right">
        <TriggerButton onClick={open}>
          <Icon name="calendar" variant={isOpen ? 'primary' : 'gray'} />
        </TriggerButton>
      </Positioner>
    </InputLabel>
  </InputGroup>
))

const StyledInput = styled(Input)`
  cursor: ${ifProp('disabled', 'default', 'text')};
  padding-right: 40px;
`

const InputLabel = styled.label`
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 0;
  cursor: ${ifProp('disabled', 'default', 'text')};
`

const TriggerButton = styled.button`
  appearance: none;
  border: 0;
  padding: 10px 14px;
  background: none;
  cursor: pointer;
  outline: none;
`
