import { createSymbiote } from 'redux-symbiote'
import { initialFetching, createFetching } from '@lib/fetching'

const initialState = {
  fetching: initialFetching,
  taxRates: [],
}

const symbiotes = {
  fetch: createFetching(),

  set: (state, payload) => ({
    ...state,
    taxRates: payload,
  }),

  reset: () => initialState,
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'integration/tax-rates',
)
