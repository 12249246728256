import React, { createContext, useState, useEffect, useContext } from 'react'
import {
  StripeProvider as StripeProviderInner,
  Elements as ElementsInner,
  injectStripe,
} from 'react-stripe-elements'

export const StripeProvider = ({ children, apiKey }) => {
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    const initializeStripe = () => {
      setStripe(window.Stripe(apiKey))
    }

    if (window.Stripe) {
      initializeStripe()
    } else {
      // Otherwise wait for Stripe script to load
      document
        .getElementById('stripe-js')
        .addEventListener('load', initializeStripe)
    }
  }, [apiKey])

  return (
    <StripeProviderInner stripe={stripe}>
      {children}
    </StripeProviderInner>
  )
}

const HookContext = createContext()
const HookProvider = injectStripe(({ stripe, children }) => (
  <HookContext.Provider value={stripe}>
    {children}
  </HookContext.Provider>
))

export const Elements = ({ children }) => (
  <ElementsInner>
    <HookProvider>{children}</HookProvider>
  </ElementsInner>
)

export const useStripe = () => useContext(HookContext)
