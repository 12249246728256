import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { palette, switchProp, theme } from 'styled-tools'

export const ButtonNative = styled.button`
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  outline: none;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  border: 1px solid transparent;
`

export const Button = styled(ButtonNative)`
  transition:
    color .15s ease-in-out,
    border-color .15s ease-in-out,
    background-color .15s ease-in-out;

  min-width: ${switchProp('size', {
    auto: 'auto',
    md: '120px',
    lg: '152px',
    xs: '90px',
  })};

  ${switchProp('size', {
    xs: css`
      font-size: 0.75rem;
      padding: 3px 0.5rem;
    `,
    full: css`
      width: 100%;
    `,
  })}

  ${({ theme, variant }) => css`
    background-color: ${theme.base[variant]};
    color: ${theme.base[variant]};
    border-color: ${theme.base[variant]};
  `}

  ${switchProp('variant', {
    primary: css`
      color: white;
    `,
    outline: css`
      background-color: white;
    `,
    link: css`
      color: ${theme('color.primary')};
    `,
    light: css`
      color: ${theme('color.gray')};
    `,
    secondary: css`
      color: ${theme('color.dark')};
    `,
    success: css`
      background-color: ${theme('color.success')};
      color: white;
    `,
    danger: css`
      background-color: ${theme('color.danger')};
      color: white;
    `,
  })}

  &:hover {
    ${({ theme, variant }) => css`
      background-color: ${theme.hover[variant]};
      color: ${theme.hover[variant]};
      border-color: ${theme.hover[variant]};
    `}

    ${switchProp('variant', {
    primary: css`
      color: white;
    `,
    outline: css`
      background-color: white;
    `,
    link: css`
      color: ${palette('primary', 2)};
    `,
    light: css`
      color: ${theme('color.gray')};
    `,
    secondary: css`
      color: ${theme('color.dark')};
    `,
  })}
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    opacity: 0.5;
  }

  &:disabled {
    cursor: default;

    ${switchProp('variant', {
    primary: css`
      border-color: ${theme('color.gray')};
      background-color: ${theme('color.gray')};
    `,
    outline: css`
      border-color: ${theme('color.gray')};
      color: ${theme('color.gray')};
    `,
    link: css`
      color: ${theme('color.gray')};
    `,
    secondary: css`
      background-color: ${theme('color.secondary')};
    `,
  })}
  }
`

export const propTypes = {
  variant: P.oneOf(['primary', 'outline', 'link', 'light', 'secondary', 'default', 'danger', 'success']),
  size: P.oneOf(['auto', 'lg', 'md', 'full', 'xs']),
  disabled: P.bool,
  as: P.any,
  type: P.string,
  onClick: P.func,
}

Button.propTypes = propTypes

Button.defaultProps = {
  variant: 'primary',
  as: 'button',
  size: 'auto',
  disabled: false,
  type: 'button',
}

export const ZeroButton = styled.button.attrs({ type: 'button' })`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  outline: none;
`

export const DangerButton = styled.button.attrs({ type: 'button' })`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  outline: none;
  font-weight: 600;
  color: ${theme('color.red')};
  
  font-size: ${switchProp('size', {
    sm: 12,
    md: 14,
  })}px;
`

DangerButton.propTypes = {
  size: P.oneOf(['sm', 'md']),
}

DangerButton.defaultProps = {
  size: 'md',
}
