import React from 'react'
import styled from 'styled-components'
import { Icon, Select } from '@ui'
import { dashboardTermOptions } from '../constants'

export const TermSelect = ({ value, onChange, dateInputValue }) => (
  <Select
    value={value}
    onChange={onChange}
    placeholder="Select..."
    options={dashboardTermOptions}
    components={{
      Input: (props) => (
        <Input
          {...props}
          value={props.value || dateInputValue }
        />
      ),
      Expander,
    }}
  />
)

const Input = styled.input`
  pointer-events: none;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  height: 30px;
  text-align: right;
  font-size: 13px;
`

const Expander = () => (
  <Icon name="arrow" width={11} height={6} />
)
