import React, { useEffect, useState } from 'react'
import { Alert } from '@ui'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { $integrationName } from '@features/session'
import { $activeProcessorSettings } from '../selectors/configuration'

export const ConfigurationAlerts = ({ scrollToTop = false }) => {
  const { settings } = useSelector($activeProcessorSettings)
  const integrationName = useSelector($integrationName)

  const [formSaved, setFormSaved] = useState(false)
  const { isSubmitting } = useFormikContext()

  const alerts = formSaved && !isSubmitting ? getAlerts({ settings, integrationName }) : []
  const hasAlerts = alerts.length > 0

  useEffect(() => {
    if (!formSaved && isSubmitting) {
      setFormSaved(true)
    }
  }, [formSaved, isSubmitting])

  useEffect(() => {
    if (scrollToTop && hasAlerts) {
      window.scroll(0, 0)
    }
  }, [hasAlerts, scrollToTop])

  return alerts
}

const getAlerts = ({ settings, integrationName }) => {
  const alerts = []

  if (!settings.bankfeeds_account) {
    alerts.push(
      () => <Alert type="danger">
        <strong>Your transactions are not being imported into {integrationName}</strong>{' '}
        Please select a bank account from "Bankfeeds Account".
      </Alert>,
    )
  }

  return alerts.map((Alert, index) => (
    <Alert key={index} />
  ))
}
