import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { useDeferredValue } from '@lib/hooks'
import { H3, H6, Progress, Text, Link } from '@ui'

export const StatusReport = ({ label, color, amount, amountPercent, status }) => (
  <div>
    <H6 variant={color}>
      <Link as={RouterLink} to={`/transactions?status[]=${status}`}>{label}</Link>
    </H6>
    <StatusRates>
      <StatusText>{amount}</StatusText>
      <H3>{amountPercent}%</H3>
    </StatusRates>
    <AnimatedProgress variant={color} value={amountPercent} />
  </div>
)

const AnimatedProgress = ({ variant, value }) => (
  <Progress
    variant={variant}
    value={useDeferredValue(value)}
    dots
  />
)

const StatusRates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const StatusText = styled(Text)`
  font-size: 30px;
  font-weight: 600;
`
