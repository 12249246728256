import React from 'react'
import styled, { css } from 'styled-components'
import * as P from 'prop-types'
import { ifProp, theme } from 'styled-tools'
import { Button, Icon } from '../index'

const range = (from, to) => {
  const results = []
  for (let i = from; i <= to; i++) {
    results.push(i)
  }
  return results
}

const mapAttrs = ({ active }) => ({ variant: active ? 'primary' : 'link' })

const PageButton = styled(Button).attrs(mapAttrs)`
  width: 32px;
  height: 32px;
  padding: 0;
  transition: none;
  
  ${ifProp(({ variant }) => variant === 'link', css`
    color: ${theme('color.dark')};
    &:hover {
      color: ${theme('color.dark')};
    }
  `)}
`

const Container = styled.div`
  ${Button} + ${Button} {
    margin-left: 7px;
  }
`

export const Pagination = ({ pagination }) => {
  const { page, pageCount, nextPage, previousPage, gotoPage, count } = pagination

  const from = Math.max(page - 3, 1)
  const to = Math.min(from + 4, pageCount)

  const firstPage = () => gotoPage(1)
  const lastPage = () => gotoPage(pageCount)
  const ranges = range(from, to)

  return count > 0 ? (
    <Container>
      {ranges.map((nthPage) =>
        <PageButton
          onClick={() => gotoPage(nthPage)}
          active={nthPage === page}
          key={nthPage}
        >
          {nthPage}
        </PageButton>,
      )}
      <PageButton onClick={firstPage} disabled={page === 1}>
        <Icon name="doubleChevronLeft" height={13} />
      </PageButton>
      <PageButton onClick={previousPage} disabled={page === 1}>
        <Icon name="chevronLeft" height={13} />
      </PageButton>
      <PageButton onClick={nextPage} disabled={page === pageCount}>
        <Icon name="chevronRight" height={13} />
      </PageButton>
      <PageButton onClick={lastPage} disabled={page === pageCount}>
        <Icon name="doubleChevronRight" height={13} />
      </PageButton>
    </Container>
  ) : null
}

Pagination.propTypes = {
  pagination: P.shape({
    count: P.number,
    page: P.number.isRequired,
    pageCount: P.number.isRequired,
    pageSize: P.number.isRequired,
    setPageSize: P.func.isRequired,
    gotoPage: P.func.isRequired,
    previousPage: P.func.isRequired,
    nextPage: P.func.isRequired,
  }).isRequired,
}
