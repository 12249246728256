import * as Yup from 'yup'

Yup.addMethod(Yup.object, 'uniqueProperty', function (property, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[property]) {
      return true
    }

    const { path } = this
    const options = [...this.parent]
    const currentIndex = options.indexOf(value)

    const subOptions = options.slice(0, currentIndex)

    if (subOptions.some((option) => option[property] === value[property])) {
      throw this.createError({
        path: `${path}.${property}`,
        message,
      })
    }

    return true
  })
})
