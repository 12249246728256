import { request } from '@features/common'

export const api = {
  startCheckout: (plan, source) => request('POST', '/checkout', { data: { plan, source } }),

  subscribe: (params) => request('POST', '/subscription', { data: (params || { }) }),

  updatePlan: (plan) => request('PUT', '/subscription', { data: { plan }}),

  getPaymentDetails: () => request('GET', '/payment-details'),

  addCard: (cardToken) => request('POST', '/payment-details', {
    data: {
      do: 'add-card',
      token: cardToken,
    },
  }),

  makeCardDefault: (cardId) => request('POST', '/payment-details', {
    data: {
      do: 'make-default',
      id: cardId,
    },
  }),

  removeCard: (cardId) => request('POST', '/payment-details', {
    data: {
      do: 'remove-card',
      id: cardId,
    },
  }),
}
