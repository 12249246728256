import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { usePopper } from 'react-popper'
import { Portal } from '@lib/portal'
import { useOpened } from '@lib/hooks'

export const TooltipContainer = ({ children, tooltip, tooltipDelay }) => {
  const [showPopper, setShowPopper] = useState(false)
  const { opened, open, close } = useOpened()
  const reference = useRef()
  const popper = useRef()

  const { styles, attributes } = usePopper(reference.current, popper.current, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [5, 5],
        },
      },
    ],
  })

  useEffect(() => {
    if (reference.current) {
      const enterHandler = () => setShowPopper(true)
      const exitHandler = () => setShowPopper(false)
      const element = reference.current
      element.addEventListener('mouseenter', enterHandler)
      element.addEventListener('mouseleave', exitHandler)

      return () => {
        element.removeEventListener('mouseenter', enterHandler)
        element.removeEventListener('mouseleave', exitHandler)
      }
    }

    return () => { }
  }, [reference, setShowPopper])

  useEffect(() => {
    if (showPopper) {
      if (tooltipDelay) {
        let timeout = setTimeout(() => {
          open()
        }, tooltipDelay)
  
        return () => {
          clearInterval(timeout)
        }
      }

      open()
      return () => { }
    }

    close()
    return () => { }
  // eslint-disable-next-line
  }, [showPopper])

  return (
    <Container>
      <Trigger ref={reference}>
        {children}
      </Trigger>
      {showPopper ? (
        <Portal id="tooltip">
          <Popper
            ref={popper}
            style={styles.popper}
            {...attributes.popper}
          >
            <Content show={opened}>
              {tooltip}
            </Content>
          </Popper>
        </Portal>
      ) : null}
    </Container>
  )
}

const Container = styled.div``

const Arrow = styled.div`
  &, &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    background: ${theme('color.tooltipBg')};
  }
`

const Popper = styled.div`
  &[data-popper-placement^='top'] ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] ${Arrow} {
    left: -4px;
  }
`

const Content = styled.div`
  background: ${theme('color.tooltipBg')};
  color: white;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 3px;
  max-width: 220px;
  line-height: 1.55;
  display: ${ifProp('show', 'block', 'none')};
`

const Trigger = styled.div`
  display: inline-block;
`
