import { handleFetching } from '@lib/fetching'
import { transactionTypes } from '@lib/constants'
import { transactionsApi } from '@features/transactions'
import { transactionsActions } from '../symbiotes'
import { $latestActiveImportJob } from '../selectors/active-import-jobs'
import { pageSizes } from '../constants'

/**
 * Fetches transactions associated with the latest active import job.
 */
export const importingTransactionsFetch = (params = {}) =>
  handleFetching(transactionsActions.fetch, {
    async run(dispatch, getState) {
      const importJob = $latestActiveImportJob(getState())
      if (!importJob) return

      const { data, count, totalCount } = await transactionsApi.getImportingList(importJob.id, params)
      const pageSize = params.pageSize || pageSizes.transactions
      const pageCount = Math.ceil(count / pageSize)

      dispatch(transactionsActions.set({ data, count, totalCount, pageCount }))
    },
  })

export const importingTransactionsFetchAll = () => (dispatch) => {
  // Don't show old transactions on the page.
  dispatch(transactionsActions.reset())

  dispatch(importingTransactionsFetch({
    filters: { types: transactionTypes },
  }))
}
