const isDev = process.env.NODE_ENV !== 'production'

export const buildTransactionLink = ({ metadata = {}, source, value }) => {
  const builder = builders[source] || defaultBuilder
  return builder({ metadata, value }) || emptyResult
}

const emptyResult = {
  text: null,
  url: null,
}

const defaultBuilder = () => emptyResult

const builders = {
  stripe({ metadata, value }) {
    const baseUrl = `https://dashboard.stripe.com${isDev ? '/test' : ''}`
    const { stripe_transfer_id, stripe_charge_id, stripe_dispute_id, stripe_app_fee_id } = metadata

    if (stripe_transfer_id) {
      const finalId = stripe_transfer_id
      return {
        url: `${baseUrl}/transfers/${finalId}`,
        text: value || finalId,
      }
    } else if (stripe_charge_id ) {
      const finalId = stripe_charge_id
      return {
        url: `${baseUrl}/payments/${finalId}`,
        text: value || finalId,
      }
    } else if (stripe_dispute_id) {
      const finalId = stripe_dispute_id
      return {
        url: `${baseUrl}/payments/${finalId}`,
        text: value || finalId,
      }
    } else if (stripe_app_fee_id) {
      const finalId = stripe_app_fee_id
      return {
        url: `${baseUrl}/connect/application_fees/${finalId}`,
        text: value || finalId,
      }
    }

    return {
      url: null,
      text: value,
    }
  },

  pinpayments({ metadata, value }) {
    const baseUrl = `https://dashboard.pinpayments.com${isDev ? '/test' : ''}`
    const { pin_transfer_id, pin_charge_id } = metadata

    if (pin_transfer_id) {
      const finalId = pin_transfer_id
      return {
        url: `${baseUrl}/transfers/${finalId}`,
        text: value || finalId,
      }
    } else if (pin_charge_id) {
      const finalId = pin_charge_id
      return {
        url: `${baseUrl}/charges/${finalId}`,
        text: value || finalId,
      }
    }
  },

  gocardless({ metadata, value }) {
    const baseUrl = `https://manage${isDev ? '-sandbox': ''}.gocardless.com`
    const { gocardless_payment_id, gocardless_payout_id } = metadata

    if (gocardless_payment_id) {
      const finalId = gocardless_payment_id
      return {
        url: `${baseUrl}/payments/${finalId}`,
        text: value || finalId,
      }
    } else if (gocardless_payout_id) {
      const finalId = gocardless_payout_id
      return {
        url: `${baseUrl}/payouts/${finalId}`,
        text: value || finalId,
      }
    }
  },

  shopify({ metadata, value }) {
    const { shopify_charge_id, shopify_transfer_id, shop } = metadata
    // shopify url starts with shop name, use shop from metadata to build the url
    const baseUrl = `https://${shop}/admin`

    if (shopify_charge_id) {
      const finalId = shopify_charge_id
      return {
        url: `${baseUrl}/orders/${finalId}`,
        text: value || finalId,
      }
    } else if (shopify_transfer_id) {
      const finalId = shopify_transfer_id
      return {
        url: `${baseUrl}/payouts/${finalId}`,
        text: value || finalId,
      }
    }
  },

  woocommerce({ metadata, value }) {
    const { woocommerce_order_id, domain } = metadata
    const getOrderUrl = (id) => `https://${domain}/wp-admin/post.php?post=${id}&action=edit`

    if (woocommerce_order_id) {
      return {
        url: getOrderUrl(woocommerce_order_id),
        text: value || woocommerce_order_id,
      }
    }
  },
}
