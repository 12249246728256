import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import { useRequest } from '@lib/fetching'
import { restCountriesApi } from './api'

export const timezonesOptions = moment.tz.names().map((name) => ({ label: name, value: name }))

export const useCountries = () => {
  const countriesRequest = useRequest(restCountriesApi.getCountries, {
    initialData: [],
  })

  useEffect(() => {
    countriesRequest()
  }, [countriesRequest])

  return countriesRequest.data
}

export const useCountriesOptions = () => {
  const countries = useCountries()

  return useMemo(
    () => createCountriesOptions(countries),
    [countries],
  )
}

/**
 * @param {[]<{ name: string, alpha2Code: string }>} countries
 * @return {[]}
 */
const createCountriesOptions = (countries) => {
  // Countries with the given codes will be on top of options list.
  const topOptions = {
    AU: null,
    GB: null,
    US: null,
  }

  const options = []

  for (const { name, alpha2Code } of countries) {
    const option = {
      label: name,
      value: alpha2Code,
    }

    if (alpha2Code in topOptions) {
      topOptions[alpha2Code] = option
    } else {
      options.push(option)
    }
  }

  return [
    ...Object.values(topOptions).filter(Boolean),
    ...options,
  ]
}
