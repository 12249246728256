import { AccountSettingsPage } from './pages/settings'
import { VerifyEmailPage } from './pages/verify-email'

export const accountRoutes = () => [
  {
    path: '/settings',
    component: AccountSettingsPage,
  },
  {
    path: '/verify-email',
    component: VerifyEmailPage,
  },
]
