import React, { useLayoutEffect, useRef } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { ifProp, theme } from 'styled-tools'
import styled, { css } from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import { formatTime, useTimePicker } from '../../lib/date-picker-hooks'
import { List, ListItem } from '../List'
import { roundShadow } from '../shadows'
import { borderRadius } from './theme'
import { BackgroundPositioner } from '../Positioner'

export const TimePicker = ({ date, onChange, disabled }) => {
  const {
    isOpen,
    hour,
    minute,
    hourOptions,
    minuteOptions,
    show,
    close,
    changeHour,
    changeMinute,
  } = useTimePicker({
    date,
    onChange,
  })

  const bodyRef = useRef(null)
  useOnClickOutside(bodyRef, close)

  return (
    <Wrapper>
      <SelectedTime onClick={show} disabled={disabled} active={isOpen}>
        {formatTime(hour)}:{formatTime(minute)}
      </SelectedTime>
      <BackgroundPositioner position="top">
        {isOpen && (
          <Body ref={bodyRef}>
            <PickerList
              options={hourOptions}
              onSelectItem={changeHour}
              selected={hour}
            />
            <PickerList
              options={minuteOptions}
              onSelectItem={changeMinute}
              selected={minute}
            />
          </Body>
        )}
      </BackgroundPositioner>
    </Wrapper>
  )
}

const PickerList = ({ options, selected, onSelectItem }) => {
  const scrollbar = useRef(null)

  useLayoutEffect(() => {
    if (scrollbar.current) {
      const index = options.findIndex(({ value }) => value === selected)
      scrollbar.current._osInstance.scroll({ y: index * 32 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OverlayScrollbarsComponent
      style={{
        width,
        height: scrollHeight,
      }}
      ref={scrollbar}
    >
      <TimeList hoverable>
        {options.map(({ label, value }) => (
          <TimeListItem
            key={value}
            onClick={() => onSelectItem(value)}
            isSelected={value === selected}
          >
            {label}
          </TimeListItem>
        ))}
      </TimeList>
    </OverlayScrollbarsComponent>
  )
}

const width = '48px'
const height = '32px'
const scrollHeight = '192px'
const timeTextWidth = '98px'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const Body = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid ${theme('color.border')};
  border-radius: ${borderRadius};

  ${roundShadow};
`

const activeCss = css`
  background-color: ${theme('color.lightGray')};
`

const SelectedTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${timeTextWidth};
  height: ${height};

  &:hover {
    ${activeCss};
  }

  ${ifProp('active', activeCss)}

  ${ifProp('disabled', css`
    pointer-events: none;
    color: ${theme('color.gray')};
  `)}
`

const TimeList = styled(List)`
  border: none;
`

const TimeListItem = styled(ListItem)`
  cursor: pointer;
  width: 100%;
  height: ${height};
  line-height: ${height};
  padding: 0 0 0 0.75rem;
`
