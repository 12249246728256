// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from 'axios'
import { Token } from '@lib/token'

/**
 * @param {"GET"|"POST"|"PUT"|"PATCH"|"DELETE"} method
 * @param {string} url
 * @param {AxiosRequestConfig} options
 */
export const request = async (method, url, options = {}) => {
  const baseURL = process.env.REACT_APP_API_HOST
  const token = Token.get()

  const response = await axios({
    method,
    url,
    baseURL,
    ...options,
    // Specified headers in options overrides default headers.
    headers: options.headers || {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}
