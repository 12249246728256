import React from 'react'
import styled from 'styled-components'
import { Text } from './Text'

export const Empty = ({ img, description }) => (
  <Container>
    <div><img src={img} alt="no data" /></div>
    <Text mt="36px">
      {description}
    </Text>
  </Container>
)

const Container = styled.div`
  margin-top: 120px;
  text-align: center;
`
