import React from 'react'
import * as P from 'prop-types'
import { switchProp } from 'styled-tools'
import styled, { css, keyframes } from 'styled-components'

const border = keyframes`
  100% {
    transform: rotate(1turn);
  }
`

export const InlineSpinner = styled.div`
  display: inline-block;
  height: 2rem;
  vertical-align: text-bottom;
  border-style: solid;
  border-right: .25em solid transparent;
  border-radius: 50%;
  animation: ${border} .75s linear infinite;
  font-size: 13px;
  color: ${switchProp('variant', {
    secondary: '#676a6c',
    white: 'white',
  })};

  ${switchProp('size', {
    sm: css`
      width: 1rem;
      height: 1rem;
      border-width: .2em;
    `,
  }, css`
    width: 2rem;
    height: 2rem;
    border-width: .25em;
  `)}
`

InlineSpinner.propTypes = {
  size: P.oneOf(['sm']),
  variant: P.oneOf(['secondary', 'white']),
}

InlineSpinner.defaultProps = {
  variant: 'secondary',
}

export const Spinner = ({ className, marginTop }) => (
  <Container marginTop={marginTop} className={className}>
    <InlineSpinner
      animation="border"
      variant="secondary"
    />
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.marginTop ===  undefined ? '200px' : p.marginTop};
`

export const FullScreenSpinner = () => (
  <FullScreenContainer marginTop={0}>
    <InlineSpinner />
  </FullScreenContainer>
)

const FullScreenContainer = styled(Container)`
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  
  // increase spinner size
  & > ${InlineSpinner} {
    width: 3rem;
    height: 3rem;
  }
`
