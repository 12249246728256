import React from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { humanizeSource } from '@lib/constants'
import { Button, FieldError, FieldStatus, FormGroup, FormSubmit, InputField, Modal, Text } from '@ui'
import { quickbooksEntitiesCreate } from '../effects/integrations'
import { $activeProcessorSettings } from '../selectors/configuration'
import { quickbooksEntitiesSchema } from '../validation-schemas'

export const QboCreateEntityModal = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const { processor } = useSelector($activeProcessorSettings)
  const processorName = humanizeSource(processor.connection_source)

  const handleSubmit = async (values, { setErrors, setStatus }) => {
    setStatus(null)
    const { successes, failures } = await dispatch(quickbooksEntitiesCreate(processor.id, values))

    setStatus(successes)
    setErrors(failures)
  }

  return (
    <Modal show={show} onClose={onClose}>
      <Formik
        initialValues={getInitialValues({ processorName })}
        onSubmit={handleSubmit}
        validationSchema={quickbooksEntitiesSchema}
      >
        <Form>
          <Modal.Title>Create Entities in Quickbooks</Modal.Title>
          <Modal.Body>
            <FormGroup>
              <InputField
                name="bankfeeds_account"
                label="Bank Account"
              />
              <Description>
                QuickBooks Sales Receipts, Refund Receipt and Payments will be deposited to this account.
              </Description>
              <FieldStatus name="bankfeeds_account" />
            </FormGroup>
            <FormGroup>
              <InputField
                name="payment_method"
                label="Payment Method"
              />
              <Description>
                This Payment Method will be set for QuickBooks Sales Receipts, Refund Receipts, Payments and Expenses.
              </Description>
              <FieldStatus name="payment_method" />
            </FormGroup>
            <FormGroup>
              <InputField
                name="vendor"
                label="Vendor"
              />
              <Description>
                This Vendor will be set for QuickBooks Expenses and Checks.
              </Description>
              <FieldStatus name="vendor" />
            </FormGroup>
            <FormGroup>
              <InputField
                name="default_customer"
                label="Customer"
              />
              <Description>
                This Default Customer will be set for QuickBooks Payments if no customer found in the transaction.
              </Description>
              <FieldStatus name="default_customer" />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
            <FormSubmit size="lg">CREATE</FormSubmit>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}

const Description = ({ children }) => (
  <Text mt="3px" variant="light" size="sm">
    {children}
  </Text>
)

const getInitialValues = ({ processorName }) => ({
  bankfeeds_account: `${processorName} Bank Account`,
  payment_method: processorName,
  // QBO doesn't allow to have a vendor and a customer with the same DisplayName
  vendor: processorName,
  default_customer: `${processorName} Customer`,
})
