import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, DangerButton, Spinner, YesNoModal } from '@ui'
import { ConnectionInfo } from '../../components/ConnectionInfo'
import { FlexCenter } from '../../components/FlexCenter'
import { NoProcessors } from '../../components/EmptyViews'
import { $isIntegrationDataLoading } from '../../selectors/integration'
import { $connectionsLimitReached, $processorsList, $sourcesIsLoading } from '../../selectors/sources'
import { useSettingsModel } from './payments.model'
import { IntegrationsHeading } from '../../components/IntegrationsHeading'
import { AddProcessorModalButton } from '../../connect/AddIntegrationModalButtons'
import { LimitReachedAlert } from '../../components/LimitReachedAlert'

export const PaymentsPage = () => {
  const limitReached = useSelector($connectionsLimitReached)

  return (
    <div className="animated fadeInRight">
      <IntegrationsHeading title="My Processors">
        {!limitReached && <AddProcessorModalButton />}
      </IntegrationsHeading>
      {limitReached && <LimitReachedAlert />}
      <ProcessorsList />
    </div>
  )
}

const ProcessorsList = () => {
  const sourcesIsLoading = useSelector($sourcesIsLoading)
  const integrationDataIsLoading = useSelector($isIntegrationDataLoading)
  const processors = useSelector($processorsList)

  if (sourcesIsLoading || integrationDataIsLoading) return <Spinner />
  if (!processors.length) return <NoProcessors />

  return (
    <ListWrapper>
      {processors.map(({ id, info, settings }) => (
        <Card
          key={settings.id}
          header={<ConnectionInfo
            name={info.name}
            createdAt={info.createdAt}
            sourceName={info.sourceName}
            status={info.status}
          />}
        >
          <ProcessorSettings processorId={id} settings={settings} />
        </Card>
      ))}
    </ListWrapper>
  )
}

const ProcessorSettings = ({ processorId, settings }) => {
  const {
    deleteProcessor,
    deleteModal,
    deleteRequest,
  } = useSettingsModel({ settings })

  return (
    <>
      <FlexCenter justify="space-between">
        <DangerButton onClick={deleteModal.show}>Delete processor</DangerButton>
        <Button variant="outline" as={Link} to={`/configuration/${processorId}`}>CONFIGURATION</Button>
      </FlexCenter>
      <YesNoModal
        title="Delete processor"
        show={deleteModal.isShown}
        loading={deleteRequest.isLoading}
        onClose={deleteModal.close}
        onConfirm={deleteProcessor}
        yesText="DELETE"
      >
        Your transactions for this account will no longer flow into BankFeeds.
      </YesNoModal>
    </>
  )
}

const ListWrapper = styled.div`
  & > :not(:first-child) {
    margin-top: 40px;
  }
`
