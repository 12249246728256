import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useActions } from '@lib/hooks'
import { Button, FormSubmit, Modal } from '@ui'
import { RuleConditionList } from './ConditionList'
import { emptyFormRule } from '../../constants'
import { exportPayoutsRuleSchema, exportRuleSchema } from '../../validation-schemas'
import { ruleCreate } from '../../effects/rules'
import { $currentRule, $rulesForCurrentRuleAccount } from '../../selectors/current-rule'
import { formDataToRule } from '../../mappers'
import { checkDuplicateConditions } from '../../validation'
import { currentRuleActions } from '../../symbiotes'

export const RuleCreateModal = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const currentRule = useActions(currentRuleActions)
  const { processorId, account } = useSelector($currentRule)
  const rules = useSelector($rulesForCurrentRuleAccount)

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const {
      [FormSubmit.SUBMIT_TYPE_FIELD]: submitType,
      ...data
    } = values

    const rule = formDataToRule(data, {
      account,
    })
    const errors = checkDuplicateConditions(rule, rules)

    if (errors.length > 0) {
      return setErrors({
        conditions: errors,
      })
    }

    await dispatch(ruleCreate({ processorId, rule, account }))

    switch (submitType) {
      case 'addMore':
        resetForm(emptyFormRule)
        break
      case 'save':
        handleClose()
        break
      default:
    }
  }

  const handleClose = () => {
    currentRule.reset()
    onClose()
  }

  return (
    <Modal show={show} onClose={handleClose} size="lg">
      <Formik
        initialValues={emptyFormRule}
        validationSchema={account === 'payouts_account' ? exportPayoutsRuleSchema : exportRuleSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Modal.Title>Rule creation</Modal.Title>
          <Modal.Body>
            <RuleConditionList account={account} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              CANCEL
            </Button>
            <FormSubmit variant="outline" submitType="addMore">
              SAVE AND ADD MORE
            </FormSubmit>
            <FormSubmit size="lg" submitType="save">
              SAVE
            </FormSubmit>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}
