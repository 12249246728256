import styled from 'styled-components'
import * as P from 'prop-types'

export const StatusLabel = styled.div`
  background-color: ${({ theme, variant }) => theme.status[variant]};
  max-width: 130px;
  font-weight: 600;
  padding: 14px 0;
  text-align: center;
  text-transform: uppercase;
`

StatusLabel.propTypes = {
  variant: P.oneOf(['done', 'queue', 'progress', 'failed', 'skipped']).isRequired,
}
