import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'
import { useMultiSelectionHeader } from '@lib/table-hooks'
import { filterEmpty } from '@lib/object'
import { groupBy } from '@lib/array'
import { exportStatusToStatuses } from '@lib/mappers'
import { Storage } from '@lib/storage'
import { useQueryParams, useSyncQueryParams } from '@lib/hooks'
import { defaultFilters, defaultSort, pageSizes } from '../constants'
import { transactionsFetch } from '../effects'
import { $pageCount, $transactionsForTable, $transactionsList } from '../selectors'
import { CellDescription, CellId, CellStatus } from '../components/Cells'
import {
  AmountFilter,
  CurrencyFilter,
  DateFilter,
  ProcessorFilter,
  StatusFilter,
  TypeFilter,
} from '../components/TableFilters'
import { useMassActions } from '../hooks'

export const useModel = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const transactions = useSelector($transactionsList)
  const filtersFromQuery = useQueryParams()
  const table = useTransactionsTable(useMemo(() =>
    ungroupFilters(filtersFromQuery), [filtersFromQuery]),
  )

  const {
    gotoPage,
    state: { pageIndex, pageSize, sortBy, filters, globalFilter: selectionType, selectedRowIds },
  } = table

  const selectedTransactions = useMemo(
    () => Object.keys(selectedRowIds).map((index) => transactions[index]).filter(Boolean),
    [selectedRowIds, transactions],
  )

  useSyncQueryParams(useMemo(() => {
    const { source, payment_type, status, currency } = groupBy(filters, ({ id, value }) => [id, value])

    return { source, payment_type, status, currency }
  }, [filters]))

  const queryFilters = useMemo(
    () => {
      const filtersById = groupBy(filters, ({ id, value }) => [id, value])
      const { processed_at, source, payment_type, status = [], amount, currency } = filtersById

      return filterEmpty({
        search,
        statuses: [].concat(...Array.from(status).map(exportStatusToStatuses)),
        date: processed_at,
        types: payment_type,
        sources: source,
        amount,
        currency,
      })
    },
    [filters, search],
  )

  const dispatchUpdate = useCallback(
    () => {
      const params = filterEmpty({
        page: pageIndex + 1,
        pageSize,
        sort: sortBy[0],
        filters: queryFilters,
      })

      dispatch(transactionsFetch(params))
    },
    [pageIndex, pageSize, sortBy, queryFilters, dispatch],
  )

  const massActions = useMassActions({
    selectedTransactions,
    selectionType,
    filters: queryFilters,
    onDone: dispatchUpdate,
  })

  useEffect(() => {
    dispatchUpdate()
  }, [dispatchUpdate])

  // reset current page on filters change
  useEffect(() => {
    gotoPage(0)
  }, [filters, gotoPage])

  useEffect(() => {
    Storage.set('transactions-pageSize', pageSize)
  }, [pageSize])

  return {
    table,
    setSearch,
    massActions,
  }
}

const ungroupFilters = (filters) => Object.keys(filters).map((filter) => ({ id: filter, value: filters[filter] }))

const useTransactionsTable = (initialFilters) => useTable(
  {
    columns: useColumns(),
    data: useSelector($transactionsForTable),
    pageCount: useSelector($pageCount),
    manualSortBy: true,
    manualPagination: true,
    manualGlobalFilter: true,
    manualFilters: true,
    defaultColumn: useMemo(() => ({
      Filter: () => null,
      filter: defaultFilter,
    }), []),
    initialState: {
      pageSize: Storage.get('transactions-pageSize', pageSizes.transactions),
      sortBy: [defaultSort.transactions],
      filters: useMemo(() =>
        initialFilters || defaultFilters.transactions, 
      [initialFilters],
      ),
    },
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useMultiSelectionHeader,
)

// create custom filter
const defaultFilter = (x) => x
// auto remove filter from table state only when it gets undefined
defaultFilter.autoRemove = (val) => val === undefined

const useColumns = () => useMemo(() => ([
  { accessor: 'id', width: 20, Header: '#ID', Cell: CellId, disableFilters: true },
  { accessor: 'processed_at', width: 190, Header: 'Date', Filter: DateFilter },
  { accessor: 'source', width: 120, Header: 'Processor', Filter: ProcessorFilter },
  { accessor: 'payment_type', width: 120, Header: 'Type', Filter: TypeFilter },
  { accessor: 'description', width: 200, Header: 'Description', Cell: CellDescription,  disableSorting: true, disableFilters: true },
  { accessor: 'status', width: 100, Header: 'Status', Filter: StatusFilter, Cell: CellStatus },
  { accessor: 'amount', width: 140, Header: 'Amount', Filter: AmountFilter },
  { accessor: 'currency', width: 120, Header: 'Currency', Filter: CurrencyFilter },
]), [])
