import * as P from 'prop-types'
import styled, { css } from 'styled-components'
import { prop, theme } from 'styled-tools'
import space from '@styled-system/space'
import { spacePT, headingVariantsPT } from './prop-types'

const base = css`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  text-align: ${prop('align')};
  color: ${(p) => theme(`heading.${p.variant}`, p.variant || 'inherit')};

  ${space};
`

export const H1 = styled.h1`
  ${base};
  line-height: 1.2;
  font-size: 32px;
`

export const H2 = styled.h2`
  ${base};
  font-size: 20px;
  line-height: 1.55;
`

export const H3 = styled.h3`
  ${base};
  font-size: 15px;
  line-height: 1.55;
`

export const H4 = styled.h4`
  ${base};
  font-size: 14px;
  line-height: 1.55;
`

export const H5 = styled.h5`
  ${base};
  font-size: 14px;
  line-height: 1.3;
`

export const H6 = styled.h6`
  ${base};
  font-size: 12px;
  line-height: 1.55;
`

const propTypes = {
  variant: P.oneOfType([headingVariantsPT, P.string]),
  align: P.oneOf(['left', 'center', 'right']),
  ...spacePT,
}

H1.propTypes = propTypes
H2.propTypes = propTypes
H3.propTypes = propTypes
H4.propTypes = propTypes
H5.propTypes = propTypes
H6.propTypes = propTypes
