import dayjs from 'dayjs'
import { importStatsTypes } from '@lib/constants'
import { arrayTotal } from '@lib/array'
import { percent } from '@lib/convert'

export const getPercentByStatsType = ({ stats, statsType, from, to }) => {
  if (!stats.length) return 0

  switch (statsType) {
    case importStatsTypes.AMOUNT: {
      const processed = arrayTotal(stats, ({ processed }) => processed)
      const total = arrayTotal(stats, ({ total }) => total)

      return percent(processed, total)
    }

    case importStatsTypes.DATE: {
      const { processedUntil } = stats[0]
      if (!processedUntil) return 0

      const totalHours = Math.abs(dayjs(to).diff(from, 'hour'))
      const processedHours = dayjs(to).diff(processedUntil, 'hour')

      return percent(processedHours, totalHours)
    }

    default:
      return 0
  }
}
