import { useEffect, useRef } from 'react'

export const useSkipFirstRunEffect = (cb, deps) => {
  const firstRun = useRef(true)

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    cb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
