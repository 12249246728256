import React from 'react'
import * as yup from 'yup'
import { Form as FormikForm, Formik } from 'formik'
import { Button, ErrorLabel, FormGroup, FormInput, InputLabel, LoadingButton, Modal } from '@ui'

export const WooCommerceConnectModal = ({ show, onClose, onSubmit, isLoading }) => (
  <Formik
    initialValues={{ domain: '' }}
    onSubmit={({ domain }) => onSubmit(domain)}
    validationSchema={
      yup.object().shape({
        domain: yup.string().required('Website url is required'),
      })
    }
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
    }) => (
      <Modal show={show} onClose={onClose}>
        <Modal.Title>Add new connection</Modal.Title>
        <FormikForm>
          <Modal.Body>
            <FormGroup>
              <InputLabel>Please enter your Wordpress website url. (ex. mywebsite.com)</InputLabel>
              <FormInput
                name="domain"
                value={values.domain}
                placeholder="Wordpress website url"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.domain && errors.domain && (
                <ErrorLabel>{errors.domain}</ErrorLabel>
              )}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="md" onClick={onClose}>CANCEL</Button>
            <LoadingButton size="md" type="submit" loading={isLoading}>ADD</LoadingButton>
          </Modal.Footer>
        </FormikForm>
      </Modal>
    )}
  </Formik>
)
