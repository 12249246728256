import { request } from '@features/common'

export const xeroApi = {
  createBankAccount: (currency, sourceId) => request('POST', '/xero/create-bank-account', {
    data: { currency, sourceId },
  }),
}

export const qboApi = {
  createEntities: (processorId, values) => request('POST', '/quickbooks/entities', {
    data: {
      processorId,
      ...values,
    },
  }),
}

export const integrationsApi = {
  disconnect: () => request('POST', '/integrations/disconnect'),

  getSettings: () => request('GET', '/integrations/settings'),

  updateSettings: (settingsData) => request('POST', '/integrations/settings', {
    data: settingsData,
  }),

  getEntities: (types = null) => request('GET', '/integrations/entities', {
    params: { types },
  }),
}

export const sourcesApi = {
  disconnect: (id) => request('POST', `/disconnect/${id}`),

  getList: () => request('GET', '/sources'),

  updateSettings: (sourceId, integration, settingsData) =>
    request('PUT', `/sources/${sourceId}/settings`, {
      params: { integration },
      data: settingsData,
    }),
}

export const rulesApi = {
  create: ({ processorId, account, position, rule }) =>
    request('POST', `/sources/${processorId}/settings/rules`, {
      params: { account, position },
      data: rule,
    }),

  update: ({ processorId, account, position, rule }) =>
    request('PUT', `/sources/${processorId}/settings/rules`, {
      params: { account, position },
      data: rule,
    }),

  remove: ({ processorId, account, position }) =>
    request('DELETE', `/sources/${processorId}/settings/rules`, {
      params: { account, position },
    }),
}
