import React from 'react'
import { Table, TableBody, TableHead, FilterableHeader, SelectableRow } from '@ui'

export const TransactionsTable = ({ table }) => (
  <Table {...table.getTableProps()}>
    <TableHead
      headerGroups={table.headerGroups}
      renderColumnHeader={(column) => (
        <FilterableHeader
          key={column.id}
          column={column}
        />
      )}
    />
    <TableBody
      rows={table.rows}
      prepareRow={table.prepareRow}
      renderRow={(row) => (
        <SelectableRow
          key={row.index}
          row={row}
          isSelected={row.isSelected}
        />
      )}
    />
  </Table>
)
