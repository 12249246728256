import { combineReducers } from 'redux'
import { reducer as entitiesReducer, actions as entitiesActions } from './entities'
import { reducer as settingsReducer, actions as settingsActions } from './settings'
import { reducer as taxRatesReducer, actions as taxRatesActions } from './taxRates'

export const integrationReducer = combineReducers({
  entities: entitiesReducer,
  settings: settingsReducer,
  taxRates: taxRatesReducer,
})

export { entitiesActions, settingsActions, taxRatesActions }
