import { createSelector } from 'reselect'
import { createFetchingSelectors } from '@lib/fetching'
import { humanizeType } from '@lib/constants'
import { amount as convertAmount } from '@lib/convert'
import { date } from '@lib/date'
import { $userDatePrefs, buildTransactionLink } from '@features/common'

const $root = (root) => root.importingTransactions

export const $transactionsList = createSelector(
  $root,
  ({ list }) => list,
)

export const $pageCount = createSelector(
  $root,
  ({ pageCount }) => pageCount,
)

export const $totalCount = createSelector(
  $root,
  ({ totalCount }) => totalCount,
)

export const $count = createSelector(
  $root,
  ({ count }) => count,
)

export const $fetching = createSelector(
  $root,
  ({ fetching }) => fetching,
)

export const { $isLoading } = createFetchingSelectors($fetching)

export const $isInitialLoading = createSelector(
  $isLoading,
  $transactionsList,
  (isLoading, transactionsList) => isLoading && transactionsList.length === 0,
)

export const $transactionsForTable = createSelector(
  $transactionsList,
  $userDatePrefs,
  (transactions, { timezone, dateTimeFormat }) =>
    transactions.map(({ processed_at, source, payment_type, description, amount, currency, metadata }) => ({
      processed_at: date(processed_at, timezone, dateTimeFormat),
      payment_type: humanizeType(payment_type),
      description: buildTransactionLink({ source, metadata, value: description }),
      amount: convertAmount(amount, currency),
    })),
)
