import React from 'react'
import { MainTemplate } from '@features/common'
import { FilterSearch, Flex, Interactivity } from '@ui'
import { UsersTable } from '../components/UsersTable'
import { useModel } from './users.model'

export const UsersPage = () => {
  const {
    table,
    count,
    setSearch,
    isLoading,
  } = useModel()

  return (
    <MainTemplate>
      <Flex my="1rem">
        <FilterSearch onChange={setSearch} />
      </Flex>
      <Interactivity off={isLoading}>
        <UsersTable
          table={table}
          isLoading={isLoading}
          count={count}
        />
      </Interactivity>
    </MainTemplate>
  )
}
