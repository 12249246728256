import React from 'react'
import { useField } from 'formik'
import { FormCheck } from '../Form/Check'

export const CheckField = ({
  name,
  label,
  disabled,
}) => {
  const [
    { onChange },
    { touched, error, value },
  ] = useField({
    name,
  })

  return (
    <FormCheck
      name={name}
      label={label}
      disabled={disabled}
      checked={value}
      error={touched && error}
      onChange={onChange}
    />
  )
}
