import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { ErrorLabel } from '../Label'
import { checkPropTypes } from './propTypes'
import { HiddenInput, InputContainer, InputLabel, StyledInput } from './Controls'

export const FormRadio = ({
  name,
  label,
  error,
  checked,
  onChange,
  onBlur,
  title,
  disabled,
  reversed,
  block,
  className,
}) => (
  <>
    <InputContainer
      title={title}
      block={block}
      disabled={disabled}
      className={className}
    >
      <HiddenInput
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <StyledRadio checked={checked}>
        <RadioIcon />
      </StyledRadio>
      {label && (
        <InputLabel
          reversed={reversed}
          block={block}
        >
          {label}
        </InputLabel>
      )}
    </InputContainer>
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </>
)

FormRadio.propTypes = {
  ...checkPropTypes,
}

const RadioIcon = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: ${theme('color.primary')};
  border-radius: 10px;
  top: 2px;
  left: 2px;
`

const StyledRadio = styled(StyledInput)`
  width: 14px;
  height: 14px;
  border: 1px solid ${theme('color.gray')};
  border-radius: 10px;
  position: relative;

  ${HiddenInput}:disabled + & {
    opacity: 0.5;
    background-color: ${theme('color.secondary')};
  }

  ${ifProp('checked', css`
    border-color: ${theme('color.primary')};
  `)}

  ${RadioIcon} {
    visibility: ${ifProp('checked', 'visible', 'hidden')}
  }
`
