import '@lib/yup-extentions'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@lib/auth0'
import { theme } from '@lib/theme'
import { Auth0Loader } from '@features/session'
import { App } from './App'
import { configureStore } from './store'
import { auth0Config } from './auth0-config'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Auth0Provider {...auth0Config}>
        <Auth0Loader>
          <App />
        </Auth0Loader>
      </Auth0Provider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
