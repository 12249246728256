import moment from 'moment-timezone'

export const date = (value, timezone, format = 'MMM Do YYYY h:mm a') => moment(value).tz(timezone).format(format)

/**
 * Converts given date to utc date using the timezone.
 * @param date
 * @param timezone
 * @returns {Date}
 */
export const toUtcDate = (date, timezone) => {
  const offsetMins = moment.tz.zone(timezone).utcOffset(moment(date))
  return moment(date).add(offsetMins, 'minutes').toDate()
}

/**
 * Converts given utc date to a timezone specific date.
 * @param date
 * @param timezone
 * @returns {Date}
 */
export const fromUtcDate = (date, timezone) => {
  const offsetMins = moment.tz.zone(timezone).utcOffset(moment(date))
  return moment(date).subtract(offsetMins, 'minutes').toDate()
}
