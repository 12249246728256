import React from 'react'
import { FieldErrorMark, VisibleForField } from '@ui'
import { CustomSwitchField, ErrorMarkContainer } from './shared'

export const Track = ({ name, account, label }) => (
  <CustomSwitchField
    name={name}
    label={
      <ErrorMarkContainer>
        {label}
        <VisibleForField
          name={name}
          fallback={<FieldErrorMark name={account} />}
        />
      </ErrorMarkContainer>
    }
  />
)
