import React, { useState } from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, FormGroup, FormSwitch, H2, InputLabel, Link, SelectField, Spinner, Text } from '@ui'
import { qboEntityTypes } from '@lib/constants'
import { InputTemplate } from './shared'
import { useAccountPlaceholder } from '../../hooks'
import { $activeProcessorSettings } from '../../selectors/configuration'
import { $isEntitiesLoading, $isIntegrationExpired } from '../../selectors/integration'
import { integrationEntityFetch } from '../../effects/integrations'
import { $taxOptions } from '../../selectors/quickbooks-entities'

export const QboTaxRates = () => {
  const dispatch = useDispatch()
  const { processor } = useSelector($activeProcessorSettings)
  const taxRatesError = processor.taxRates.error
  const isEntitiesLoading = useSelector($isEntitiesLoading)
  const [showTaxesAlert, setShowTaxesAlert] = useState(false)
  const [, {
    value: taxesEnabled,
  }, {
    setValue: setTaxesEnabled,
  }] = useField('taxes_enabled')

  const onTaxesEnabledChange = async (e) => {
    const { checked } = e.target
    setTaxesEnabled(checked)
    setShowTaxesAlert(false)

    if (checked) {
      const { preferences } = await dispatch(integrationEntityFetch(qboEntityTypes.PREFERENCES))

      if (!preferences.taxesEnabled) {
        setTaxesEnabled(false)
        setShowTaxesAlert(true)
      }
    }
  }

  return (
    <InputTemplate>
      <TitleContainer>
        <H2 mb="8px">Tax Mapping</H2>
        <FormSwitch
          reversed
          label="Enable taxes"
          checked={taxesEnabled}
          onChange={onTaxesEnabledChange}
        />
      </TitleContainer>
      {isEntitiesLoading && <Spinner marginTop="24px" />}
      {showTaxesAlert && (
        <Alert noButton type="warning">
          Taxes is not enabled in Quickbooks. Please enable them{' '}
          <Link href="https://app.qbo.intuit.com/app/tax/home" target="_blank">here</Link>.
        </Alert>
      )}
      {taxRatesError && (
        <Text variant="danger">Error retrieving tax rates.</Text>
      )}
      {taxesEnabled && !isEntitiesLoading && <TaxesMappingList />}
    </InputTemplate>
  )
}

const TaxesMappingList = () => {
  const taxRatePlaceholder = useAccountPlaceholder('Select a Quickbooks tax rate...')
  const isIntegrationExpired = useSelector($isIntegrationExpired)
  const taxOptions = useSelector($taxOptions)

  return taxOptions.map(({ id, displayName, description, options }) => (
    <FormGroup key={id}>
      <InputLabel>
        {displayName}
        {!!description && <p>{description}</p>}
      </InputLabel>
      <SelectField
        name={`tax_mapping[${id}]`}
        placeholder={taxRatePlaceholder}
        options={options}
        disabled={isIntegrationExpired}
        isClearable
      />
    </FormGroup>
  ))
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
