import React from 'react'
import styled from 'styled-components'
import { pageSizes } from '../../lib/constants'
import { Input, Select } from '../index'

const SelectWrapper = styled.span`
  display: inline-block;
  width: 75px;
  margin: 0 10px;
  
  ${Input} {
    height: 32px;
  }
`

export const PaginationStats = ({ pagination }) => {
  const { count, pageSize, setPageSize } = pagination

  if (count === 0) {
    return 'No results found'
  }

  return (
    <div>
      Show on page:
      <SelectWrapper>
        <Select
          options={pageSizeOptions}
          value={pageSize}
          menuPosition="top"
          onChange={(value) => setPageSize(value)}
        />
      </SelectWrapper>
      out of ({count || 0} items)
    </div>
  )
}

const pageSizeOptions = pageSizes.map((value) => ({ label: value, value }))
