import t from 'timestamp-utils'
import { range } from '@lib/array'

const DAYS_PER_VIEW = 42
const MONTHS_LENGHT = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const now = () => new Date().getTime()
export const decompose = (ts) => t.decompose(ts).map(Number)

export const fillDays = (firstDay) => range(DAYS_PER_VIEW).map((idx) => t.addDays(firstDay, idx))

export const initMonth = (timestamp = now()) => {
  let [year, month, day] = decompose(timestamp)

  const firstDay = resetTime(t.addDays(timestamp, -day + 1))
  const daysInMonth = getDaysInMonth(month, year)
  const lastDay = t.addDays(firstDay, daysInMonth - 1)
  const firstDayNumber = t.getWeekDay(firstDay)
  const firstDayToShow = t.addDays(firstDay, -firstDayNumber - 1)

  return {
    daysInMonth,
    firstDay,
    lastDay,
    firstDayToShow,
    month,
    year,
  }
}

export const parseRange = (startDate, endDate) => {
  // Allow setting up equal dates
  if (startDate === endDate) return [startDate, endDate]
  return [
    endDate ? startDate ? Math.min(startDate, endDate) : null : startDate,
    endDate && (endDate !== startDate) ? Math.max(startDate, endDate) : null,
  ]
}

export const resetTime = (timestamp = 0) => {
  const [,,, hours, minutes, seconds, milliseconds] = decompose(timestamp)
  return t.add(timestamp, { hours: -hours, minutes: -minutes, seconds: -seconds, milliseconds: -milliseconds })
}

/**
 * Formats timestamp like: 2020-03-03 23:59:59
 */
export const setMaxDayTime = (timestamp) => {
  if (!timestamp) return timestamp
  return t.add(timestamp, { hours: 23, minutes: 59, seconds: 59 })
}

export const isBetween = (date, start, end) => date > start && date < end
export const isOut = (date, start, end) => date < start || date > end
export const formatTime = (value) => (`0${value}`).slice(-2)
export const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

const getDaysInMonth = (month, year) => MONTHS_LENGHT[month - 1] || (isLeapYear(year) ? 29 : 28)
