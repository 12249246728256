import React, { useEffect, useState, createContext, useContext } from 'react'
import auth0 from 'auth0-js'

const Auth0WebContext = createContext(null)

export const useAuth0Web = () => useContext(Auth0WebContext)

export const Auth0WebProvider = ({ children }) => {
  const [auth0Context, setAuth0Context] = useState(null)

  useEffect(() => {
    const newContext = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      responseType: 'code',
    })

    setAuth0Context(newContext)
  }, [])

  if (!auth0Context) {
    return null
  }

  return (
    <Auth0WebContext.Provider value={auth0Context}>
      {children}
    </Auth0WebContext.Provider>
  )
}