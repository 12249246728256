import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest } from '@lib/fetching'
import { useBoolean } from '@lib/hooks'
import { notify } from '@lib/notification'
import { $currentUser, $userDatePrefs } from './selectors'
import { linkAutoClick } from './lib/link-auto-click'
import { userApi } from './api'

export const usePagination = (initPage, initPageSize, count) => {
  const [page, setPage] = useState(initPage)
  const [pageSize, setPageSize] = useState(initPageSize)

  const pageCount = Math.ceil(count / pageSize)
  const canPreviousPage = page > 1
  const canNextPage = page < pageCount

  const gotoPage = useCallback((newPage) => {
    if (newPage > 0 && newPage <= pageCount) {
      setPage(newPage)
    }
  }, [pageCount])

  const previousPage = () => {
    if (canPreviousPage) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (canNextPage) {
      setPage(page + 1)
    }
  }

  return { gotoPage, previousPage, nextPage, setPageSize, page, pageSize, pageCount, count, canNextPage, canPreviousPage }
}

export const useSorting = (initField, initDirection = undefined) => {
  const [values, setValues] = useState({
    field: initField,
    direction: initDirection,
  })

  const set = (field, direction) => {
    setValues({
      field,
      direction,
    })
  }

  const clear = () => {
    setValues({
      field: undefined,
      direction: undefined,
    })
  }

  const { field, direction } = values

  return { set, clear, values, field, direction }
}

export const useUserDatePrefs = () => (
  useSelector($userDatePrefs)
)

export const useModal = (initialShown = false) => {
  const { val, on, off } = useBoolean(initialShown)
  return { isShown: val, show: on, close: off }
}

export const useRouteGuards = (routes) => {
  const user = useSelector($currentUser)

  return useMemo(
    () => routes.filter((route) => (
      route.guard
        ? route.guard({ user })
        : true
    )),
    [routes, user],
  )
}

export const useConnectRequest = (handler, { redirect = false } = { }) => {
  const [clicked, setClicked] = useState(false)
  const request = useRequest(handler, {
    fail(error) {
      notify({
        type: 'danger',
        message: error.error || error.message,
      })
    },
  })

  const connectRequest = async (...args) => {
    const url = await request(...args)
    if (redirect) {
      window.location.href = url
    } else {
      linkAutoClick(url)
    }

    setClicked(true)
  }

  connectRequest.isLoading = request.isLoading || clicked

  return connectRequest
}

export const useXeroConnectRequest = () => useConnectRequest(userApi.xeroGetConnectUrl)
export const useQuickbooksConnectRequest = () => useConnectRequest(userApi.quickbooksGetConnectUrl)
