import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useSelector } from 'react-redux'
import { Button, Flex, H4 } from '@ui'
import { CancelImportModalButton } from './Modals'
import { $currentImport } from '../selectors/current-import'
import { $count } from '../selectors/transactions'

export const ResultsBlock = ({ onImportClick }) => {
  const count = useSelector($count)
  const { types } = useSelector($currentImport)

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <H4>{count} TRANSACTIONS</H4>
        <Flex align="center">
          <Button size="lg" onClick={onImportClick} disabled={!count || !types.length}>IMPORT</Button>
          <CancelContainer>
            <CancelImportModalButton buttonText="CANCEL" />
          </CancelContainer>
        </Flex>
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 24px;
  padding: 30px 24px;
  border-radius: 3px;
  background-color: ${theme('color.activeLight')};
`

const CancelContainer = styled.span`
  margin-left: 8px;
`
