import { request } from '@features/common'

export const api = {
  getList: (params) => request('GET', '/import-jobs', { params }),

  getActive: () => request('GET', '/import-jobs/active'),

  create: (data) => request('POST', '/import-jobs', { data }),

  complete: (id, { types, duplicateCheck, exportEnabled }) => request('PUT', `/import-jobs/${id}/complete`, {
    data: { types, duplicateCheck, exportEnabled },
  }),

  cancel: (id) => request('POST', `/import-jobs/${id}/cancel`),

  getStatsForLatest: () => request('GET', '/import-jobs/latest/stats'),
}

window.api = api
