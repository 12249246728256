import { createSymbiote } from 'redux-symbiote'
import { sources, transactionTypes } from '@lib/constants'

const initialState = {
  startDate: null,
  endDate: null,
  duplicateCheck: true,
  processor: sources.STRIPE,
  connection: null,
  types: transactionTypes,
  exportEnabled: true,
}

const symbiotes = {
  setInitial: (state, { startDate, endDate, connection, processor }) => ({
    ...state,
    startDate,
    endDate,
    connection,
    processor,
  }),

  reset: (state) => ({
    ...initialState,
    connection: state.connection,
  }),

  resetDates: (state) => ({
    ...state,
    startDate: null,
    endDate: null,
  }),

  setDates: (state, [startDate, endDate]) => ({
    ...state,
    startDate,
    endDate,
  }),

  setDuplicateCheck: (state, duplicateCheck) => ({
    ...state,
    duplicateCheck,
  }),

  setConnection: (state, connection) => ({
    ...state,
    connection,
  }),

  setProcessor: (state, processor) => ({
    ...state,
    processor,
  }),

  setTypes: (state, types) => ({
    ...state,
    types,
  }),

  setExportEnabled: (state, exportEnabled) => ({
    ...state,
    exportEnabled,
  }),
}

export const {
  actions,
  reducer,
} = createSymbiote(
  initialState,
  symbiotes,
  'currentImport',
)
