import { useEffect, useRef } from 'react'

export const useLatest = (obj) => {
  const ref = useRef(obj)

  useEffect(() => {
    ref.current = obj
  }, [obj])

  return ref
}
