import React from 'react'
import { labelDays } from './constants'
import { DayBody, DayHeader, DayView, MonthWrapper, WeekDay } from './MonthView.styled'

export const MonthView = ({ daysData, monthIdx, getDayProps }) => (
  <MonthWrapper>
    <DayHeader>
      {labelDays.map((day) => (
        <WeekDay key={day}>
          {day}
        </WeekDay>
      ))}
    </DayHeader>
    <DayBody>
      {daysData.map(({ day }, dayIdx) => (
        <DayView
          {...getDayProps({ dayIdx, monthIdx })}
        >
          {day}
        </DayView>
      ))}
    </DayBody>
  </MonthWrapper>
)
