import { request } from './request'

export const userApi = {
  login: () => request('POST', '/login'),

  resendEmail: () => request('POST', '/email/resend'),

  sendMfaCode: () => request('POST', '/mfa/request'),

  confirmMfaCode: (code) => request('POST', '/mfa/confirm', { data: { code } }),

  xeroGetConnectUrl: () => request('GET', '/xero/connect').then(({ authUrl }) => authUrl),

  quickbooksGetConnectUrl: () => request('GET', '/quickbooks/connect').then(({ authUrl }) => authUrl),
}

export const connectApi = {
  connectStripe: () => request('GET', '/stripe/connect').then(mapUrl),

  connectGocardless: () => request('GET', '/gocardless/connect').then(mapUrl),

  connectShopify: (shop) => request('GET', '/shopify/connect', {
    params: { shop },
  }).then(mapUrl),

  connectPin: (token) => request('POST', '/pin/connect', { data: { token } }),

  connectWooCommerce: (domain) => request('GET', 'woocommerce/connect', {
    params: { domain },
  }).then(mapUrl),
}

const mapUrl = ({ url }) => url
